import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {SourceChannelId, SourceDataEnabledState, SourceDataEnabledStateMap, State} from "./monitoringStoreStates";

interface Values { [ sourceChannel: string]: boolean; }

const Empty: SourceDataEnabledState = {};

export default class SetSourceDataEnabled
{
    public static action (id: string, sourceChannel: SourceChannelId, enabled: boolean): Modifier<State>
    {
        const values = { [sourceChannel]: enabled };
        return this.setMultiple(id, values);
    }

    public static setMultiple(id: string, values: Values): Modifier<State>
    {
        return (state: State) =>
        {
            const sourceDataEnabledState: Editable<SourceDataEnabledState> =
            {
                ...state.sourceDataEnabledState[id],
            };

            for (const sourceChannel in values)
            {
                sourceDataEnabledState[sourceChannel] = values[sourceChannel];
            }

            return {sourceDataEnabledState: {...state.sourceDataEnabledState, [id]: sourceDataEnabledState}};
        }
    }

    public static getEnabled(id: string, input: SourceDataEnabledStateMap)
    {
        return input[id] || Empty;
    }
}