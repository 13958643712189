import Color from "color";
import React from "react";
import monitoringStore from "./monitoringStore";
import {ChannelTypeId, SourceChannelId, SourceChannelInfoBase, State} from "./monitoringStoreStates";

interface CachePair
{
    base: SourceChannelInfoBase;
    info: SourceChannelInfo;
}
interface InfoCache
{
    [sourceChannel: string]: CachePair;
}

interface UnknownCache
{
    [sourceChannel: string]: SourceChannelInfoBase;
}

export class SourceChannelInfoManager
{
    private static infoCache: InfoCache = {};
    private static unknownCache: UnknownCache = {};

    public static get(channel: SourceChannelId, state: State = monitoringStore.state()): SourceChannelInfo
    {
        const base = state.sourceChannelInfo[channel] || this.createUnknownBase(channel);

        const existingResult = this.infoCache[channel];
        if (existingResult != null && existingResult.base === base)
        {
            return existingResult.info;
        }

        const info = new SourceChannelInfo(base);
        this.infoCache[channel] = {base, info};
        return info;
    }

    private static createUnknownBase(channel: SourceChannelId): SourceChannelInfoBase
    {
        let result = this.unknownCache[channel];
        if (result)
        {
            return result;
        }

        result = {
            sourceChannel: channel,
            channelType: 'unknown' as ChannelTypeId,
            mainName: channel,
            subscriptName: '',
            mainColour: '#596570'
        }
        this.unknownCache[channel] = result;
        return result;
    }
}

export class SourceChannelInfo
{
    public readonly base: SourceChannelInfoBase;
    public readonly mainColour: Color;
    public readonly minMaxColour: Color;
    public readonly nameSimple: string;
    public readonly nameSubscript: JSX.Element;

    constructor(sourceChannelInfoBase: SourceChannelInfoBase)
    {
        this.base = sourceChannelInfoBase;
        this.mainColour = Color(this.base.mainColour);

        this.minMaxColour = SourceChannelInfo.getMinMaxColour(this.mainColour);

        this.nameSimple = this.base.mainName + ' ' + this.base.subscriptName;
        this.nameSubscript = <>{this.base.mainName} <sub>{this.base.subscriptName}</sub></>;
    }

    public getFrequencyColour(frequency: number, type: 'mean' | 'min' | 'max')
    {
        const hueOffset = frequency * 0.5;
        const saturationOffset = Math.log10(frequency) / 10;
        const lightenOffset = type === 'mean' ? 0 : (type === 'min' ? -0.1 : 0.1);
        return this.mainColour.rotate(hueOffset).lighten(lightenOffset).saturate(saturationOffset).rgb();
    }

    public static getMinMaxColour(colour: Color)
    {
        if (colour.lightness() > 0.5)
        {
            return colour.darken(0.2).rotate(-20).saturate(-0.2).fade(0.3);
        }
        else
        {
            return colour.lighten(0.2).rotate(20).saturate(0.2).fade(0.3);
        }
    }
}