import {ignoreValue} from "../components/lineChart/lineChartCommon";
import {WebGLDataSeries, WebGLValueRange} from "../components/webglChart/webglChartStore";
import {createLineDataSeries, createMinMaxDataSeries, makeValueRange} from "../components/webglChart/webglUtils";
import {Editable} from "../utils/commonTypes";
import {MicroSeconds, microToMoment, ZeroMicro} from "../utils/timeTypes";
import {findSource} from "../utils/utils";
import monitoringStore from "./monitoringStore";
import {ChannelTypeId, PreviewLookupResult, SourceChannelId, SourceChannelToWebGLDataSourceMap, SourceChannelToWebGLPreviewMap, SourceData, SourceDataPreview, SourceId, SourceToSourceChannelToWebGLDataSourceMap, SourceToSourceChannelToWebGLPreviewMap} from "./monitoringStoreStates";
import {SourceChannelInfoManager} from "./sourceChannelInfo";

interface SourceDataMap {readonly [sourceId: string]: SourceData[]}
export function processSourceDatas(input: SourceDataMap): SourceToSourceChannelToWebGLDataSourceMap
{
    const dataLookupDataSources: Editable<SourceToSourceChannelToWebGLDataSourceMap> = {};

    for (const sourceIdString in input)
    {
        const channelDataSources: Editable<SourceChannelToWebGLDataSourceMap> = {};
        dataLookupDataSources[sourceIdString] = channelDataSources;

        const sourceDatas = input[sourceIdString];

        for (const sourceData of sourceDatas)
        {
            const payload = sourceData.payload;

            for (const sourceChannel in payload)
            {
                const sourceDataInfo = SourceChannelInfoManager.get(sourceChannel as SourceChannelId);
                const channelType = sourceDataInfo.base.channelType;
                const list = getChartDataPairWebGL(channelDataSources, channelType);
                addDataSourceWebGL(sourceData, sourceChannel as SourceChannelId, list);
            }
        }
    }

    return dataLookupDataSources;
}

export function getSourceDatasTimeSpan(input: SourceDataMap)
{
    let start = Number.MAX_SAFE_INTEGER as MicroSeconds;
    let end = ZeroMicro;
    for (const sourceIdString in input)
    {
        const sourceDatas = input[sourceIdString];
        for (const sourceData of sourceDatas)
        {
            if (sourceData.time < start)
            {
                start = sourceData.time;
            }
            if (sourceData.time > end)
            {
                end = sourceData.time;
            }
        }
    }

    return {start: microToMoment(start), end: microToMoment(end).add(1, 'second')}
}

export function getPreviewTimeSpan(previewLookup: PreviewLookupResult)
{
    let start = Number.MAX_SAFE_INTEGER as MicroSeconds;
    let end = ZeroMicro;

    for (const sourceIdString in previewLookup.previews)
    {
        const previews = previewLookup.previews[sourceIdString];
        for (const preview of previews)
        {
            if (preview.time < start)
            {
                start = preview.time;
            }
            if (preview.time > end)
            {
                end = preview.time;
            }
        }
    }

    return {start: microToMoment(start), end: microToMoment(end).add(1, 'hour')}
}

export function processSourceDataWebGL(sourceData: SourceData): SourceChannelToWebGLDataSourceMap
{
    const channelDataSources: Editable<SourceChannelToWebGLDataSourceMap> = {};

    const payload = sourceData.payload;

    for (const sourceChannel in payload)
    {
        const sourceDataInfo = SourceChannelInfoManager.get(sourceChannel as SourceChannelId);
        const channelType = sourceDataInfo.base.channelType;
        const list = channelDataSources[channelType] || (channelDataSources[channelType] = []);
        addDataSourceWebGL(sourceData, sourceChannel as SourceChannelId, list);
    }

    return channelDataSources;
}

export function processPreviewSourceDatas(previewLookup: PreviewLookupResult)
{
    const result: Editable<SourceToSourceChannelToWebGLPreviewMap> = {};

    if (!previewLookup)
    {
        return result;
    }

    for (const sourceIdString in previewLookup.previews)
    {
        const sourceId = sourceIdString as SourceId;
        const channelDataSources: Editable<SourceChannelToWebGLPreviewMap> = {};
        result[sourceId] = channelDataSources;

        const previews = previewLookup.previews[sourceId];
        for (const preview of previews)
        {
            const payload = preview.data;

            for (const sourceChannel in payload)
            {
                const sourceDataInfo = SourceChannelInfoManager.get(sourceChannel as SourceChannelId);
                const channelType = sourceDataInfo.base.channelType;
                const list = getChartDataPairWebGL(channelDataSources, channelType);
                addPreviewDataSourceWebGL(sourceChannel as SourceChannelId, preview, list);
            }
        }
    }

    return result;
}

function getChartDataPairWebGL(channelDataSources: Editable<SourceChannelToWebGLPreviewMap> | Editable<SourceChannelToWebGLDataSourceMap>, channelType: ChannelTypeId)
{
    return channelDataSources[channelType] || (channelDataSources[channelType] = []);
}

function addDataSourceWebGL(sourceData: SourceData, key: SourceChannelId, output: WebGLDataSeries[])
{
    const payload = sourceData.payload[key];
    if (!payload)
    {
        return;
    }

    const keyData = payload.data;
    const dataInfo = SourceChannelInfoManager.get(key);

    if (!keyData || keyData.length === 0)
    {
        return;
    }

    let valueRange: WebGLValueRange = null;
    if (isFinite(payload.maxValue) && isFinite(payload.minValue))
    {
        valueRange = makeValueRange(payload.minValue, payload.maxValue) ;
    }

    const source = findSource(monitoringStore.state(), sourceData.sourceId);

    const dataSeries = createLineDataSeries(key, source.name, payload.sps, dataInfo.nameSimple, sourceData.time, keyData, dataInfo.base.mainColour, valueRange);

    output.push(dataSeries);
}

function addPreviewDataSourceWebGL (sourceChannel: SourceChannelId, preview: SourceDataPreview, output: WebGLDataSeries[])
{
    const data = preview.data[sourceChannel];
    if (!data)
    {
        return;
    }

    const minData: number[] = new Array<number>(data.length);
    const maxData: number[] = new Array<number>(data.length);
    const meanData: number[] = new Array<number>(data.length);
    let maxValue = Number.NEGATIVE_INFINITY;
    let minValue = Number.POSITIVE_INFINITY;

    for (let i = 0; i < data.length; i++)
    {
        const range = data[i];
        if (typeof(range) === 'number')
        {
            minData[i] = range;
            maxData[i] = range;
            meanData[i] = range;

            minValue = Math.min(minValue, range);
            maxValue = Math.max(maxValue, range);
        }
        else if (range.length === 0)
        {
            minData[i] = ignoreValue;
            maxData[i] = ignoreValue;
            meanData[i] = ignoreValue;
        }
        else
        {
            minData[i] = range[0];
            maxData[i] = range[1];
            meanData[i] = range[2];

            minValue = Math.min(minValue, range[0]);
            maxValue = Math.max(maxValue, range[1]);
        }
    }
    const sourceChannelInfo = SourceChannelInfoManager.get(sourceChannel);
    const name = sourceChannelInfo.nameSimple;
    const valueRange = makeValueRange(minValue, maxValue);
    const minMaxColour = sourceChannelInfo.minMaxColour.unitArray();
    const source = findSource(monitoringStore.state(), preview.host);

    const minMax = createMinMaxDataSeries(sourceChannel, source.name, 1, `Min Max ${name}`, preview.time, maxData, minData, meanData, minMaxColour, sourceChannelInfo.base.mainColour, valueRange);

    output.push(minMax);
}

export function concatTrimWebGLDataSeries<T extends WebGLDataSeries>(list: T[], newItems: T[], trimCutoff: MicroSeconds): T[]
{
    const combined = [...(list || []), ...newItems];
    const mostRecentTime = combined
        .map(item => item.endTime)
        .reduce((max, cur) => Math.max(max, cur), 0);

    const cutoffTime = mostRecentTime - trimCutoff;

    return combined.filter((item) => item.endTime > cutoffTime);
}