import {Modifier} from "simple-data-store";
import {SourceStatus, State} from "./monitoringStoreStates";

export default class SetSourceStatus
{
    public static action (sourceStatus: SourceStatus): Modifier<State>
    {
        return (state: State) =>
        {
            const newSourceStatuses = {...state.sourceStatuses, [sourceStatus.sourceId]: sourceStatus};

            return {sourceStatuses: newSourceStatuses};
        }
    }
}