import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {arrayToMap} from "../utils/utils";
import {SourceChannelId, SourceChannelInfoBase, SourceChannelInfoMap, State} from "./monitoringStoreStates";

export default class SetSourceChannelInfo
{
    public static action (merge: boolean, newSourceChannelInfo: SourceChannelInfoBase[]): Modifier<State>
    {
        return (state: State) =>
        {
            let sourceChannelInfo: Editable<SourceChannelInfoMap> = merge ? state.sourceChannelInfo : {};
            sourceChannelInfo = {...sourceChannelInfo, ...arrayToMap(newSourceChannelInfo, (x) => x.sourceChannel)};

            return {sourceChannelInfo};
        }
    }

    public static remove (id: SourceChannelId): Modifier<State>
    {
        return (state: State) =>
        {
            const sourceChannelInfo: Editable<SourceChannelInfoMap> = state.sourceChannelInfo;
            delete sourceChannelInfo[id];

            return {sourceChannelInfo};
        }
    }
}