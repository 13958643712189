import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {LocalState, SourceEventLevel, SourceEventMap, SourceEventStatus, SourceId, State} from "./monitoringStoreStates";

export interface PartialUpdateSourceEvent
{
    id: number;
    sourceId: SourceId;
    message?: string;
    status?: SourceEventStatus;
    level?: SourceEventLevel;
}

export default class UpdateSourceEvent
{
    public static status (sourceId: SourceId, sourceEventId: number, status: SourceEventStatus): Modifier<State>
    {
        return this.execute({sourceId, id: sourceEventId, status});
    }

    public static message (sourceId: SourceId, sourceEventId: number, message: string): Modifier<State>
    {
        return this.execute({sourceId, id: sourceEventId, message});
    }

    public static action (data: PartialUpdateSourceEvent): Modifier<State>
    {
        return this.execute(data);
    }

    private static execute(data: PartialUpdateSourceEvent): Modifier<State>
    {
        return (state: State) =>
        {
            const currentSourceMap = state.sourceEvents[data.sourceId];
            if (currentSourceMap == null)
            {
                console.error('Attempting to update source event for a source we do not know about');
                return state;
            }

            let sourceEvent = currentSourceMap[data.id];
            if (sourceEvent == null)
            {
                console.error('Attempting to update source event that we do not know about');
                return state;
            }

            let localState: LocalState = state.localState;
            const modifyingSelectedEvent = localState.selectedEvent ? localState.selectedEvent.id === data.id : false;

            const newSourceMap: Editable<SourceEventMap> = {...currentSourceMap};

            if (data.message != null)
            {
                sourceEvent = {...sourceEvent, message: data.message};
                newSourceMap[data.id] = sourceEvent;
            }

            if (data.level != null)
            {
                sourceEvent = {...sourceEvent, level: data.level};
                newSourceMap[data.id] = sourceEvent;
            }

            if (data.status != null)
            {
                sourceEvent = {...sourceEvent, status: data.status};
                newSourceMap[data.id] = sourceEvent;
            }

            if (modifyingSelectedEvent && localState.selectedEvent !== sourceEvent)
            {
                localState = {...localState, selectedEvent: sourceEvent};
            }

            const newSourceToEventsMap = {...state.sourceEvents, [data.sourceId]: newSourceMap};

            return {sourceEvents: newSourceToEventsMap, localState};
        }
    }
}