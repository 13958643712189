import React from 'react';
import {Api} from '../api/api';
import monitoringStore from '../store/monitoringStore';
import {CreateEventState, SourceEventLevel} from '../store/monitoringStoreStates';
import SetCreateEvent from '../store/setCreateEventState';
import {SourceChannelInfoManager} from '../store/sourceChannelInfo';
import {formatDateTime} from '../utils/utils';
import ButtonGroup from './buttonGroup';
import './createEventModal.scss';
import {ModalBase} from './modalBase';

interface Props
{
    createEventState: CreateEventState;
}

interface State
{
    sourceChannelIndex: number;
    level: SourceEventLevel;
    message: string;
}

export default class CreateEventModal extends React.PureComponent<Props, State>
{
    constructor (props: Props)
    {
        super(props);

        this.state = {
            sourceChannelIndex: 0,
            level: 'info',
            message: ''
        }
    }

    public render()
    {
        const { show, sourceChannels, time } = this.props.createEventState;
        if (!show)
        {
            return null;
        }

        const sourceChannelInfos = sourceChannels.map(s => SourceChannelInfoManager.get(s));

        return (
            <ModalBase isOpen={true}
                className={`create-event-modal is--${this.state.level}`}
                onRequestClose={() => this.close()}>

                <h1>Create Event</h1>
                <div><strong>Timestamp: </strong>{formatDateTime(time)}</div>
                <div><strong>Source Data Type: </strong>
                    <select value={this.state.sourceChannelIndex} onChange={(e) => this.updateSourceChannel(e.target.value)}>
                    { sourceChannelInfos.map((s, i) => <option key={s.base.sourceChannel} value={i}>{s.nameSimple}</option>)}
                    </select>
                </div>
                <div><strong>Level: </strong>
                    <select defaultValue={this.state.level} onChange={(e) => this.setState({level: e.target.value as SourceEventLevel})}>
                        <option value="info">Info</option>
                        <option value="warning">Warning</option>
                        <option value="alert">Alert</option>
                    </select>
                </div>
                <div><strong>Message: </strong>
                    <textarea
                        placeholder="Enter a message for the event"
                        value={this.state.message}
                        onChange={(e) => this.setState({message: e.target.value})}/>
                </div>

                <div className="create-event-modal__buttons">
                    <ButtonGroup>
                        <button onClick={() => this.createEvent()}>Create</button>
                    </ButtonGroup>
                    <button onClick={() => this.close()}>Close</button>
                </div>
            </ModalBase>

        )
    }

    private updateSourceChannel(channelIndex: string)
    {
        this.setState({sourceChannelIndex: parseInt(channelIndex)});
    }

    private close()
    {
        monitoringStore.execute(SetCreateEvent.close());
    }

    private createEvent()
    {
        if (confirm('Are you sure you want to create this event?'))
        {
            const { sourceId, time, sourceChannels } = this.props.createEventState;
            const sourceChannel = sourceChannels[this.state.sourceChannelIndex]
            Api.createEvent(this.state.level, this.state.message, time, sourceChannel, sourceId);
            this.close();
        }
    }
}