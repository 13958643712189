import React from 'react';
import ButtonGroup from '../buttonGroup';
import CollapsibleList from '../collapsibleList';
import './adminListViews.scss';

interface Props
{
    extraButtons?: JSX.Element[] | JSX.Element;
    title: JSX.Element;
}

interface State
{
    hideContents: boolean;
}

export default class AdminListView extends React.PureComponent<Props, State>
{
    constructor (props: Props)
    {
        super (props);

        this.state = {
            hideContents: false
        }
    }

    public render()
    {
        return (
            <div className="admin-list-view">
                { this.props.title }

                <ButtonGroup>
                    { this.props.extraButtons }

                    <button onClick={() => this.toggleHide()}>{this.state.hideContents ? 'Show' : 'Hide'}</button>
                </ButtonGroup>

                <CollapsibleList hidden={this.state.hideContents}>
                    {this.props.children}
                </CollapsibleList>
            </div>
        )
    }

    private toggleHide()
    {
        this.setState({hideContents: !this.state.hideContents});
    }
}