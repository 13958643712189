import React from 'react';
import {State as MonitoringState} from '../../store/monitoringStoreStates';

interface Props
{
    state: MonitoringState;
}

export default class BaseTab extends React.PureComponent<Props>
{

}