import React from 'react';
import SetUpdateGroupState from '../../store/admin/setUpdateGroupState';
import monitoringStore from '../../store/monitoringStore';
import {Group, GroupId} from '../../store/monitoringStoreStates';
import AdminListView from './adminListViews';
import GroupView from './groupView';

interface Props
{
    groups: Group[];
}

export default class GroupViews extends React.PureComponent<Props>
{
    public render()
    {
        return (
            <AdminListView
                title={<h4>Groups:</h4>}
                extraButtons={<button onClick={this.showCreateAdd}>Add Group</button>}>
                    {
                        this.props.groups.length === 0 ?
                            <div>
                                <strong>No groups</strong>
                            </div>
                            :
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Group Id</th>
                                        <th>Sort Order</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderFullGroups()}
                                </tbody>
                            </table>
                    }
                </AdminListView>

        )
    }

    private renderFullGroups()
    {
        return this.props.groups.map(
            g => <GroupView key={g.id} group={g} />);
    }

    private showCreateAdd()
    {
        monitoringStore.execute(SetUpdateGroupState.showCreate({
            id: '' as GroupId,
            name: '',
            sortOrder: 0
        }));
    }
}