import React from 'react';
import monitoringStore from '../store/monitoringStore';
import {StartupStatsError} from '../store/monitoringStoreStates';
import ShowStartupErrors from '../store/showStartupErrors';
import {ModalBase} from './modalBase';
import './startupErrorsModal.scss';

interface Props
{
    errors: StartupStatsError[];
    open: boolean;
}

export default class StartupErrorsModal extends React.PureComponent<Props>
{
    public render ()
    {
        if (!this.props.open)
        {
            return null;
        }

        return (
            <ModalBase
                isOpen={true}
                className='startup-errors-modal'
                onRequestClose={() => this.close()}
                >
                <h1>Startup Errors</h1>

                { this.props.errors.map((error) =>
                    {
                        return <div>
                            <strong>{error.message}</strong>
                            { error.data &&
                                <pre>
                                {JSON.stringify(error.data, null, 2)}
                                </pre>
                            }
                        </div>;
                    })}

                <div className="startup-errors-modal__buttons">
                    <button onClick={() => this.close()}>Close</button>
                </div>
            </ModalBase>
        );
    }

    private close ()
    {
        monitoringStore.execute(ShowStartupErrors.close());
    }
}
