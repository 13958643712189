import moment from 'moment';
import {LineChartThreshold} from '../components/lineChart/lineChartTypes';
import {Source, SourceChannelId, SourceChannelThresholdMap, SourceId, State} from '../store/monitoringStoreStates';
import {MicroSeconds, microToMilli} from './timeTypes';

export const RMSText = 'ʀᴍꜱ';

export function shallowEquals (obj1: any, obj2: any)
{
    if (obj1 === obj2)
    {
        return true;
    }
    if (obj1 == null || obj2 == null)
    {
        return false;
    }

    for (const prop in obj1)
    {
        if (obj1[prop] !== obj2[prop])
        {
            return false;
        }
    }

    return true;
}

export function shallowEqualsArray<T> (arr1: Array<T>, arr2: Array<T>)
{
    if (arr1 === arr2)
    {
        return true;
    }

    if (!arr1 || !arr2)
    {
        return false;
    }

    const len = arr1.length;
    if (arr2.length !== len)
    {
        return false;
    }

    for (let i = 0; i < len; i++)
    {
        if (arr1[i] !== arr2[i])
        {
            return false;
        }
    }

    return true;
}

export function isEmptyObject (obj: any)
{
    if (obj === null || obj === undefined)
    {
        return true;
    }

    for (const prop in obj)
    {
        if (obj.hasOwnProperty(prop))
        {
            return false;
        }
    }

    return true;
}
export function padZero (input: number, numOfZeros: number = 2)
{
    if (isNaN(input) || input < 0)
    {
        return input.toString(10);
    }

    return input.toString(10).padStart(numOfZeros, '0');
}
export function formatPercent (input: number)
{
    return input.toFixed(1);
}
export function formatTemperature (input: number)
{
    return formatPercent(input) + '°';
}

function processDateInput (inputMicroSecondsOrDate: MicroSeconds | Date | moment.Moment): moment.Moment
{
    if (typeof(inputMicroSecondsOrDate) === 'number')
    {
        return toMoment(inputMicroSecondsOrDate);
    }

    if (inputMicroSecondsOrDate instanceof Date)
    {
        return moment(inputMicroSecondsOrDate);
    }

    if (moment.isMoment(inputMicroSecondsOrDate))
    {
        return inputMicroSecondsOrDate;
    }

    return toMoment(inputMicroSecondsOrDate);
}
export function formatTime (inputMicroSeconds: MicroSeconds | Date | moment.Moment)
{
    if (inputMicroSeconds == null || inputMicroSeconds < 0)
    {
        return 'Unknown';
    }

    const date = processDateInput(inputMicroSeconds);
    return date.format('HH:mm:ss');
}
export function formatDate (inputMicroSeconds: MicroSeconds | Date | moment.Moment)
{
    if (inputMicroSeconds == null || inputMicroSeconds < 0)
    {
        return 'Unknown';
    }

    const date = processDateInput(inputMicroSeconds);
    return date.format('YYYY-MM-DD');
}
export function formatDateTime (inputMicroSeconds: MicroSeconds | Date | moment.Moment)
{
    if (inputMicroSeconds == null || inputMicroSeconds < 0)
    {
        return 'Unknown';
    }

    const date = processDateInput(inputMicroSeconds);
    const formattedDate = formatDate(date);
    const formattedTime = formatTime(date);

    return `${formattedDate} ${formattedTime}`;
}
export function formatDateTimeInput (inputMicroSeconds: MicroSeconds | Date | moment.Moment)
{
    if (inputMicroSeconds == null || inputMicroSeconds < 0)
    {
        return '';
    }

    const date = processDateInput(inputMicroSeconds);
    return date.format('YYYY-MM-DDTHH:mm');
}
export function parseDateTimeInput (input: string): moment.Moment
{
    return moment(input, 'YYYY-MM-DD\\THH:mm');
}
export function formatFileDateTime (inputMicroSeconds: MicroSeconds | Date | moment.Moment)
{
    if (inputMicroSeconds == null || inputMicroSeconds < 0)
    {
        return 'Unknown';
    }

    const date = processDateInput(inputMicroSeconds);
    return date.format('YYYY-MM-DD_HH-mm-ss');
}
export function parseInputDate (input: string, isStartOfDay: boolean)
{
    const split = input.split('-');

    const year = parseInt(split[0], 10);
    const month = parseInt(split[1], 10) - 1;
    const day = parseInt(split[2], 10);

    const hours = isStartOfDay ? 0 : 23;
    const minutes = isStartOfDay ? 0 : 59;
    const seconds = isStartOfDay ? 0 : 59;
    const milliseconds = isStartOfDay ? 0 : 999;

    return new Date(year, month, day, hours, minutes, seconds, milliseconds);
}

export function groupBy<T> (input: T[], getValue: (x: T) => any): {[key: string]: T[]; }
{
    return input.reduce((acc: {[key: string]: T[]; }, currentValue: T) =>
    {
        if (currentValue == null)
        {
            return acc;
        }

        const value: any = getValue(currentValue);
        let list: T[] = acc[value];
        if (!list)
        {
            list = [];
            acc[value] = list;
        }

        list.push(currentValue);
        return acc;
    }, {});
}

export function arrayToMap<T>(input: T[], getKey: (x: T) => string): {[key: string]: T}
{
    return input.reduce((acc: {[key: string]: T}, value) =>
    {
        const key = getKey(value);
        acc[key] = value;
        return acc;
    }, {});
}

// tslint:disable-next-line: ban-types
export function once (func: Function, context?: any)
{
    let fn: Function | undefined = func;
    return function ()
    {
        if (fn)
        {
            const temp = fn;
            fn = undefined;
            return temp.apply(context || window, arguments);
        }
    };
}

export function preventDefault (e: Event)
{
    e.preventDefault();
}
export function subPreventPageDrag (ref: React.RefObject<Element>)
{
    if (ref.current)
    {
        ref.current.addEventListener('touchstart', preventDefault);
        ref.current.addEventListener('touchmove', preventDefault);
        ref.current.addEventListener('mousedown', preventDefault);
    }
}
export function unsubPreventPageDrag (ref: React.RefObject<Element>)
{
    if (ref.current)
    {
        ref.current.removeEventListener('touchstart', preventDefault);
        ref.current.removeEventListener('touchmove', preventDefault);
        ref.current.removeEventListener('mousedown', preventDefault);
    }
}

export function lerp(v0: number, v1: number, t: number)
{
    return (1.0 - t) * v0 + t * v1;
}

export function getThresholdsForTypes(sourceChannelObjs: Array<{sourceChannel: SourceChannelId}>, sourceDataThresholds: SourceChannelThresholdMap)
{
    const result: LineChartThreshold[] = [];
    let containsAll = false;

    for (const { sourceChannel } of sourceChannelObjs)
    {
        containsAll = containsAll || sourceChannel === 'All' || sourceChannel === '*';

        const thresholds = sourceDataThresholds[sourceChannel];
        if (!thresholds)
        {
            continue;
        }

        for (const threshold of thresholds)
        {
            if (result.indexOf(threshold) < 0)
            {
                result.push(threshold);
            }
        }
    }

    if (!containsAll)
    {
        const thresholds = sourceDataThresholds.All || sourceDataThresholds['*'];
        if (thresholds)
        {
            for (const threshold of thresholds)
            {
                if (result.indexOf(threshold) < 0)
                {
                    result.push(threshold);
                }
            }
        }
    }

    return result;
}

export function addKeysToList (input: { [key: string]: any}, result: string[])
{
    for (const key in input)
    {
        if (result.indexOf(key) < 0)
        {
            result.push(key);
        }
    }
}

export function toMoment(input: MicroSeconds)
{
    return moment(microToMilli(input));
}

const startOfUrlRegex = /^\w+\:\/\/[^\/]+\/?/;
export function urlHasPath(url: string)
{
    const path = url.replace(startOfUrlRegex, '');
    return path.length > 0;
}

export function isNumber(x: any): x is number
{
    if (typeof(x) !== 'number')
    {
        return false;
    }

    return !isNaN(x) && isFinite(x);
}

export function merge<T>(input1: T, input2: Partial<T>, fallbackInput1: T): T
{
    if (input2 == null)
    {
        return input1 || fallbackInput1;
    }

    if (input1 == null)
    {
        return {...fallbackInput1, ...input2};
    }

    return {...input1, ...input2};
}

export function throttleStart<T extends Function>(fn: T, wait: number)
{
    let isCalled = false;

    return function (...args: any)
    {
        if (!isCalled)
        {
            fn(...args);
            isCalled = true;
            setTimeout(function ()
            {
                isCalled = false;
            }, wait)
        }
    };
}

export function throttleEnd<T extends Function>(fn: T, wait: number)
{
    let isCalled = false;

    return function (...args: any)
    {
        if (!isCalled)
        {
            isCalled = true;
            setTimeout(function ()
            {
                fn(...args);
                isCalled = false;
            }, wait)
        }
    };
}


export function compareStrings(s1: string, s2: string)
{
    s1 = s1.toUpperCase();
    s2 = s2.toUpperCase();

    if (s1 === s2)
    {
        return 0;
    }

    if (s1 == null)
    {
        return -1;
    }
    if (s2 == null)
    {
        return 1;
    }

    if (s1 > s2)
    {
        return 1;
    }
    if (s1 < s2)
    {
        return -1;
    }

    return 0;
}

export function normalise(value: number, min: number, max: number)
{
    value -= min;

    const diff = max - min;
    const result = value / diff;

    if (result < 0) return 0;
    if (result > 1) return 1;

    return result;
}

interface IHasTimestamp
{
    readonly time: MicroSeconds;
}
export function concatTrimPackets<T extends IHasTimestamp>(list: T[], newItem: T, trimCutoff: MicroSeconds): T[]
{
    const combined = [...(list || []), newItem];
    const mostRecentTime = combined
        .map(item => item.time)
        .reduce((max, cur) => Math.max(max, cur), 0);

    const cutoffTime = mostRecentTime - trimCutoff;

    return combined.filter((item) => item.time > cutoffTime);
}

export function hexToRgbaFloat(colour: string, defaultAlpha: number = 1)
{
    if (colour.length < 7)
    {
        console.error('Cannot convert hex string to RGBA array', colour);
        return [1, 1, 0, 1];
    }

    let alpha = defaultAlpha;
    const r = colour.substr(1, 2);
    const g = colour.substr(3, 2);
    const b = colour.substr(5, 2);
    if (colour.length >= 9)
    {
        const a = colour.substr(7, 2);
        alpha = parseInt(a, 16) / 255;
    }

    const red = parseInt(r, 16) / 255;
    const green = parseInt(g, 16) / 255;
    const blue = parseInt(b, 16) / 255;
    return [red, green, blue, alpha];
}

export function rgbaFloatToHex(colour: number[])
{
    const red = clamp(Math.round(colour[0] * 255), 0, 255);
    const green = clamp(Math.round(colour[1] * 255), 0, 255);
    const blue = clamp(Math.round(colour[2] * 255), 0, 255);
    const alpha = clamp(Math.round(colour[3] * 255), 0, 255);

    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');
    const alphaHex = alpha.toString(16).padStart(2, '0');

    return '#' + redHex + greenHex + blueHex + alphaHex;
}

export function clamp(x: number, min: number, max: number)
{
    if (x < min) return min;
    if (x > max) return max;
    return x;
}
export function clamp01(x: number)
{
    if (x < 0) return 0;
    if (x > 1) return 1;
    return x;
}

export function findSource(state: State, sourceId: SourceId): Source
{
    const source = state.sources.find(s => s.id === sourceId);
    if (source)
    {
        return source;
    }

    return {
        id: sourceId,
        location: '',
        name: sourceId,
        sortOrder: 0,
        sourceType: 'unknown'
    }
}

export function distinct<T>(input: T[], getKey: (v: T) => string)
{
    const keyMap: {[key: string]: boolean} = {};
    const result: T[] = [];
    for (let value of input)
    {
        const key = getKey(value);
        if (keyMap[key] === true)
        {
            continue;
        }

        keyMap[key] = true;
        result.push(value);
    }

    return result;
}