import React from 'react';
import SetUpdateSourceChannelInfo from '../../store/admin/setUpdateSourceChannelInfoState';
import monitoringStore from '../../store/monitoringStore';
import {ChannelTypeId, SourceChannelId, SourceChannelInfoMap} from '../../store/monitoringStoreStates';
import {isEmptyObject} from '../../utils/utils';
import AdminListView from './adminListViews';
import SourceChannelInfoView from './sourceChannelInfoView';

interface Props
{
    sourceChannelInfos: SourceChannelInfoMap;
}

export default class SourceChannelInfoViews extends React.PureComponent<Props>
{
    constructor (props: Props)
    {
        super(props);
    }

    public render()
    {
        return (
            <AdminListView
                extraButtons={<button onClick={this.showCreateAdd}>
                    Add Source Channel Info
                </button>}

                title={<h4>Source Channel Infos:</h4>}>

                {
                    isEmptyObject(this.props.sourceChannelInfos) ?
                    <div>
                        <strong>No source channel infos!</strong>
                    </div>
                    :
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Source Channel</th>
                                <th>Channel Type</th>
                                <th>Main Colour</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderSourceChannelInfos()}
                        </tbody>
                    </table>
                }
            </AdminListView>
        )
    }

    private renderSourceChannelInfos()
    {
        return Object.values(this.props.sourceChannelInfos)
            .map(s => <SourceChannelInfoView key={s.sourceChannel} sourceChannelInfo={s} />);
    }

    private showCreateAdd = () =>
    {
        monitoringStore.execute(SetUpdateSourceChannelInfo.showCreate({
            channelType: '' as ChannelTypeId,
            mainColour: '',
            mainName: '',
            sourceChannel: '' as SourceChannelId,
            subscriptName: ''
        }));
    }
}