import {Modifier} from "simple-data-store";
import {Editable} from "../../utils/commonTypes";
import {modifyAdminState} from "../monitoringStore";
import {Group, State, UpdateGroupState} from "../monitoringStoreStates";

export default class SetUpdateGroupState
{
    public static showCreate (group: Group): Modifier<State>
    {
        return this.execute(true, true, group);
    }
    public static showUpdate (group: Group): Modifier<State>
    {
        return this.execute(true, false, group);
    }
    public static close (): Modifier<State>
    {
        return this.execute(false, false);
    }

    private static execute (show: boolean, createNew: boolean, group?: Group): Modifier<State>
    {
        return (state: State) =>
        {
            const updateGroupState: Editable<UpdateGroupState> = {
                ...state.adminState.updateSourceState, show, createNew
            }

            if (group)
            {
                updateGroupState.group = group;
            }

            return modifyAdminState(state, {updateGroupState});
        }
    }
}