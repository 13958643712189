import React, {CSSProperties} from 'react';
import {WebGLAxis, WebGLValueRange} from './webglChartStore';

type GetCanvasWidth = () => number;

interface Props
{
    readonly valueViewport: WebGLValueRange;
    readonly axis: WebGLAxis;
    readonly getCanvasWidth: GetCanvasWidth;
}

export default class WebGLChartLeftAxis extends React.PureComponent<Props>
{
    private ref: React.RefObject<HTMLDivElement> = React.createRef();

    public componentDidMount()
    {
        this.forceUpdate();
    }

    public render()
    {
        const { valueViewport, axis } = this.props;

        const ticks: JSX.Element[] = [];
        const lines: JSX.Element[] = [];

        let containerHeight = 100;
        if (valueViewport && this.ref.current)
        {
            const { minValue, maxValue, height } = valueViewport;
            const roughTickHeight = 40 * window.devicePixelRatio;
            containerHeight = this.ref.current.offsetHeight;
            const numRows = containerHeight / roughTickHeight;

            const valuePerRow = height / numRows;
            const log = Math.log10(valuePerRow);
            const logFactor = Math.floor(log);
            const logRemainder = log - logFactor;
            const remainder = Math.pow(10, logRemainder);

            let closest = 10;
            let closestDiff = 10 - remainder;
            let diff = Math.abs(5 - remainder);
            if (diff < closestDiff)
            {
                closest = 5;
                closestDiff = diff;
            }

            diff = Math.abs(2 - remainder);
            if (diff < closestDiff)
            {
                closest = 2;
                closestDiff = diff;
            }

            diff = Math.abs(1 - remainder);
            if (diff < closestDiff)
            {
                closest = 1;
            }

            const step = Math.pow(10, logFactor) * closest;

            const start = Math.ceil(maxValue / step) * step;
            const end = Math.floor(minValue / step) * step;

            const precision = Math.min(6, Math.max(2, -log + 1));

            for (let value = start; value > end; value -= step)
            {
                const percent = (value - minValue) / height;
                if (percent < 0 || percent > 1)
                {
                    continue;
                }
                const style: CSSProperties = {
                    top: ((1 - percent) * 100) + '%'
                }

                let nearZero = Math.abs(value) < 1e-10;
                let valueText = nearZero ? '0' : value.toFixed(precision);
                ticks.push(<span key={value} style={style} className='webgl-chart__left-axis-tick'>
                    {valueText}
                    <span className="tick" />
                </span>);

                lines.push(<span key={value} style={style} className={`webgl-chart__left-axis-line ${nearZero ? 'is--near-zero' : ''}`} />);
            }
        }

        const canvasWidth = this.props.getCanvasWidth();

        return <div ref={this.ref} className="webgl-chart__left-axis-bumper webgl-chart__left-axis">

            { axis.enabled &&
                <div className='webgl-chart__left-axis-label' style={{width: `${containerHeight}px`}}>
                    <strong>{ axis.label } ({this.renderUnits(axis.unit, axis.isRMS)})</strong>
                </div>
            }

            <div className='webgl-chart__left-axis-ticks'>
                { ticks }
            </div>

            <div className='webgl-chart__left-axis-lines' style={{width: `${canvasWidth}px`}}>
                { lines }
            </div>
        </div>;
    }

    private renderUnits(unit: string, isRMS: boolean): JSX.Element
    {
        if (isRMS)
        {
            return <span>{unit} <small>RMS</small></span>;
        }

        return <span>{unit}</span>;
    }
}