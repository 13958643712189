import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {EventRule, EventRuleResult, SourceChannelId, SourceChannelThresholdMap, State} from "./monitoringStoreStates";

interface Threshold
{
    readonly value: number;
    readonly title: string;
    readonly applyToTypes: SourceChannelId[];
}

export default class SetEventRules
{
    public static action (eventRuleResult: EventRuleResult): Modifier<State>
    {
        return (state: State) =>
        {
            const thresholds = processThresholds(eventRuleResult.rules);
            const fftThresholds = processThresholds(eventRuleResult.fftRules);
            const rmsThresholds = processThresholds(eventRuleResult.rmsRules);

            return {eventRules: {eventRuleResult, thresholds, fftThresholds, rmsThresholds}};
        }
    }
}

function processThresholds(rules: EventRule[])
{
    const thresholds = rules.map((rule) =>
    {
        return {
            value: rule.threshold,
            title: rule.ruleType,
            applyToTypes: rule.applyToTypes
        };
    });

    const sourceDataThresholds: Editable<SourceChannelThresholdMap> = {};
    for (const threshold of thresholds)
    {
        for (const sourceChannel of threshold.applyToTypes)
        {
            const list = sourceDataThresholds[sourceChannel] || (sourceDataThresholds[sourceChannel] = []);
            list.push(threshold);
        }
    }

    return sourceDataThresholds;
}