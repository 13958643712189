import React from 'react';
import ButtonGroup from '../buttonGroup';
import Icon from '../icon';
import WebGLBaseReactChart, {BaseProps} from './webglBaseReactChart';
import WebGLBottomLegend from './webglBottomLegend';
import WebGLChartBottomAxis from './webglChartBottomAxis';
import WebGLChartCanvas from './webglChartCanvas';
import WebGLChartLeftAxis from './webglChartLeftAxis';
import WebGLChartSelection from './webglChartSelection';
import './webglChartStyles.scss';
import WebGLMouseChartPoints from './webglMouseChartPoints';

export default class WebGLChart extends WebGLBaseReactChart<BaseProps>
{
    public render()
    {
        const { valueViewport, timeViewport, valueSelection, timeSelection, chartState, tooltip, mouseChartPoints, sourceDataEnabled, extraButtons, enableButtons } = this.props;

        return <div className="webgl-chart">
            <div className="webgl-chart__header">
                <div className="webgl-chart__left-axis-bumper"></div>
                <h1 className="webgl-chart__title">{this.props.chartState.title}</h1>
                <div className="webgl-chart__buttons">
                    { enableButtons &&
                    <ButtonGroup>
                        <button onClick={this.resetZoom} data-rh='Reset zoom'><Icon type='fullscreen' forceTheme='dark' /></button>
                        <button onClick={this.resetTimeZoom} data-rh='Reset time zoom'><Icon type='fullscreen-axis-x' forceTheme='dark' /></button>
                        <button onClick={this.autoYZoom} data-rh='Auto zoom Y axis'><Icon type='fullscreen-axis-y' forceTheme='dark' /></button>
                        { extraButtons }
                    </ButtonGroup> }
                </div>
            </div>
            <div className="webgl-chart__body">
                <WebGLChartLeftAxis valueViewport={valueViewport} axis={chartState.leftAxis} getCanvasWidth={this.getCanvasWidth} />

                <div className='webgl-chart__canvas-holder' onMouseDown={this.onCanvasMouseDown} onTouchStart={this.onCanvasTouchStart} data-rh-chart={tooltip}>
                    <WebGLChartCanvas ref={this.canvasRef} chartState={chartState} valueViewport={valueViewport} timeViewport={timeViewport} sourceDataEnabled={sourceDataEnabled} />

                    <WebGLMouseChartPoints timeViewport={timeViewport} valueViewport={valueViewport} chartPoints={mouseChartPoints} sourceDataEnabled={sourceDataEnabled} />

                    <WebGLChartSelection valueViewport={valueViewport} timeViewport={timeViewport} timeSelect={timeSelection} valueSelect={valueSelection} enableTimeSelect={chartState.enableTimeSelect} enableValueSelect={chartState.enableValueSelect} />
                </div>
            </div>
            <div className="webgl-chart__footer">
                <div className="webgl-chart__left-axis-bumper"></div>
                <WebGLChartBottomAxis timeViewport={timeViewport} enableAxis={true} />
            </div>

            <WebGLBottomLegend chartState={chartState} chartPoints={mouseChartPoints} sourceDataEnabled={sourceDataEnabled} />
        </div>;
    }
}