import React, {CSSProperties} from 'react';
import {MilliSeconds} from '../../utils/timeTypes';
import {normalise} from '../../utils/utils';
import Timeline from './timeline';
import {canZoomIn, canZoomOut, endOf, findZoomLevelAbove, findZoomLevelBelow, getZoomInfo, RenderContext, startOf, TimelineState, TimelineViewChangeHandler, TimelineZoomLevelInfo, ZoomLevelType} from './timelineCommon';
import moment = require('moment');

interface Props
{
    readonly parent: Timeline;
    readonly renderContext: RenderContext;
    readonly timelineState: TimelineState;
    readonly onChangeView: TimelineViewChangeHandler;
}

interface TimePosition
{
    readonly time: moment.Moment;
    readonly xpos: number;
}

function distSqr(x1: number, y1: number, x2: number, y2: number)
{
    const dx = x2 - x1;
    const dy = y2 - y1;
    return dx * dx + dy * dy;
}

interface MouseEventListener {
    (evt: MouseEvent): void;
}
interface TouchEventListener {
    (evt: TouchEvent): void;
}

export default class TimelineTopRail extends React.PureComponent<Props>
{
    public upperRef: React.RefObject<HTMLDivElement>;
    public lowerRef: React.RefObject<HTMLDivElement>;

    private onClickUpperHandler: MouseEventListener;
    private onTouchUpperHandler: TouchEventListener;
    private onClickLowerHandler: MouseEventListener;
    private onTouchLowerHandler: TouchEventListener;

    constructor (props: Props)
    {
        super(props);

        this.upperRef = React.createRef();
        this.lowerRef = React.createRef();
    }

    public componentDidMount()
    {
        this.onClickLowerHandler = (e) => this.onClickLowerTimeHeader(e.target as HTMLElement, e.clientX, e.clientY);
        this.onClickUpperHandler = (e) => this.onClickUpperTimeHeader(e.target as HTMLElement, e.clientX, e.clientY);
        this.onTouchLowerHandler = (e) => this.onClickLowerTimeHeader(e.target as HTMLElement, e.changedTouches[0].clientX, e.changedTouches[0].clientY);
        this.onTouchUpperHandler = (e) => this.onClickUpperTimeHeader(e.target as HTMLElement, e.changedTouches[0].clientX, e.changedTouches[0].clientY);

        this.upperRef.current.addEventListener('click', this.onClickUpperHandler);
        this.upperRef.current.addEventListener('touchend', this.onTouchUpperHandler);
        this.lowerRef.current.addEventListener('click', this.onClickLowerHandler);
        this.lowerRef.current.addEventListener('touchend', this.onTouchLowerHandler);
    }

    public componentWillUnmount()
    {
        if (this.upperRef.current)
        {
            this.upperRef.current.removeEventListener('click', this.onClickUpperHandler);
            this.upperRef.current.removeEventListener('touchend', this.onTouchUpperHandler);
        }
        if (this.lowerRef.current)
        {
            this.lowerRef.current.removeEventListener('click', this.onClickLowerHandler);
            this.lowerRef.current.removeEventListener('touchend', this.onTouchLowerHandler);
        }
    }

    public render()
    {
        return (
            <div>
                <div className="timeline__time-headers is--upper" ref={this.upperRef}>
                    { this.renderUpperTopRail() }
                </div>
                <div className="timeline__time-headers is--lower" ref={this.lowerRef}>
                    { this.renderLowerTopRail() }
                </div>
            </div>
        )
    }

    private onClickLowerTimeHeader(target: HTMLElement, clientX: number, clientY: number)
    {
        if (!target.classList.contains('is--clickable'))
        {
            return;
        }

        const mouseDown = this.props.parent.mouseDownClient;
        if (mouseDown && distSqr(clientX, clientY, mouseDown.x, mouseDown.y) > 5)
        {
            return;
        }

        const startTime = Number(target.attributes.getNamedItem('data-start-time').value) as MilliSeconds;
        const endTime = Number(target.attributes.getNamedItem('data-end-time').value) as MilliSeconds;
        const { centerTime, timePerPixel } = this.props.renderContext.calculateViewFromStartEnd(startTime, endTime);

        this.props.onChangeView(centerTime, moment.duration(timePerPixel * 1.1, 'milliseconds'));
    }

    private onClickUpperTimeHeader(target: HTMLElement, clientX: number, clientY: number)
    {
        if (!target.classList.contains('is--clickable'))
        {
            return;
        }

        const mouseDown = this.props.parent.mouseDownClient;
        if (mouseDown && distSqr(clientX, clientY, mouseDown.x, mouseDown.y) > 5)
        {
            return;
        }

        const nextZoomProp = target.attributes.getNamedItem('data-zoom-info-prop').value;
        const nextZoomInfo = getZoomInfo(nextZoomProp as ZoomLevelType);

        this.props.onChangeView(this.props.timelineState.centerTime, nextZoomInfo.timePerPixel);
    }

    private renderUpperTopRail(): JSX.Element[]
    {
        const { renderContext } = this.props;

        const result: JSX.Element[] = [];
        if (renderContext.isEmpty)
        {
            return result;
        }

        const { zoomLevelInfo } = renderContext;
        const { upperFormat } = zoomLevelInfo;

        const nextZoomLevel = findZoomLevelAbove(zoomLevelInfo.timePerPixel.asMilliseconds() as MilliSeconds);
        const isClickable = canZoomOut(zoomLevelInfo);
        const timePositions = this.calculateUpperAxisData();

        for (let i = 0; i < timePositions.length - 1; i++)
        {
            const { time, xpos } = timePositions[i];
            const nextTimePosition = timePositions[i + 1];
            const nextXpos = nextTimePosition.xpos;

            const style: CSSProperties = {
                transform: `translateX(${xpos}px)`,
                width: `${nextXpos - xpos}px`
            };

            const text = time.format(upperFormat);
            result.push(<div key={text}
                className={`timeline__time-header ${isClickable ? 'is--clickable' : ''}`}
                data-zoom-info-prop={nextZoomLevel.prop}
                style={style} >
                    {text}
                </div>);
        }

        return result;
    }

    private calculateUpperAxisData()
    {
        const { renderContext } = this.props;

        const result: TimePosition[] = [];
        if (renderContext.isEmpty)
        {
            return result;
        }

        const { zoomLevelInfo } = renderContext;

        let time = moment(renderContext.leftMs);
        result.push({ time, xpos: 0 });

        time = endOf(time, zoomLevelInfo.upperRound).add(1, 'millisecond');
        while (time.valueOf() < renderContext.rightMs)
        {
            const xpos = renderContext.calculateXposFromTime(time, false);
            result.push({time, xpos});
            time = endOf(time, zoomLevelInfo.upperRound).add(1, 'millisecond');
        }

        const finalXpos = renderContext.calculateXposFromTime(time, false);
        result.push({time, xpos: finalXpos});

        return result;
    }

    private renderLowerTopRail(): JSX.Element[]
    {
        const { renderContext } = this.props;

        const result: JSX.Element[] = [];
        if (renderContext.isEmpty)
        {
            return result;
        }

        const { zoomLevelInfo } = renderContext;
        const isClickable = canZoomIn(zoomLevelInfo);

        const timePositions = this.calculateLowerAxisData(zoomLevelInfo);

        for (let i = 0; i < timePositions.length - 1; i++)
        {
            const { xpos, time } = timePositions[i];
            const nextTimePosition = timePositions[i + 1];
            const nextXpos = nextTimePosition.xpos;

            const translate = `translateX(${xpos}px)`;
            const headerStyle: CSSProperties = {
                transform: translate,
                width: `${nextXpos - xpos}px`
            };
            const key = time.valueOf();

            result.push(
                <span key={key}
                    className={`timeline__time-header ${isClickable ? 'is--clickable' : ''}`}
                    data-start-time={time.valueOf().toString()}
                    data-end-time={nextTimePosition.time.valueOf().toString()}
                    style={headerStyle}>
                    {time.format(zoomLevelInfo.lowerFormat)}
                </span>);

            const barStyle: CSSProperties = {
                transform: translate,
                height: `${renderContext.bounds.width}px`
            }

            result.push(<span key={key + '_bar'} className="timeline__time-header-bar" style={barStyle} />);
        }

        if (isClickable)
        {
            const prevZoomLevel = findZoomLevelBelow(zoomLevelInfo.timePerPixel.asMilliseconds() as MilliSeconds);
            const ttpRatioDiff = renderContext.timePerPixelMs / prevZoomLevel.info.timePerPixel.asMilliseconds();
            if (ttpRatioDiff < 4.2)
            {
                const prevTimePositions = this.calculateLowerAxisData(prevZoomLevel.info);
                const opacity = 1 - normalise(ttpRatioDiff, 3.0, 4.2);

                for (let i = 0; i < prevTimePositions.length - 1; i++)
                {
                    const {xpos, time} = prevTimePositions[i];

                    const translate = `translateX(${xpos}px)`;
                    const barStyle: CSSProperties = {
                        transform: translate,
                        height: `${renderContext.bounds.width}px`,
                        opacity
                    }

                    const key = time.valueOf();
                    result.push(<span key={key + '_bar-dashed'} className="timeline__time-header-bar is--dashed" style={barStyle} />);
                }
            }
        }

        return result;
    }

    private calculateLowerAxisData(zoomLevelInfo: TimelineZoomLevelInfo)
    {
        const { renderContext } = this.props;

        const result: TimePosition[] = [];
        if (renderContext.isEmpty)
        {
            return result;
        }

        let time = startOf(moment(renderContext.leftMs), zoomLevelInfo.lowerRound);
        let xpos = renderContext.calculateXposFromTime(time, false);

        while (xpos <= renderContext.eventsBounds.width + 120)
        {
            result.push({ time, xpos });

            time = endOf(time, zoomLevelInfo.lowerRound).add(1, 'millisecond');
            xpos = renderContext.calculateXposFromTime(time, false);
        }

        result.push({ time, xpos });

        return result;
    }
}