import React from 'react';
import {Api} from '../api/api';
import {router} from '../router';
import monitoringStore from '../store/monitoringStore';
import {SourceEvent, SourceEventLevel, SourceEventRmsData} from '../store/monitoringStoreStates';
import ShowEventModal from '../store/showEventModal';
import {SourceChannelInfoManager} from '../store/sourceChannelInfo';
import {Editable} from '../utils/commonTypes';
import {formatDateTime, isEmptyObject, toMoment} from '../utils/utils';
import ButtonGroup from './buttonGroup';
import {ModalBase} from './modalBase';
import './sourceEventModal.scss';

interface Props
{
    readonly sourceEvent: SourceEvent;
    readonly open: boolean;
}

interface State
{
    readonly message: string;
    readonly level: SourceEventLevel;
    readonly editing: boolean;
}

// Number is before my birthday in 2018.
// Arbitrary cutoff where if the timestamp is before that it has to be invalid given that the project started after that time.
const gotoTimeCutoff = 1523714400000000;
export default class SourceEventModal extends React.PureComponent<Props, State>
{
    constructor(props: Props)
    {
        super(props);

        this.state = {
            message: '',
            level: 'info',
            editing: false
        }
    }

    public render ()
    {
        const sourceEvent = this.props.sourceEvent;

        if (!this.props.open || !sourceEvent)
        {
            return null;
        }

        const className = `source-event-modal is--${sourceEvent.level}`;
        const showGoto = sourceEvent.timestamp > gotoTimeCutoff;
        const sourceChannelInfo = SourceChannelInfoManager.get(sourceEvent.sourceChannel);

        return (
            <ModalBase isOpen={true}
                className={className}
                onRequestClose={() => this.close()}
                >
                <h1></h1>
                <div><strong>Timestamp: </strong>{formatDateTime(sourceEvent.timestamp)}</div>
                <div><strong>Source Data Type: </strong>{sourceChannelInfo.nameSubscript}</div>
                { this.state.editing &&
                <div><strong>Event Level: </strong>
                    <select defaultValue={this.state.level} onChange={(e) => this.setState({level: e.target.value as SourceEventLevel})}>
                        <option value="info">Info</option>
                        <option value="warning">Warning</option>
                        <option value="alert">Alert</option>
                    </select>
                </div>
                }
                { this.state.editing ?
                    <textarea value={this.state.message} onChange={(e) => this.setState({message: e.target.value})} /> :
                    <p className="source-event-modal__text"> {sourceEvent.message} </p>
                }

                { this.renderRmsData() }

                <div className="source-event-modal__buttons">
                    <ButtonGroup>
                        <button onClick={() => this.editMessage()}>{this.state.editing ? 'Save' : 'Edit'}</button>
                        { showGoto && <button onClick={() => this.gotoEvent()}>Goto</button> }
                        <button onClick={() => this.markUnread()}>Mark Unread</button>
                        <button onClick={() => this.dismiss()}>Dismiss</button>
                    </ButtonGroup>
                    <button onClick={() => this.close()}>Close</button>
                </div>
            </ModalBase>
        );
    }

    private renderRmsData()
    {
        const { thresholdData } = this.props.sourceEvent;

        if (isEmptyObject(thresholdData))
        {
            return null;
        }

        let data:Editable<SourceEventRmsData> = Object.assign({}, thresholdData);
        let units: React.ReactNode = thresholdData.units;
        if (units === 'RMS')
        {
            units = <small>RMS</small>;
        }

        const result: JSX.Element[] = [];
        result.push(<div><strong>Peak: </strong>{data.peak.toFixed(4)}{units}</div>);
        result.push(<div><strong>Mean: </strong>{data.mean.toFixed(4)}{units}</div>);
        result.push(<div><strong>Duration: </strong>{data.durationMs}ms</div>);

        delete data['peak'];
        delete data['mean'];
        delete data['durationMs'];
        delete data['units'];

        for (const prop in data)
        {
            result.push(<div><strong>{prop}: </strong>{(data as any)[prop]}</div>);
        }

        return <div>
            <div><strong>Threshold Data:</strong></div>
            {result}
        </div>;
    }

    private editMessage()
    {
        const sourceEvent = this.props.sourceEvent;
        if (this.state.editing)
        {
            Api.updateEvent({sourceId: sourceEvent.sourceId, id: sourceEvent.id, message: this.state.message, level: this.state.level});
            this.setState({editing: false});
        }
        else
        {
            this.setState({message: sourceEvent.message, level: sourceEvent.level, editing: true});
        }
    }

    private gotoEvent()
    {
        const sourceEvent = this.props.sourceEvent;
        const start = toMoment(sourceEvent.timestamp).subtract(2, 'second');
        const end = start.clone().add(3, 'seconds');
        this.close();

        router.changeToAndDoDataLookup(start, end);
    }
    private dismiss ()
    {
        const sourceEvent = this.props.sourceEvent;
        Api.updateEventStatus(sourceEvent.sourceId, sourceEvent.id, 'dismissed');
        monitoringStore.execute(ShowEventModal.close());
    }

    private close ()
    {
        monitoringStore.execute(ShowEventModal.close());
    }

    private markUnread ()
    {
        const sourceEvent = this.props.sourceEvent;
        Api.updateEventStatus(sourceEvent.sourceId, sourceEvent.id, 'unread');
        monitoringStore.execute(ShowEventModal.close());
    }
}
