import {makeTimeRange} from "./components/webglChart/webglUtils";
import monitoringStore from "./store/monitoringStore";
import {State} from "./store/monitoringStoreStates";
import SetGraphState from "./store/setGraphState";
import {MilliSeconds, milliToMicro, subMicro} from "./utils/timeTypes";

export default class GraphRenderRefresh
{
    private static currentlyRunning: boolean = false;

    public static updateGraphTime()
    {
        const {graphState} = monitoringStore.state();
        const now = milliToMicro(Date.now() as MilliSeconds);
        const endTime = subMicro(now, graphState.startTimeOffset);
        const startTime = subMicro(endTime, graphState.viewportWidth);
        monitoringStore.execute(SetGraphState.updateTime(makeTimeRange(startTime, endTime)));
    }

    public static runUpdateGraphTime = () =>
    {
        if (!GraphRenderRefresh.currentlyRunning)
        {
            return;
        }

        GraphRenderRefresh.currentlyRunning = false;
        const { serverState, localState } = monitoringStore.state();
        if (serverState.view === 'graphs' && !localState.pauseLiveGraphs)
        {
            GraphRenderRefresh.updateGraphTime();
            GraphRenderRefresh.callUpdateTimeout();
        }
    }

    public static callUpdateTimeout()
    {
        if (this.currentlyRunning)
        {
            return;
        }
        this.currentlyRunning = true;

        const {refreshRate} = monitoringStore.state().graphState;
        if (refreshRate === 'VSync')
        {
            window.requestAnimationFrame(this.runUpdateGraphTime);
        }
        else if (refreshRate === '30hz')
        {
            setTimeout(this.runUpdateGraphTime, 1000 / 30);
        }
        else if (refreshRate === '10hz')
        {
            setTimeout(this.runUpdateGraphTime, 1000 / 10);
        }
        else
        {
            setTimeout(this.runUpdateGraphTime, 1000);
        }
    }

    public static init()
    {
        this.updateGraphTime();
        this.callUpdateTimeout();

        setInterval(() =>
        {
            monitoringStore.execute(SetGraphState.trimOldData());
        }, 2000);

        monitoringStore.subscribe((state: State) => state.serverState.view, this.checkForRestart, undefined, 'checkViewChangeForRefresh');

        monitoringStore.subscribe((state: State) => state.localState.pauseLiveGraphs, this.checkForRestart, undefined, 'checkPausedChangeForRefresh');
    }

    private static checkForRestart = (state: State) =>
    {
        if (!state.localState.pauseLiveGraphs && state.serverState.view === 'graphs')
        {
            GraphRenderRefresh.updateGraphTime();
            GraphRenderRefresh.callUpdateTimeout();
        }
    }
}