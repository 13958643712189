import React from 'react';
import {Api} from '../../api/api';
import {router} from '../../router';
import {ChannelTypeInfoManager} from '../../store/channelTypeInfoManager';
import monitoringStore from '../../store/monitoringStore';
import {ChannelTypeId, GraphRefreshRate} from '../../store/monitoringStoreStates';
import SetGraphState from '../../store/setGraphState';
import SetPauseLiveGraphs from '../../store/setPauseLiveGraphs';
import SetSourceDataEnabled from '../../store/setSourceDataEnabled';
import {microToSeconds, Seconds, secondsToMicro} from '../../utils/timeTypes';
import ButtonGroup from '../buttonGroup';
import Icon from '../icon';
import SourceDataTypeSelectors from '../sourceDataTypeSelector/sourceDataTypeSelectors';
import ToggleButton from '../toggleButton';
import WebGLControlledChart from '../webglChart/webglControlledChart';
import BaseTab from './baseTab';
import './graphsTab.scss';

export default class GraphsTab extends BaseTab
{
    public render()
    {
        const { state } = this.props;
        const { graphState } = state;
        const { pauseLiveGraphs } = state.localState;

        const startTimeOffset = microToSeconds(graphState.startTimeOffset).toFixed(1);
        const viewportWidth = microToSeconds(graphState.viewportWidth).toFixed(1);

        const showExtraButtonsClass = graphState.showExtraButtons ? '' : 'close';

        return <>
                <SourceDataTypeSelectors
                    forMapId='main'
                    sourceChannels={graphState.sourceChannels}
                    sourceDataEnabled={state.sourceDataEnabledState}
                    autoResetYZoom={state.serverState.autoResetYZoomOnSourceToggle}
                    />

                <div className='graphs-tab__inputs'>
                    <div className='graphs-tab__inputs-left'>
                        <ButtonGroup>
                            <ToggleButton toggled={pauseLiveGraphs} onClick={this.togglePauseLiveGraphs}>
                                { pauseLiveGraphs ? <><Icon type='play' forceTheme='dark' /> Play</> : <><Icon type='pause' forceTheme='dark' /> Pause</> }
                            </ToggleButton>


                            <button onClick={this.gotoDataLookup}><Icon type='zoom' forceTheme='dark' /> Show in lookup</button>
                        </ButtonGroup>

                        <button className='graphs-tab__show-extra-buttons' onClick={this.toggleExtraButtons}>▼</button>
                    </div>

                    <div className={`graphs-tab__inputs-right ${showExtraButtonsClass}`}>
                        <label className='graphs-tab__input'>
                            <span>Delay: </span>
                            <input type='range' min='0' step='0.1' max='8' value={startTimeOffset} onChange={this.onChangeStartTimeOffset} />
                            <span className='graphs-tab__input-value'>{`${startTimeOffset}s`}</span>
                        </label>

                        <label className='graphs-tab__input'>
                            <span>Window:</span>
                            <input type='range' min='1' step='0.1' max='20' value={viewportWidth} onChange={this.onChangeViewportWidth} />
                            <span className='graphs-tab__input-value'>{`${viewportWidth}s`}</span>
                        </label>

                        <label className='graphs-tab__input'>
                            <span>Refresh: </span>
                            <select value={graphState.refreshRate} onChange={this.onChangeRefreshRate}>
                                <option value='1hz'>1 Hz</option>
                                <option value='10hz'>10 Hz</option>
                                <option value='30hz'>30 Hz</option>
                                <option value='VSync'>Fastest</option>
                            </select>
                        </label>
                    </div>
                </div>

                <div>
                    { this.renderCharts() }
                </div>
            </>;
    }

    private toggleExtraButtons = () =>
    {
        monitoringStore.execute(SetGraphState.setShowExtraButtons(!this.props.state.graphState.showExtraButtons));
    }

    private onChangeStartTimeOffset = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        const startTimeOffset = secondsToMicro(parseFloat(e.target.value) as Seconds);
        if (isNaN(startTimeOffset))
        {
            return;
        }
        monitoringStore.execute(SetGraphState.setStartTimeOffset(startTimeOffset));
    }
    private onChangeViewportWidth = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        const viewportWidth = secondsToMicro(parseFloat(e.target.value) as Seconds);
        if (isNaN(viewportWidth))
        {
            return;
        }
        monitoringStore.execute(SetGraphState.setViewportWidth(viewportWidth));
    }
    private onChangeRefreshRate = (e: React.ChangeEvent<HTMLSelectElement>) =>
    {
        const refreshRate = e.target.value as GraphRefreshRate;
        monitoringStore.execute(SetGraphState.setRefreshRate(refreshRate));
    }

    private togglePauseLiveGraphs = () =>
    {
        const pause = !this.props.state.localState.pauseLiveGraphs;
        monitoringStore.execute(SetPauseLiveGraphs.action(pause));
        Api.setPauseLiveGraphs(pause);
    }

    private gotoDataLookup = () =>
    {
        const { sources } = this.props.state;
        if(sources.length === 0)
        {
            return;
        }

        const timespan = this.props.state.graphState.sourceDataTimespan;
        if (!timespan.start.isValid())
        {
            return;
        }

        const start = timespan.start.clone().subtract(10, 'seconds').startOf('second');
        const end = timespan.end.clone().add(1, 'seconds').startOf('second');

        router.changeToAndDoDataLookup(start, end);
    }

    private renderCharts()
    {
        const { state } = this.props;
        const { webglChartState, graphState, sources } = state;

        const sourceDataEnabled = SetSourceDataEnabled.getEnabled('main', state.sourceDataEnabledState);

        const result: JSX.Element[] = [];

        for (const source of sources)
        {
            const channelTypeIds = graphState.webglChartIds[source.id];
            if (!channelTypeIds)
            {
                continue;
            }
            const sortedChannelTypeInfos = ChannelTypeInfoManager.sortTypes(Object.keys(channelTypeIds) as ChannelTypeId[]);

            for (let channelTypeInfo of sortedChannelTypeInfos)
            {
                const pair = channelTypeIds[channelTypeInfo.channelType];
                if (!pair || !pair.mainChartId)
                {
                    continue;
                }

                result.push(<WebGLControlledChart type='main' webglChartState={webglChartState} chartId={pair.mainChartId} key={pair.mainChartId} sourceDataEnabled={sourceDataEnabled} enableButtons={false} />);

                if (pair.rmsChartId)
                {
                    result.push(<WebGLControlledChart type='rms' webglChartState={webglChartState} chartId={pair.rmsChartId} key={pair.rmsChartId} sourceDataEnabled={sourceDataEnabled} enableButtons={false} />);
                }

                if (pair.fftChartId)
                {
                    result.push(<WebGLControlledChart type='fft' webglChartState={webglChartState} chartId={pair.fftChartId} key={pair.fftChartId} sourceDataEnabled={sourceDataEnabled} enableButtons={false} />);
                }
            }
        }

        return result;
    }
}
