import React from 'react';
import {Api} from '../../api/api';
import SetServerConfig from '../../store/admin/setServerConfig';
import monitoringStore from '../../store/monitoringStore';
import ButtonGroup from '../buttonGroup';
import {ModalBase} from '../modalBase';
import './listActiveUsersModal.scss';

interface Props
{
    show: boolean;
    summary: string;
}

export default class ServerConfigModal extends React.PureComponent<Props>
{
    public render()
    {
        return (
            <ModalBase isOpen={this.props.show}
                className='list-active-users-modal'
                onRequestClose={() => this.close()}>

                <h2>Server Config Summary</h2>

                <pre>
                    {this.props.summary.length > 0 ? this.props.summary : 'Loading...'}
                </pre>

                <div className="update-channel-info-modal__buttons">
                    <ButtonGroup>
                        <button onClick={() => this.update()}>Reload Config</button>
                    </ButtonGroup>
                    <button onClick={() => this.close()}>Close</button>
                </div>

            </ModalBase>
        )
    }

    private close()
    {
        monitoringStore.execute(SetServerConfig.close());
    }

    private update()
    {
        monitoringStore.execute(SetServerConfig.update(''));
        Api.listActiveUsers();
    }
}