interface CacheEntry
{
    lastAccessed: number;
    canvas: HTMLCanvasElement;
    context2d: CanvasRenderingContext2D;
}
interface CacheMap { [src: string]: CacheEntry };

const EmptyPixels = new Uint8ClampedArray(4);

export class ImageZoomCache
{
    private static cache: CacheMap = {};
    private static cleanupInterval: number;
    private static cleanupStarted: boolean;

    public static getPixelValues(img: HTMLImageElement, x: number, y: number)
    {
        if (x < 0 || y < 0 || x >= img.width || y >= img.height || img.naturalWidth === 0 || !img.complete)
        {
            return EmptyPixels;
        }

        this.startCleanupInterval();

        let cacheEntry = this.cache[img.src];
        if (!cacheEntry)
        {
            cacheEntry = this.createCacheEntry(img);
            this.cache[img.src] = cacheEntry;
        }
        else
        {
            cacheEntry.lastAccessed = Date.now();
        }

        return cacheEntry.context2d.getImageData(x, y, 1, 1).data;
    }

    private static createCacheEntry(img: HTMLImageElement): CacheEntry
    {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const context2d = canvas.getContext('2d');
        context2d.drawImage(img, 0, 0, img.width, img.height);

        const lastAccessed = Date.now();

        return { lastAccessed, canvas, context2d };
    }

    private static startCleanupInterval()
    {
        if (this.cleanupStarted)
        {
            return;
        }

        this.cleanupStarted = true;
        this.cleanupInterval = window.setInterval(() => this.doCleanup(), 30000);
    }

    private static doCleanup()
    {
        const now = Date.now();
        for (const prop in this.cache)
        {
            const cacheEntry = this.cache[prop];
            if (now - cacheEntry.lastAccessed > 30000)
            {
                cacheEntry.context2d = null;
                cacheEntry.canvas = null;
                delete this.cache[prop];
            }
        }
    }
}