import React from 'react';
import SourcesView from '../sourcesView';
import BaseTab from './baseTab';

export default class OverviewTab extends BaseTab
{
    public render()
    {
        const state = this.props.state;

        return (<>
            <SourcesView
                sourcesLoading={state.localState.sourcesLoading}
                sources={state.sources}
                sourceToEvents={state.sourceEvents}
                statuses={state.sourceStatuses}
                occupancies={state.sourceOccupancies}
                activeStatuses={state.sourceActiveStatuses}
            />
        </>);
    }
}