import React from 'react';
import {Api} from '../../api/api';
import SetUpdateUserProfile from '../../store/admin/setUpdateUserProfileState';
import monitoringStore from '../../store/monitoringStore';
import {UserProfile} from '../../store/monitoringStoreStates';
import AdminListView from './adminListViews';
import UserProfileView from './userProfileView';

interface Props
{
    userProfiles: UserProfile[];
}

export default class UserProfileViews extends React.PureComponent<Props>
{
    public render()
    {
        return (
            <AdminListView
                title={<h4>User Profiles:</h4>}
                extraButtons={<>
                    <button onClick={this.showCreateAdd}>Add User</button>
                    <button onClick={this.listUserProfiles}>List Users</button>
                    </>
                }>
                { this.props.userProfiles.length === 0 ?
                    <div>
                        <strong>No loaded users</strong>
                    </div>
                    :
                    <table>
                            <thead>
                            <tr>
                                <th>Display Name</th>
                                <th>Username</th>
                                <th>Permissions</th>
                                <th>Sources</th>
                                <th>SourceChannels</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.renderUserProfiles() }
                        </tbody>
                    </table>
                }

            </AdminListView>
        )
    }

    private renderUserProfiles()
    {
        return Object.values(this.props.userProfiles)
            .map(u => <UserProfileView key={u.id} userProfile={u} />);
    }

    private listUserProfiles = () =>
    {
        Api.listUserProfiles();
    }

    private showCreateAdd = () =>
    {
        monitoringStore.execute(SetUpdateUserProfile.showCreate({
            id: '',
            displayName: '',
            username: '',
            permissions: [],
            whitelistedSources: [],
            whitelistedSourceChannels: []
        }));
    }
}