import cogoToast from 'cogo-toast';
import React from 'react';
import {Api} from '../../api/api';
import SetUpdateUserProfile from '../../store/admin/setUpdateUserProfileState';
import monitoringStore from '../../store/monitoringStore';
import {FullSource, SourceChannelId, SourceChannelInfoMap, SourceId, UserProfileWithPassword} from '../../store/monitoringStoreStates';
import CachedValue from '../../utils/cachedValue';
import {InputChangeEvent} from '../../utils/commonTypes';
import ButtonGroup from '../buttonGroup';
import {ModalBase} from '../modalBase';
import TagInput, {TagValue} from '../tagInput';
import './updateChannelTypeInfoModal.scss';

interface Props
{
    readonly createNew: boolean;
    readonly userProfile: UserProfileWithPassword;
    readonly sourceChannels: SourceChannelInfoMap;
    readonly sources: FullSource[];
}

const AllTag: TagValue = {value: '*', label: 'All', tooltip: 'Click to remove'};

export default class UpdateUserProfileModal extends React.PureComponent<Props>
{
    private cachedAllSourceChannelTags: CachedValue<SourceChannelInfoMap, TagValue[]> = new CachedValue((sourceChannelInfos) =>
    {
        const infos = Object.values(sourceChannelInfos);

        return infos.map(sc => ({
            value: sc.sourceChannel,
            label: `${sc.mainName} ${sc.subscriptName}`,
            tooltip: 'Click to remove'
        }));

    });
    private cachedAllSourceTags: CachedValue<FullSource[], TagValue[]> = new CachedValue((sources) =>
    {
        return sources.map(s => ({
            value: s.source.id,
            label: s.source.name,
            tooltip: 'Click to remove'
        }));
    });

    public render()
    {
        const { createNew, userProfile, sourceChannels, sources } = this.props;

        const sourceChannelTags = this.cachedAllSourceChannelTags.get(sourceChannels);
        const sourceTags = this.cachedAllSourceTags.get(sources);

        return (
            <ModalBase isOpen={true}
                className="update-channel-info-modal"
                onRequestClose={this.close}>

                <h1>{createNew ? 'Create' : 'Update'} UserProfile</h1>

                { !createNew  &&
                    <div>
                        <strong>User Id: </strong>
                        <span data-rh='Internal id of the user.'>{userProfile.id}</span>
                    </div>
                }

                <div>
                    <strong>Username: </strong>
                    <input type="text" data-rh='Username used to login. Changing the username is allowed.'
                        value={userProfile.username}
                        onChange={this.updateUsername} />
                </div>

                <div>
                    <strong>Password: </strong>
                    <input type="password" data-rh='Password for login, must be at least 8 characters.'
                        value={userProfile.password}
                        onChange={this.updatePassword} />
                </div>

                <div>
                    <strong>Confirm Password: </strong>
                    <input type="password" data-rh='Confirm the password entered above.'
                        value={userProfile.confirmPassword}
                        onChange={this.updateConfirmPassword} />
                </div>

                <div>
                    <strong>Display Name: </strong>
                    <input type="text" data-rh='Display name of the user.'
                        value={userProfile.displayName}
                        onChange={this.updateDisplayName} />
                </div>

                <div>
                    <strong>Permissions: </strong>
                    <input type="text" data-rh='List of coma separated permission tags, admin is the main one.'
                        value={userProfile.permissions.join(', ')}
                        onChange={this.updatePermissions} />
                </div>

                <div>
                    <strong>Whitelisted Sources: </strong>
                    <TagInput values={userProfile.whitelistedSources}
                        allSuggestion={AllTag}
                        suggestions={sourceTags}
                        tooltip='Whitelisted sources let this user see this source.'
                        placeholder='Select source' noPlaceholder='No sources'
                        onChange={this.updateWhitelistedSources} />
                </div>

                <div>
                    <strong>Whitelisted SourceChannels: </strong>
                    <TagInput values={userProfile.whitelistedSourceChannels}
                        allSuggestion={AllTag}
                        suggestions={sourceChannelTags}
                        tooltip='Whitelisted source channels let this user see data for this source channel.'
                        placeholder='Select source channel' noPlaceholder='No source channels'
                        onChange={this.updateWhitelistedSourceChannels} />
                </div>

                <div className="update-channel-info-modal__buttons">
                    <ButtonGroup>
                        <button onClick={this.submit}>{createNew ? 'Create' : 'Update'}</button>
                        <button onClick={this.remove}>Delete</button>
                    </ButtonGroup>
                    <button onClick={this.close}>Close</button>
                </div>

            </ModalBase>
        )
    }

    private close = () =>
    {
        monitoringStore.execute(SetUpdateUserProfile.close());
    }

    private submit = () =>
    {
        const { userProfile } = this.props;

        if (userProfile.displayName.length === 0 || userProfile.username.length === 0)
        {
            cogoToast.warn('All fields need to be filled');
            return;
        }

        if (userProfile.whitelistedSources.length === 0 || userProfile.whitelistedSourceChannels.length === 0)
        {
            if (!confirm('No whitelisted sources or no whitelisted source channels. This user will not be able to see any data, do you wish to continue?'))
            {
                return;
            }
        }

        if (this.props.createNew)
        {
            if (userProfile.password.length < 8 || userProfile.password !== userProfile.confirmPassword)
            {
                cogoToast.warn('Password must be at least 8 characters');
                return;
            }

            Api.createUser(userProfile);
        }
        else
        {
            // Empty password field assumes no update.
            if (userProfile.password.length > 0 && (userProfile.password.length < 8 || userProfile.password !== userProfile.confirmPassword))
            {
                cogoToast.warn('Password must be at least 8 characters');
                return;
            }

            Api.updateUser(userProfile);
        }
    }

    private remove = () =>
    {
        Api.removeUser(this.props.userProfile.id);
    }

    private updateUsername = (e: InputChangeEvent) => this.updateUser({username: e.target.value});
    private updatePassword = (e: InputChangeEvent) => this.updateUser({password: e.target.value});
    private updateConfirmPassword = (e: InputChangeEvent) => this.updateUser({confirmPassword: e.target.value});
    private updateDisplayName = (e: InputChangeEvent) => this.updateUser({displayName: e.target.value});
    private updatePermissions = (e: InputChangeEvent) => this.updateUser({permissions: e.target.value.split(',')});

    private updateWhitelistedSources = (tags: string[]) =>
        this.updateUser({whitelistedSources: tags.map(t => t as SourceId)});
    private updateWhitelistedSourceChannels = (tags: string[]) =>
    this.updateUser({whitelistedSourceChannels: tags.map(t => t as SourceChannelId)});


    private updateUser(data: Partial<UserProfileWithPassword>)
    {
        monitoringStore.execute(SetUpdateUserProfile.updateUserProfile(data));
    }
}