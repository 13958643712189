import React from 'react';
import monitoringStore from '../../store/monitoringStore';
import {SourceDataEnabledState} from '../../store/monitoringStoreStates';
import {addMicro, MicroSeconds, subMicro} from '../../utils/timeTypes';
import {clamp01} from '../../utils/utils';
import WebGLChartCanvas from './webglChartCanvas';
import WebGLChartLeftAxis from './webglChartLeftAxis';
import {ReselectionType} from './webglChartSelection';
import WebGLChartStore, {WebGLChartState, WebGLMouseChartPoint, WebGLTimeRange, WebGLValueRange} from "./webglChartStore";
import {getValueRangeOfVisible, makeTimeRange, makeValueRange} from './webglUtils';

export interface TimeValuePair
{
    readonly mouseX: number;
    readonly mouseY: number;
    readonly time: MicroSeconds;
    readonly value: number;
    readonly outsideBounds: boolean;
}

export type WebGLSelectionState = 'in-progress' | 'done' | 'cancelled';
export type WebGLTimeSelectionHandler = (timeSelectionId: string, selectState: WebGLSelectionState, selection: WebGLTimeRange) => void;
export type WebGLValueSelectionHandler = (valueSelectionId: string, selectState: WebGLSelectionState, selection: WebGLValueRange) => void;
export type ResetTimeViewportHandler = (chartId: string, timeViewportId: string) => void;
export type ResetValueViewportHandler = (chartId: string, valueViewportId: string) => void;

export const EmptyTimeValuePair: TimeValuePair = {time: null, value: null, outsideBounds: true, mouseX: -1, mouseY: -1}
const BoxZoomThreshold = 20;

export interface BaseProps
{
    readonly chartState: WebGLChartState;
    readonly enableTooltips: boolean;
    readonly mouseChartPoints: WebGLMouseChartPoint[];
    readonly tooltip: string;
    readonly sourceDataEnabled: SourceDataEnabledState;

    readonly timeViewport: WebGLTimeRange;
    readonly valueViewport: WebGLValueRange;
    readonly timeSelection: WebGLTimeRange;
    readonly valueSelection: WebGLValueRange;

    readonly onTimeSelect: WebGLTimeSelectionHandler;
    readonly onValueSelect?: WebGLValueSelectionHandler;
    readonly onResetTimeViewport: ResetTimeViewportHandler;
    readonly onResetValueViewport?: ResetValueViewportHandler;
    readonly extraButtons: JSX.Element[];
    readonly enableButtons: boolean;
}

export default class WebGLBaseReactChart<Props extends BaseProps> extends React.PureComponent<Props>
{
    public static defaultProps: Partial<BaseProps> = {
        tooltip: '',
        mouseChartPoints: [],
        extraButtons: [],
        enableButtons: true
    }

    protected mouseDownPair: TimeValuePair = EmptyTimeValuePair;
    protected reselectionType: ReselectionType = 'none';

    protected leftAxisRef: React.RefObject<WebGLChartLeftAxis> = React.createRef();
    protected canvasRef: React.RefObject<WebGLChartCanvas> = React.createRef();
    protected canvasBounds: DOMRect | ClientRect;
    private canvasBoundsDirty: boolean = true;

    public componentDidMount()
    {
        document.addEventListener('mousemove', this.onDocumentMouseMove);
        document.addEventListener('mouseup', this.onDocumentMouseUp);
        document.addEventListener('touchmove', this.onDocumentTouchMove, {passive: false});
        document.addEventListener('touchend', this.onDocumentTouchEnd);
        document.addEventListener('scroll', this.markCanvasBoundsDirty);
        window.addEventListener('resize', this.markCanvasBoundsDirty);

        this.canvasRef.current.canvas.addEventListener('wheel', this.onCanvasMouseWheel, {passive: false});
    }

    public componentWillUnmount()
    {
        document.removeEventListener('mousemove', this.onDocumentMouseMove);
        document.removeEventListener('mouseup', this.onDocumentMouseUp);
        document.removeEventListener('touchmove', this.onDocumentTouchMove);
        document.removeEventListener('touchend', this.onDocumentTouchEnd);
        document.removeEventListener('scroll', this.markCanvasBoundsDirty);
        window.removeEventListener('resize', this.markCanvasBoundsDirty);
    }

    public render(): JSX.Element
    {
        // Does nothing!
        return null;
    }

    public startReselectionResize = (clientX: number, clientY: number, type: ReselectionType) =>
    {
        this.reselectionType = type;
        const {timeViewport, valueViewport} = this.props;
        const mouseDownPair = this.getTimeValueAtLocation(clientX, clientY);

        const middleValue = (valueViewport.maxValue + valueViewport.minValue) * 0.5;
        const middleTime = ((timeViewport.maxTime + timeViewport.minTime) * 0.5) as MicroSeconds;

        if (type === 'left')
        {
            this.mouseDownPair = {...mouseDownPair, time: timeViewport.maxTime, value: middleValue};
        }
        else if (type === 'right')
        {
            this.mouseDownPair = {...mouseDownPair, time: timeViewport.minTime, value: middleValue};
        }
        else if (type === 'top')
        {
            this.mouseDownPair = {...mouseDownPair, time: middleTime, value: valueViewport.minValue};
        }
        else if (type === 'bottom')
        {
            this.mouseDownPair = {...mouseDownPair, time: middleTime, value: valueViewport.maxValue};
        }
        else if (type === 'top-left')
        {
            this.mouseDownPair = {...mouseDownPair, time: timeViewport.maxTime, value: valueViewport.minValue};
        }
        else if (type === 'top-right')
        {
            this.mouseDownPair = {...mouseDownPair, time: timeViewport.minTime, value: valueViewport.minValue};
        }
        else if (type === 'bottom-left')
        {
            this.mouseDownPair = {...mouseDownPair, time: timeViewport.maxTime, value: valueViewport.maxValue};
        }
        else if (type === 'bottom-right')
        {
            this.mouseDownPair = {...mouseDownPair, time: timeViewport.minTime, value: valueViewport.maxValue};
        }
        else
        {
            this.mouseDownPair = mouseDownPair;
        }
    }

    public stopReselection()
    {
        this.reselectionType = 'none';
    }

    protected resetZoom = () =>
    {
        const { chartState } = this.props;
        this.props.onResetTimeViewport(chartState.id, chartState.timeSelectionId);

        if (this.props.onResetValueViewport)
        {
            this.props.onResetValueViewport(chartState.id, chartState.valueSelectionId);
        }
    }

    protected resetTimeZoom = () =>
    {
        const { chartState } = this.props;
        this.props.onResetTimeViewport(chartState.id, chartState.timeSelectionId);
    }

    protected autoYZoom = () =>
    {
        const { chartState, timeViewport, sourceDataEnabled, onValueSelect } = this.props;
        if (!onValueSelect)
        {
            return;
        }

        const valueRange = getValueRangeOfVisible(chartState, timeViewport, sourceDataEnabled);
        onValueSelect(chartState.id, 'done', valueRange);
    }

    protected onCanvasMouseDown = (event: React.MouseEvent) =>
    {
        this.onCanvasPointerDown(event.clientX, event.clientY);
    }

    protected onCanvasTouchStart = (event: React.TouchEvent) =>
    {
        const touch = event.touches[0];
        this.onCanvasPointerDown(touch.clientX, touch.clientY);
    }

    protected onCanvasPointerDown = (clientX: number, clientY: number) =>
    {
        this.cancelSelection();
        this.mouseDownPair = this.getTimeValueAtLocation(clientX, clientY);
    }

    protected onDocumentMouseMove = (event: MouseEvent) =>
    {
        this.onPointerMove(event.clientX, event.clientY);
    }

    protected onDocumentTouchMove = (event: TouchEvent) =>
    {
        const touch = event.touches[0];
        this.onPointerMove(touch.clientX, touch.clientY);
    }

    protected onPointerMove = (clientX: number, clientY: number) =>
    {
        const mouseMovePair = this.getTimeValueAtLocation(clientX, clientY);

        if (this.props.enableTooltips)
        {
            const chartId = this.props.chartState.id;
            if (mouseMovePair.outsideBounds)
            {
                monitoringStore.execute(WebGLChartStore.setMouseOff(chartId));
            }
            else
            {
                monitoringStore.execute(WebGLChartStore.setMouseOver(chartId, mouseMovePair.time));
            }
        }

        if (this.mouseDownPair.value == null)
        {
            return false;
        }

        const timeSelection = this.getTimeSelection(mouseMovePair);
        this.props.onTimeSelect(this.props.chartState.timeSelectionId, 'in-progress', timeSelection);

        if (this.props.onValueSelect)
        {
            const valueSelection = this.getValueSelection(mouseMovePair);
            this.props.onValueSelect(this.props.chartState.valueSelectionId, 'in-progress', valueSelection);
        }

        return true;
    }

    protected onDocumentMouseUp = (event: MouseEvent) =>
    {
        this.onPointerUp(event.clientX, event.clientY);
    }

    protected onDocumentTouchEnd = (event: TouchEvent) =>
    {
        const touch = event.changedTouches[0];
        this.onPointerUp(touch.clientX, touch.clientY);
    }

    protected onPointerUp = (clientX: number, clientY: number) =>
    {
        if (this.mouseDownPair.value == null)
        {
            return;
        }

        const currentPair = this.getTimeValueAtLocation(clientX, clientY);
        const timeSelection = this.getTimeSelection(currentPair);

        this.props.onTimeSelect(this.props.chartState.timeSelectionId, 'done', timeSelection);

        if (this.props.onValueSelect)
        {
            const valueSelection = this.getValueSelection(currentPair);
            this.props.onValueSelect(this.props.chartState.valueSelectionId, 'done', valueSelection);
        }

        this.mouseDownPair = EmptyTimeValuePair;
        this.stopReselection();
    }

    private onCanvasMouseWheel = (e: MouseWheelEvent) =>
    {
        if (e.ctrlKey)
        {
            e.preventDefault();

            const viewport = this.props.timeViewport;

            const middle = (viewport.maxTime + viewport.minTime) * 0.5;
            const diff = viewport.maxTime - middle;

            const factor = e.deltaY > 0 ? 1.05 : 0.95;

            const maxTime = (middle + diff * factor) as MicroSeconds;
            const minTime = (middle - diff * factor) as MicroSeconds;
            const newViewport = makeTimeRange(minTime, maxTime);

            this.props.onTimeSelect(this.props.chartState.timeSelectionId, 'done', newViewport);
        }

        if (e.shiftKey)
        {
            e.preventDefault();

            const viewport = this.props.timeViewport;

            const middle = (viewport.maxTime + viewport.minTime) * 0.5;
            const diff = viewport.maxTime - middle;

            const factor = e.deltaY > 0 ? 0.05 : -0.05;

            const maxTime = (viewport.maxTime + diff * factor) as MicroSeconds;
            const minTime = (viewport.minTime + diff * factor) as MicroSeconds;
            const newViewport = makeTimeRange(minTime, maxTime);

            this.props.onTimeSelect(this.props.chartState.timeSelectionId, 'done', newViewport);
        }
    }

    protected getValueSelection(currentMouse: TimeValuePair): WebGLValueRange
    {
        if (this.reselectionType === 'none')
        {
            if (!this.props.chartState.enableValueSelect)
            {
                return this.props.valueViewport;
            }

            const dx = Math.abs(this.mouseDownPair.mouseX - currentMouse.mouseX);
            const dy = Math.abs(this.mouseDownPair.mouseY - currentMouse.mouseY);

            if (dx > BoxZoomThreshold && dy < BoxZoomThreshold)
            {
                return this.props.valueViewport;
            }
        }
        else if (this.reselectionType === 'left' || this.reselectionType === 'right')
        {
            return this.props.valueViewport;
        }
        else if (this.reselectionType === 'move')
        {
            const valueOffset = currentMouse.value - this.mouseDownPair.value;

            const minValue = this.props.valueViewport.minValue + valueOffset;
            const maxValue = this.props.valueViewport.maxValue + valueOffset;

            return makeValueRange(minValue, maxValue);
        }

        const minValue = Math.min(this.mouseDownPair.value, currentMouse.value);
        const maxValue = Math.max(this.mouseDownPair.value, currentMouse.value);

        return makeValueRange(minValue, maxValue);
    }

    protected getTimeSelection(currentMouse: TimeValuePair): WebGLTimeRange
    {
        if (this.reselectionType === 'none')
        {
            if (!this.props.chartState.enableTimeSelect)
            {
                return this.props.timeViewport;
            }

            const dx = Math.abs(this.mouseDownPair.mouseX - currentMouse.mouseX);
            const dy = Math.abs(this.mouseDownPair.mouseY - currentMouse.mouseY);

            if (dx < BoxZoomThreshold && dy > BoxZoomThreshold)
            {
                return this.props.timeViewport;
            }
        }
        else if (this.reselectionType === 'top' || this.reselectionType === 'bottom')
        {
            return this.props.timeViewport;
        }
        else if (this.reselectionType === 'move')
        {
            const timeOffset = subMicro(currentMouse.time, this.mouseDownPair.time);

            const minTime = addMicro(this.props.timeViewport.minTime, timeOffset);
            const maxTime = addMicro(this.props.timeViewport.maxTime, timeOffset);
            return makeTimeRange(minTime, maxTime);
        }

        const minTime = Math.min(this.mouseDownPair.time, currentMouse.time) as MicroSeconds;
        const maxTime = Math.max(this.mouseDownPair.time, currentMouse.time) as MicroSeconds;

        return makeTimeRange(minTime, maxTime);
    }

    protected cancelSelection()
    {
        this.mouseDownPair = EmptyTimeValuePair;

        this.props.onTimeSelect(this.props.chartState.timeSelectionId, 'cancelled', null);

        if (this.props.onValueSelect)
        {
            this.props.onValueSelect(this.props.chartState.valueSelectionId, 'cancelled', null);
        }
    }

    protected getTimeValueAtLocation(clientX: number, clientY: number): TimeValuePair
    {
        this.updateCanvasBounds();

        const mouseX = clientX - this.canvasBounds.left;
        const mouseY = clientY - this.canvasBounds.top;

        const outsideBounds = mouseX < 0 || mouseY < 0 || mouseX > this.canvasBounds.width || mouseY > this.canvasBounds.height;

        const percentX = clamp01(mouseX / this.canvasBounds.width);
        const percentY = clamp01(mouseY / this.canvasBounds.height);

        const time = this.getTimeAtLocation(percentX);
        const value = this.getValueAtLocation(percentY);

        return { time, value, outsideBounds, mouseX, mouseY }
    }

    protected getTimeAtLocation(percentX: number): MicroSeconds
    {
        const { timeViewport } = this.props;
        return (percentX * timeViewport.width + timeViewport.minTime) as MicroSeconds;
    }

    protected getValueAtLocation(percentY: number)
    {
        const { valueViewport } = this.props;

        return (1 - percentY) * valueViewport.height + valueViewport.minValue;
    }

    protected updateCanvasBounds()
    {
        if (this.canvasBoundsDirty)
        {
            this.canvasBounds = this.canvasRef.current.canvas.getBoundingClientRect();
            this.canvasBoundsDirty = false;
        }
    }

    protected getCanvasWidth = () =>
    {
        if (this.canvasRef.current)
        {
            return this.canvasRef.current.canvas.offsetWidth;
        }
        return 0;
    }

    private markCanvasBoundsDirty = () =>
    {
        this.canvasBoundsDirty = true;
    }
}