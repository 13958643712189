import React, {CSSProperties} from 'react';
import WebGLBaseReactChart from './webglBaseReactChart';
import {WebGLTimeRange, WebGLValueRange} from './webglChartStore';

interface Props
{
    readonly timeViewport?: WebGLTimeRange;
    readonly valueViewport?: WebGLValueRange;
    readonly timeSelect?: WebGLTimeRange;
    readonly valueSelect?: WebGLValueRange;

    readonly enableTimeSelect: boolean;
    readonly enableValueSelect: boolean;

    readonly reselectableParent?: WebGLBaseReactChart<any>;
}

export type ReselectionType = 'none' | 'move' | 'top-left' | 'top' | 'top-right' | 'left' | 'right' | 'bottom-left' | 'bottom' | 'bottom-right';

export default class WebGLChartSelection extends React.PureComponent<Props>
{
    public render()
    {
        const { enableTimeSelect, enableValueSelect, valueViewport, timeViewport } = this.props;
        if ((enableTimeSelect && this.props.timeSelect == null) && (enableValueSelect && this.props.valueSelect == null))
        {
            return null;
        }

        const timeSelect = this.props.timeSelect || timeViewport;
        const valueSelect = this.props.valueSelect || valueViewport;

        let percentMinX = 0;
        let percentMaxX = 1;

        if (enableTimeSelect)
        {
            percentMinX = (timeSelect.minTime - timeViewport.minTime) / timeViewport.width;
            percentMaxX = (timeSelect.maxTime - timeViewport.minTime) / timeViewport.width;
        }

        let percentMinY = 0;
        let percentMaxY = 1;

        if (enableValueSelect)
        {
            percentMinY = (valueSelect.minValue - valueViewport.minValue) / valueViewport.height;
            percentMaxY = (valueSelect.maxValue - valueViewport.minValue) / valueViewport.height;
        }

        percentMinX = Math.max(0, percentMinX);
        percentMinY = Math.max(0, percentMinY);
        percentMaxX = Math.min(1, percentMaxX);
        percentMaxY = Math.min(1, percentMaxY);

        if (percentMaxX >= 1 && percentMaxY >= 1 && percentMinY <= 0 && percentMinX <= 0)
        {
            return null;
        }

        const selectWidth = percentMaxX - percentMinX;
        const selectHeight = percentMaxY - percentMinY;

        const style: CSSProperties = {
            left: `${percentMinX * 100}%`,
            width: `${selectWidth * 100}%`,

            top: `${(1 - percentMaxY) * 100}%`,
            height: `${selectHeight * 100}%`
        }

        if (this.props.reselectableParent)
        {
            return <div className='webgl-chart__selection is--reselectable' style={style} onMouseDown={this.onMouseDown} >
                    <div className='webgl-chart__selection-handle is--top-left' />
                    <div className='webgl-chart__selection-handle is--top' />
                    <div className='webgl-chart__selection-handle is--top-right' />
                    <div className='webgl-chart__selection-handle is--left' />
                    <div className='webgl-chart__selection-handle is--right' />
                    <div className='webgl-chart__selection-handle is--bottom-left' />
                    <div className='webgl-chart__selection-handle is--bottom' />
                    <div className='webgl-chart__selection-handle is--bottom-right' />
                </div>;
        }

        return <div className='webgl-chart__selection' style={style} />;
    }

    private onMouseDown = (e: React.MouseEvent) =>
    {
        e.stopPropagation();
        const target = e.target as HTMLElement;
        this.onPointerDown(target, e.clientX, e.clientY);
    }

    private onPointerDown = (target: HTMLElement, clientX: number, clientY: number) =>
    {
        if (target.classList.contains('webgl-chart__selection'))
        {
            this.props.reselectableParent.startReselectionResize(clientX, clientY, 'move');
        }
        else
        {
            let reselectionType: ReselectionType = 'none';
            target.classList.forEach((value: string) =>
            {
                if (value.startsWith('is--'))
                {
                    reselectionType = value.replace('is--', '') as ReselectionType;
                }
            });
            this.props.reselectableParent.startReselectionResize(clientX, clientY, reselectionType);
        }

    }
}