import React, {CSSProperties} from 'react';
import monitoringStore from '../../store/monitoringStore';
import {Source, SourceEvent, SourceEventLevel, SourceToEventMap} from '../../store/monitoringStoreStates';
import ShowEventModal from '../../store/showEventModal';
import ShowEventSelectorModal from '../../store/showEventSelectorModal';
import {microToMilli} from '../../utils/timeTypes';
import {formatDateTime} from '../../utils/utils';
import {RenderContext} from './timelineCommon';

interface Props
{
    readonly renderContext: RenderContext;
    readonly sources: Source[];
    readonly sourceToEventMap: SourceToEventMap;
}

interface RenderEventData
{
    xpos: number;
    sourceEvents: SourceEvent[];
    level: SourceEventLevel;
}

function levelNumber(level: SourceEventLevel)
{
    switch (level)
    {
        case 'alert': return 3;
        case 'warning': return 2;
        case 'info': return 1;
        default: return 1;
    }
}

export default class TimelineSourceEvents extends React.PureComponent<Props>
{
    private eventsRenderMerge: { [timeKey: string]: RenderEventData }

    public render()
    {
        if (!this.props.sourceToEventMap || !this.props.sources)
        {
            return null;
        }

        return (
            <div className="timeline__source-events">
                { this.renderEvents() }
            </div>
        )
    }

    private renderEvents(): JSX.Element[]
    {
        const { renderContext, sources, sourceToEventMap } = this.props;

        const result: JSX.Element[] = [];

        const startValue = renderContext.leftMs.valueOf();
        const endValue = renderContext.rightMs.valueOf();

        this.eventsRenderMerge = {}

        for (const source of sources)
        {
            const sourceEvents = sourceToEventMap[source.id];

            for (const sourceEventId in sourceEvents)
            {
                const sourceEvent = sourceEvents[sourceEventId];
                const timestamp = microToMilli(sourceEvent.timestamp);

                if (timestamp < startValue || timestamp > endValue)
                {
                    continue;
                }

                this.preRenderEvent(sourceEvent);
            }
        }

        for (const key in this.eventsRenderMerge)
        {
            const renderData = this.eventsRenderMerge[key];
            result.push(this.renderEvent(renderData));
        }

        return result;
    }

    private preRenderEvent(sourceEvent: SourceEvent)
    {
        const { renderContext } = this.props;
        const timestamp = microToMilli(sourceEvent.timestamp);
        const xpos = renderContext.calculateXposFromTime(timestamp, false);

        const timeKey = Math.round(timestamp / renderContext.timePerPixelMs / 4).toString(10);
        let renderData = this.eventsRenderMerge[timeKey];
        if (!renderData)
        {
            renderData = {
                xpos,
                level: sourceEvent.level,
                sourceEvents: []
            }
            this.eventsRenderMerge[timeKey] = renderData;
        }

        renderData.sourceEvents.push(sourceEvent);
        if (sourceEvent.level !== renderData.level && (levelNumber(sourceEvent.level) > levelNumber(renderData.level)))
        {
            renderData.level = sourceEvent.level;
        }
    }

    private renderEvent(renderEventData: RenderEventData)
    {
        let tooltip = '';
        for (var sourceEvent of renderEventData.sourceEvents)
        {
            if (tooltip.length > 0)
            {
                tooltip += '\n---\n';
            }
            tooltip += `Event: ${sourceEvent.level}
                Time: ${formatDateTime(sourceEvent.timestamp)}
                Message: ${sourceEvent.message}`;
        }

        const style: CSSProperties = {
            transform: `translateX(${renderEventData.xpos}px)`
        }

        const className=`timeline-source-event is--${renderEventData.level}`;
        const timestamp = microToMilli(sourceEvent.timestamp);
        const key = `timeline-source-event_${sourceEvent.id}_${timestamp}`;

        const onclick = renderEventData.sourceEvents.length > 1 ?
            () => this.showSelectorModal(renderEventData.sourceEvents) :
            () => this.showModal(renderEventData.sourceEvents[0]);

        return <span key={key} className={className} style={style} data-rh={tooltip} onClick={onclick} />;
    }

    private showModal(sourceEvent: SourceEvent)
    {
        monitoringStore.execute(ShowEventModal.show(sourceEvent));
    }

    private showSelectorModal(sourceEvents: SourceEvent[])
    {
        monitoringStore.execute(ShowEventSelectorModal.show(sourceEvents));
    }
}