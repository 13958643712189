import React from 'react';
import {ConnectionState} from '../store/monitoringStoreStates';
import {ClickHandler} from '../utils/commonTypes';
import './connectionIndicator.scss';
import Icon from './icon';
import LoadingSpinner from './loadingSpinner';

interface Props
{
    status: ConnectionState;
    onClick?: ClickHandler;
}

export default class ConnectionIndicator extends React.PureComponent<Props>
{
    public render ()
    {
        const { status } = this.props;
        let title = `Currently ${status} to the server.`;
        if (status === 'closed')
        {
            title += ' Click to attempt to connect.';
        }

        return <span data-rh={title}
            onClick={this.props.onClick}
            className={`connection-indicator is--${this.props.status}`}>
                <span className="connection-indicator__icon">
                    { this.renderIcon() }
                </span>
                <span className="connection-indicator__text">{this.props.status}</span>
            </span>;
    }

    private renderIcon()
    {
        const { status } = this.props;

        if (status === 'connected')
        {
            return <Icon type="check" forceTheme='light' />;
        }
        if (status === 'connecting' || status === 'reconnecting')
        {
            return <LoadingSpinner key={'connection-indicator-connecting'} black={true} small={true} />;
        }
        if (status === 'closed' || status === 'error')
        {
            return <Icon type="cross" forceTheme='light' />;
        }

        return null;
    }
}
