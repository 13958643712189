import React from 'react';
import {Api} from '../api/api';
import monitoringStore from '../store/monitoringStore';
import {SourceEvent} from '../store/monitoringStoreStates';
import ShowEventModal from '../store/showEventModal';
import UpdateSourceEvent from '../store/updateSourceEvent';
import './sourceEventView.scss';

interface Props
{
    readonly sourceEvent: SourceEvent;
    readonly fullWidth?: boolean;
}

export default class SourceEventView extends React.PureComponent<Props>
{
    public render()
    {
        const sourceEvent = this.props.sourceEvent;

        let className = `source-event is--${this.props.sourceEvent.level}`;

        if (this.props.sourceEvent.status === 'unread')
        {
            className += ` is--highlighted-${this.props.sourceEvent.level}`;
        }

        if (this.props.fullWidth === true)
        {
            className += ' full-width';
        }

        let title = this.props.sourceEvent.status === 'unread'
            ? 'This alert has not been looked at yet.'
            : 'This alert has been looked at but not dismissed.';

        return (
            <div data-rh={title} className={className} onClick={this.showModal}>
                {sourceEvent.message}
            </div>
        );
    }

    private showModal = () =>
    {
        const sourceEvent = this.props.sourceEvent;

        monitoringStore.execute(ShowEventModal.show(sourceEvent));

        Api.updateEventStatus(sourceEvent.sourceId, sourceEvent.id, 'active');
        monitoringStore.execute(UpdateSourceEvent.status(sourceEvent.sourceId, sourceEvent.id, 'active'));
    }

}