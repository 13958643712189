import React from 'react';
import {addThemeChangeListener, isDarkTheme, RemoveListener} from '../..';
import {SourceDataEnabledState} from '../../store/monitoringStoreStates';
import {WebGLChartState, WebGLTimeRange, WebGLValueRange} from './webglChartStore';
import WebGLLineChart from './webglLineChart';

interface Props
{
    readonly chartState: WebGLChartState;
    readonly sourceDataEnabled: SourceDataEnabledState;
    readonly onWebGLRender?: (webgl: WebGLLineChart) => void;

    readonly timeViewport: WebGLTimeRange;
    readonly valueViewport: WebGLValueRange;
}

export default class WebGLChartCanvas extends React.PureComponent<Props>
{
    public canvas: HTMLCanvasElement;

    private webgl: WebGLLineChart;
    private canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();
    private removeThemeChange: RemoveListener;

    public componentDidMount()
    {
        if (!this.canvasRef.current)
        {
            return;
        }

        this.canvas = this.canvasRef.current;
        this.webgl = new WebGLLineChart(this.canvas);
        this.webgl.init();
        this.updateDarkTheme(isDarkTheme());

        this.removeThemeChange = addThemeChangeListener(this.updateDarkTheme);

        this.checkForChartDataChange();

        window.addEventListener('resize', this.handleResize);
    }

    public componentDidUpdate(prevProps: Props)
    {
        this.checkForChartDataChange();
    }

    public componentWillUnmount()
    {
        if (this.canvas)
        {
            this.webgl.destroy();
        }

        if (this.removeThemeChange)
        {
            this.removeThemeChange();
        }

        window.removeEventListener('resize', this.handleResize);
    }

    public render()
    {
        return <canvas className='webgl-chart__canvas' ref={this.canvasRef} />;
    }

    private handleResize = () =>
    {
        this.webgl.resizeCanvas();
    }

    private checkForChartDataChange()
    {
        const { chartState, sourceDataEnabled } = this.props;

        if (this.webgl != null)
        {
            this.webgl.checkForSourceDataEnabledChanges(sourceDataEnabled);
            this.webgl.checkForDataSeriesChanges(chartState.dataSeries);
            this.doWebGLRender();
        }
    }

    private doWebGLRender()
    {
        if (this.props.onWebGLRender)
        {
            this.props.onWebGLRender(this.webgl);
        }
        else
        {
            const { valueViewport, timeViewport, chartState } = this.props;
            this.webgl.render(timeViewport, valueViewport, chartState.minStartTime, chartState.id);
        }
    }

    private updateDarkTheme = (darkTheme: boolean) =>
    {
        const v = darkTheme ? 0 : 1;
        this.webgl.setClearColor(v, v, v);
        this.doWebGLRender();
    }
}