import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {LoginState, LoginStatus, State} from "./monitoringStoreStates";

export default class SetLoginToken
{
    public static loginStatus (loginStatus: LoginStatus, isNewLogin: boolean): Modifier<State>
    {
        return this.execute(loginStatus, null, null, isNewLogin);
    }

    public static token (token: string): Modifier<State>
    {
        return this.execute('not-logged-in', token, true);
    }

    public static invalid (): Modifier<State>
    {
        return this.execute('login-error', null, false);
    }

    public static logout (): Modifier<State>
    {
        return this.execute('logged-out', null, false);
    }

    private static execute(loginStatus: LoginStatus, loginToken?: string, tokenValid?: boolean, isNewLogin?: boolean): Modifier<State>
    {
        return (state: State) =>
        {
            const status = loginStatus === 'logged-out' ? 'not-logged-in' : loginStatus;
            const newLoginState: Editable<LoginState> = { ...state.loginState, loginStatus: status };
            if (tokenValid != null)
            {
                newLoginState.tokenStatus = tokenValid ? 'valid' : 'invalid';
            }
            if (isNewLogin)
            {
                newLoginState.isNewLogin = true;
            }

            let newServerState = state.serverState;
            if (loginToken != null)
            {
                newServerState = { ...newServerState, loginToken: loginToken };
            }

            if (loginStatus === 'logged-out')
            {
                newServerState = { ...newServerState, loginToken: '' };
            }

            return {serverState: newServerState, loginState: newLoginState};
        }
    }
}