import React from 'react';
import monitoringStore from '../../store/monitoringStore';
import {Source, SourceDataEnabledState} from '../../store/monitoringStoreStates';
import {WebGLSelectionState} from './webglBaseReactChart';
import WebGLChart from './webglChart';
import WebGLChartFFT from './webglChartFFT';
import WebGLChartPreview from './webglChartPreview';
import WebGLChartRms from './webglChartRms';
import WebGLChartStore, {ChartId, WebGLChartsState, WebGLMouseChartPoint, WebGLTimeRange, WebGLValueRange} from './webglChartStore';

interface Props
{
    readonly webglChartState: WebGLChartsState;
    readonly chartId: ChartId;
    readonly sourceDataEnabled: SourceDataEnabledState;
    readonly timeChartId?: ChartId;
    readonly extraButtons?: JSX.Element[];
    readonly source: Source;
    readonly enableButtons: boolean;

    readonly type: 'main' | 'preview' | 'rms' | 'fft';
}

const EmptyMouseChartPoints: WebGLMouseChartPoint[] = [];

export default class WebGLControlledChart extends React.PureComponent<Props>
{
    public static defaultProps: Partial<Props> = {
        timeChartId: '' as ChartId,
        extraButtons: [],
        enableButtons: true
    }

    public render()
    {
        const { chartId, webglChartState, type, sourceDataEnabled, extraButtons, enableButtons } = this.props;

        const graphState = webglChartState.charts[chartId];
        if (!graphState)
        {
            return null;
        }

        const sourceChannels = graphState.dataSeries.map(ds => ds.sourceChannelId);
        const checkForAnyEnabled = sourceChannels.filter(sc => sourceDataEnabled[sc] !== false);
        if (checkForAnyEnabled.length === 0)
        {
            return null;
        }


        const timeSelect = webglChartState.timeSelections[graphState.timeSelectionId];
        const valueSelect = webglChartState.valueSelections[graphState.valueSelectionId];
        const timeViewport = webglChartState.timeViewports[graphState.timeSelectionId];
        const valueViewport = webglChartState.valueViewports[graphState.valueSelectionId];

        let tooltip = '';
        let mouseChartPoints = EmptyMouseChartPoints;

        if (webglChartState.mouseData.enabled)
        {
            if (webglChartState.mouseData.triggeredChartId === chartId)
            {
                tooltip = webglChartState.mouseData.tooltipText;
            }

            mouseChartPoints = webglChartState.mouseData.chartPoints[chartId] || EmptyMouseChartPoints;
        }

        if (type === 'preview')
        {
            return <WebGLChartPreview chartState={graphState} timeSelection={timeSelect} valueSelection={valueSelect}
                timeViewport={timeViewport} valueViewport={valueViewport}
                onTimeSelect={onChartTimeSelect}
                onValueSelect={onChartValueSelect}
                onResetTimeViewport={this.onChartResetTimeZoom}
                onResetValueViewport={onChartResetValueZoom}
                enableTooltips={false}
                sourceDataEnabled={sourceDataEnabled}
                />;
        }
        else if (type === 'rms')
        {
            return <WebGLChartRms chartState={graphState} timeSelection={timeSelect} valueSelection={valueSelect}
                timeViewport={timeViewport} valueViewport={valueViewport}
                onTimeSelect={onChartTimeSelect}
                onValueSelect={onChartValueSelect}
                onResetTimeViewport={this.onChartResetTimeZoom}
                onResetValueViewport={onChartResetValueZoom}
                enableTooltips={true}
                tooltip={tooltip}
                mouseChartPoints={mouseChartPoints}
                sourceDataEnabled={sourceDataEnabled}
                extraButtons={extraButtons}
                enableButtons={enableButtons}
                />;
        }
        else if (type === 'fft')
        {
            return <WebGLChartFFT chartState={graphState} timeSelection={timeSelect} valueSelection={valueSelect}
                timeViewport={timeViewport} valueViewport={valueViewport}
                onTimeSelect={onChartTimeSelect}
                onValueSelect={onChartValueSelect}
                onResetTimeViewport={this.onChartResetTimeZoom}
                onResetValueViewport={onChartResetValueZoom}
                enableTooltips={true}
                tooltip={tooltip}
                mouseChartPoints={mouseChartPoints}
                sourceDataEnabled={sourceDataEnabled}
                extraButtons={extraButtons}
                enableButtons={enableButtons}
                />;
        }

        return <WebGLChart chartState={graphState} timeSelection={timeSelect} valueSelection={valueSelect}
            timeViewport={timeViewport} valueViewport={valueViewport}
            onTimeSelect={onChartTimeSelect}
            onValueSelect={onChartValueSelect}
            onResetTimeViewport={this.onChartResetTimeZoom}
            onResetValueViewport={onChartResetValueZoom}
            enableTooltips={true}
            tooltip={tooltip}
            mouseChartPoints={mouseChartPoints}
            sourceDataEnabled={sourceDataEnabled}
            extraButtons={extraButtons}
            enableButtons={enableButtons}
            />;
    }

    private onChartResetTimeZoom = (chartId: ChartId, timeViewportId: ChartId) =>
    {
        const {timeChartId} = this.props;
        if (timeChartId)
        {
            chartId = timeChartId;
        }

        monitoringStore.execute(WebGLChartStore.resetTimeViewport(chartId, timeViewportId))
    }

}

function onChartResetValueZoom(chartId: ChartId, valueViewportId: ChartId)
{
    monitoringStore.execute(WebGLChartStore.resetValueViewport(chartId, valueViewportId))
}

function onChartTimeSelect(timeSelectionId: ChartId, selectState: WebGLSelectionState, timeSelect: WebGLTimeRange)
{
    if (selectState === 'done')
    {
        monitoringStore.execute(WebGLChartStore.setTimeSelectionAndViewport(timeSelectionId, null, timeSelect))
    }
    else if (selectState === 'in-progress')
    {
        monitoringStore.execute(WebGLChartStore.setTimeSelection(timeSelectionId, timeSelect));
    }
    else
    {
        monitoringStore.execute(WebGLChartStore.setTimeSelection(timeSelectionId, null));
    }
}

function onChartValueSelect(valueSelectionId: ChartId, selectState: WebGLSelectionState, valueSelect: WebGLValueRange)
{
    if (selectState === 'done')
    {
        monitoringStore.execute(WebGLChartStore.setValueSelection(valueSelectionId, null));
        monitoringStore.execute(WebGLChartStore.setValueViewport(valueSelectionId, valueSelect));
    }
    else if (selectState === 'in-progress')
    {
        monitoringStore.execute(WebGLChartStore.setValueSelection(valueSelectionId, valueSelect));
    }
    else
    {
        monitoringStore.execute(WebGLChartStore.setValueSelection(valueSelectionId, null));
    }
}
