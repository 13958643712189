import {Modifier} from "simple-data-store";
import {modifyAdminState} from "../monitoringStore";
import {EventReportsState, State} from "../monitoringStoreStates";
import moment = require("moment");

function modifyEventReports(state: State, reprocessEvent: Partial<EventReportsState>): Partial<State>
{
    return modifyAdminState(state, {eventReportsState:
        {...state.adminState.eventReportsState, ...reprocessEvent}
    });
}

export default class SetEventReportsModal
{
    public static show (start: moment.Moment, end: moment.Moment): Modifier<State>
    {
        return (state: State) => modifyEventReports(state, {isOpen: true, start, end});
    }

    public static start (start: moment.Moment): Modifier<State>
    {
        return (state: State) => modifyEventReports(state, {start});
    }

    public static end (end: moment.Moment): Modifier<State>
    {
        return (state: State) => modifyEventReports(state, {end});
    }

    public static close (): Modifier<State>
    {
        return (state: State) => modifyEventReports(state, {isOpen: false});
    }
}