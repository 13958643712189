import React from 'react';
import monitoringStore from '../store/monitoringStore';
import {Source, SourceActiveStatus, SourceActiveStatuses, SourceId, SourceStatuses} from '../store/monitoringStoreStates';
import ShowDetailedInfoModal from '../store/showDetailedInfoModal';
import {ZeroMicro} from '../utils/timeTypes';
import SourceInfoView from './sourceInfoView';
import './sourceInfoView.scss';

interface Props
{
    sources: Source[];
    sourceStatuses: SourceStatuses;
    sourceActiveStatuses: SourceActiveStatuses;
}
const defaultActiveStatus: SourceActiveStatus = {
    isWebsocketConnected: false,
    lastDataReceivedTime: ZeroMicro,
    lastHeartbeatTime: ZeroMicro,
    sourceId: "unknown" as SourceId,
    timestamp: ZeroMicro
}
export default class SourceInfosView extends React.PureComponent<Props>
{
    private showInfo(source: Source)
    {
        monitoringStore.execute(ShowDetailedInfoModal.show(source));
    }

    public render()
    {
        return (
            <table className="source-info">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Timestamps</th>
                        <th>Temps</th>
                        <th>Sys Info</th>
                        <th>IPs</th>
                        <th>Repo Commit</th>
                        <th>Load Averages</th>
                    </tr>
                </thead>

                <tbody>
                { this.props.sources.map((source) => {
                    const status = this.props.sourceStatuses[source.id] || null;
                    const activeStatus = this.props.sourceActiveStatuses[source.id] || defaultActiveStatus;
                    return (
                        <tr key={`info-${source.id}`}>
                            <td>
                                <strong>{source.name}</strong><br/>
                                { activeStatus && <small>{activeStatus.isWebsocketConnected ? 'Active' : 'Inactive'}</small>}
                                <br/>
                                <button className="button button-outline" onClick={() => this.showInfo(source)}>Show Info</button>
                            </td>
                            { status && <SourceInfoView sourceStatus={status} sourceActiveStatus={activeStatus} /> }
                        </tr>
                    )
                }) }
                </tbody>
            </table>
        )
    }
}