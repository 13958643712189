import React from 'react';
import monitoringStore from '../../store/monitoringStore';
import {SourceChannelId} from '../../store/monitoringStoreStates';
import SetSourceDataEnabled from '../../store/setSourceDataEnabled';
import {SourceChannelInfoManager} from '../../store/sourceChannelInfo';
import SliderCheckbox from '../sliderCheckbox';
import WebGLChartStore from '../webglChart/webglChartStore';
import './sourceDataTypeSelector.scss';

interface Props
{
    readonly forMapId: string;
    readonly sourceChannelId: SourceChannelId;
    readonly enabled: boolean;
    readonly autoResetYZoom?: boolean;
}

export default class SourceDataTypeSelector extends React.PureComponent<Props>
{
    public render()
    {
        const { enabled, sourceChannelId: sourceChannel } = this.props;
        const sourceChannelInfo = SourceChannelInfoManager.get(sourceChannel);

        return <span className="source-data-type-selector">
            <label>
                <SliderCheckbox checked={enabled} onChange={this.onCheck} thumbColour={sourceChannelInfo.base.mainColour} />
                <strong>{sourceChannelInfo.nameSubscript}</strong>
            </label>
        </span>;

    }

    private onCheck = () =>
    {
        const { autoResetYZoom, sourceChannelId: sourceChannel, forMapId, enabled } = this.props;
        monitoringStore.execute(SetSourceDataEnabled.action(forMapId, sourceChannel, !enabled));

        if (autoResetYZoom)
        {
            monitoringStore.execute(WebGLChartStore.resetValueViewportsWithSourceChannel(forMapId, sourceChannel));
        }
    }
}