import Navigo from 'navigo';
import {Api} from './api/api';
import {EmptyTimelineState, getZoomInfo} from './components/timeline/timelineCommon';
import WebGLChartStore, {ChartId, WebGLTimeRange, WebGLTimeRangeMap, WebGLValueRange, WebGLValueRangeMap} from './components/webglChart/webglChartStore';
import monitoringStore from './store/monitoringStore';
import {GroupId, SourceEventId, UnknownGroupId, UserView} from './store/monitoringStoreStates';
import SetDataLookup from './store/setDataLookup';
import SetFFTTime from './store/setFFTTime';
import {GraphTimeId} from './store/setGraphState';
import SetSourceDataEnabled from './store/setSourceDataEnabled';
import SetTimelineState from './store/setTimelineState';
import {Editable, JsonObject} from './utils/commonTypes';
import {addMicro, MicroSeconds} from './utils/timeTypes';
import {createUrlQuery, parseUrlQuery} from './utils/urlQuery';
import {isEmptyObject, throttleEnd} from './utils/utils';
import moment = require('moment');

const momentFormat = 'YYYY-MM-DD-HH-mm-ss-SSS';
function urlMomentFormat(input: moment.Moment)
{
    return input.format(momentFormat);
}
function urlMomentParse(input: string)
{
    return moment(input, momentFormat);
}

interface QueryInterface
{
    readonly group?: string;
    readonly time?: string;
    readonly zoom?: string;
    readonly selectStart?: string;
    readonly selectEnd?: string;
    readonly sourceChannelDisabled?: string;
    readonly fftZoomLevel?: string;
    readonly fftTime?: string;
    readonly enableFFT?: string;
    readonly showEvent?: string;
    readonly timeViewport?: string;
    readonly valueViewport?: string;
}

function compareTimeViewports(prevValue: WebGLTimeRangeMap, currValue: WebGLTimeRangeMap)
{
    if (prevValue === currValue)
    {
        return true;
    }

    for (const prop in currValue)
    {
        if (prop === GraphTimeId)
        {
            continue;
        }

        const prev = prevValue[prop];
        const curr = currValue[prop];

        if (prev !== curr)
        {
            return false;
        }
    }

    return true;
}

const userViews: UserView[] = ['overview', 'graphs', 'dataLookup', 'fft', 'sourceInfo', 'admin'];
type RouteHandler = (params: any, query: string) => void;

class Router
{
    private readonly navigo: Navigo = new Navigo();

    public init()
    {
        this.navigo.on(this.createUserViewRoutes());
        this.navigo.notFound(() => console.log('Not found'));

        this.navigo.resolve();

        this.updateUrlQuery();

        const boundUpdateQuery = throttleEnd(this.updateUrlQuery, 100).bind(this);

        monitoringStore.subscribe((state) => state.serverState, boundUpdateQuery, undefined, 'routerServerState');
        monitoringStore.subscribe((state) => state.localState, boundUpdateQuery, undefined, 'routerLocalState');
        monitoringStore.subscribe((state) => state.timelineStateMap, boundUpdateQuery, undefined, 'routerTimeline');
        monitoringStore.subscribe((state) => state.sourceDataEnabledState, boundUpdateQuery, undefined, 'routerSourceDataEnabled');
        monitoringStore.subscribe((state) => state.dataLookup.enableRMS, boundUpdateQuery, undefined, 'routerEnableFFT');
        monitoringStore.subscribe((state) => state.fftState, boundUpdateQuery, undefined, 'routerFFTState');

        // Use a custom comparer to ignore the graph time viewport id.
        monitoringStore.subscribe((state) => state.webglChartState.timeViewports, boundUpdateQuery, compareTimeViewports, 'webglTimeSelections');
        monitoringStore.subscribe((state) => state.webglChartState.valueViewports, boundUpdateQuery, undefined, 'webglValueSelections');
    }

    public changeGroup(groupId: GroupId)
    {
        Api.changeGroup(groupId);
    }

    public changeView(view: UserView)
    {
        if (view == 'fft')
        {
            monitoringStore.execute(SetFFTTime.updateTimeForAllSources());
        }
        Api.changeUserView(view);
    }

    public doDataLookup(start: moment.Moment, end: moment.Moment, includeFFT: boolean, resetViewport: boolean)
    {
        const diff = moment.duration(end.diff(start));
        const diffMinutes = diff.asMinutes();
        const currentGroupId = monitoringStore.state().serverState.selectedGroupId;

        if (diffMinutes <= 5)
        {
            Api.doStaggeredSourceDataLookupForGroup(currentGroupId, start, end, resetViewport);
        }
        else
        {
            Api.doPreviewLookup(currentGroupId, start, end, resetViewport);
        }

        if (includeFFT)
        {
            this.doOnlineRmsDataLookup(start, end, resetViewport);
            this.doFFTResponseDataLookup(start, end, resetViewport);
        }
    }

    public doOnlineRmsDataLookup(start: moment.Moment, end: moment.Moment, resetViewport: boolean)
    {
        const diff = moment.duration(end.diff(start));
        const diffMinutes = diff.asMinutes();
        const currentGroupId = monitoringStore.state().serverState.selectedGroupId;

        if (diffMinutes <= 5)
        {
            Api.doOnlineRmsResultLookupForGroup(currentGroupId, start, end, resetViewport);
        }
        else
        {
            Api.doOnlineRmsPreviewLookup(currentGroupId, start, end, resetViewport);
        }
    }

    public doFFTResponseDataLookup(start: moment.Moment, end: moment.Moment, resetViewport: boolean)
    {
        const diff = moment.duration(end.diff(start));
        const diffMinutes = diff.asMinutes();
        const currentGroupId = monitoringStore.state().serverState.selectedGroupId;

        if (diffMinutes <= 5)
        {
            Api.doFFTResponseLookupForGroup(currentGroupId, start, end, resetViewport);
        }
        else
        {
            Api.doFFTResponsePreviewLookup(currentGroupId, start, end, resetViewport);
        }
    }

    public changeToAndDoDataLookup(start: moment.Moment, end: moment.Moment)
    {
        router.changeView('dataLookup');

        const centerTime = moment((start.valueOf() + end.valueOf()) * 0.5);
        const timePerPixel = getZoomInfo('10second').timePerPixel;

        monitoringStore.execute(SetTimelineState.view('datalookup', centerTime, timePerPixel));
        router.doDataLookup(start, end, monitoringStore.state().dataLookup.enableRMS, true);
    }

    private createUserViewRoutes()
    {
        const result: {[key: string]: RouteHandler} = {};

        for (const view of userViews)
        {
            const handler = (params: any, query: string) => this.onRouterChange(query, view);
            result[view] = handler;
            result[`${view}/*`] = handler;
        }

        return result;
    }

    private updateUrlQuery = () =>
    {
        const view = monitoringStore.state().serverState.view;

        const queryObj = this.createUrlState();
        const urlQuery = createUrlQuery(queryObj);

        this.navigo.pause();
        this.navigo.navigate(`${view}/?${urlQuery}`);
        this.navigo.resume();
    }

    private onRouterChange(query: string, view: UserView)
    {
        const parsedQuery = parseUrlQuery(query) as QueryInterface;

        const queryGroup = parsedQuery.group;
        if (queryGroup !== undefined)
        {
            const groupId = queryGroup as GroupId;
            this.changeGroup(groupId);
        }

        if (!isEmptyObject(parsedQuery))
        {
            this.processQuery(parsedQuery, view);
        }

        Api.changeUserView(view);
    }

    private processQuery(query: QueryInterface, view: UserView)
    {
        let enableFFT = monitoringStore.state().dataLookup.enableRMS;

        if (view === 'dataLookup' || view === 'fft')
        {
            const centerTime = urlMomentParse(query.time);
            if (centerTime.isValid())
            {
                let zoomMs = parseFloat(query.zoom);
                if (isNaN(zoomMs))
                {
                    zoomMs = getZoomInfo('hour').timePerPixel.asMilliseconds();
                }

                const zoom = moment.duration(zoomMs, 'milliseconds');

                const viewId = view === 'fft' ? 'fft' : 'datalookup';
                monitoringStore.execute(SetTimelineState.view(viewId, centerTime, zoom));
            }
        }

        if (view === 'dataLookup')
        {
            if (query.enableFFT)
            {
                enableFFT = query.enableFFT === 'true';

                monitoringStore.execute(SetDataLookup.setEnableRMS(enableFFT));
            }

            if (typeof (query.selectStart) === 'string' && query.selectStart.length > 0)
            {
                const selectionStart = urlMomentParse(query.selectStart);
                const selectionEnd = urlMomentParse(query.selectEnd);
                this.doDataLookup(selectionStart, selectionEnd, enableFFT, false);
            }
        }

        if (query.sourceChannelDisabled)
        {
            const sourceChannelDisabledJson: string[] = JSON.parse(query.sourceChannelDisabled);
            this.processSourceChannelDisabled(sourceChannelDisabledJson);
        }

        if (query.fftTime)
        {
            const fftTime = urlMomentParse(query.fftTime);
            monitoringStore.execute(SetFFTTime.updateTimeForAllSources(fftTime));
        }

        const fftZoomLevel = parseInt(query.fftZoomLevel, 10);
        if (!isNaN(fftZoomLevel))
        {
            monitoringStore.execute(SetFFTTime.zoom(fftZoomLevel));
        }

        if (query.showEvent)
        {
            const eventId = parseInt(query.showEvent);
            if (!isNaN(eventId))
            {
                Api.getEvent(eventId as SourceEventId);
            }
        }

        if (query.timeViewport)
        {
            const parsed: {[key: string]: [MicroSeconds, MicroSeconds]} = JSON.parse(query.timeViewport);
            for (const key in parsed)
            {
                const chartId = key as ChartId;
                const range = parsed[key];
                const minTime = range[0];
                const width = range[1];
                const maxTime = addMicro(minTime, width);
                const selection: WebGLTimeRange = { minTime, maxTime, width };

                monitoringStore.execute(WebGLChartStore.setTimeViewport(chartId, selection));
            }
        }

        if (query.valueViewport)
        {
            const parsed: {[key: string]: [number, number]} = JSON.parse(query.valueViewport);
            for (const key in parsed)
            {
                const chartId = key as ChartId;
                const range = parsed[key];
                const minValue = range[0];
                const height = range[1];
                const maxValue = minValue + height;
                const selection: WebGLValueRange = {minValue, maxValue, height};

                monitoringStore.execute(WebGLChartStore.setValueViewport(chartId, selection));
            }
        }
    }

    private processSourceChannelDisabled(sourceChannelDisabledJson: string[])
    {
        const sourceChannelDisabled: {[sourceChannel: string]: boolean} = {};

        for (const sourceChannel of sourceChannelDisabledJson)
        {
            sourceChannelDisabled[sourceChannel] = false;
        }

        monitoringStore.execute(SetSourceDataEnabled.setMultiple('main', sourceChannelDisabled));
    }

    private createUrlState()
    {
        const state = monitoringStore.state();
        const view = state.serverState.view;

        const result: Editable<QueryInterface> = {group: undefined};
        if (state.serverState.selectedGroupId !== UnknownGroupId)
        {
            result.group = state.serverState.selectedGroupId;
        }

        if (state.localState.showSourceEventModal)
        {
            result.showEvent = state.localState.selectedEvent.id.toString(10);
        }

        if (view === 'dataLookup' || view === 'fft')
        {
            const viewId = view === 'fft' ? 'fft' : 'datalookup';
            const timelineState = state.timelineStateMap[viewId] || EmptyTimelineState;

            const time = urlMomentFormat(timelineState.centerTime);
            const zoom = timelineState.timePerPixel.asMilliseconds().toFixed(0);

            result.time = time;
            result.zoom = zoom;
        }

        if (view === 'dataLookup')
        {
            const timelineState = state.timelineStateMap['datalookup'] || EmptyTimelineState;
            if (timelineState.selection.enabled)
            {
                result.selectStart = urlMomentFormat(timelineState.selection.start);
                result.selectEnd = urlMomentFormat(timelineState.selection.end);
            }

            if (!state.dataLookup.enableRMS)
            {
                result.enableFFT = 'false';
            }
        }

        if (view === 'dataLookup' || view === 'graphs' || view === 'fft')
        {
            const { sourceDataEnabledState } = state;

            const mainSourceDataEnabled = sourceDataEnabledState['main'];

            if (mainSourceDataEnabled)
            {
                const sourceChannelDisabledJson: string[] = [];
                for (const sourceChannel in mainSourceDataEnabled)
                {
                    const enabled = mainSourceDataEnabled[sourceChannel];
                    if (!enabled)
                    {
                        sourceChannelDisabledJson.push(sourceChannel);
                    }
                }

                if (!isEmptyObject(sourceChannelDisabledJson))
                {
                    result.sourceChannelDisabled = JSON.stringify(sourceChannelDisabledJson);
                }
            }
        }

        if (view === 'fft')
        {
            result.fftZoomLevel = state.fftState.zoomLevel.toString(10);
            result.fftTime = urlMomentFormat(state.fftState.time);
        }

        const timeViewportJson = this.timeRangesToUrl(state.webglChartState.timeViewports);
        if (!isEmptyObject(timeViewportJson))
        {
            result.timeViewport = JSON.stringify(timeViewportJson);
        }

        const valueViewportJson = this.valueRangesToUrl(state.webglChartState.valueViewports);
        if (!isEmptyObject(valueViewportJson))
        {
            result.valueViewport = JSON.stringify(valueViewportJson);
        }

        return result;
    }

    private timeRangesToUrl(ranges: WebGLTimeRangeMap)
    {
        const result: JsonObject = {};
        for (const key in ranges)
        {
            if (key === GraphTimeId)
            {
                continue;
            }
            const range = ranges[key];
            result[key] = [range.minTime, range.width];
        }

        return result;
    }

    private valueRangesToUrl(ranges: WebGLValueRangeMap)
    {
        const result: JsonObject = {};
        for (const key in ranges)
        {
            const range = ranges[key];
            result[key] = [range.minValue, range.height];
        }

        return result;
    }
}

export const router = new Router();
