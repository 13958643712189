import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {arrayToMap} from "../utils/utils";
import {ChannelTypeId, ChannelTypeInfo, ChannelTypeInfoMap, State} from "./monitoringStoreStates";

export default class SetChannelTypeInfo
{
    public static action (merge: boolean, newChannelTypeInfo: ChannelTypeInfo[]): Modifier<State>
    {
        return (state: State) =>
        {
            let channelTypeInfo: Editable<ChannelTypeInfoMap> = merge ? state.channelTypeInfo : {};
            channelTypeInfo = {...channelTypeInfo, ...arrayToMap(newChannelTypeInfo, (x) => x.channelType)};

            return {channelTypeInfo};
        }
    }

    public static remove (id: ChannelTypeId): Modifier<State>
    {
        return (state: State) =>
        {
            const channelTypeInfo: Editable<ChannelTypeInfoMap> = {...state.channelTypeInfo};
            delete channelTypeInfo[id];

            return {channelTypeInfo};
        }
    }
}