import React from 'react';
import monitoringStore from '../../store/monitoringStore';
import {Source, SourceChannelId, State} from '../../store/monitoringStoreStates';
import SetFFTTime from '../../store/setFFTTime';
import SetSourceDataEnabled from '../../store/setSourceDataEnabled';
import SetTimelineState from '../../store/setTimelineState';
import {toMoment} from '../../utils/utils';
import ImageZoom from '../imageZoom/imageZoom';
import {zoomLevelInfos} from '../imageZoom/imageZoomCommon';
import SourceDataTypeSelectors from '../sourceDataTypeSelector/sourceDataTypeSelectors';
import Timeline from '../timeline/timeline';
import {EmptyTimelineState, TimelineEvent, TimelineEventMap, TimelineGroup, TimelineSelect, TimelineState} from '../timeline/timelineCommon';
import BaseTab from './baseTab';
import moment = require('moment');

export default class FFTTab extends BaseTab
{
    private fftImageLayer: React.RefObject<HTMLDivElement>;

    constructor (props: any)
    {
        super(props);
        this.fftImageLayer = React.createRef();
    }

    public componentWillMount()
    {
        this.forceUpdate();
    }

    public render()
    {
        const state = this.props.state;

        if (state.sources.length === 0)
        {
            return null;
        }

        const groups: TimelineGroup[] = state.sources.map((s) =>
            {
                return {
                     name: s.name,
                     id: '' + s.id
                };
            });

        const events: TimelineEventMap = {};
        for (const sourceId in state.fftProcessedStore)
        {
            const sourceMap = state.fftProcessedStore[sourceId];
            const rangeEvents: TimelineEvent[] = [];
            for (const rangeId in sourceMap)
            {
                const range = sourceMap[rangeId];

                rangeEvents.push({
                    title: rangeId,
                    start: toMoment(range.start),
                    end: toMoment(range.end)
                });
            }

            events[sourceId] = rangeEvents;
        }

        const { startTime, endTime } = this.getStartEndTime();
        const fftViewportTimeWidth = this.getImageViewportTimeWidth();

        const timelineSelect: TimelineSelect = {
            enabled: true,
            start: startTime,
            end: endTime
        }
        const timelineState: TimelineState = {
            ...state.timelineStateMap['fft'] || EmptyTimelineState,
            selection: timelineSelect
        }

        const sourceChannels: SourceChannelId[] = [];
        for (const source of state.sources)
        {
            const fftSourceChannels = state.fftState.sourceChannelsMap[source.id];
            if (!fftSourceChannels)
            {
                continue;
            }

            for (const sourceChannelId of fftSourceChannels)
            {
                if (sourceChannels.indexOf(sourceChannelId) < 0)
                {
                    sourceChannels.push(sourceChannelId);
                }
            }
        }

        return (
            <div>
                <Timeline
                    timelineState={timelineState}
                    groups={groups}
                    events={events}
                    selectFixedBoxWidth={fftViewportTimeWidth}
                    selectMode={'fixed-box'}
                    restrictSelectToEvents={false}
                    onChangeView={this.onChangeView}
                    onTimeSelect={this.onMoveSelect}

                />

                <SourceDataTypeSelectors
                    forMapId='main'
                    sourceDataEnabled={state.sourceDataEnabledState}
                    sourceChannels={sourceChannels}
                />

                <div ref={this.fftImageLayer}>
                    { state.sources.map(source => this.renderFFTForSource(state, source)) }
                </div>
            </div>
        );
    }

    private getStartEndTime()
    {
        const fftState = this.props.state.fftState;

        let startTime = fftState.time;
        let endTime = fftState.time;
        if (this.fftImageLayer.current)
        {
            const zoomLevelInfo = zoomLevelInfos[fftState.zoomLevel];
            const timePerPixel = zoomLevelInfo.timePerTile.asMilliseconds() / zoomLevelInfo.tileWidth;

            const viewportWidth = this.fftImageLayer.current.clientWidth;
            const viewportTimeWidth = viewportWidth * timePerPixel * 0.5;

            startTime = startTime.clone().subtract(viewportTimeWidth, 'milliseconds');
            endTime = endTime.clone().add(viewportTimeWidth, 'milliseconds');
        }

        return { startTime, endTime };
    }

    private getImageViewportTimeWidth()
    {
        const fftState = this.props.state.fftState;

        if (this.fftImageLayer.current)
        {
            const zoomLevelInfo = zoomLevelInfos[fftState.zoomLevel];
            const timePerPixel = zoomLevelInfo.timePerTile.asMilliseconds() / zoomLevelInfo.tileWidth;

            const viewportWidth = this.fftImageLayer.current.clientWidth;
            return moment.duration(viewportWidth * timePerPixel * 0.5, 'milliseconds');
        }

        return moment.duration(1, 'second');
    }

    private renderFFTForSource(state: State, source: Source)
    {
        const sourceChannels = state.fftState.sourceChannelsMap[source.id];
        if (!sourceChannels)
        {
            return null;
        }

        const sourceDataEnabled = SetSourceDataEnabled.getEnabled('main', state.sourceDataEnabledState);

        return <ImageZoom
            key={`imagezoom_${source.id}`}
            fftTileHostname={state.serverInfo.fftTileHostname}
            fftScalingFactor={state.serverInfo.fftScalingFactor}
            source={source}
            sourceChannels={sourceChannels}
            sourceDataEnabledState={sourceDataEnabled}
            centerTime={state.fftState.time}
            zoomLevel={state.fftState.zoomLevel} />;
    }

    private onChangeView = (centerTime: moment.Moment, timePerPixel: moment.Duration) =>
    {
        monitoringStore.execute(SetTimelineState.view('fft', centerTime, timePerPixel));
    }

    private onMoveSelect = (start: moment.Moment, end: moment.Moment) =>
    {
        const centerTime = moment(new Date((start.valueOf() + end.valueOf()) * 0.5));
        monitoringStore.execute(SetFFTTime.updateTimeForAllSources(centerTime));
    }
}