import React from 'react';
import SetUpdateSourceState from '../../store/admin/setUpdateSourceState';
import monitoringStore from '../../store/monitoringStore';
import {FullSource} from '../../store/monitoringStoreStates';

interface Props
{
    fullSource: FullSource;
}

export default class FullSourceView extends React.PureComponent<Props>
{
    public render()
    {
        const { source } = this.props.fullSource;

        return (
            <tr>
                <td><strong>{source.name}</strong></td>
                <td>{source.id}</td>
                <td>{source.location}</td>
                <td>{source.sourceType}</td>
                <td>{source.sortOrder}</td>
                <td>{this.props.fullSource.groupIds.join(', ')}</td>
                <td><button onClick={this.showUpdateModal}>Edit</button></td>
            </tr>
        )
    }

    private showUpdateModal = () =>
    {
        monitoringStore.execute(SetUpdateSourceState.showUpdate(this.props.fullSource));
    }
}