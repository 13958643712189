import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import {Api} from './api/api';
import App from './app';
import GraphRenderRefresh from './graphRenderRefresh';
import {getLocalConfig} from './localConfig';
import './polyfills/string';
import {router} from './router';
import monitoringStore from './store/monitoringStore';
import {LoginStatus, State} from './store/monitoringStoreStates';
import ShowScriptVersionErrorModal from './store/showScriptVersionErrorModal';
import './style.scss';
import {shallowEqualsArray, urlHasPath} from './utils/utils';

const rootEl = document.getElementById('root');
if (rootEl == null)
{
    throw new Error('Unable to find root element');
}
Modal.setAppElement(rootEl);

export type ThemeChangeListener = (darkTheme: boolean) => void;
export type RemoveListener = () => void;
const themeChangeListeners: ThemeChangeListener[] = [];

export function addThemeChangeListener(listener: ThemeChangeListener): RemoveListener
{
    if (themeChangeListeners.indexOf(listener) >= 0)
    {
        return () => {};
    }

    themeChangeListeners.push(listener);
    return () =>
    {
        const index = themeChangeListeners.indexOf(listener);
        if (index >= 0)
        {
            themeChangeListeners.splice(index, 1);
        }
    }
}

export function isDarkTheme()
{
    return document.body.classList.contains('dark-theme');
}

function triggerThemeChange(darkTheme: boolean)
{
    for (const listener of themeChangeListeners)
    {
        listener(darkTheme);
    }
}

function render (state: State)
{
    const { darkTheme } = state.serverState;
    if (isDarkTheme() !== darkTheme)
    {
        document.body.classList.toggle('dark-theme', darkTheme);
        triggerThemeChange(darkTheme);
    }

    ReactDOM.render( <App state={state} />, rootEl );
}

let waitingForRenderCurrent = false;
function renderCurrentState()
{
    waitingForRenderCurrent = false;
    render(monitoringStore.state());
}

let pageVisible = true;
let renderedWhilePaused = false;
if (typeof(document.visibilityState) !== 'undefined')
{
    pageVisible = document.visibilityState === 'visible';

    document.addEventListener('visibilitychange', () =>
    {
        pageVisible = document.visibilityState === 'visible';
        if (pageVisible && renderedWhilePaused)
        {
            renderedWhilePaused = false;
            render(monitoringStore.state());
        }
        Api.setPauseLiveGraphs(!pageVisible || monitoringStore.state().localState.pauseLiveGraphs);
    })
}

GraphRenderRefresh.init();

render(monitoringStore.state());
monitoringStore.subscribeAny(function mainRenderSub(state: State)
{
    if (pageVisible)
    {
        if (!waitingForRenderCurrent)
        {
            waitingForRenderCurrent = true;
            window.requestAnimationFrame(renderCurrentState);
        }
    }
    else
    {
        renderedWhilePaused = true;
    }
}, 'mainRenderSub');

const localConfig = getLocalConfig();
const connectionLocation = `${window.location.hostname}:${localConfig.port}`;

Api.connect(connectionLocation, localConfig.useSecure, () =>
    {
        if (monitoringStore.state().serverState.loginToken)
        {
            Api.validateToken(monitoringStore.state().serverState.loginToken);
            return;
        }
    });

let previousLoginStatus: LoginStatus = 'not-logged-in';
monitoringStore.subscribe((state) => state.loginState, (state) =>
    {
        if (state.loginState.tokenStatus === 'valid' && state.loginState.loginStatus === 'not-logged-in')
        {
            Api.resumeSession(state.serverState.loginToken);
        }
        else if (state.loginState.loginStatus === 'logged-in' && previousLoginStatus !== 'logged-in')
        {
            const skipLoadingFromState = urlHasPath(window.location.href);
            // Handle logged in
            router.init();

            if (!skipLoadingFromState)
            {
                const { serverState } = monitoringStore.state();

                if (state.loginState.isNewLogin)
                {
                    const serverInfo = monitoringStore.state().serverInfo;
                    Api.changeGroup(serverInfo.startingGroupId);
                    Api.changeUserView(serverInfo.startingUserView);
                }
                else
                {
                    Api.changeGroup(serverState.selectedGroupId);
                    Api.changeUserView(serverState.view);
                }
            }
        }

        previousLoginStatus = state.loginState.loginStatus;
    }, undefined, 'indexCheckForLoginChange');

monitoringStore.subscribe((state) => state.serverInfo, (state) =>
 {
    if (!shallowEqualsArray(state.serverInfo.scriptFilename, ['local']))
    {
        const sorted = state.serverInfo.scriptFilename.slice().sort();
        const scriptsEqual = shallowEqualsArray(localConfig.scriptFilenames, sorted);
        if (!scriptsEqual)
        {
            monitoringStore.execute(ShowScriptVersionErrorModal.show());
        }
    }
 }, undefined, 'indexValidateScriptFilename');