import {Modifier} from "simple-data-store";
import {EmptyTimelineSelect, EmptyTimelineState, TimelineSelect, TimelineState} from "../components/timeline/timelineCommon";
import {Editable} from "../utils/commonTypes";
import {merge} from "../utils/utils";
import {State, TimelineStateMap} from "./monitoringStoreStates";
import moment = require("moment");

interface UpdateTimelineState
{
    readonly id: string;
    readonly centerTime?: moment.Moment;
    readonly timePerPixel?: moment.Duration;
    readonly select?: Partial<TimelineSelect>;
    readonly loading?: boolean;
}

export default class SetTimelineState
{
    public static view (id: string, centerTime: moment.Moment, timePerPixels: moment.Duration): Modifier<State>
    {
        return this.execute([{id, centerTime, timePerPixel: timePerPixels}]);
    }

    public static select(id: string, select: Partial<TimelineSelect>): Modifier<State>
    {
        return this.execute([{id, select}]);
    }

    public static setStates(states: UpdateTimelineState[]): Modifier<State>
    {
        return this.execute(states);
    }

    public static isLoading(id: string, loading: boolean): Modifier<State>
    {
        return this.execute([{id, loading}]);
    }

    private static execute (timelineStates: UpdateTimelineState[]): Modifier<State>
    {
        return (state: State) =>
        {
            const timelineStateMap: Editable<TimelineStateMap> = {...state.timelineStateMap};

            for (const timelineState of timelineStates)
            {
                const newState: Editable<TimelineState> = {
                    ...EmptyTimelineState,
                    ...timelineStateMap[timelineState.id]
                }

                newState.centerTime = timelineState.centerTime || newState.centerTime;
                newState.timePerPixel = timelineState.timePerPixel || newState.timePerPixel;
                newState.selection = merge(newState.selection, timelineState.select, EmptyTimelineSelect);
                if (typeof (timelineState.loading) === 'boolean')
                {
                    newState.loading = timelineState.loading;
                }

                timelineStateMap[timelineState.id] = newState;
            }

            return {timelineStateMap};
        }
    }
}