import React, {ChangeEvent} from 'react';
import {Api} from '../../api/api';
import ShowReprocessEventsModal from '../../store/admin/showEventReportsModal';
import monitoringStore from '../../store/monitoringStore';
import {EventReportsState, GroupId} from '../../store/monitoringStoreStates';
import {formatDateTimeInput, parseDateTimeInput} from '../../utils/utils';
import {ModalBase} from '../modalBase';
import './showEventReportsModal.scss';

interface Props
{
    readonly eventReportsState: EventReportsState;
    readonly groupId: GroupId;
}

interface State
{
}

export default class ShowEventReportsModal extends React.PureComponent<Props, State>
{
    public render ()
    {
        const {isOpen, start, end} = this.props.eventReportsState;
        if (!isOpen)
        {
            return null;
        }

        return (
            <ModalBase isOpen={true}
                className="event-reports-modal"
                onRequestClose={() => this.close()}
                >
                <h1>Create Event Report</h1>
                <p>
                    Enter a start and end date to create an events report.
                </p>
                <div>
                    <strong>From Time: </strong> <input type="datetime-local" onChange={(e) => this.onStartChange(e)} value={formatDateTimeInput(start)}/>
                </div>
                <div>
                    <strong>To Time: </strong> <input type="datetime-local" onChange={(e) => this.onEndChange(e)} value={formatDateTimeInput(end)}/>

                </div>

                <div className="event-reports-modal__buttons">
                    <button onClick={() => this.showReport()}>Show Report</button>
                    <button onClick={() => this.close()}>Close</button>
                </div>
            </ModalBase>
        );
    }

    private onStartChange(event: ChangeEvent<HTMLInputElement>)
    {
        const parsed = parseDateTimeInput(event.target.value);
        if (parsed.isValid())
        {
            monitoringStore.execute(ShowReprocessEventsModal.start(parsed));
        }
    }

    private onEndChange(event: ChangeEvent<HTMLInputElement>)
    {
        const parsed = parseDateTimeInput(event.target.value);
        if (parsed.isValid())
        {
            monitoringStore.execute(ShowReprocessEventsModal.end(parsed));
        }
    }

    private showReport()
    {
        const {start, end} = this.props.eventReportsState;
        const url = Api.getEventReportUrl(this.props.groupId, start, end);
        window.open(url, '_blank');
    }

    private close ()
    {
        monitoringStore.execute(ShowReprocessEventsModal.close());
    }
}