import {Modifier} from "simple-data-store";
import {ChartId, EmptyGroupChartId, GroupChartId, setChartData, WebGLAxis, WebGLChartIdToState} from "../components/webglChart/webglChartStore";
import {Editable} from "../utils/commonTypes";
import {ZeroMicro} from "../utils/timeTypes";
import {addKeysToList, findSource} from "../utils/utils";
import {ChannelTypeInfoManager} from "./channelTypeInfoManager";
import {ChannelTypeId, DataLookupChartPair, DataLookupResult, DataLookupState, FFTResponseDataLookup, FFTResponsePreviewLookupResult, GroupedDataLookupCharts, OnlineRmsPreviewLookupResult, OnlineRmsResultLookup, PreviewLookupResult, SourceChannelId, SourceId, SourceToGroupedDataLookupCharts, State} from "./monitoringStoreStates";
import {processFFTResponseDatasWebGL, processFFTResponsePreviewWebGL} from "./processFFTResponseResult";
import {processOnlineRmsPreviewWebGL, processOnlineRmsResultsWebGL} from "./processOnlineRmsResult";
import {processPreviewSourceDatas, processSourceDatas} from "./processSourceData";

function modifyDataLookup(state: State, dataLookup: Partial<DataLookupState>): Partial<State>
{
    return {dataLookup: Object.assign({}, state.dataLookup, dataLookup)};
}

function getSourceChannelsFromPreview(previewResult: PreviewLookupResult)
{
    const result: string[] = [];

    for (const sourceId in previewResult.previews)
    {
        const previews = previewResult.previews[sourceId];

        for (const preview of previews)
        {
            addKeysToList(preview.data, result);
        }
    }

    return result as SourceChannelId[];
}

function getSourceChannelsFromData(lookupResult: DataLookupResult)
{
    const result: string[] = [];

    for (const sourceId in lookupResult.data)
    {
        const sourceDatas = lookupResult.data[sourceId];

        for (const sourceData of sourceDatas)
        {
            addKeysToList(sourceData.payload, result);
        }
    }

    return result as SourceChannelId[];
}

export function getDataLookupPair(grouped: Editable<GroupedDataLookupCharts>, key: string): Editable<DataLookupChartPair>
{
    const current = grouped[key];
    let result: Editable<DataLookupChartPair>;
    if (!current)
    {
        result = {rmsChartId: null, mainChartId: null, previewChartId: null, fftChartId: null};
    }
    else
    {
        result = Object.assign({}, current);
    }

    grouped[key] = result;
    return result;
}

export const SharedPreviewTimeId = 'datalookup-preview' as ChartId;
export const SharedTimeId = 'datalookup' as ChartId;

export function makePreviewRmsChartId(sourceId: SourceId, channelTypeId: ChannelTypeId): ChartId
{
    return `preview-rms-${sourceId}-${channelTypeId}` as ChartId;
}
export function makePreviewFFTChartId(sourceId: SourceId, channelTypeId: ChannelTypeId): ChartId
{
    return `preview-fft-${sourceId}-${channelTypeId}` as ChartId;
}
export function makeFFTChartId(sourceId: SourceId, channelTypeId: ChannelTypeId): ChartId
{
    return `fft-${sourceId}-${channelTypeId}` as ChartId;
}
export function makeRmsChartId(sourceId: SourceId, channelTypeId: ChannelTypeId): ChartId
{
    return `rms-${sourceId}-${channelTypeId}` as ChartId;
}
export function makePreviewChartId(sourceId: SourceId, channelTypeId: ChannelTypeId): ChartId
{
    return `preview-${sourceId}-${channelTypeId}` as ChartId;
}
export function makeDataLookupChartId(sourceId: SourceId, channelTypeId: ChannelTypeId): ChartId
{
    return `datalookup-${sourceId}-${channelTypeId}` as ChartId;
}

function removeChartIdsFromWebGL (charts: Editable<WebGLChartIdToState>, chartIdLookup: SourceToGroupedDataLookupCharts)
{
    for (const sourceId in chartIdLookup)
    {
        const grouped = chartIdLookup[sourceId];
        for (const key in grouped)
        {
            const pair = grouped[key];
            delete charts[pair.mainChartId];
            delete charts[pair.previewChartId];
            delete charts[pair.rmsChartId];
            delete charts[pair.fftChartId];
        }
    }
}


export default class SetDataLookup
{
    public static setIsLoading(): Modifier<State>
    {
        return (state: State) => modifyDataLookup(state, {isLoading: true, sourceChannels: []});
    }

    public static setIsLoadingRMS(): Modifier<State>
    {
        return (state: State) => modifyDataLookup(state, {isLoadingRMS: true});
    }

    public static setEnableRMS(enable: boolean): Modifier<State>
    {
        return (state: State) => modifyDataLookup(state, {enableRMS: enable});
    }

    public static clearDataLookup(): Modifier<State>
    {
        return (state: State) =>
        {
            const charts: Editable<WebGLChartIdToState> = Object.assign({}, state.webglChartState.charts);

            removeChartIdsFromWebGL(charts, state.dataLookup.previewWebGLChartIds);
            removeChartIdsFromWebGL(charts, state.dataLookup.dataLookupWebGLChartIds);

            const result = modifyDataLookup(state, {
                previewWebGLChartIds: {},
                dataLookupWebGLChartIds: {},
                sourceChannels: [],
                error: ''
            });

            (result as Editable<Partial<State>>).webglChartState = {
                ...state.webglChartState,
                charts
            }

            return result;
        }
    }

    public static setPreviewResult(previewLookup: PreviewLookupResult, resetViewport: boolean): Modifier<State>
    {
        const sourceChannels = getSourceChannelsFromPreview(previewLookup);
        return (state: State) =>
        {
            const previewDataSources = processPreviewSourceDatas(previewLookup);
            const previewWebGLChartIds: Editable<SourceToGroupedDataLookupCharts> = { ...state.dataLookup.previewWebGLChartIds };

            let newWebGLChartsState = state.webglChartState;
            for (let sourceId in previewDataSources)
            {
                const source = findSource(state, sourceId as SourceId);
                const sourceChannelsToPreview = previewDataSources[sourceId];

                const currentSourcePreviewChartIds = previewWebGLChartIds[sourceId];
                const sourcePreviewChartIds: Editable<GroupedDataLookupCharts> = currentSourcePreviewChartIds ? { ...currentSourcePreviewChartIds} : {};

                previewWebGLChartIds[sourceId] = sourcePreviewChartIds;

                for (let channelTypeId in sourceChannelsToPreview)
                {
                    const mainChartId = makePreviewChartId(sourceId as SourceId, channelTypeId as ChannelTypeId);
                    const previewSources = sourceChannelsToPreview[channelTypeId];

                    const channelType = ChannelTypeInfoManager.get(channelTypeId as ChannelTypeId, state);
                    const leftAxis: WebGLAxis = {
                        enabled: true,
                        label: channelType.displayName,
                        unit: channelType.prefix,
                        isRMS: false
                    }

                    const title = `${source.name} ${channelType.chartTitle}`;

                    const groupId = `${channelTypeId}-preview` as GroupChartId;
                    newWebGLChartsState = setChartData(newWebGLChartsState, mainChartId, groupId, title, previewSources, leftAxis, true, true, null, SharedPreviewTimeId, resetViewport, resetViewport);

                    const previewChartId = `${mainChartId}-preview` as ChartId;

                    newWebGLChartsState = setChartData(newWebGLChartsState, previewChartId, EmptyGroupChartId,'', previewSources, leftAxis, true, false, null, SharedPreviewTimeId, resetViewport, resetViewport);

                    const newPair = getDataLookupPair(sourcePreviewChartIds, channelTypeId);
                    newPair.mainChartId = mainChartId;
                    newPair.previewChartId = previewChartId;
                }

            }

            const result = modifyDataLookup(state, {
                sourceChannels,
                isLoading: false,
                isDataLookup: false,
                previewWebGLChartIds
            });

            (result as any).webglChartState = newWebGLChartsState;

            return result;
        };
    }

    public static setOnlineRmsPreviewResult (rmsPreview: OnlineRmsPreviewLookupResult, resetViewport: boolean): Modifier<State>
    {
        return (state: State) =>
        {
            const onlineRmsPreviewDataSources = processOnlineRmsPreviewWebGL(rmsPreview);

            const previewWebGLChartIds: Editable<SourceToGroupedDataLookupCharts> = { ...state.dataLookup.previewWebGLChartIds };

            let newWebGLChartsState = state.webglChartState;
            for (let sourceId in onlineRmsPreviewDataSources)
            {
                const sourceChannelsToDataSource = onlineRmsPreviewDataSources[sourceId];

                const currentSourceDataLookupChartIds = previewWebGLChartIds[sourceId];
                const sourceDataLookupChartIds: Editable<GroupedDataLookupCharts> = currentSourceDataLookupChartIds ? { ...currentSourceDataLookupChartIds} : {};

                previewWebGLChartIds[sourceId] = sourceDataLookupChartIds;

                for (let channelTypeId in sourceChannelsToDataSource)
                {
                    const rmsChartId = makePreviewRmsChartId(sourceId as SourceId, channelTypeId as ChannelTypeId);
                    const dataSources = sourceChannelsToDataSource[channelTypeId];

                    const channelType = ChannelTypeInfoManager.get(channelTypeId as ChannelTypeId, state);
                    const leftAxis: WebGLAxis = {
                        enabled: true,
                        label: channelType.displayName,
                        unit: channelType.prefix,
                        isRMS: true
                    }

                    const groupId = `${channelTypeId}-preview` as GroupChartId;
                    newWebGLChartsState = setChartData(newWebGLChartsState, rmsChartId, groupId, '', dataSources, leftAxis, true, true, null, SharedPreviewTimeId, false, resetViewport);

                    const newPair = getDataLookupPair(sourceDataLookupChartIds, channelTypeId);
                    newPair.rmsChartId = rmsChartId;
                }
            }

            const result = modifyDataLookup(state, {
                isLoadingRMS: false,
                previewWebGLChartIds
            });

            (result as any).webglChartState = newWebGLChartsState;

            return result;
        }
    }

    public static setOnlineRmsResult (lookupResult: OnlineRmsResultLookup, resetViewport: boolean): Modifier<State>
    {
        return (state: State) =>
        {
            const onlineRmsResultDataSources = processOnlineRmsResultsWebGL(lookupResult.data, false, ZeroMicro);

            const dataLookupWebGLChartIds: Editable<SourceToGroupedDataLookupCharts> = { ...state.dataLookup.dataLookupWebGLChartIds };

            let newWebGLChartsState = state.webglChartState;
            for (let sourceId in onlineRmsResultDataSources)
            {
                const sourceChannelsToDataSource = onlineRmsResultDataSources[sourceId];

                const currentSourceDataLookupChartIds = dataLookupWebGLChartIds[sourceId];
                const sourceDataLookupChartIds: Editable<GroupedDataLookupCharts> = currentSourceDataLookupChartIds ? { ...currentSourceDataLookupChartIds} : {};

                dataLookupWebGLChartIds[sourceId] = sourceDataLookupChartIds;

                for (let channelTypeId in sourceChannelsToDataSource)
                {
                    const rmsChartId = makeRmsChartId(sourceId as SourceId, channelTypeId as ChannelTypeId);
                    const dataSources = sourceChannelsToDataSource[channelTypeId];

                    const channelType = ChannelTypeInfoManager.get(channelTypeId as ChannelTypeId, state);
                    const leftAxis: WebGLAxis = {
                        enabled: true,
                        label: channelType.displayName,
                        unit: channelType.prefix,
                        isRMS: true
                    }

                    const groupId = `${channelTypeId}-full` as GroupChartId;
                    newWebGLChartsState = setChartData(newWebGLChartsState, rmsChartId, groupId, '', dataSources, leftAxis, true, true, null, SharedTimeId, false, resetViewport);

                    const newPair = getDataLookupPair(sourceDataLookupChartIds, channelTypeId);
                    newPair.rmsChartId = rmsChartId;
                }
            }

            const result = modifyDataLookup(state, {
                dataLookupWebGLChartIds,
                isLoadingRMS: false
            });

            (result as any).webglChartState = newWebGLChartsState;

            return result;
        }
    }

    public static setFFTResponsePreviewResult (fftPreview: FFTResponsePreviewLookupResult, resetViewport: boolean): Modifier<State>
    {
        return (state: State) =>
        {
            const fftPreviewDataSources = processFFTResponsePreviewWebGL(fftPreview);

            const previewWebGLChartIds: Editable<SourceToGroupedDataLookupCharts> = { ...state.dataLookup.previewWebGLChartIds };

            let newWebGLChartsState = state.webglChartState;
            for (let sourceId in fftPreviewDataSources)
            {
                const sourceChannelsToDataSource = fftPreviewDataSources[sourceId];

                const currentSourceDataLookupChartIds = previewWebGLChartIds[sourceId];
                const sourceDataLookupChartIds: Editable<GroupedDataLookupCharts> = currentSourceDataLookupChartIds ? { ...currentSourceDataLookupChartIds} : {};

                previewWebGLChartIds[sourceId] = sourceDataLookupChartIds;

                for (let channelTypeId in sourceChannelsToDataSource)
                {
                    const fftChartId = makePreviewFFTChartId(sourceId as SourceId, channelTypeId as ChannelTypeId);
                    const dataSources = sourceChannelsToDataSource[channelTypeId];

                    const channelType = ChannelTypeInfoManager.get(channelTypeId as ChannelTypeId, state);
                    const leftAxis: WebGLAxis = {
                        enabled: true,
                        label: channelType.displayName,
                        unit: channelType.prefix,
                        isRMS: false
                    }

                    const groupId = `${channelTypeId}-preview` as GroupChartId;
                    newWebGLChartsState = setChartData(newWebGLChartsState, fftChartId, groupId, '', dataSources, leftAxis, true, true, null, SharedPreviewTimeId, false, resetViewport);

                    const newPair = getDataLookupPair(sourceDataLookupChartIds, channelTypeId);
                    newPair.fftChartId = fftChartId;
                }
            }

            const result = modifyDataLookup(state, {
                previewWebGLChartIds
            });

            (result as any).webglChartState = newWebGLChartsState;

            return result;
        }
    }

    public static setFFTResponseData (lookupResult: FFTResponseDataLookup, resetViewport: boolean): Modifier<State>
    {
        return (state: State) =>
        {
            const fftResponseDataSources = processFFTResponseDatasWebGL(lookupResult.data, false, ZeroMicro);

            const dataLookupWebGLChartIds: Editable<SourceToGroupedDataLookupCharts> = { ...state.dataLookup.dataLookupWebGLChartIds };

            let newWebGLChartsState = state.webglChartState;
            for (let sourceId in fftResponseDataSources)
            {
                const sourceChannelsToDataSource = fftResponseDataSources[sourceId];

                const currentSourceDataLookupChartIds = dataLookupWebGLChartIds[sourceId];
                const sourceDataLookupChartIds: Editable<GroupedDataLookupCharts> = currentSourceDataLookupChartIds ? { ...currentSourceDataLookupChartIds} : {};

                dataLookupWebGLChartIds[sourceId] = sourceDataLookupChartIds;

                for (let channelTypeId in sourceChannelsToDataSource)
                {
                    const fftChartId = makeFFTChartId(sourceId as SourceId, channelTypeId as ChannelTypeId);
                    const dataSources = sourceChannelsToDataSource[channelTypeId];

                    const channelType = ChannelTypeInfoManager.get(channelTypeId as ChannelTypeId, state);
                    const leftAxis: WebGLAxis = {
                        enabled: true,
                        label: channelType.displayName,
                        unit: channelType.prefix,
                        isRMS: false
                    }

                    const groupId = `${channelTypeId}-full` as GroupChartId;
                    newWebGLChartsState = setChartData(newWebGLChartsState, fftChartId, groupId, '', dataSources, leftAxis, true, true, null, SharedTimeId, false, resetViewport);

                    const newPair = getDataLookupPair(sourceDataLookupChartIds, channelTypeId);
                    newPair.fftChartId = fftChartId;
                }
            }

            const result = modifyDataLookup(state, {
                dataLookupWebGLChartIds
            });

            (result as any).webglChartState = newWebGLChartsState;

            return result;
        }
    }

    public static setDataResult(dataLookup: DataLookupResult, resetViewport: boolean): Modifier<State>
    {
        const sourceChannels = getSourceChannelsFromData(dataLookup);
        return (state: State) =>
        {
            const dataLookupDataSources = processSourceDatas(dataLookup.data);

            const dataLookupWebGLChartIds: Editable<SourceToGroupedDataLookupCharts> = { ...state.dataLookup.dataLookupWebGLChartIds };

            let newWebGLChartsState = state.webglChartState;
            for (let sourceId in dataLookupDataSources)
            {
                const source = findSource(state, sourceId as SourceId);
                const sourceChannelsToDataSource = dataLookupDataSources[sourceId];

                const currentSourceDataLookupChartIds = dataLookupWebGLChartIds[sourceId];
                const sourceDataLookupChartIds: Editable<GroupedDataLookupCharts> = currentSourceDataLookupChartIds ? { ...currentSourceDataLookupChartIds} : {};

                dataLookupWebGLChartIds[sourceId] = sourceDataLookupChartIds;

                for (let channelTypeId in sourceChannelsToDataSource)
                {
                    const mainChartId = makeDataLookupChartId(source.id, channelTypeId as ChannelTypeId);
                    const dataSources = sourceChannelsToDataSource[channelTypeId];

                    const channelType = ChannelTypeInfoManager.get(channelTypeId as ChannelTypeId, state);
                    const leftAxis: WebGLAxis = {
                        enabled: true,
                        label: channelType.displayName,
                        unit: channelType.prefix,
                        isRMS: false
                    }

                    const title = `${source.name} ${channelType.chartTitle}`;

                    const groupId = `${channelTypeId}-full` as GroupChartId;
                    newWebGLChartsState = setChartData(newWebGLChartsState, mainChartId, groupId, title, dataSources, leftAxis, true, true, null, SharedTimeId, resetViewport, resetViewport);

                    const previewChartId = `${mainChartId}-preview` as ChartId;
                    newWebGLChartsState = setChartData(newWebGLChartsState, previewChartId, EmptyGroupChartId, '', dataSources, leftAxis, true, false, null, SharedTimeId, resetViewport, resetViewport);

                    const newPair = getDataLookupPair(sourceDataLookupChartIds, channelTypeId);
                    newPair.mainChartId = mainChartId;
                    newPair.previewChartId = previewChartId;
                }
            }

            const result = modifyDataLookup(state, {
                sourceChannels,
                isLoading: false,
                isDataLookup: true,
                dataLookupWebGLChartIds
            });

            (result as any).webglChartState = newWebGLChartsState;

            return result;
        }
    }

    public static failed (error: string): Modifier<State>
    {
        return (state: State) => modifyDataLookup(state, {isLoading: false, error});
    }
}