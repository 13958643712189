import {Modifier} from "simple-data-store";
import {modifyServerState} from "./monitoringStore";
import {State, UserView} from "./monitoringStoreStates";

export default class SelectUserView
{
    public static action (view: UserView): Modifier<State>
    {
        return (state: State) => modifyServerState(state, {view});
    }
}