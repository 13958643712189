import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {MicroSeconds} from "../utils/timeTypes";
import {CreateEventState, SourceChannelId, SourceId, State} from "./monitoringStoreStates";

export default class SetCreateEvent
{
    public static show (time: MicroSeconds, sourceChannels: SourceChannelId[], sourceId: SourceId): Modifier<State>
    {
        return (state: State) =>
        {
            const createEventState: Editable<CreateEventState> = {
                ...state.createEventState, time, sourceId,
                show: true,
                sourceChannels: [...new Set(sourceChannels)]
            }

            return {createEventState};
        }
    }

    public static close(): Modifier<State>
    {
        return (state: State) =>
        {
            const createEventState: Editable<CreateEventState> = {
                ...state.createEventState, show: false
            }

            return {createEventState}
        }
    }
}