import React from 'react';
import {SourceActiveStatus, SourceStatus} from '../store/monitoringStoreStates';
import {formatDateTime, formatPercent, formatTemperature, formatTime} from '../utils/utils';
import Icon from './icon';

interface Props
{
    sourceStatus: SourceStatus;
    sourceActiveStatus: SourceActiveStatus;
}

function formatShortRepoVersion(input: string)
{
    input = input.trim();
    const spaceIndex = input.indexOf(' ');
    if (spaceIndex > 0)
    {
        return input.substring(0, spaceIndex);
    }
    return input;
}

export default class SourceInfoView extends React.PureComponent<Props>
{
    public render()
    {
        const { sourceStatus, sourceActiveStatus } = this.props;
        const sys = sourceStatus.systemLoadInfo;
        const repoVersionShort = formatShortRepoVersion(sourceStatus.repoVersion);

        const coreTemp = formatTemperature(sys.coreTemp);
        const ambientTemp1 = formatTemperature(sys.ambientTemp1);
        const ambientTemp2 = formatTemperature(sys.ambientTemp2);

        const timestampTooltip = `Heartbeat time: ${formatDateTime(sourceActiveStatus.lastHeartbeatTime)}
            Date received time: ${formatDateTime(sourceActiveStatus.lastDataReceivedTime)}
            Boot time: ${formatDateTime(sys.bootTimestamp)}`;

        const tempTooltip = `Core temperature: ${coreTemp}
            Ambient temperature 1: ${ambientTemp1}
            Ambient temperature 2: ${ambientTemp2}`;

        const sysInfoTooltip = `Memory usage: ${formatPercent(sys.memory)}%
            CPU usage: ${formatPercent(sys.cpu)}%
            Disk usage: ${formatPercent(sys.disk)}%`;

        const ipTooltip = `IP: ${sourceStatus.ip}
            CJDNS IP: ${sourceStatus.cjdnsIp}`;

        return (
            <>
                <td>
                    <span data-rh={timestampTooltip}>
                        <span>
                            <Icon type='heartbeat' />
                             <small> {formatDateTime(sourceActiveStatus.lastHeartbeatTime)}</small>
                        </span><br/>
                        <span>
                            <Icon type='data-signal' />
                            <small> {formatDateTime(sourceActiveStatus.lastDataReceivedTime)}</small>
                        </span><br/>
                        <span>
                            <Icon type='power' />
                            <small> {formatDateTime(sys.bootTimestamp)}</small>
                        </span>
                    </span>
                </td>
                <td>
                    <small data-rh={tempTooltip}>
                        <Icon type='cpu' /> {coreTemp} <br/>
                        <Icon type='sun' /> {ambientTemp1}<br/>
                        <Icon type='sun' /> {ambientTemp2}
                    </small>
                </td>
                <td>
                    <small data-rh={sysInfoTooltip}>
                        <span>
                            <Icon type='memory' /> {formatPercent(sys.memory)}%
                        </span><br/>
                        <span>
                            <Icon type='cpu' /> {formatPercent(sys.cpu)}%
                        </span><br/>
                        <span>
                            <Icon type='disk' /> {formatPercent(sys.disk)}%
                        </span>
                    </small>
                </td>
                <td>
                    <small data-rh-persist={ipTooltip}>
                        <strong>External IP</strong><br/>
                        <strong>CJDNS</strong>
                    </small>
                </td>
                <td data-rh={`Repository Version: ${sourceStatus.repoVersion}`}><small>{repoVersionShort}</small></td>
                <td><small>[{sys.loadAverages.map(formatPercent).join(', ')}]</small></td>
            </>
        )
    }
}