export type SIPrefix = 'k' | 'm' | 'μ' | '';

export function getSIPrefix(...values: number[]): SIPrefix
{
    if (values.every(n => Math.abs(n) < 1e-3))
    {
        return 'μ';
    }
    if (values.every(n => Math.abs(n) < 1))
    {
        return 'm';
    }
    else if (values.every(n => Math.abs(n) > 1e3))
    {
        return 'k';
    }
    return '';
}

export function formatSIValue(prefix: SIPrefix, value: number)
{
    if (prefix === 'μ')
    {
        value *= 1e6;
    }
    if (prefix === 'm')
    {
        value *= 1e3;
    }
    else if (prefix === 'k')
    {
        value /= 1e3;
    }

    let s = value.toLocaleString('en-US', {
        maximumFractionDigits: 3,
        minimumSignificantDigits: 4,
        maximumSignificantDigits: 4,
    });

    let s2 = s.split('.');
    if (s2.length == 2) {
        return [s2[0], s2[1].substring(0, 3)].join('.');
    }
    return s;

}