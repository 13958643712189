import moment = require("moment");
import {DataSourceWithType, LineChartDataRange, LineChartDataSelect, LineChartThreshold, LineChartTimeRange, LineChartTimeSelect} from "../components/lineChart/lineChartTypes";
import {TimelineState} from "../components/timeline/timelineCommon";
import {ChartId, WebGLChartsState, WebGLDataSeries} from "../components/webglChart/webglChartStore";
import {Opaque} from "../utils/commonTypes";
import {MicroSeconds, MilliSeconds, Seconds, ZeroMicro, ZeroMilli} from "../utils/timeTypes";

// Types
export type OccupiedStatus = 'occupied' | 'unoccupied';
export type SourceEventLevel = 'alert' | 'warning' | 'info';
export type SourceEventStatus = 'active' | 'dismissed' | 'unread';
export type SourceEventType = 'general' | 'signal' | 'eventRule' | 'eventRuleRms' | 'eventRuleFFT';
export type SourceType = 'unknown' | 'signalbox' | 'substation';

export type GroupId = Opaque<'GroupId', string>;
export const UnknownGroupId = 'unknown_group' as GroupId;
export interface Group
{
    readonly id: GroupId;
    readonly name: string;
    readonly sortOrder: number;
}

export type FFTResponseConfigId = Opaque<'FFTResponseConfigId', string>;
export type OnlineRmsFilterId = Opaque<'OnlineRmsFilterId', string>;
export const UnknownOnlineRmsFilterId = 'unknown_filter_id' as OnlineRmsFilterId;

export type SourceId = Opaque<'SourceId', string>;
export const UnknownSourceId = 'unknown_source' as SourceId;
export interface Source
{
    readonly id: SourceId;
    readonly name: string;
    readonly location: string;
    readonly sourceType: SourceType;
    readonly sortOrder: number;
}

export interface FullSource
{
    readonly source: Source;
    readonly groupIds: GroupId[];
}

interface BaseSourceEvent
{
    readonly sourceId: SourceId;
    readonly timestamp: MicroSeconds;
}

export type SourceEventId = Opaque<'SourceEventId', number>;

export interface SourceEventRmsData
{
    readonly peak: number;
    readonly mean: number;
    readonly durationMs: MilliSeconds;
    readonly units: string;
}
export interface SourceEvent extends BaseSourceEvent
{
    readonly id: SourceEventId;
    readonly level: SourceEventLevel;
    readonly status: SourceEventStatus;
    readonly type: SourceEventType;
    readonly sourceChannel: SourceChannelId;
    readonly message: string;
    readonly thresholdData: SourceEventRmsData;
}

export interface SourceStatus extends BaseSourceEvent
{
    readonly ip: string;
    readonly cjdnsIp: string;
    readonly repoVersion: string;
    readonly systemLoadInfo: SystemLoadInfo;
    readonly picoUnitInfo: PicoUnitInfo;
    readonly modemInfo: object;
}

export interface SystemLoadInfo
{
    readonly bootTimestamp: MicroSeconds;
    readonly coreTemp: number;
    readonly ambientTemp1: number;
    readonly ambientTemp2: number;
    readonly loadAverages: number[];
    readonly memory: number;
    readonly cpu: number;
    readonly disk: number;
}

export interface PicoUnitInfo { readonly [key: string]: string; }

const EmptySystemLoadInfo: SystemLoadInfo = {
    bootTimestamp: ZeroMicro,
    coreTemp: -1,
    ambientTemp1: -1,
    ambientTemp2: -1,
    loadAverages: [],
    memory: -1,
    cpu: -1,
    disk: -1
};

export interface ColourModifiers
{
    readonly hueRotate: number;
    readonly lighten: number;
    readonly saturate: number;
}

export interface IHasColour
{
    readonly colour: ColourModifiers;
}
export interface OnlineRmsFilter extends IHasColour
{
    readonly displayName: string;
    readonly id: OnlineRmsFilterId;
}

export interface FFTResponseConfig extends IHasColour
{
    readonly id: FFTResponseConfigId;
    readonly name: string;
    readonly frequencyBands: MinMaxValue[];
}

export interface ServerInfo
{
    readonly buildNumber: string;
    readonly startingGroupId: GroupId;
    readonly startingUserView: UserView;
    readonly fftTileHostname: string;
    readonly fftScalingFactor: number;
    readonly serverStartTime: MicroSeconds;
    readonly scriptFilename: string[];
    readonly rmsFilters: { readonly [filterId: string]: OnlineRmsFilter };
    readonly fftResponseConfigs: { readonly [fftResponseConfigId: string]: FFTResponseConfig };
}

export type SourceOccupiedStatus = 'unknown' | 'occupied' | 'unoccupied';
export interface SourceOccupied extends BaseSourceEvent
{
    readonly status: SourceOccupiedStatus;
}
export interface SourceActiveStatus extends BaseSourceEvent
{
    readonly isWebsocketConnected: boolean;
    readonly lastHeartbeatTime: MicroSeconds;
    readonly lastDataReceivedTime: MicroSeconds;
}
export interface SourceFullStatus
{
    readonly status: SourceStatus;
    readonly occupied: SourceOccupied;
    readonly activeStatus: SourceActiveStatus;
}

export type ChannelTypeId = Opaque<'ChannelTypeId', string>;
export interface ChannelTypeInfo
{
    readonly channelType: ChannelTypeId;
    readonly displayName: string;
    readonly prefix: string;
    readonly chartTitle: string;
    readonly chartPriority: number;
}
export interface ChannelTypeInfoMap { readonly [channelType: string]: ChannelTypeInfo };

export type SourceChannelId = Opaque<'SourceChannelId', string>;
export interface SourceChannelInfoBase
{
    readonly sourceChannel: SourceChannelId;
    readonly mainName: string;
    readonly subscriptName: string;
    readonly channelType: ChannelTypeId;
    readonly mainColour: string;
}
export interface SourceChannelInfoMap { readonly [sourceChannel: string]: SourceChannelInfoBase };

export interface SourceDataPayload
{
    readonly sps: number;
    readonly scale: number;
    readonly offset: number;
    readonly data: number[];
    readonly maxValue: number;
    readonly minValue: number;
    readonly isVirtual: boolean;
}
export interface SourceData
{
    readonly sourceId: SourceId;
    readonly time: MicroSeconds;
    readonly payload: { readonly [sourceChannel: string]: SourceDataPayload }; // Key should be SourceChannel
}

export interface OnlineRmsValues
{
    readonly values: number[];
    readonly timeOffsets: Seconds[];
}
export interface OnlineRmsPayload
{
    readonly data: { readonly [onlineRmsFilterId: string]: OnlineRmsValues }; // Key should be OnlineRmsFilterId
}
export interface OnlineRmsResult
{
    readonly sourceId: SourceId;
    readonly time: MicroSeconds;
    readonly payload: { readonly [sourceChannel: string]: OnlineRmsPayload };
}

export interface FFTResponseDataPayload
{
    readonly id: FFTResponseConfigId;
    readonly name: string;
    readonly frequencies: MinMaxValue[];
    readonly values: number[];
    readonly time: number[];
}

export interface FFTResponseData
{
    readonly sourceId: SourceId;
    readonly time: MicroSeconds;
    readonly payload: { readonly [sourceChannel: string]: FFTResponseDataPayload[] };
}

export interface GraphData
{
    readonly sourceData: SourceData;
    readonly rmsResult: OnlineRmsResult;
    readonly fftResponse: FFTResponseData;
}

export type MinMaxValue = [number, number, number] | [] | number;
export interface SourceDataPreview
{
    readonly time: MicroSeconds;
    readonly host: SourceId;
    readonly data: { readonly [sourceChannel: string]: MinMaxValue[] }; // Key should be SourceChannel
}

export interface FFTDataPreviewSourceChannelMap { readonly [sourceChannel: string]: MinMaxValue[] }; // Key should be SourceChannel
export interface FFTDataPreviewFrequencySourceChannelMap { readonly [frequency: string]: FFTDataPreviewSourceChannelMap };
export interface FFTDataPreview
{
    readonly time: MicroSeconds;
    readonly host: SourceId;
    readonly data: { readonly [samplesPerSecond: string]: FFTDataPreviewFrequencySourceChannelMap };
}

export interface SourceDataEnabledState { readonly [sourceChannel: string]: boolean; } // Key should be SourceChannel
export interface SourceDataEnabledStateMap { readonly [id: string]: SourceDataEnabledState; } // Key can be whatever

export interface StartupStatsError
{
    readonly message: string;
    readonly data?: object;
}

export interface StatusLookupResult
{
    readonly startDate: MicroSeconds;
    readonly endDate: MicroSeconds;
    readonly sourceId: SourceId;
    readonly statuses: SourceStatus[];
}

export interface OnlineRmsFilterToPreviewData { readonly [filterId: string]: MinMaxValue[] };
export interface OnlineRmsSourceChannelToFilterPreview { readonly [sourceChannel: string]: OnlineRmsFilterToPreviewData };
export interface OnlineRmsPreview
{
    readonly time: MicroSeconds;
    readonly host: SourceId;
    readonly data: OnlineRmsSourceChannelToFilterPreview;
}

export interface FFTResponseFilterToPreviewData { readonly [fftResponseConfigId: string]: MinMaxValue[] };
export interface FFTResponseSourceChannelToFilterPreview { readonly [sourceChannel: string]: FFTResponseFilterToPreviewData };
export interface FFTResponsePreview
{
    readonly time: MicroSeconds;
    readonly host: SourceId;
    readonly data: FFTResponseSourceChannelToFilterPreview;
}

export interface SourceDataPreviews { readonly [sourceId: string]: SourceDataPreview[]; }
export interface FFTDataPreviews { readonly [sourceId: string]: FFTDataPreview[]; }
export interface OnlineRmsPreviews { readonly [sourceId: string]: OnlineRmsPreview[]; }
export interface FFTResponsePreviews { readonly [sourceId: string]: FFTResponsePreview[]; }

export interface PreviewLookupResult
{
    readonly startDate: MicroSeconds;
    readonly endDate: MicroSeconds;
    readonly groupId: GroupId;
    readonly previews: SourceDataPreviews;
}
export interface OnlineRmsPreviewLookupResult
{
    readonly startDate: MicroSeconds;
    readonly endDate: MicroSeconds;
    readonly groupId: GroupId;
    readonly previews: OnlineRmsPreviews;
}
export interface FFTResponsePreviewLookupResult
{
    readonly startDate: MicroSeconds;
    readonly endDate: MicroSeconds;
    readonly groupId: GroupId;
    readonly previews: FFTResponsePreviews;
}
export interface FFTPreviewLookupResult
{
    readonly startDate: MicroSeconds;
    readonly endDate: MicroSeconds;
    readonly groupId: GroupId;
    readonly fftPreviews: FFTDataPreviews;
}
export interface StatusLookup
{
    readonly isLoading: boolean;
    readonly result: StatusLookupResult;
}
export interface PreviewLookup
{
    readonly isLoading: boolean;
    readonly result?: PreviewLookupResult;
}
export interface FFTPreviewLookup
{
    readonly isLoading: boolean;
    readonly result?: FFTPreviewLookupResult;
}

export interface SourceDataChunk
{
    readonly time: MicroSeconds;
    readonly host: string;
    readonly data: SourceData[];
}
export interface DataLookResultMap { readonly [sourceId: string]: SourceData[]; }
export interface DataLookupResult
{
    readonly startDate: MicroSeconds;
    readonly endDate: MicroSeconds;
    readonly groupId: GroupId;
    readonly data: DataLookResultMap;
}

export interface OnlineRmsResultLookupMap { readonly [sourceId: string]: OnlineRmsResult[]; }
export interface OnlineRmsResultLookup
{
    readonly startDate: MicroSeconds;
    readonly endDate: MicroSeconds;
    readonly groupId: GroupId;
    readonly data: OnlineRmsResultLookupMap;
}

export interface FFTResponseDataLookupMap { readonly [sourceId: string]: FFTResponseData[]; }
export interface FFTResponseDataLookup
{
    readonly startDate: MicroSeconds;
    readonly endDate: MicroSeconds;
    readonly groupId: GroupId;
    readonly data: FFTResponseDataLookupMap;
}


export interface SourceChannelToDataSourceMap { readonly [sourceChannel: string]: DataSourceWithType[] };
export interface SourceToSourceChannelToDataSourceMap { readonly [sourceId: string]: SourceChannelToDataSourceMap };
export interface SourceChannelToPreviewMap { readonly [sourceChannel: string]: DataSourceWithType[]; };
export interface SourceToSourceChannelToPreviewMap { readonly [sourceId: string]: SourceChannelToPreviewMap };

export interface SourceChannelToWebGLDataSourceMap { readonly [sourceChannel: string]: WebGLDataSeries[] };
export interface SourceToSourceChannelToWebGLDataSourceMap { readonly [sourceId: string]: SourceChannelToWebGLDataSourceMap };

export interface SourceChannelToWebGLPreviewMap { readonly [sourceChannel: string]: WebGLDataSeries[]; };
export interface SourceToSourceChannelToWebGLPreviewMap { readonly [sourceId: string]: SourceChannelToWebGLPreviewMap };

export interface TimeSpan
{
    readonly start: moment.Moment;
    readonly end: moment.Moment;
}
export const EmptyTimeSpan: TimeSpan = {start: moment(), end: moment()}

export interface DataLookupChartPair
{
    readonly mainChartId: ChartId;
    readonly rmsChartId: ChartId;
    readonly fftChartId: ChartId;
    readonly previewChartId: ChartId;
}
export interface GroupedDataLookupCharts { readonly [key: string]: DataLookupChartPair; }
export interface SourceToGroupedDataLookupCharts { readonly [sourceId: string]: GroupedDataLookupCharts; }

export interface DataLookupState
{
    readonly error: string;
    readonly sourceChannels: SourceChannelId[];

    readonly isDataLookup: boolean;
    readonly isLoading: boolean;
    readonly isLoadingRMS: boolean;
    readonly enableRMS: boolean;

    readonly previewWebGLChartIds: SourceToGroupedDataLookupCharts;
    readonly dataLookupWebGLChartIds: SourceToGroupedDataLookupCharts;
}

interface SourceChannelsForFFT { readonly [sourceId: string]: SourceChannelId[]; }
export interface FFTState
{
    readonly time: moment.Moment;
    readonly zoomLevel: number;
    readonly sourceChannelsMap: SourceChannelsForFFT;
}
export interface DebugState
{
    readonly items: string[];
    readonly enabled: boolean;
}

export interface DateRange
{
    readonly id: number;
    readonly start: MicroSeconds;
    readonly end: MicroSeconds;
}
export interface SourceDataReceived { readonly [sourceId: string]: DateRange[]; }
export interface DateRangeMap { readonly [rangeId: number]: DateRange; }

export interface SourceDataReceivedMap { readonly [sourceId: string]: DateRangeMap; }
export interface FFTProcessedStore { readonly [sourceId: string]: DateRangeMap; }

export function EmptyOccupied (sourceId: string): SourceOccupied
{
    if (occuCache[sourceId]) {
        return occuCache[sourceId];
    }

    const result: SourceOccupied = {
        sourceId: sourceId as SourceId,
        timestamp: ZeroMicro,
        status: 'unknown'
    };
    occuCache[sourceId] = result;
    return result;
}
export function EmptyStatus (sourceId: string): SourceStatus
{
    if (statusCache[sourceId]) {
        return statusCache[sourceId];
    }

    const result: SourceStatus = {
        sourceId: sourceId as SourceId,
        timestamp: ZeroMicro,
        ip: "Unknown",
        cjdnsIp: "Unknown",
        repoVersion: "Unknown",
        systemLoadInfo: EmptySystemLoadInfo,
        picoUnitInfo: {},
        modemInfo: {}
    };
    statusCache[sourceId] = result;
    return result;
}
export function EmptyActiveStatus (sourceId: string): SourceActiveStatus
{
    if (activeStatusCache[sourceId]) {
        return activeStatusCache[sourceId];
    }

    const result: SourceActiveStatus = {
        sourceId: sourceId as SourceId,
        timestamp: ZeroMicro,
        isWebsocketConnected: false,
        lastDataReceivedTime: ZeroMicro,
        lastHeartbeatTime: ZeroMicro
    };
    activeStatusCache[sourceId] = result;
    return result;
}

const statusCache: {[sourceId: string]: SourceStatus} = {};
const activeStatusCache: {[sourceId: string]: SourceActiveStatus} = {};
const occuCache: {[sourceId: string]: SourceOccupied} = {};

export interface FFTResultPayloadMap { readonly [sourceChannel: string]: FFTResultPayload; }
export interface FFTResultPayload
{
    readonly frequencies: number[];
    readonly timeSegments: MicroSeconds[];
    readonly fftData: number[][];
}
export interface FFTResult
{
    readonly sourceId: SourceId;
    readonly timestamp: MicroSeconds;
    readonly payloads: FFTResultPayloadMap;
}

export type EventRuleType = 'TestRule';

export interface SourceChannelThresholdMap { readonly [sourceChannel: string]: LineChartThreshold[]; }
export interface EventRule
{
    readonly enabled: boolean;
    readonly ruleType: EventRuleType;
    readonly threshold: number;
    readonly applyToTypes: SourceChannelId[];
}
export interface EventRules
{
    readonly eventRuleResult: EventRuleResult;
    readonly thresholds: SourceChannelThresholdMap;
    readonly fftThresholds: SourceChannelThresholdMap;
    readonly rmsThresholds: SourceChannelThresholdMap;
}
export interface EventRuleResult
{
    readonly rules: EventRule[];
    readonly fftRules: EventRule[];
    readonly rmsRules: EventRule[];
}

export interface OnlineRmsResultDataSourceMap { readonly [sourceId: string]: SourceChannelToDataSourceMap };
export interface OnlineRmsResultMap { readonly [sourceId: string]: OnlineRmsResult[] };

export interface GraphChartPair
{
    readonly mainChartId: ChartId;
    readonly rmsChartId: ChartId;
    readonly fftChartId: ChartId;
}

export type GraphRefreshRate = '1hz' | '10hz' | '30hz' | 'VSync';
export interface GroupedGraphCharts { readonly [key: string]: GraphChartPair; }
export interface SourceToGroupedGraphCharts { readonly [sourceId: string]: GroupedGraphCharts; }
export interface GraphState
{
    readonly sourceChannels: SourceChannelId[];
    readonly sourceDataTimespan: TimeSpan;
    readonly webglChartIds: SourceToGroupedGraphCharts;
    readonly startTimeOffset: MicroSeconds;
    readonly viewportWidth: MicroSeconds;
    readonly refreshRate: GraphRefreshRate;
    readonly showExtraButtons: boolean;
}

export interface DownloadCSVState
{
    readonly start: moment.Moment;
    readonly end: moment.Moment;
    readonly isOpen: boolean;
}

export interface ReprocessEventJob
{
    readonly sourceIds: SourceId[];
    readonly from: MicroSeconds;
    readonly to: MicroSeconds;
    readonly current: MicroSeconds;
    readonly startedBy: string;
    readonly error: string;
    readonly numberOfEventsCreated: number;
    readonly currentSourceIdIndex: number;
}
export interface ReprocessEventsState
{
    readonly start: moment.Moment;
    readonly end: moment.Moment;
    readonly isOpen: boolean;
    readonly updateState?: ReprocessEventJob;
    readonly updateStatus: 'unknown' | 'processing' | 'stopped';
}
export interface EventReportsState
{
    readonly start: moment.Moment;
    readonly end: moment.Moment;
    readonly isOpen: boolean;
}

// Store types
export interface SourceEventMap { readonly [sourceEventId: number]: SourceEvent; }
export interface SourceToEventMap { readonly [sourceId: string]: SourceEventMap; }
export interface SourceStatuses { readonly [sourceId: string]: SourceStatus; }
export interface SourceOccupancies { readonly [sourceId: string]: SourceOccupied; }
export interface SourceDatas { readonly [sourceId: string]: SourceData[]; }
export interface SourceActiveStatuses { readonly [sourceId: string]: SourceActiveStatus; }
export interface StatusLookupMap { readonly [sourceId: string]: StatusLookup; }

export interface LineChartStateX
{
    readonly selectX: LineChartTimeSelect;
    readonly zoomX?: LineChartTimeRange;
}
export interface LineChartStateXMap { readonly [key: string]: LineChartStateX; }
export const EmptyLineChartStateX: LineChartStateX = {
    selectX: {enabled: false, min: ZeroMilli, max: ZeroMilli},
    zoomX: null,
}

export interface LineChartStateY
{
    readonly selectY: LineChartDataSelect;
    readonly zoomY?: LineChartDataRange;
}
export interface LineChartStateYMap { readonly [key: string]: LineChartStateY; }
export const EmptyLineChartStateY: LineChartStateY = {
    selectY: {enabled: false, min: 0, max: 0},
    zoomY: null
}

export interface TimelineStateMap { readonly [key: string]: TimelineState }

export type ConnectionState = 'connecting' | 'connected' | 'reconnecting' | 'closed' | 'error';
export type UserView = 'overview' | 'graphs' | 'dataLookup' | 'sourceInfo' | 'fft' | 'admin';
export type LoginStatus = 'not-logged-in' | 'logging-in' | 'logged-in' | 'login-error' | 'logging-out' | 'logged-out';
export type TokenStatus = 'unknown' | 'valid' | 'invalid';

export interface UserProfile
{
    readonly id: string;
    readonly username: string;
    readonly displayName: string;
    readonly permissions: string[];
    readonly whitelistedSources: SourceId[];
    readonly whitelistedSourceChannels: SourceChannelId[];
}

export interface LoginState
{
    readonly loginStatus: LoginStatus;
    readonly tokenStatus: TokenStatus;
    readonly isNewLogin: boolean;
    readonly userProfile: UserProfile;
}

export interface ServerState
{
    readonly selectedGroupId: GroupId;
    readonly view: UserView;
    readonly loginToken: string;
    readonly darkTheme: boolean;
    readonly autoResetYZoomOnSourceToggle: boolean;
}

export interface LocalState
{
    readonly selectedEvent: SourceEvent;
    readonly showSourceEventModal: boolean;
    readonly eventsToSelect: SourceEvent[];
    readonly showScriptVersionErrorModal: boolean;
    readonly startupErrors: StartupStatsError[];
    readonly showStartupErrors: boolean;
    readonly showDetailedSourceInfo?: Source;
    readonly showLoginModal: boolean;
    readonly pauseLiveGraphs: boolean;
    readonly sourcesLoading: boolean;
}

export interface AdminState
{
    readonly showActiveUsers: boolean;
    readonly activeUsersSummary: string;
    readonly showConfig: boolean;
    readonly showCombineDataReceived: boolean;
    readonly configSummary: string;

    readonly updateChannelTypeInfoState: UpdateChannelTypeInfoState;
    readonly updateSourceChannelInfoState: UpdateSourceChannelInfoState;
    readonly updateUserProfileState: UpdateUserProfileState;
    readonly updateSourceState: UpdateSourceState;
    readonly updateGroupState: UpdateGroupState;
    readonly userProfiles: UserProfile[];

    readonly allSources: FullSource[];
    readonly unknownSources: SourceId[];
    readonly reprocessEventsState: ReprocessEventsState;
    readonly eventReportsState: EventReportsState;
}

export interface CreateEventState
{
    readonly show: boolean;
    readonly time: MicroSeconds;
    readonly sourceChannels: SourceChannelId[];
    readonly sourceId: SourceId;
}

export interface AdminModalState
{
    show: boolean;
    createNew: boolean;
}

export interface UserProfileWithPassword extends UserProfile
{
    readonly password: string;
    readonly confirmPassword: string;
}

export interface UpdateChannelTypeInfoState extends AdminModalState
{
    readonly channelTypeInfo?: ChannelTypeInfo;
}

export interface UpdateSourceChannelInfoState extends AdminModalState
{
    readonly sourceChannelInfo?: SourceChannelInfoBase;
}

export interface UpdateUserProfileState extends AdminModalState
{
    readonly userProfile?: UserProfileWithPassword;
}

export interface UpdateSourceState extends AdminModalState
{
    readonly fullSource?: FullSource;
}

export interface UpdateGroupState extends AdminModalState
{
    readonly group?: Group;
}

export interface State
{
    readonly serverInfo: ServerInfo;
    readonly groups: Group[];
    readonly sources: Source[];
    readonly sourceEvents: SourceToEventMap;
    readonly connectionState: ConnectionState;
    readonly serverState: ServerState;
    readonly localState: LocalState;
    readonly statusLookupMap: StatusLookupMap;
    readonly sourceDataReceived: SourceDataReceivedMap;
    readonly fftProcessedStore: FFTProcessedStore;
    readonly graphState: GraphState;
    readonly dataLookup: DataLookupState;
    readonly sourceChannelInfo: SourceChannelInfoMap;
    readonly channelTypeInfo: ChannelTypeInfoMap;
    readonly createEventState: CreateEventState;
    readonly downloadCSVState: DownloadCSVState;

    readonly sourceStatuses: SourceStatuses;
    readonly sourceOccupancies: SourceOccupancies;
    readonly sourceActiveStatuses: SourceActiveStatuses;
    readonly fftState: FFTState;
    readonly debugState: DebugState;
    readonly loginState: LoginState;
    readonly eventRules: EventRules;
    readonly sourceDataEnabledState: SourceDataEnabledStateMap;
    readonly timelineStateMap: TimelineStateMap;

    readonly webglChartState: WebGLChartsState;

    // Admin related states
    readonly adminState: AdminState;
}
