import React from 'react';
import {Api} from '../api/api';
import monitoringStore from '../store/monitoringStore';
import {DownloadCSVState, Source, SourceChannelId, SourceDataEnabledState, SourceDataEnabledStateMap, SourceId} from '../store/monitoringStoreStates';
import SetDownloadCSVState from '../store/setDownloadCSVState';
import {formatDateTime, formatFileDateTime} from '../utils/utils';
import './downloadCSVModal.scss';
import LoadingBar from './loadingBar';
import {ModalBase} from './modalBase';
import SourceDataTypeSelectors from './sourceDataTypeSelector/sourceDataTypeSelectors';
import moment = require('moment');

interface Props
{
    readonly sources: Source[];
    readonly sourceChannels: SourceChannelId[];
    readonly sourceDataEnabled: SourceDataEnabledStateMap;
    readonly downloadState: DownloadCSVState;
}

interface State
{
    readonly downloading: boolean;
    readonly currentDownloadIndex: number;
    readonly numberOfDownloads: number;
    readonly downloadedBytes: number;
}

interface RenderContext
{
    readonly sourceDataEnabledForDownload: SourceDataEnabledState;
    readonly sourceChannelList: SourceChannelId[];
    readonly sourceIds: SourceId[];
    readonly canDownload: boolean;
}

function formatBytes(bytes: number)
{
    if (bytes < 0)
    {
        return '';
    }

    if (bytes < 1024)
    {
        return bytes.toString(10) + ' bytes';
    }

    if (bytes < 1024 * 1024)
    {
        return (bytes / 1024).toFixed(1) + ' KB';
    }

    if (bytes < 1024 * 1024 * 1024)
    {
        return (bytes / (1024 * 1024)).toFixed(1) + 'MB';
    }

    return (bytes / (1024 * 1024 * 1024)).toFixed(1) + 'GB';
}

export default class DownloadCSVModal extends React.PureComponent<Props, State>
{
    private renderContext: RenderContext = {
        canDownload: false,
        sourceChannelList: [],
        sourceIds: [],
        sourceDataEnabledForDownload: {}
    }

    constructor(props: Props)
    {
        super(props);

        this.state =
        {
            downloading: false,
            currentDownloadIndex: 0,
            numberOfDownloads: 0,
            downloadedBytes: -1
        }
    }

    public render()
    {
        const { downloadState, sourceChannels, sourceDataEnabled } = this.props;

        this.createRenderContext();

        return (
            <ModalBase isOpen={true}
                onRequestClose={() => this.close()}
                className='download-csv-modal'>

                <h1>Download CSV</h1>

                <div>
                    <strong>From Time: </strong> {formatDateTime(downloadState.start)}
                </div>
                <div>
                    <strong>To Time: </strong> {formatDateTime(downloadState.end)}
                </div>

                <SourceDataTypeSelectors
                    forMapId='download'
                    sourceChannels={sourceChannels}
                    sourceDataEnabled={sourceDataEnabled} />

                <div className="source-event-modal__buttons">
                    {this.state.downloading &&
                        <span>Downloaded {formatBytes(this.state.downloadedBytes)} {this.state.currentDownloadIndex} of {this.state.numberOfDownloads}</span>}

                    { this.renderDownloadButton() }

                    <button onClick={() => this.close()}>Close</button>
                </div>

                <LoadingBar show={this.state.downloading} />
            </ModalBase>
        )
    }

    private close()
    {
        monitoringStore.execute(SetDownloadCSVState.close());
    }

    private createRenderContext()
    {
        const { sources, sourceChannels, sourceDataEnabled } = this.props;

        const sourceDataEnabledForDownload = sourceDataEnabled['download'] || {};

        const sourceIds = sources.map(s => s.id);
        const sourceChannelList = sourceChannels.filter(s => sourceDataEnabledForDownload[s] !== false);
        const canDownload = sourceChannelList.length > 0;

        this.renderContext = {
            sourceChannelList, sourceDataEnabledForDownload, sourceIds, canDownload
        }
    }

    private renderDownloadButton(): JSX.Element
    {
        if (this.state.downloading)
        {
            return <button disabled data-rh='Already downloading'>Download</button>;
        }

        if (this.renderContext.canDownload)
        {
            return <button onClick={() => this.startDownload()}>Download</button>;
        }
        return <button disabled data-rh="Must select at least one source channel to download">Download</button>;
    }

    private async startDownload()
    {
        const {downloadState} = this.props;

        const timestep = moment.duration(30, 'minutes');
        const endTimeValue = downloadState.end.valueOf();

        const timePairs: {start: moment.Moment, end: moment.Moment}[] = [];

        for (let time = downloadState.start.clone(); time.valueOf() < endTimeValue; time.add(timestep))
        {
            let endTime = time.clone().add(timestep);
            if (endTime.valueOf() > endTimeValue)
            {
                endTime = downloadState.end;
            }

            timePairs.push({start: time.clone(), end: endTime.subtract(1, 'second')});
        }

        for (let i = 0; i < timePairs.length; i++)
        {
            this.setState({
                downloading: true,
                currentDownloadIndex: i + 1,
                numberOfDownloads: timePairs.length
            });

            const timePair = timePairs[i];
            try
            {
                await this.downloadTimeSegment(timePair.start, timePair.end, this.renderContext.sourceIds, this.renderContext.sourceChannelList);
            }
            catch
            {
                try
                {
                    await this.downloadTimeSegment(timePair.start, timePair.end, this.renderContext.sourceIds, this.renderContext.sourceChannelList);
                }
                catch
                {
                    alert(`Error trying to download ${formatFileDateTime(timePair.start)} - ${formatFileDateTime(timePair.end)}`);
                }
            }
        }

        this.setState({
            downloading: false
        });
    }

    private async downloadTimeSegment(start: moment.Moment, end: moment.Moment, sourceIds: SourceId[], sourceChannelList: SourceChannelId[])
    {
        return new Promise((resolve, reject) =>
        {
            const url = Api.getDownloadCSVUrl(sourceIds, start, end, sourceChannelList);
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';

            xhr.addEventListener('load', () =>
            {
                if (xhr.status === 200)
                {
                    const blob = xhr.response as Blob;
                    if (blob.size < 100)
                    {
                        reject();
                        return;
                    }
                    const downloadUrl = URL.createObjectURL(blob);

                    const downloadTag = document.createElement('a');
                    downloadTag.download = `data-${formatFileDateTime(start)}_${formatFileDateTime(end)}.zip`;
                    downloadTag.href = downloadUrl;
                    downloadTag.click();

                    URL.revokeObjectURL(downloadUrl);
                    resolve();
                }
                else
                {
                    reject();
                }
            });

            xhr.addEventListener('progress', (e) =>
            {
                this.setState({downloadedBytes: e.loaded});
            });

            xhr.addEventListener('error', () =>
            {
                reject();
            });

            xhr.send();
        });
    }
}