import React from 'react';
import '../icons.scss';
import SVGIcons from '../img/*.svg';

export type IconTypes = 'heartbeat' | 'no-heartbeat' | 'data-signal' | 'no-data-signal' |
    'fire' | 'power' | 'disk' | 'cpu' | 'memory' | 'sun' | 'check' | 'cross' | 'zoom' | 'reset' | 'create' | 'load' | 'fullscreen' | 'fullscreen-axis-x' | 'fullscreen-axis-y' | 'pause' | 'play' | 'show' | 'hide';

const mapping: {readonly [key: string]: string} = {
    'no-heartbeat': 'noHeartbeat',
    'data-signal': 'dataSignal',
    'no-data-signal': 'noDataSignal',
    'power': 'powerIcon',
    'disk': 'diskIcon',
    'cpu': 'cpuIcon',
    'memory': 'memoryIcon',
    'sun': 'sunIcon',
}

interface Props
{
    type: IconTypes;
    forceTheme?: 'light' | 'dark';
}

export function AllIcons() { return Object.keys(SVGIcons).map(s => <Icon type={s as IconTypes} />); };

export default class Icon extends React.PureComponent<Props>
{
    public render()
    {
        const iconName = mapping[this.props.type] || this.props.type;
        // Some wildness from using a parcel bundler plugin to get static SVGs.
        const iconModule = SVGIcons[iconName];
        const icon = iconModule ? iconModule.default() : null;
        const forceTheme = this.props.forceTheme ? `${this.props.forceTheme}-theme` : '';

        return <span className={`icon ${forceTheme}`}> { icon } </span>;
    }
}