import React from 'react';
import SetUpdateGroupState from '../../store/admin/setUpdateGroupState';
import monitoringStore from '../../store/monitoringStore';
import {Group} from '../../store/monitoringStoreStates';

interface Props
{
    group: Group;
}

export default class GroupView extends React.PureComponent<Props>
{
    public render()
    {
        const { group } = this.props;

        return (
            <tr>
                <td><strong>{group.name}</strong></td>
                <td>{group.id}</td>
                <td>{group.sortOrder}</td>
                <td><button onClick={this.showUpdateModal}>Edit</button></td>
            </tr>
        )
    }

    private showUpdateModal = () =>
    {
        monitoringStore.execute(SetUpdateGroupState.showUpdate(this.props.group));
    }
}