import {Modifier} from 'simple-data-store';
import {SourceChannelId, SourceDataEnabledState, State} from '../../store/monitoringStoreStates';
import SetSourceDataEnabled from '../../store/setSourceDataEnabled';
import {Editable, Opaque} from '../../utils/commonTypes';
import {addMicro, MicroSeconds, ZeroMicro} from '../../utils/timeTypes';
import {lerp} from '../../utils/utils';
import {formatForTimeResolution, getTimeResolution, isValidNumber} from '../lineChart/lineChartCommon';
import {calculateTimeRangeForAll, calculateValueRangeForAll, getDataIndexAtTime, getValueRangeOfVisible, makeTimeRange, makeValueRange, toStringColour} from './webglUtils';

export type ChartId = Opaque<'WebGLChartId', string>;
export type GroupChartId = Opaque<'WebGLGroupChartId', string>;
export const EmptyGroupChartId = '_empty' as GroupChartId;

export interface WebGLTimeRange
{
    readonly minTime: MicroSeconds;
    readonly maxTime: MicroSeconds;
    readonly width: MicroSeconds;
}
export interface WebGLValueRange
{
    readonly minValue: number;
    readonly maxValue: number;
    readonly height: number;
}
export interface WebGLAxis
{
    readonly unit: string;
    readonly label: string;
    readonly enabled: boolean;
    readonly isRMS: boolean;
}

export interface WebGLChartState
{
    readonly dataSeries: WebGLDataSeries[];
    readonly id: ChartId;
    readonly groupId: GroupChartId;
    readonly title: string;
    readonly timeSelectionId: ChartId;
    readonly valueSelectionId: ChartId;
    readonly enableTimeSelect: boolean;
    readonly enableValueSelect: boolean;
    readonly leftAxis: WebGLAxis;

    readonly originalTimeViewport: WebGLTimeRange;
    readonly originalValueViewport: WebGLValueRange;
    readonly minStartTime: MicroSeconds;
}

export type WebGLColour = number[] | string;
export interface WebGLDataMap { readonly [key: string]: number[]; };
export interface WebGLDataSeries
{
    readonly data: WebGLDataMap;
    readonly dataLength: number;
    readonly type: 'line' | 'minmax' | 'dots';
    readonly colour: WebGLColour;
    readonly startTime: MicroSeconds;
    readonly endTime: MicroSeconds;
    readonly title: string;
    readonly sps: number;
    readonly pointSize: number;
    readonly sourceChannelId: SourceChannelId;
    readonly sourceName: string;
    readonly valueRange: WebGLValueRange;
    readonly prefix?: string;
}

export interface WebGLDataSeriesMinMax extends WebGLDataSeries
{
    readonly type: 'minmax';
    readonly minMaxColour: WebGLColour;
}

export interface WebGLTimeRangeMap { readonly [timeSelectId: string]: WebGLTimeRange; };
export interface WebGLValueRangeMap { readonly [valueSelectId: string]: WebGLValueRange; };

export interface WebGLChartIdToState { readonly [id: string]: WebGLChartState }
export interface WebGLChartsState
{
    readonly charts: WebGLChartIdToState;
    readonly timeSelections: WebGLTimeRangeMap;
    readonly valueSelections: WebGLValueRangeMap;
    readonly timeViewports: WebGLTimeRangeMap;
    readonly valueViewports: WebGLValueRangeMap;
    readonly mouseData: WebGLMouseData;
}

export interface WebGLMouseChartPoint
{
    readonly chartId: ChartId;
    readonly dataSeries: WebGLDataSeries;
    readonly value: number;
    readonly time: MicroSeconds;
    readonly colour: string;
    readonly pointKey: string;
}
export interface WebGLChartIdToChartPoints { [chartId: string]: WebGLMouseChartPoint[] }
export interface WebGLMouseData
{
    readonly enabled: boolean;
    readonly groupId: GroupChartId;
    readonly triggeredChartId: ChartId;
    readonly time: MicroSeconds;

    readonly chartPoints: WebGLChartIdToChartPoints;
    readonly tooltipText: string;
}

export interface WebGLGroup
{
    readonly chartIds: ChartId[];
}

const EmptyMouseData: WebGLMouseData = {
    enabled: false,
    chartPoints: {},
    groupId: '' as GroupChartId,
    triggeredChartId: '' as ChartId,
    time: ZeroMicro,
    tooltipText: ''
}
export const DefaultWebGLChartState: WebGLChartsState = {
    charts: {},
    timeSelections: {},
    valueSelections: {},
    timeViewports: {},
    valueViewports: {},
    mouseData: EmptyMouseData
}

function modifyWebGL(state: State, webglChartState: Partial<WebGLChartsState>): Partial<State>
{
    return {webglChartState: Object.assign({}, state.webglChartState, webglChartState)}
}

function modifyTimeViewport(state: State, viewportId: ChartId, timeViewport: WebGLTimeRange): Partial<State>
{
    return modifyWebGL(state,
    {
        timeViewports: {
            ...state.webglChartState.timeViewports,
            [viewportId]: timeViewport
        }
    });
}

function modifyValueViewport(state: State, viewportId: ChartId, valueViewport: WebGLValueRange): Partial<State>
{
    return modifyWebGL(state,
    {
        valueViewports: {
            ...state.webglChartState.valueViewports,
            [viewportId]: valueViewport
        }
    });
}

function modifyTimeSelection(state: State, selectionId: ChartId, timeSelection: WebGLTimeRange): Partial<State>
{
    return modifyWebGL(state,
    {
        timeSelections: {
            ...state.webglChartState.timeSelections,
            [selectionId]: timeSelection
        }
    });
}

function modifyValueSelection(state: State, selectionId: ChartId, valueSelection: WebGLValueRange): Partial<State>
{
    return modifyWebGL(state,
    {
        valueSelections: {
            ...state.webglChartState.valueSelections,
            [selectionId]: valueSelection
        }
    });
}

export function setChartData(webglChartState: WebGLChartsState, chartId: ChartId, groupChartId: GroupChartId, title: string, dataSeries: WebGLDataSeries[], leftAxis: WebGLAxis, enableTimeSelect: boolean = true, enableValueSelect: boolean = true, valueViewportId?: ChartId, timeViewportId?: ChartId, resetTimeViewport: boolean = true, resetValueViewport: boolean = true): WebGLChartsState
{
    if (!dataSeries || dataSeries.length === 0)
    {
        const newWebGLChartState = Object.assign({}, webglChartState);
        delete (newWebGLChartState.charts as any)[chartId];

        return newWebGLChartState;
    }

    const valueViewport = calculateValueRangeForAll(dataSeries);
    const timeViewport = calculateTimeRangeForAll(dataSeries);
    let minStartTime = Number.POSITIVE_INFINITY;
    for (let i = 0; i < dataSeries.length; i++)
    {
        minStartTime = Math.min(minStartTime, dataSeries[i].startTime);
    }

    const chartState: WebGLChartState =
    {
        dataSeries: dataSeries,
        id: chartId,
        groupId: groupChartId,
        title,
        leftAxis,
        timeSelectionId: timeViewportId || chartId,
        valueSelectionId: valueViewportId || chartId,
        enableTimeSelect,
        enableValueSelect,
        originalTimeViewport: timeViewport,
        originalValueViewport: valueViewport,
        minStartTime: minStartTime as MicroSeconds
    }

    const valueViewports = resetValueViewport ? {
        ...webglChartState.valueViewports,
        [chartState.valueSelectionId]: valueViewport
    } : webglChartState.valueViewports;

    const timeViewports = resetTimeViewport ? {
        ...webglChartState.timeViewports,
        [chartState.timeSelectionId]: timeViewport
    } : webglChartState.timeViewports;

    return {
        ...webglChartState,
        charts: {
            ...webglChartState.charts,
            [chartId]: chartState
        },
        valueViewports,
        timeViewports
    };
}

function findInGroup(state: State, groupId: GroupChartId): ChartId[]
{
    const result: ChartId[] = [];
    const { charts } = state.webglChartState;
    for (const chartId in charts)
    {
        const chartState = charts[chartId];
        if (chartState.groupId === groupId)
        {
            result.push(chartState.id);
        }
    }
    return result;
}

function calculateDataValueAtTime(data: number[], roughIndex: number)
{
    const prevIndex = Math.floor(roughIndex);
    const nextIndex = Math.ceil(roughIndex);

    const dataY1 = data[prevIndex];
    const dataY2 = data[nextIndex];

    if (!isValidNumber(dataY1) || !isValidNumber(dataY2))
    {
        return NaN;
    }

    const lerpT = roughIndex % 1;
    return lerp(dataY1, dataY2, lerpT);
}

function calculateDataAtTime(chartState: WebGLChartState, time: MicroSeconds, dataPoints: WebGLMouseChartPoint[], sourceDataEnabled: SourceDataEnabledState)
{
    const { id: chartId } = chartState;

    for (const dataSeries of chartState.dataSeries)
    {
        if (time < dataSeries.startTime || time > dataSeries.endTime || dataSeries.type === 'dots')
        {
            continue;
        }

        if (sourceDataEnabled && sourceDataEnabled[dataSeries.sourceChannelId] === false)
        {
            continue;
        }

        const keyprefix = dataSeries.title;
        const roughIndex = getDataIndexAtTime(dataSeries, time);

        if (dataSeries.type === 'line')
        {
            const data = dataSeries.data['line'];
            const dataY = calculateDataValueAtTime(data, roughIndex);
            if (isNaN(dataY))
            {
                continue;
            }

            const colour = toStringColour(dataSeries.colour);
            dataPoints.push({
                chartId,
                dataSeries,
                colour,
                time,
                value: dataY,
                pointKey: `${keyprefix}-line`
            });
        }
        else if (dataSeries.type === 'minmax')
        {
            const minData = dataSeries.data['min'];
            const maxData = dataSeries.data['max'];
            const meanData = dataSeries.data['mean'];

            const minDataY = calculateDataValueAtTime(minData, roughIndex);
            const maxDataY = calculateDataValueAtTime(maxData, roughIndex);
            const meanDataY = calculateDataValueAtTime(meanData, roughIndex);
            if (isNaN(minDataY) || isNaN(maxDataY) || isNaN(meanDataY))
            {
                continue;
            }

            const minMaxColour = toStringColour((dataSeries as WebGLDataSeriesMinMax).minMaxColour);
            const colour = toStringColour(dataSeries.colour);
            dataPoints.push({
                chartId,
                dataSeries,
                colour,
                time,
                value: meanDataY,
                pointKey: `${keyprefix}-mean`
            });
            dataPoints.push({
                chartId,
                dataSeries,
                colour: minMaxColour,
                time,
                value: maxDataY,
                pointKey: `${keyprefix}-max`
            });
            dataPoints.push({
                chartId,
                dataSeries,
                colour: minMaxColour,
                time,
                value: minDataY,
                pointKey: `${keyprefix}-min`
            });
        }
    }
}

export default class WebGLChartStore
{
    public static resetTimeViewport(chartId: ChartId, timeViewportId: ChartId): Modifier<State>
    {
        return (state: State) =>
        {
            let chartState = state.webglChartState.charts[chartId];
            if (!chartState)
            {
                return state;
            }

            const viewport = chartState.originalTimeViewport;
            return modifyTimeViewport(state, timeViewportId, viewport);
        }
    }

    public static resetValueViewport(chartId: ChartId, valueViewportId: ChartId): Modifier<State>
    {
        return (state: State) =>
        {
            let chartState = state.webglChartState.charts[chartId];
            if (!chartState)
            {
                return state;
            }

            const viewport = calculateValueRangeForAll(chartState.dataSeries);
            return modifyValueViewport(state, valueViewportId, viewport);
        }
    }

    public static resetValueViewportsWithSourceChannel(forMapId: string, sourceChannelId: SourceChannelId) : Modifier<State>
    {
        return (state: State) =>
        {
            const { webglChartState, sourceDataEnabledState } = state;
            let valueViewports: Editable<WebGLValueRangeMap> = {...webglChartState.valueViewports}

            for (const chartId in webglChartState.charts)
            {
                const chartState = webglChartState.charts[chartId];
                if (!chartState.dataSeries.find(ds => ds.sourceChannelId === sourceChannelId))
                {
                    continue;
                }

                const timeViewport = webglChartState.timeViewports[chartState.timeSelectionId];
                const sourceDataEnabled = SetSourceDataEnabled.getEnabled(forMapId, sourceDataEnabledState);
                const viewport = getValueRangeOfVisible(chartState, timeViewport, sourceDataEnabled);
                valueViewports[chartState.valueSelectionId] = viewport;
            }

            return modifyWebGL(state, { valueViewports });
        }
    }

    public static setTimeSelection(timeSelectionId: ChartId, timeSelection: WebGLTimeRange): Modifier<State>
    {
        if (timeSelection && timeSelection.maxTime === timeSelection.minTime)
        {
            timeSelection = makeTimeRange(timeSelection.minTime, addMicro(timeSelection.minTime, 1));
        }
        return (state: State) => modifyTimeSelection(state, timeSelectionId, timeSelection);
    }

    public static setValueSelection(valueSelectionId: ChartId, valueSelection: WebGLValueRange): Modifier<State>
    {
        if (valueSelection && valueSelection.maxValue === valueSelection.minValue)
        {
            valueSelection = makeValueRange(valueSelection.minValue, valueSelection.minValue + 1);
        }
        return (state: State) => modifyValueSelection(state, valueSelectionId, valueSelection);
    }

    public static setTimeViewport(timeViewportId: ChartId, timeViewport: WebGLTimeRange): Modifier<State>
    {
        if (timeViewport && timeViewport.maxTime === timeViewport.minTime)
        {
            timeViewport = makeTimeRange(timeViewport.minTime, addMicro(timeViewport.minTime, 1));
        }
        return (state: State) => modifyTimeViewport(state, timeViewportId, timeViewport);
    }

    public static setValueViewport(valueViewportId: ChartId, valueViewport: WebGLValueRange): Modifier<State>
    {
        if (valueViewport && valueViewport.maxValue === valueViewport.minValue)
        {
            valueViewport = makeValueRange(valueViewport.minValue, valueViewport.minValue + 1);
        }
        return (state: State) => modifyValueViewport(state, valueViewportId, valueViewport);
    }

    public static setTimeSelectionAndViewport(timeSelectionId: ChartId, timeSelection: WebGLTimeRange, timeViewport: WebGLTimeRange)
    {
        if (timeSelection && timeSelection.maxTime === timeSelection.minTime)
        {
            timeSelection = makeTimeRange(timeSelection.minTime, addMicro(timeSelection.minTime, 1));
        }
        if (timeViewport && timeViewport.maxTime === timeViewport.minTime)
        {
            timeViewport = makeTimeRange(timeViewport.minTime, addMicro(timeViewport.minTime, 1));
        }

        return (state: State) =>
        {
            return modifyWebGL(state, {
                timeSelections: {
                    ...state.webglChartState.timeSelections,
                    [timeSelectionId]: timeSelection
                },
                timeViewports: {
                    ...state.webglChartState.timeViewports,
                    [timeSelectionId]: timeViewport
                }
            });
        }
    }

    public static setValueSelectionAndViewport(valueSelectionId: ChartId, valueSelection: WebGLValueRange, valueViewport: WebGLValueRange)
    {
        return (state: State) =>
        {
            return modifyWebGL(state, {
                valueSelections: {
                    ...state.webglChartState.valueSelections,
                    [valueSelectionId]: valueSelection
                },
                valueViewports: {
                    ...state.webglChartState.valueViewports,
                    [valueSelectionId]: valueViewport
                }
            });
        }
    }

    public static setSelectionAndViewport(timeSelectionId: ChartId, valueSelectionId: ChartId, timeSelection: WebGLTimeRange, timeViewport: WebGLTimeRange, valueSelection: WebGLValueRange, valueViewport: WebGLValueRange)
    {
        return (state: State) =>
        {
            return modifyWebGL(state, {
                timeSelections: {
                    ...state.webglChartState.timeSelections,
                    [timeSelectionId]: timeSelection
                },
                timeViewports: {
                    ...state.webglChartState.timeViewports,
                    [timeSelectionId]: timeViewport
                },
                valueSelections: {
                    ...state.webglChartState.valueSelections,
                    [valueSelectionId]: valueSelection
                },
                valueViewports: {
                    ...state.webglChartState.valueViewports,
                    [valueSelectionId]: valueViewport
                }
            });
        }
    }

    public static zoomValueViewport(valueViewportId: ChartId, factor: number): Modifier<State>
    {
        return (state: State) =>
        {
            const viewport = state.webglChartState.valueViewports[valueViewportId];
            if (!viewport)
            {
                return state;
            }

            const maxValue = viewport.maxValue * factor;
            const minValue = viewport.minValue * factor;
            const newViewport = makeValueRange(minValue, maxValue);

            return modifyValueViewport(state, valueViewportId, newViewport);
        }
    }

    public static zoomTimeViewport(timeViewportId: ChartId, factor: number): Modifier<State>
    {
        return (state: State) =>
        {
            const viewport = state.webglChartState.timeViewports[timeViewportId];
            if (!viewport)
            {
                return state;
            }

            const middle = (viewport.maxTime + viewport.minTime) * 0.5;
            const diff = viewport.maxTime - middle;

            const maxTime = (middle + diff * factor) as MicroSeconds;
            const minTime = (middle - diff * factor) as MicroSeconds;
            const newViewport = makeTimeRange(minTime, maxTime);

            return modifyTimeViewport(state, timeViewportId, newViewport);
        }
    }

    public static setMouseOver(chartId: ChartId, time: MicroSeconds): Modifier<State>
    {
        return (state: State) =>
        {
            const triggeredChartState = state.webglChartState.charts[chartId];
            if (!triggeredChartState)
            {
                return null;
            }

            const sourceDataEnabled = state.sourceDataEnabledState['main'];

            const groupId = triggeredChartState.groupId;
            const chartIds = findInGroup(state, groupId);

            const timeResolution = getTimeResolution(ZeroMicro);
            const tooltipText = `{Time}: ${formatForTimeResolution(timeResolution, time)}`;

            const chartPoints: WebGLChartIdToChartPoints = {};

            for (const chartId of chartIds)
            {
                const chartState = state.webglChartState.charts[chartId];
                if (!chartState)
                {
                    console.warn('Setting webgl mouse over for group', groupId, 'with missing chart ids', chartId);
                    continue;
                }

                const chartPointList: WebGLMouseChartPoint[] = [];
                calculateDataAtTime(chartState, time, chartPointList, sourceDataEnabled);
                if (chartPointList.length > 0)
                {
                    chartPoints[chartId] = chartPointList;
                }
            }

            return modifyWebGL(state, {
                mouseData: {
                    enabled: true,
                    chartPoints,
                    triggeredChartId: chartId,
                    groupId,
                    time,
                    tooltipText
                }
            })
        }
    }

    public static setMouseOff(chartId: ChartId): Modifier<State>
    {
        return (state: State) =>
        {
            if (state.webglChartState.mouseData.triggeredChartId !== chartId)
            {
                return null;
            }

            return modifyWebGL(state, { mouseData: EmptyMouseData });
        }
    }
}
