import {Modifier} from "simple-data-store";
import {modifyLocalState} from "./monitoringStore";
import {Source, State} from "./monitoringStoreStates";

export default class ShowDetailedInfoModal
{
    public static show (source: Source): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {showDetailedSourceInfo: source});
    }

    public static close (): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {showDetailedSourceInfo: null});
    }
}