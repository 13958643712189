import React, {CSSProperties} from 'react';
import {MicroSeconds} from '../../utils/timeTypes';
import {formatForTimeResolution, getTimeResolution} from '../lineChart/lineChartCommon';
import {WebGLTimeRange} from './webglChartStore';

interface Props
{
    readonly timeViewport: WebGLTimeRange;
    readonly enableAxis: boolean;
}

export default class WebGLChartBottomAxis extends React.PureComponent<Props>
{
    private ref: React.RefObject<HTMLDivElement> = React.createRef();

    public componentDidMount()
    {
        this.forceUpdate();
    }

    public render()
    {
        const { timeViewport, enableAxis } = this.props;
        const { minTime, width: timeWidth } = timeViewport;

        const timeResolution = getTimeResolution(timeWidth);

        const ticks: JSX.Element[] = [];
        if (this.ref.current)
        {
            // Very rough estimate on how long the text will be for each tick.
            const textWidth = Math.max(150, timeResolution.axisLabel.length * 15);

            const width = this.ref.current.offsetWidth;
            const numSteps = Math.floor(width / textWidth);

            const step = timeWidth / numSteps;
            if (isNaN(step) || step <= 0)
            {
                return null;
            }

            for (let i = 0; i <= numSteps; i++)
            {
                const value = (minTime + step * i) as MicroSeconds;
                const percent = (value - minTime) / timeWidth;

                const style: CSSProperties = {
                    left: `${percent * 100}%`
                }

                if (i === numSteps)
                {
                    style.left = 'calc(100% - 1px)';
                }

                let valueText = formatForTimeResolution(timeResolution, value);
                ticks.push(<span key={value} style={style} className='webgl-chart__bottom-axis-tick'>
                    <span className='text'>{valueText}</span>
                    <span className='tick' />
                </span>);
            }
        }

        return <div ref={this.ref} className='webgl-chart__bottom-axis-bumper webgl-chart__bottom-axis'>
            <div className='webgl-chart__bottom-axis-ticks'>
                { ticks }
            </div>

            { enableAxis &&
                <div className='webgl-chart__bottom-axis-units'>
                    <strong>Time ({timeResolution.axisLabel})</strong>
                </div>
            }
        </div>;
    }
}