import React, {MouseEventHandler} from 'react';

interface Props
{
    readonly toggled: boolean;
    readonly className?: string;
    readonly onClick?: MouseEventHandler<HTMLButtonElement>;
}

export default class ToggleButton extends React.PureComponent<Props>
{
    public render()
    {
        return <button className={`${this.props.toggled ? 'button-active' : ''} ${this.props.className || ''}`}
            onClick={this.props.onClick}
            >
                { this.props.children }
            </button>;
    }
}