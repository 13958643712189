import React, {CSSProperties} from 'react';
import {formatDateTime} from '../../utils/utils';
import {RenderContext, TimelineEvent, TimelineEventMap, TimelineGroup} from './timelineCommon';

interface Props
{
    readonly events: TimelineEventMap;
    readonly groups: TimelineGroup[];
    readonly renderContext: RenderContext;
    readonly restrictSelectToEvents: boolean;
}

interface RenderBounds {
    minX?: number;
    maxX?: number;
    minY?: number;
    maxY?: number;
}

function min(x?: number, y?: number)
{
    if (x == undefined) { return y; }
    if (y == undefined) { return x; }

    return x > y ? y : x;
}

function max(x?: number, y?: number)
{
    if (x == undefined) { return y; }
    if (y == undefined) { return x; }

    return x > y ? x : y;
}

export default class TimelineEvents extends React.PureComponent<Props>
{
    private eventsRenderThrottle: { [timeKey: string]: boolean} = {};
    private eventRenderBounds: RenderBounds = {};

    public render()
    {
        return <> { this.renderData() } </>;
    }

    private renderData()
    {
        const { renderContext } = this.props;

        const result: JSX.Element[] = [];
        if(renderContext == undefined)
        {
            return result;
        }

        const startValue = renderContext.leftMs;
        const endValue = renderContext.rightMs;

        this.eventsRenderThrottle = {};
        this.eventRenderBounds = {};

        for (const groupId in this.props.events)
        {
            const groupEvents = this.props.events[groupId];
            const groupIndex = this.props.groups.findIndex(g => g.id === groupId);
            if (groupIndex < 0)
            {
                continue;
            }

            for (const e of groupEvents)
            {
                if ((e.start.valueOf() < startValue && e.end.valueOf() < startValue) ||
                    (e.start.valueOf() > endValue && e.end.valueOf() > endValue))
                {
                    continue;
                }

                result.push(this.renderEvent(e, groupIndex));
            }
        }

        result.push(this.renderEventSelectBox());

        return result;
    }

    private renderEvent(e: TimelineEvent, groupIndex: number)
    {
        const { renderContext } = this.props;

        const yOffset = groupIndex * 25;
        const leftXpos = renderContext.calculateXposFromTime(e.start, true);
        const rightXpos = renderContext.calculateXposFromTime(e.end, true);
        const width = Math.max(2, rightXpos - leftXpos);

        const throttleKey = `${groupIndex}_${Math.round(leftXpos * 8)}_${Math.round(width * 8)}`;
        if (this.eventsRenderThrottle[throttleKey])
        {
            return null;
        }
        this.eventsRenderThrottle[throttleKey] = true;
        const renderKey = `${groupIndex}_${e.start.valueOf()}_${e.end.valueOf()}`;

        const isPastStart = e.start.valueOf() > renderContext.leftMs;

        this.eventRenderBounds.minX = min(this.eventRenderBounds.minX, leftXpos);
        this.eventRenderBounds.maxX = max(this.eventRenderBounds.maxX, rightXpos);
        this.eventRenderBounds.minY = min(this.eventRenderBounds.minY, groupIndex * 25 - 3);
        this.eventRenderBounds.maxY = max(this.eventRenderBounds.maxY, (groupIndex + 1) * 25 + 5);

        const style: CSSProperties = {
            transform: `translate(${leftXpos}px, ${yOffset}px)`,
            width: `${width}px`
        }

        const tooltip = `Start: ${formatDateTime(e.start)}
            End: ${formatDateTime(e.end)}`;

        return <span key={renderKey} data-rh={tooltip} className="timeline__event" style={style}>
                { isPastStart && <span className="timeline__event-start" /> }
            </span>;
    }

    private renderEventSelectBox()
    {
        const { renderContext, groups, restrictSelectToEvents } = this.props;

        const style: CSSProperties = {
            top: `${this.eventRenderBounds.minY}px`,
            height: `${this.eventRenderBounds.maxY - this.eventRenderBounds.minY}px`
        }

        if (restrictSelectToEvents)
        {
            if (this.eventRenderBounds.minX === this.eventRenderBounds.maxX)
            {
                return null;
            }

            const maxX = Math.min(this.eventRenderBounds.maxX + 20, renderContext.eventsBounds.width);
            const minX = Math.max(this.eventRenderBounds.minX - 20, 0);

            style.left = `${minX}px`;
            style.width = `${maxX - minX}px`;
        }
        else
        {
            style.width = `${renderContext.eventsBounds.width}px`;
            style.height = `${groups.length * 25}px`;
        }

        return <span key='event-select-box' className="timeline__event-select-box" style={style} />;
    }
}