import {Modifier} from "simple-data-store";
import monitoringStore from "./monitoringStore";
import {DebugState, State} from "./monitoringStoreStates";

export function debug (message: string)
{
    monitoringStore.execute(SetDebugState.addItems([message]));
}

function modifyDebugState(state: State, debugState: Partial<DebugState>)
{
    return {debugState: Object.assign({}, state.debugState, debugState)};
}
export default class SetDebugState
{
    public static enabled (enabled: boolean): Modifier<State>
    {
        return (state: State) => modifyDebugState(state, {enabled});
    }

    public static addItems(newItems: string[]): Modifier<State>
    {
        return (state: State) =>
        {
            const items = state.debugState.items.concat(newItems);
            return modifyDebugState(state, {items});
        }
    }
}