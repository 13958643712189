import {Modifier} from "simple-data-store";
import {Editable} from "../../utils/commonTypes";
import {modifyAdminState} from "../monitoringStore";
import {State, UpdateUserProfileState, UserProfile, UserProfileWithPassword} from "../monitoringStoreStates";

export default class SetUpdateUserProfile
{
    public static showCreate (userProfile: UserProfile): Modifier<State>
    {
        return this.execute(true, true, userProfile);
    }
    public static showUpdate (userProfile: UserProfile): Modifier<State>
    {
        return this.execute(true, false, userProfile);
    }
    public static close (): Modifier<State>
    {
        return this.execute(false, false);
    }

    public static updateUserProfile(userProfile: Partial<UserProfileWithPassword>)
    {
        return (state: State) =>
        {
            const updateUserProfileState: UpdateUserProfileState = {
                ...state.adminState.updateUserProfileState,
                userProfile: {...state.adminState.updateUserProfileState.userProfile, ...userProfile}
            }

            return modifyAdminState(state, {updateUserProfileState});
        }
    }

    private static execute (show: boolean, createNew: boolean, userProfile?: UserProfile): Modifier<State>
    {
        return (state: State) =>
        {
            const updateUserProfileState: Editable<UpdateUserProfileState> = {
                ...state.adminState.updateUserProfileState, show, createNew
            }

            if (userProfile)
            {
                updateUserProfileState.userProfile = {...userProfile, password: '', confirmPassword: ''};
            }

            return modifyAdminState(state, {updateUserProfileState});
        }
    }
}