import React from 'react';
import './loadingSpinner.scss';

interface Props
{
    show: boolean;
    center: boolean;
    small: boolean;
    black: boolean;
}

export default class LoadingSpinner extends React.PureComponent<Props>
{
    public static defaultProps: Partial<Props> = {
        center: false,
        show: true,
        small: false,
        black: false
    };

    public render ()
    {
        return <span className={`loading-spinner ${this.props.center ? 'center' : ''}`}>
            <span className={
                `loading-spinner__graphic
                ${this.props.show ? 'show' : ''}
                ${this.props.black ? 'black' : ''}
                ${this.props.small ? 'small' : ''}
                `
            }></span>
        </span>;
    }
}
