import React from 'react';
import SetUpdateUserProfile from '../../store/admin/setUpdateUserProfileState';
import monitoringStore from '../../store/monitoringStore';
import {UserProfile} from '../../store/monitoringStoreStates';

interface Props
{
    userProfile: UserProfile;
}

export default class UserProfileView extends React.PureComponent<Props>
{
    public render()
    {
        const { userProfile } = this.props;

        return (
           <tr>
               <td data-rh={`User Id: ${userProfile.id}`}><strong>{userProfile.displayName}</strong></td>
               <td>{userProfile.username}</td>
               <td>{userProfile.permissions.join(', ')}</td>
               <td>{userProfile.whitelistedSources.join(', ')}</td>
               <td>{userProfile.whitelistedSourceChannels.join(', ')}</td>
               <td><button onClick={this.showUpdateModal}>Edit</button></td>
           </tr>
       )
    }

    public showUpdateModal = () =>
    {
        monitoringStore.execute(SetUpdateUserProfile.showUpdate(this.props.userProfile));
    }
}