import {Modifier} from "simple-data-store";
import {modifyAdminState} from "../monitoringStore";
import {State} from "../monitoringStoreStates";

export default class SetActiveUsers
{
    public static update (summary: string): Modifier<State>
    {
        return (state: State) => modifyAdminState(state, {activeUsersSummary: summary});
    }

    public static show (): Modifier<State>
    {
        return (state: State) => modifyAdminState(state, {showActiveUsers: true});
    }

    public static close (): Modifier<State>
    {
        return (state: State) => modifyAdminState(state, {showActiveUsers: false});
    }
}
