import React from 'react';
import monitoringStore from '../../store/monitoringStore';
import {SourceChannelId, SourceDataEnabledStateMap} from '../../store/monitoringStoreStates';
import SetAutoYZoomReset from '../../store/setAutoYZoomReset';
import SliderCheckbox from '../sliderCheckbox';
import SourceDataTypeSelector from './sourceDataTypeSelector';
import './sourceDataTypeSelector.scss';

interface Props
{
    readonly forMapId: string;
    readonly sourceChannels: SourceChannelId[];
    readonly sourceDataEnabled: SourceDataEnabledStateMap;
    readonly autoResetYZoom?: boolean;
}

export default class SourceDataTypeSelectors extends React.PureComponent<Props>
{
    public render()
    {
        const { sourceChannels, autoResetYZoom } = this.props;
        const hasSourceChannels = sourceChannels.length > 0;

        return <div className='source-data-type-selectors'>
            <strong className='source-data-type-selectors__title'>Source Data Types</strong>
            { typeof(autoResetYZoom) === 'boolean' &&
            <span className='source-data-type-selectors__auto-y-zoom' data-rh-at='left' data-rh='Automatically reset Y zoom on charts when toggling a source channel.'>
                <SliderCheckbox mini checked={autoResetYZoom} onChange={this.onChangeAutoYZoom} /> Auto Reset Y
            </span>
            }
            <div>
                { hasSourceChannels && this.renderSourceChannels() }
                { !hasSourceChannels && <span>No data loaded</span>}
            </div>
        </div>;
    }

    private onChangeAutoYZoom = () =>
    {
        monitoringStore.execute(SetAutoYZoomReset.action(!this.props.autoResetYZoom));
    }

    private renderSourceChannels()
    {
        const result: JSX.Element[] = [];
        for (const sourceChannel of this.props.sourceChannels)
        {
            let enabled = (this.props.sourceDataEnabled[this.props.forMapId] || {})[sourceChannel];
            if (enabled !== false)
            {
                enabled = true;
            }

            result.push(<SourceDataTypeSelector
                key={sourceChannel}
                forMapId={this.props.forMapId}
                sourceChannelId={sourceChannel}
                autoResetYZoom={this.props.autoResetYZoom}
                enabled={enabled} />);
        }

        return result;
    }
}