import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {modifyLocalState} from "./monitoringStore";
import {Source, SourceId, SourceToEventMap, State} from "./monitoringStoreStates";

interface SourceMap
{
    [sourceId: string]: object;
}

function hasSourceId (sources: Source[], sourceId: SourceId)
{
    for (const source of sources)
    {
        if (source.id === sourceId)
        {
            return true;
        }
    }
    return false;
}

function processSourceMap(map: SourceMap, sources: Source[], newObject: object)
{
    // Remove events that aren't related to sources we care about.
    for (const sourceIdString in map)
    {
        const sourceId = sourceIdString as SourceId;
        if (!hasSourceId(sources, sourceId))
        {
            delete map[sourceId];
        }
    }

    // Add empty maps for new sources.
    for (const source of sources)
    {
        if (!map[source.id])
        {
            map[source.id] = newObject;
        }
    }
}

export default class SetSourceList
{
    public static setLoading (isLoading: boolean): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {sourcesLoading: isLoading});
    }

    public static mergeSource (source: Source): Modifier<State>
    {
        return (state: State) =>
        {
            const sources = [...state.sources];
            const existingIndex = sources.findIndex(s => s.id === source.id);
            if (existingIndex < 0)
            {
                sources.push(source);
            }
            else
            {
                sources[existingIndex] = source;
            }

            return this.action(sources)(state);
        }
    }
    public static removeSource (sourceId: SourceId): Modifier<State>
    {
        return (state: State) =>
        {
            const existingIndex = state.sources.findIndex(s => s.id === sourceId);
            if (existingIndex < 0)
            {
                return null;
            }

            const sources = [...state.sources];
            sources.splice(existingIndex, 1);

            return this.action(sources)(state);
        }
    }

    public static action (sources: Source[]): Modifier<State>
    {
        return (state: State) =>
        {
            const localState = {...state.localState, sourcesLoading: false};
            const sourceToEventsMap: Editable<SourceToEventMap> = {...state.sourceEvents};

            sources = [...sources];
            sources.sort((x, y) =>
            {
                const order = y.sortOrder - x.sortOrder;
                if (order === 0)
                {
                    return x.name.localeCompare(y.name);
                }
                return order;
            });

            processSourceMap(sourceToEventsMap, sources, {});

            return {
                sources,
                localState,
                sourceEvents: sourceToEventsMap
            };
        }
    }
}