export default class Vector4
{
    public readonly data: Float32Array = new Float32Array(4);

    constructor(initialValues?: Iterable<number>)
    {
        if (initialValues)
        {
            let i = 0;
            for (let v of initialValues)
            {
                this.data[i++] = v;

                if (i >= 4)
                {
                    break;
                }
            }
        }
    }

    add(x: number, y: number, z: number = 0, w: number = 0)
    {
        this.data[0] += x;
        this.data[1] += y;
        this.data[2] += z;
        this.data[3] += w;
    }
}