import {Opaque} from "./commonTypes";
import moment = require("moment");

export type Seconds = Opaque<'Seconds', number>;
export type MilliSeconds = Opaque<'MilliSeconds', number>;
export type MicroSeconds = Opaque<'MicroSeconds', number>;

export const MaxMicro = Number.POSITIVE_INFINITY as MicroSeconds;
export const MinMicro = Number.NEGATIVE_INFINITY as MicroSeconds;

export const MaxMilli = Number.POSITIVE_INFINITY as MilliSeconds;
export const MinMilli = Number.NEGATIVE_INFINITY as MilliSeconds;

export const ZeroMicro = 0 as MicroSeconds;
export const ZeroMilli = 0 as MilliSeconds;

export function microToMilli(input: MicroSeconds): MilliSeconds
{
    return input * 0.001 as MilliSeconds;
}

export function microToSeconds(input: MicroSeconds): Seconds
{
    return input * 0.000001 as Seconds;
}

export function milliToMicro(input: MilliSeconds): MicroSeconds
{
    return input * 1000 as MicroSeconds;
}

export function dateToMicro(input: Date): MicroSeconds
{
    return input.getTime() * 1000 as MicroSeconds;
}

export function momentToMicro(input: moment.Moment): MicroSeconds
{
    return input.valueOf() * 1000 as MicroSeconds;
}

export function microToMoment(input: MicroSeconds): moment.Moment
{
    return moment(microToMilli(input));
}

export function secondsToMilli(input: Seconds): MilliSeconds
{
    return input * 1000 as MilliSeconds;
}

export function secondsToMicro(input: Seconds): MicroSeconds
{
    return input * 1000000 as MicroSeconds;
}

export function addMicro(x: MicroSeconds, y: MicroSeconds | number): MicroSeconds
{
    return x + y as MicroSeconds;
}
export function subMicro(x: MicroSeconds, y: MicroSeconds): MicroSeconds
{
    return x - y as MicroSeconds;
}
export function scaleMicro(x: MicroSeconds, y: number): MicroSeconds
{
    return x * y as MicroSeconds;
}

export function addMilli(x: MilliSeconds, y: MilliSeconds): MilliSeconds
{
    return x + y as MilliSeconds;
}
export function subMilli(x: MilliSeconds, y: MilliSeconds): MilliSeconds
{
    return x - y as MilliSeconds;
}
export function scaleMilli(x: MilliSeconds, y: number): MilliSeconds
{
    return x * y as MilliSeconds;
}

export function maxMilli(x: MilliSeconds, y: MilliSeconds): MilliSeconds
{
    return Math.max(x, y) as MilliSeconds;
}
export function minMilli(x: MilliSeconds, y: MilliSeconds): MilliSeconds
{
    return Math.min(x, y) as MilliSeconds;
}

export function maxMicro(x: MicroSeconds, y: MicroSeconds): MicroSeconds
{
    return Math.max(x, y) as MicroSeconds;
}
export function minMicro(x: MicroSeconds, y: MicroSeconds): MicroSeconds
{
    return Math.min(x, y) as MicroSeconds;
}

export function nowMicro(): MicroSeconds
{
    return milliToMicro(nowMilli());
}

export function nowMilli(): MilliSeconds
{
    return Date.now() as MilliSeconds;
}