import React from 'react';
import {Api} from '../../api/api';
import SetActiveUsers from '../../store/admin/setActiveUsers';
import monitoringStore from '../../store/monitoringStore';
import ButtonGroup from '../buttonGroup';
import {ModalBase} from '../modalBase';
import './listActiveUsersModal.scss';

interface Props
{
    show: boolean;
    summary: string;
}

export default class ListActiveUsersModal extends React.PureComponent<Props>
{
    public render()
    {
        return (
            <ModalBase isOpen={this.props.show}
                className="list-active-users-modal"
                onRequestClose={() => this.close()}>

                <h2>Active Connected Users Summary</h2>

                <pre>
                    {this.props.summary.length > 0 ? this.props.summary : 'Loading...'}
                </pre>

                <div className="update-channel-info-modal__buttons">
                    <ButtonGroup>
                        <button onClick={() => this.update()}>Update List</button>
                    </ButtonGroup>
                    <button onClick={() => this.close()}>Close</button>
                </div>

            </ModalBase>
        )
    }

    private close()
    {
        monitoringStore.execute(SetActiveUsers.close());
    }

    private update()
    {
        monitoringStore.execute(SetActiveUsers.update(''));
        Api.listActiveUsers();
    }
}