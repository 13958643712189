import {Modifier} from "simple-data-store";
import WebGLChartStore, {ChartId, GroupChartId, setChartData, WebGLChartsState, WebGLDataSeries, WebGLTimeRange} from "../components/webglChart/webglChartStore";
import {Editable} from "../utils/commonTypes";
import {addSourceChannels, CachedLeftAxis, combineDataSourceWebGL, expandTimeSpanOverSeries, trimWebGLDataSeries} from "../utils/dataSourceUtils";
import {addMicro, MicroSeconds, nowMicro, Seconds, secondsToMicro, subMicro} from "../utils/timeTypes";
import {findSource, groupBy} from "../utils/utils";
import {ChannelTypeInfoManager} from "./channelTypeInfoManager";
import {ChannelTypeId, EmptyTimeSpan, GraphChartPair, GraphData, GraphRefreshRate, GraphState, GroupedGraphCharts, SourceChannelId, SourceId, SourceToGroupedGraphCharts, State} from "./monitoringStoreStates";
import {processFFTResponseDataWebGL} from "./processFFTResponseResult";
import {processOnlineRmsResultWebGL} from "./processOnlineRmsResult";
import {concatTrimWebGLDataSeries, processSourceDataWebGL} from "./processSourceData";

function getGraphChartPair(grouped: Editable<GroupedGraphCharts>, key: string): Editable<GraphChartPair>
{
    const current = grouped[key];
    let result: Editable<GraphChartPair>;
    if (!current)
    {
        result = {rmsChartId: null, mainChartId: null, fftChartId: null};
    }
    else
    {
        result = Object.assign({}, current);
    }

    grouped[key] = result;
    return result;
}

export const GraphTimeId: ChartId = 'graph-time-id' as ChartId;

function getSourceChannelsFromGraph(graphWebGLChartIds: SourceToGroupedGraphCharts, webglChartsState: WebGLChartsState)
{
    const sourceChannels = new Set<SourceChannelId>();
    for (const sourceId in graphWebGLChartIds)
    {
        const graphSourceCharts = graphWebGLChartIds[sourceId];
        for (const key in graphSourceCharts)
        {
            const pair = graphSourceCharts[key];
            const chart = webglChartsState.charts[pair.mainChartId];
            addSourceChannels(sourceChannels, chart.dataSeries);
        }
    }

    return sourceChannels;
}

export default class SetGraphState
{
    public static updateTime(timeViewport: WebGLTimeRange): Modifier<State>
    {
        return WebGLChartStore.setTimeViewport(GraphTimeId, timeViewport);
    }

    public static setStartTimeOffset(startTimeOffset: MicroSeconds): Modifier<State>
    {
        return (state: State) =>
        {
            return { graphState: { ...state.graphState, startTimeOffset } }
        }
    }

    public static setViewportWidth(viewportWidth: MicroSeconds): Modifier<State>
    {
        return (state: State) =>
        {
            return { graphState: { ...state.graphState, viewportWidth } }
        }
    }

    public static setRefreshRate(refreshRate: GraphRefreshRate): Modifier<State>
    {
        return (state: State) =>
        {
            return { graphState: { ...state.graphState, refreshRate } }
        }
    }

    public static setShowExtraButtons(showExtraButtons: boolean): Modifier<State>
    {
        return (state: State) =>
        {
            return { graphState: { ...state.graphState, showExtraButtons } }
        }
    }

    public static trimOldData(): Modifier<State>
    {
        return (state: State) =>
        {
            const { webglChartIds, startTimeOffset, viewportWidth } = state.graphState;
            const { webglChartState } = state;

            const endTime = addMicro(startTimeOffset, viewportWidth);
            const cutoffTime = addMicro(endTime, secondsToMicro(3 as Seconds));
            const timeFromNow = subMicro(nowMicro(), cutoffTime);
            let newWebglChartIds: Editable<SourceToGroupedGraphCharts> = webglChartIds;

            for (const sourceId in webglChartIds)
            {
                const sourceCharts = webglChartIds[sourceId];
                let newSourceCharts: Editable<GroupedGraphCharts> = sourceCharts;
                for (const key in sourceCharts)
                {
                    const pair = sourceCharts[key];
                    const chart = webglChartState.charts[pair.mainChartId];
                    if (chart.originalTimeViewport.maxTime < timeFromNow)
                    {
                        // Todo remove webgl chart
                        newSourceCharts = {...newSourceCharts};
                        delete newSourceCharts[key];
                    }
                }

                if (newSourceCharts !== sourceCharts)
                {
                    if (newWebglChartIds === webglChartIds)
                    {
                        newWebglChartIds = Object.assign({}, newWebglChartIds);
                    }
                    newWebglChartIds[sourceId] = newSourceCharts;
                }
            }

            if (newWebglChartIds !== webglChartIds)
            {
                const sourceChannels = getSourceChannelsFromGraph(newWebglChartIds, webglChartState);
                return {
                    graphState: {
                        ...state.graphState,
                        sourceChannels: [...sourceChannels],
                        webglChartIds: newWebglChartIds
                    }
                }
            }

            return null;
        }
    }

    public static action(graphData: GraphData): Modifier<State>
    {
        return (state: State) =>
        {
            const {sourceData, rmsResult, fftResponse} = graphData;
            const {startTimeOffset, viewportWidth} = state.graphState;
            const sourceDataByChannelType = processSourceDataWebGL(sourceData);
            const rmsByChannelType = processOnlineRmsResultWebGL(rmsResult);
            const fftByChannelType = processFFTResponseDataWebGL(fftResponse);

            const source = findSource(state, sourceData.sourceId as SourceId);

            let timespan = EmptyTimeSpan;

            const graphWebGLChartIds: Editable<SourceToGroupedGraphCharts> = { ...state.graphState.webglChartIds };

            const currentGraphChartIds = graphWebGLChartIds[sourceData.sourceId];
            const graphSourceChartIds: Editable<GroupedGraphCharts> = currentGraphChartIds ? { ...currentGraphChartIds} : {};

            graphWebGLChartIds[sourceData.sourceId] = graphSourceChartIds;
            const cutoffTime = addMicro(addMicro(startTimeOffset, viewportWidth), secondsToMicro(2 as Seconds));
            const rmsCutoffTime = addMicro(cutoffTime, secondsToMicro(1 as Seconds));

            // Process data per channel type (Voltage, Current, etc)
            let newWebGLChartsState = state.webglChartState;
            for (let channelTypeId in sourceDataByChannelType)
            {
                const chartId = `graph-${sourceData.sourceId}-${channelTypeId}` as ChartId;
                const rmsChartId = `rms-graph-${sourceData.sourceId}-${channelTypeId}` as ChartId;
                const fftChartId = `fft-graph-${sourceData.sourceId}-${channelTypeId}` as ChartId;

                const sourceDataSeries = sourceDataByChannelType[channelTypeId];
                const rmsDataSeries = rmsByChannelType[channelTypeId];
                const fftDataSeries = fftByChannelType[channelTypeId];
                const currentSourceDataChart = state.webglChartState.charts[chartId];
                const currentSourceDataList: WebGLDataSeries[] = currentSourceDataChart ? currentSourceDataChart.dataSeries : [];

                const sourceDataWebglDataSeries = concatTrimWebGLDataSeries(currentSourceDataList, sourceDataSeries, cutoffTime);

                timespan = expandTimeSpanOverSeries(timespan, sourceDataWebglDataSeries);

                const channelType = ChannelTypeInfoManager.get(channelTypeId as ChannelTypeId, state);
                const leftAxis = CachedLeftAxis.get(channelType);

                const title = `${source.name} ${channelType.chartTitle}`;

                const groupId = `${channelTypeId}-graph` as GroupChartId;
                newWebGLChartsState = setChartData(newWebGLChartsState, chartId, groupId, title, sourceDataWebglDataSeries, leftAxis, false, false, undefined, GraphTimeId, false);

                const newPair = getGraphChartPair(graphSourceChartIds, channelTypeId);
                newPair.mainChartId = chartId;

                // Process RMS data if available
                if (rmsDataSeries && rmsDataSeries.length > 0)
                {
                    const currentRmsChart = state.webglChartState.charts[rmsChartId];
                    const currentRmsList: WebGLDataSeries[] = currentRmsChart ? currentRmsChart.dataSeries : [];

                    // We group by title to capture different RMS results (F25 vs F50)
                    const currentGrouped = groupBy(currentRmsList, (l => l.title));
                    const newGrouped = groupBy(rmsDataSeries, (l => l.title));

                    const finalWebglDataSeries: WebGLDataSeries[] = [];

                    for (const key in newGrouped)
                    {
                        const currentList = currentGrouped[key] || [];
                        const newList = newGrouped[key];

                        const rmsWebglDataSeries = [...currentList, ...newList];
                        let combinedRmsDataSeries: WebGLDataSeries = rmsWebglDataSeries[0];
                        if (rmsWebglDataSeries.length > 1)
                        {
                            for (let i = 1; i < rmsWebglDataSeries.length; i++)
                            {
                                combinedRmsDataSeries = combineDataSourceWebGL(combinedRmsDataSeries, rmsWebglDataSeries[i]);
                            }
                        }

                        finalWebglDataSeries.push(trimWebGLDataSeries(combinedRmsDataSeries, rmsCutoffTime));
                    }

                    newWebGLChartsState = setChartData(newWebGLChartsState, rmsChartId, groupId, title, finalWebglDataSeries, leftAxis, false, false, null, GraphTimeId, false);

                    newPair.rmsChartId = rmsChartId;
                }

                // Process FFT data if available
                if (fftDataSeries && fftDataSeries.length > 0)
                {
                    const currentFFTChart = state.webglChartState.charts[fftChartId];
                    const currentFFTList: WebGLDataSeries[] = currentFFTChart ? currentFFTChart.dataSeries : [];

                    // We group by title to capture different FFT results (1700Hz vs 2600Hz)
                    const currentGrouped = groupBy(currentFFTList, (l => l.title));
                    const newGrouped = groupBy(fftDataSeries, (l => l.title));

                    const finalWebglDataSeries: WebGLDataSeries[] = [];

                    for (const key in newGrouped)
                    {
                        const currentList = currentGrouped[key] || [];
                        const newList = newGrouped[key];

                        const fftWebglDataSeries = [...currentList, ...newList];
                        let combinedFFTDataSeries: WebGLDataSeries = fftWebglDataSeries[0];
                        if (fftWebglDataSeries.length > 1)
                        {
                            for (let i = 1; i < fftWebglDataSeries.length; i++)
                            {
                                combinedFFTDataSeries = combineDataSourceWebGL(combinedFFTDataSeries, fftWebglDataSeries[i]);
                            }
                        }

                        finalWebglDataSeries.push(trimWebGLDataSeries(combinedFFTDataSeries, rmsCutoffTime));
                    }

                    newWebGLChartsState = setChartData(newWebGLChartsState, fftChartId, groupId, title, finalWebglDataSeries, leftAxis, false, false, null, GraphTimeId, false);

                    newPair.fftChartId = fftChartId;
                }
            }

            const sourceChannels = getSourceChannelsFromGraph(graphWebGLChartIds, newWebGLChartsState);

            const newGraphState: GraphState = {
                ...state.graphState,
                sourceChannels: [...sourceChannels],
                sourceDataTimespan: timespan,
                webglChartIds: graphWebGLChartIds
            }

            return {
                graphState: newGraphState,
                webglChartState: newWebGLChartsState
            };
        }
    }
}