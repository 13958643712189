import {SourceChannelId, SourceDataEnabledState} from "../../store/monitoringStoreStates";
import {addMicro, MicroSeconds, milliToMicro, Seconds, secondsToMicro, secondsToMilli} from "../../utils/timeTypes";
import {clamp, rgbaFloatToHex} from "../../utils/utils";
import {ignoreLower, ignoreUpper} from "../lineChart/lineChartCommon";
import {WebGLChartState, WebGLColour, WebGLDataSeries, WebGLDataSeriesMinMax, WebGLTimeRange, WebGLValueRange} from "./webglChartStore";
import WebGLMesh from "./webglMesh";

export const DefaultVertexShader = `attribute vec4 vertexPos;

uniform mat4 viewCameraMatrix;
uniform vec4 offset;
uniform float pointSize;

void main() {
  gl_Position = viewCameraMatrix * (vertexPos + offset);
  gl_PointSize = pointSize;
}`;

export const DefaultFragShader = `
precision mediump float;
uniform vec4 fragColour;

void main() {
  gl_FragColor = fragColour;
}`;

export function initShaderProgram(gl: WebGLRenderingContext, vsSource: string = DefaultVertexShader, fsSource: string = DefaultFragShader)
{
    const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
    const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);

    // Create the shader program
    const shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    // If creating the shader program failed, alert

    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS))
    {
        alert('Unable to initialize the shader program: ' + gl.getProgramInfoLog(shaderProgram));
        return null;
    }

    return shaderProgram;
}

export function loadShader(gl: WebGLRenderingContext, type: GLenum, source: string)
{
    const shader = gl.createShader(type);

    // Send the source to the shader object
    gl.shaderSource(shader, source);

    // Compile the shader program
    gl.compileShader(shader);

    // See if it compiled successfully
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS))
    {
        alert('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
    }

    return shader;
}

export function createMesh(gl: WebGLRenderingContext, arr: Float32Array)
{
    var buf = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, buf);
    gl.bufferData(gl.ARRAY_BUFFER, arr, gl.STATIC_DRAW);
    return buf;
}

export function calculateXStep(sps: number)
{
    return secondsToMilli((1 / sps) as Seconds);
}

export function calculateEndTime(startTime: MicroSeconds, dataLength: number, sps: number)
{
    const xstep = calculateXStep(sps);
    return addMicro(startTime, milliToMicro(xstep) * dataLength);
}

export function createLineDataSeries(sourceChannel: SourceChannelId, sourceName: string, sps: number, title: string, startTime: MicroSeconds, data: number[], colour: WebGLColour, valueRange?: WebGLValueRange, prefix?: string): WebGLDataSeries
{
    const endTime = calculateEndTime(startTime, data.length, sps);

    return {
        colour,
        data: {'line': data},
        dataLength: data.length,
        title,
        sps,
        startTime,
        endTime,
        sourceChannelId: sourceChannel,
        sourceName,
        pointSize: 1,
        type: 'line',
        valueRange,
        prefix
    }
}

export function createMinMaxDataSeries(sourceChannel: SourceChannelId, sourceName: string,sps: number, title: string, startTime: MicroSeconds, max: number[], min: number[], mean: number[], minMaxColour: WebGLColour, meanColour: WebGLColour, valueRange: WebGLValueRange): WebGLDataSeriesMinMax
{
    if (max.length !== min.length || max.length !== mean.length)
    {
        throw new Error('Must be same length to create min max mesh');
    }

    const result = { min, max, mean };
    const endTime = calculateEndTime(startTime, max.length, sps);

    return {
        colour: meanColour,
        minMaxColour,
        startTime,
        endTime,
        sps,
        type: 'minmax',
        data: result,
        dataLength: max.length,
        pointSize: 1,
        sourceChannelId: sourceChannel,
        sourceName,
        title,
        valueRange
    }
}

export function createMinMaxMesh(gl: WebGLRenderingContext, dataSeries: WebGLDataSeriesMinMax)
{
    const { data, minMaxColour, dataLength } = dataSeries;

    let meshPoints = new Array<number>();
    const xStep = calculateXStep(dataSeries.sps);

    const minData = data['min'];
    const maxData = data['max'];

    const result: WebGLMesh[] = createLineMesh(gl, dataSeries, 'mean', gl.LINE_STRIP, 1);

    for (let i = 0, j = 0; i < dataLength; i++)
    {
        const value1 = minData[i];
        const value2 = maxData[i];

        if (value1 >= ignoreUpper || value2 >= ignoreUpper)
        {
            if (meshPoints.length > 4)
            {
                const meshBuffer = createMesh(gl, new Float32Array(meshPoints));
                const webglMesh = new WebGLMesh(meshBuffer, gl.TRIANGLE_STRIP, meshPoints.length / 2, minMaxColour, 1);
                webglMesh.offset = dataSeries.startTime;
                result.push(webglMesh);
            }

            if (meshPoints.length !== 0)
            {
                meshPoints = [];
            }

            j = 0;

            continue;
        }

        const xPos = i * xStep;
        meshPoints[j++] = xPos;
        meshPoints[j++] = value1;
        meshPoints[j++] = xPos;
        meshPoints[j++] = value2;
    }

    if (meshPoints.length > 4)
    {
        const meshBuffer = createMesh(gl, new Float32Array(meshPoints));
        const webglMesh = new WebGLMesh(meshBuffer, gl.TRIANGLE_STRIP, meshPoints.length / 2, minMaxColour, 1);
        webglMesh.offset = dataSeries.startTime;
        result.push(webglMesh);
    }

    return result;
}

export function createLineMesh(gl: WebGLRenderingContext, dataSeries: WebGLDataSeries, dataSeriesKey: string, mode: GLenum, pointSize: number)
{
    const { data, colour, dataLength } = dataSeries;

    const result: WebGLMesh[] = [];

    let meshPoints = new Array<number>();
    const xStep = secondsToMilli((1 / dataSeries.sps) as Seconds);

    const lineData = data[dataSeriesKey];
    for (let i = 0, j = 0; i < dataLength; i++)
    {
        const value = lineData[i];
        if (value >= ignoreUpper)
        {
            if (meshPoints.length > 2)
            {
                const meshBuffer = createMesh(gl, new Float32Array(meshPoints));
                const webglMesh = new WebGLMesh(meshBuffer, mode, meshPoints.length / 2, colour, pointSize);
                webglMesh.offset = dataSeries.startTime;
                result.push(webglMesh);
            }

            if (meshPoints.length !== 0)
            {
                meshPoints = [];
            }

            j = 0;

            continue;
        }

        const xPos = i * xStep;
        meshPoints[j++] = xPos;
        meshPoints[j++] = value;
    }

    if (meshPoints.length > 2)
    {
        const meshBuffer = createMesh(gl, new Float32Array(meshPoints));
        const webglMesh = new WebGLMesh(meshBuffer, mode, meshPoints.length / 2, colour, pointSize);
        webglMesh.offset = dataSeries.startTime;
        result.push(webglMesh);
    }

    return result;
}

export function getValueRangeOfVisible(chartState: WebGLChartState, timeViewport: WebGLTimeRange,  sourceDataEnabled: SourceDataEnabledState, addPadding: number = 0.05): WebGLValueRange
{
    let minValue = Number.MAX_VALUE;
    let maxValue = Number.MIN_VALUE;

    for (let dataSeries of chartState.dataSeries)
    {
        if (sourceDataEnabled[dataSeries.sourceChannelId] === false)
        {
            continue;
        }

        if (dataSeries.startTime > timeViewport.maxTime || dataSeries.endTime < timeViewport.minTime)
        {
            continue;
        }

        const startIndexRough = Math.floor(getDataIndexAtTime(dataSeries, timeViewport.minTime));
        const endIndexRough = Math.ceil(getDataIndexAtTime(dataSeries, timeViewport.maxTime));

        const startIndex = clamp(startIndexRough, 0, dataSeries.dataLength);
        const endIndex = clamp(endIndexRough, 0, dataSeries.dataLength);

        for (const key in dataSeries.data)
        {
            const data = dataSeries.data[key];
            for (let i = startIndex; i < endIndex; i++)
            {
                const v = data[i];
                if (v < ignoreUpper)
                {
                    minValue = Math.min(v, minValue);
                    maxValue = Math.max(v, maxValue);
                }
            }
        }
    }

    if (minValue > ignoreUpper || maxValue < ignoreLower)
    {
        return chartState.originalValueViewport;
    }

    return makeValueRange(minValue, maxValue, addPadding);
}

export function getDataIndexAtTime(dataSeries: WebGLDataSeries, time: MicroSeconds)
{
    const dataTimeWidth = dataSeries.endTime - dataSeries.startTime;

    const percentX = (time - dataSeries.startTime) / dataTimeWidth;
    return percentX * dataSeries.dataLength;
}

export function calculateValueRangeForAll(dataSeries: WebGLDataSeries[], addPadding: number = 0.05): WebGLValueRange
{
    let minValue = Number.MAX_VALUE;
    let maxValue = Number.MIN_VALUE;

    for (let data of dataSeries)
    {
        const viewport = calculateValueRange(data);
        minValue = Math.min(viewport.minValue, minValue);
        maxValue = Math.max(viewport.maxValue, maxValue);
    }

    return makeValueRange(minValue, maxValue, addPadding);
}

export function calculateTimeRangeForAll(dataSeries: WebGLDataSeries[]): WebGLTimeRange
{
    let minTime = Number.MAX_VALUE;
    let maxTime = Number.MIN_VALUE;

    for (let data of dataSeries)
    {
        const viewport = calculateTimeRange(data);
        minTime = Math.min(viewport.minTime, minTime);
        maxTime = Math.max(viewport.maxTime, maxTime);
    }

    return makeTimeRange(minTime as MicroSeconds, maxTime as MicroSeconds);
}

export function calculateValueRange(dataSeries: WebGLDataSeries): WebGLValueRange
{
    if (dataSeries.valueRange)
    {
        return dataSeries.valueRange;
    }

    let minValue = Number.MAX_VALUE;
    let maxValue = Number.MIN_VALUE;

    for (const key in dataSeries.data)
    {
        const data = dataSeries.data[key];

        for (let v of data)
        {
            if (v < ignoreUpper)
            {
                minValue = Math.min(v, minValue);
                maxValue = Math.max(v, maxValue);
            }
        }
    }

    if (minValue < ignoreLower)
    {
        minValue = 0;
    }
    if (maxValue > ignoreUpper)
    {
        maxValue = 1;
    }

    return makeValueRange(minValue, maxValue, 0);
}

export function calculateTimeRange(dataSeries: WebGLDataSeries): WebGLTimeRange
{
    const minTime = dataSeries.startTime;
    const maxTime = dataSeries.startTime + secondsToMicro(((1 / dataSeries.sps) * dataSeries.dataLength) as Seconds);

    return makeTimeRange(minTime, maxTime as MicroSeconds);
}

export function toStringColour(colour: WebGLColour): string
{
    return typeof(colour) === 'string' ? colour : rgbaFloatToHex(colour);
}

export function makeTimeRange(minTime: MicroSeconds, maxTime: MicroSeconds): WebGLTimeRange
{
    return {minTime, maxTime, width: (maxTime - minTime) as MicroSeconds};
}
export function makeValueRange(minValue: number, maxValue: number, addPadding: number = 0): WebGLValueRange
{
    if (addPadding > 0)
    {
        const rangeDiff = Math.abs(maxValue - minValue);
        maxValue = maxValue + rangeDiff * addPadding;
        minValue = minValue - rangeDiff * addPadding;
    }

    return {minValue, maxValue, height: (maxValue - minValue)};
}

export function calcLegendPriority(p: string)
{
    if (p === undefined) {
        return 0;
    }
    let priority = p.search('-') > 1 ? 1e6 : 0;
    priority += parseInt(p);
    return priority
}