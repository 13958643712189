import React, {ChangeEvent, FormEvent} from 'react';
import {Api} from '../../api/api';
import {LoginState} from '../../store/monitoringStoreStates';
import LoadingSpinner from '../loadingSpinner';

interface Props
{
    loginState: LoginState;
}
interface State
{
    username: string;
    password: string;
}

export default class LoginAppState extends React.PureComponent<Props, State>
{
    constructor (props: Props)
    {
        super(props);

        this.state = {
            username: '',
            password: ''
        };
    }

    public render ()
    {
        return (
            <main>
                <h1>Login</h1>

                <span>Login State: {this.props.loginState.loginStatus}</span>
                <LoadingSpinner show={this.props.loginState.loginStatus === 'logging-in'} />

                <form onSubmit={this.handleSubmit}>
                    <label>
                        Username:
                            <input type="text" placeholder="Username" value={this.state.username} onChange={this.handleUsernameChange} />
                    </label>
                    <label>
                        Password:
                            <input type="password" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange} />
                    </label>

                    <button type="submit">Login</button>
                </form>
            </main>
        );
    }

    private handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) =>
    {
        this.setState({username: event.target.value});
    }

    private handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) =>
    {
        this.setState({password: event.target.value});
    }

    private handleSubmit = (event: FormEvent<HTMLFormElement>) =>
    {
        event.nativeEvent.preventDefault();
        Api.login(this.state.username, this.state.password);
    }

}
