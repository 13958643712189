import {Modifier} from "simple-data-store";
import {modifyServerState} from "./monitoringStore";
import {State} from "./monitoringStoreStates";

export default class SetDarkTheme
{
    public static action (darkTheme: boolean): Modifier<State>
    {
        return (state: State) => modifyServerState(state, {darkTheme});
    }
}