import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {LoginState, State} from "./monitoringStoreStates";

export default class SetLoginProfile
{
    public static action (id: string, username: string, displayName: string, permissions: string[]): Modifier<State>
    {
        return (state: State) =>
        {
            const loginState: Editable<LoginState> = {
                ...state.loginState,
                loginStatus: 'logged-in',
                userProfile: { displayName, id, permissions, username, whitelistedSources: [], whitelistedSourceChannels: [] }
            };

            return {loginState};
        }
    }
}