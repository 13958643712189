import {Modifier} from "simple-data-store";
import {Editable} from "../../utils/commonTypes";
import {modifyAdminState} from "../monitoringStore";
import {ChannelTypeInfo, State, UpdateChannelTypeInfoState} from "../monitoringStoreStates";

export default class SetUpdateChannelTypeInfo
{
    public static showCreate (channelTypeInfo: ChannelTypeInfo): Modifier<State>
    {
        return this.execute(true, true, channelTypeInfo);
    }
    public static showUpdate (channelTypeInfo: ChannelTypeInfo): Modifier<State>
    {
        return this.execute(true, false, channelTypeInfo);
    }
    public static close (): Modifier<State>
    {
        return this.execute(false, false);
    }

    private static execute (show: boolean, createNew: boolean, channelTypeInfo?: ChannelTypeInfo): Modifier<State>
    {
        return (state: State) =>
        {
            const updateChannelTypeInfoState: Editable<UpdateChannelTypeInfoState> = {
                ...state.adminState.updateChannelTypeInfoState, show, createNew
            }

            if (channelTypeInfo)
            {
                updateChannelTypeInfoState.channelTypeInfo = channelTypeInfo;
            }

            return modifyAdminState(state, {updateChannelTypeInfoState});
        }
    }
}