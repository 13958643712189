import React from 'react';
import { EmptyActiveStatus, EmptyOccupied, EmptyStatus, Source, SourceActiveStatuses, SourceOccupancies, SourceStatuses, SourceToEventMap } from '../store/monitoringStoreStates';
import LoadingBar from './loadingBar';
import SourceView from './sourceView';

interface Props
{
    sources: Source[];
    sourcesLoading: boolean;
    sourceToEvents: SourceToEventMap;
    occupancies: SourceOccupancies;
    statuses: SourceStatuses;
    activeStatuses: SourceActiveStatuses;
}
export default class SourcesView extends React.PureComponent<Props>
{
    public render ()
    {
        return (
            <div>
                <LoadingBar show={this.props.sourcesLoading} />

                { this.props.sources.map((source) => {
                    const occupied = this.props.occupancies[source.id] || EmptyOccupied(source.id);
                    const status = this.props.statuses[source.id] || EmptyStatus(source.id);
                    const activeStatus = this.props.activeStatuses[source.id] || EmptyActiveStatus(source.id);

                    return (
                        <SourceView key={source.id}
                            source={source}
                            sourceEvents={this.props.sourceToEvents[source.id]}
                            status={status}
                            occupied={occupied}
                            activeStatus={activeStatus}
                            />
                    );
                }) }
            </div>
            );
    }
}
