import React from 'react';
import SetUpdateChannelTypeInfo from '../../store/admin/setUpdateChannelTypeInfoState';
import monitoringStore from '../../store/monitoringStore';
import {ChannelTypeId, ChannelTypeInfoMap} from '../../store/monitoringStoreStates';
import {isEmptyObject} from '../../utils/utils';
import AdminListView from './adminListViews';
import ChannelTypeInfoView from './channelTypeInfoView';

interface Props
{
    channelTypeInfos: ChannelTypeInfoMap;
}

export default class ChannelTypeInfoViews extends React.PureComponent<Props>
{
    public render()
    {
        return (
            <AdminListView
                title={<h4>Channel Type Infos:</h4>}
                extraButtons={<button onClick={this.showCreateAdd}>Add Channel Type Info</button> } >
                {
                    isEmptyObject(this.props.channelTypeInfos) ?
                        <div>
                            <strong>No channel type infos</strong>
                        </div>
                        :
                        <table>
                            <thead>
                                <tr>
                                    <th>Display Name</th>
                                    <th>Channel Type</th>
                                    <th>Chart Title</th>
                                    <th>Prefix</th>
                                    <th>Chart Priority</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderChannelInfoTypes()}
                            </tbody>
                        </table>
                }
            </AdminListView>
        );
    }

    private renderChannelInfoTypes()
    {
        return Object.values(this.props.channelTypeInfos)
            .map(c => <ChannelTypeInfoView key={c.channelType} channelTypeInfo={c} />);
    }

    private showCreateAdd = () =>
    {
        monitoringStore.execute(SetUpdateChannelTypeInfo.showCreate({
            channelType: '' as ChannelTypeId,
            chartPriority: 0,
            chartTitle: '',
            displayName: '',
            prefix: ''
        }));
    }
}