import {Modifier} from "simple-data-store";
import {modifyServerState} from "./monitoringStore";
import {GroupId, State} from "./monitoringStoreStates";

export default class SelectGroup
{
    public static action (groupId: GroupId): Modifier<State>
    {
        return (state: State) => modifyServerState(state, {selectedGroupId: groupId});
    }
}