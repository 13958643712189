// Ported from react-hint and expanded upon to support mouse following.
import React, {CSSProperties, ReactNode} from 'react';
import './tooltip.scss';

type ContentType = string;
type Position = 'top' | 'left' | 'right' | 'bottom';
type TargetType = HTMLElement | SVGElement;
type RenderContentHandler = (target: TargetType, content: ContentType) => ReactNode;
interface EventFlags
{
    all?: boolean;
    click?: boolean;
    focus?: boolean;
    hover?: boolean;
}
interface DelayValues
{
    show: number;
    hide: number;
}

interface Location
{
    left: number;
    top: number;
}

interface Bounds extends Location
{
    width: number;
    height: number;
}

interface Props
{
    attribute: string;
    autoPosition: boolean;
    className: string;
    delay: DelayValues;
    events: EventFlags;
    onRenderContent: RenderContentHandler;
    persist: boolean;
    position: Position;
    followMouseX: boolean;
    followMouseY: boolean;
    mouseOffset: Location;
}

interface State
{
    target: TargetType;
    content: ContentType;
    at: Position;
    top: number;
    left: number;

    lastContent: ContentType;
}

function getPosition(input: string): Position
{
    switch (input)
    {
        case 'top':
        case 'bottom':
        case 'left':
        case 'right':
            return input;
        default:
            return 'top';
    }
}

let mouseEventsInstalled = false;
let mousePageX = 0;
let mousePageY = 0;
function installMouseEvents()
{
    if (mouseEventsInstalled)
    {
        return;
    }

    mouseEventsInstalled = true;
    document.addEventListener('mousemove', mouseMoved);
}

function mouseMoved(e: MouseEvent)
{
    mousePageX = e.clientX;
    mousePageY = e.clientY;
}

export default class ReactHint extends React.PureComponent<Props, State>
{
    public static defaultProps: Partial<Props> =
        {
            attribute: 'data-rh',
            autoPosition: false,
            className: 'react-hint',
            delay: {
                show: 0,
                hide: 0
            },
            events: { all: true },
            onRenderContent: null,
            persist: false,
            position: 'top',
            followMouseX: false,
            followMouseY: false,
            mouseOffset: {
                left: 0,
                top: 0
            }
        };

    private containerStyle: CSSProperties = { position: 'relative' };
    private delayTimeout: number;
    private hint: React.RefObject<HTMLDivElement>;
    private container: React.RefObject<HTMLDivElement>;
    private boundToggleHint: (event: Event) => void;
    private boundMouseMove: EventListener;
    private needToRenderAgain: number;

    constructor(props: Props)
    {
        super(props);

        this.hint = React.createRef();
        this.container = React.createRef();
        this.boundToggleHint = this.toggleHint.bind(this);
        this.boundMouseMove = this.onMouseMove.bind(this);

        this.state = {
            target: null,
            content: '',
            at: 'top',
            top: 0,
            left: 0,
            lastContent: null
        };
    }

    public componentDidMount()
    {
        this.toggleEvents(this.props.events, true);

        if (this.props.followMouseX || this.props.followMouseY)
        {
            document.addEventListener('mousemove', this.boundMouseMove, false);
        }
    }

    public componentWillUnmount()
    {
        this.toggleEvents(this.props.events, false);
        clearTimeout(this.delayTimeout);

        if (this.props.followMouseX || this.props.followMouseY)
        {
            document.removeEventListener('mousemove', this.boundMouseMove);
        }
    }

    public render()
    {
        installMouseEvents();

        const { className } = this.props;
        const { target, content, at, top, left } = this.state;
        const hideClass = !content || !target ? 'hide' : '';

        if (this.needToRenderAgain > 0)
        {
            this.needToRenderAgain--;
            setTimeout(() => this.setState(this.getHintData), 0);
        }

        return <div ref={this.container}
            style={this.containerStyle}>
            {
                <div className={`${className} ${className}--${at} ${hideClass}`}
                    ref={this.hint}
                    role="tooltip"
                    style={{ top, left }}>

                    { this.renderContent() }
                </div>
            }
        </div>;
    }

    private renderContent()
    {
        const { className, onRenderContent } = this.props;
        const { target, content } = this.state;

        if (!content || !target)
        {
            return null;
        }

        if (onRenderContent)
        {
            return onRenderContent(target, content);
        }

        return <div className={`${className}__content`}>
                {content}
            </div>;
    }

    private onMouseMove(e: MouseEvent)
    {
        if (this.state.target)
        {
            this.setState(this.getHintData);
        }
    }

    private getPositionBounds(target: TargetType): Bounds
    {
        const result: Bounds = { left: 0, top: 0, width: 1, height: 1 };

        if (this.props.followMouseX)
        {
            result.left = mousePageX + this.props.mouseOffset.left;
        }
        if (this.props.followMouseY)
        {
            result.top = mousePageY + this.props.mouseOffset.top;
        }

        if (this.props.followMouseX && this.props.followMouseY)
        {
            return result;
        }

        const clientRect = target.getBoundingClientRect();
        if (!this.props.followMouseX)
        {
            result.left = clientRect.left;
            result.width = clientRect.width;
        }
        if (!this.props.followMouseY)
        {
            result.top = clientRect.top;
            result.height = clientRect.height;
        }

        return result;
    }

    private getHintData (prevState: Readonly<State>, props: Readonly<Props>): State
    {
        const { attribute, autoPosition, position } = props;
        const { target } = prevState;

        if (!target)
        {
            return prevState;
        }

        const content = (target.getAttribute(props.attribute) || '').trim();

        if (content.length === 0)
        {
            return { ...prevState, content };
        }

        let at: Position = getPosition(target.getAttribute(`${attribute}-at`)) || position;

        const {
            top: containerTop,
            left: containerLeft
        } = this.container.current.getBoundingClientRect();

        const {
            width: hintWidth,
            height: hintHeight
        } = this.hint.current.getBoundingClientRect();

        const {
            top: targetTop,
            left: targetLeft,
            width: targetWidth,
            height: targetHeight
        } = this.getPositionBounds(target);

        if (autoPosition)
        {
            const isHoriz = at === 'left' || at === 'right';

            const {
                clientHeight,
                clientWidth
            } = document.documentElement;

            const directions = {
                left: (isHoriz
                    ? targetLeft - hintWidth
                    : targetLeft + (targetWidth - hintWidth >> 1)) > 0,
                right: (isHoriz
                    ? targetLeft + targetWidth + hintWidth
                    : targetLeft + (targetWidth + hintWidth >> 1)) < clientWidth,
                bottom: (isHoriz
                    ? targetTop + (targetHeight + hintHeight >> 1)
                    : targetTop + targetHeight + hintHeight) < clientHeight,
                top: (isHoriz
                    ? targetTop - (hintHeight >> 1)
                    : targetTop - hintHeight) > 0
            };

            switch (at)
            {
                case 'left':
                    if (!directions.left) { at = 'right'; }
                    if (!directions.top) { at = 'bottom'; }
                    if (!directions.bottom) { at = 'top'; }
                    break;

                case 'right':
                    if (!directions.right) { at = 'left'; }
                    if (!directions.top) { at = 'bottom'; }
                    if (!directions.bottom) { at = 'top'; }
                    break;

                case 'bottom':
                    if (!directions.bottom) { at = 'top'; }
                    if (!directions.left) { at = 'right'; }
                    if (!directions.right) { at = 'left'; }
                    break;

                case 'top':
                default:
                    if (!directions.top) { at = 'bottom'; }
                    if (!directions.left) { at = 'right'; }
                    if (!directions.right) { at = 'left'; }
                    break;
            }
        }

        let top = 0;
        let left = 0;

        switch (at)
        {
            case 'left':
                top = targetHeight - hintHeight >> 1;
                left = -hintWidth;
                break;

            case 'right':
                top = targetHeight - hintHeight >> 1;
                left = targetWidth;
                break;

            case 'bottom':
                top = targetHeight;
                left = targetWidth - hintWidth >> 1;
                break;

            case 'top':
            default:
                top = -hintHeight;
                left = targetWidth - hintWidth >> 1;
        }

        return {
            ...prevState,
            content, at,
            top: (top + targetTop - containerTop) | 0,
            left: (left + targetLeft - containerLeft) | 0,
            lastContent: prevState.content
        };
    }

    private toggleEvents(events: EventFlags, addEvents: boolean)
    {
        const action = addEvents ? 'addEventListener' : 'removeEventListener';
        const hasEvents = events.all === true;

        if (events.click || hasEvents) { document[action]('click', this.boundToggleHint); }
        if (events.focus || hasEvents) { document[action]('focusin', this.boundToggleHint); }
        if (events.hover || hasEvents) { document[action]('mouseover', this.boundToggleHint); }
        if (events.click || events.hover || hasEvents) { document[action]('touchend', this.boundToggleHint); }
    }

    private toggleHint(event: Event)
    {
        const target = this.getHint(event.target);
        clearTimeout(this.delayTimeout);

        const delay = target === null ? this.props.delay.hide : this.props.delay.show;
        this.delayTimeout = window.setTimeout(() =>
        {
            if (target)
            {
                this.needToRenderAgain = 2;
            }
            this.setState({ target });
        }, delay);
    }

    private getHint(eventTarget: EventTarget)
    {
        const { attribute, persist } = this.props;

        while (eventTarget)
        {
            if (eventTarget === document) { break; }
            if (persist && eventTarget === this.hint.current) { return this.state.target; }

            if (!(eventTarget instanceof HTMLElement) && !(eventTarget instanceof SVGElement)) { break; }

            if (eventTarget.hasAttribute(attribute)) { return eventTarget; }
            eventTarget = eventTarget.parentNode;
        }

        return null;
    }

}
