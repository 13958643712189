
import Color from "color";
import {ignoreValue} from "../components/lineChart/lineChartCommon";
import {WebGLDataSeries} from "../components/webglChart/webglChartStore";
import {createLineDataSeries} from "../components/webglChart/webglUtils";
import {Editable} from "../utils/commonTypes";
import {combineSourceDataSourcesWebGL} from "../utils/dataSourceUtils";
import {MicroSeconds} from "../utils/timeTypes";
import {findSource} from "../utils/utils";
import monitoringStore from "./monitoringStore";
import {FFTResponseConfigId, FFTResponseData, FFTResponseDataLookupMap, FFTResponseDataPayload, FFTResponsePreview, FFTResponsePreviewLookupResult, MinMaxValue, SourceChannelId, SourceChannelToWebGLDataSourceMap, SourceChannelToWebGLPreviewMap, SourceId, SourceToSourceChannelToWebGLDataSourceMap, SourceToSourceChannelToWebGLPreviewMap} from "./monitoringStoreStates";
import {applyColour, getChartDataPairWebGL} from "./processOnlineRmsResult";
import {SourceChannelInfoManager} from "./sourceChannelInfo";

export function processFFTResponseDataWebGL(fftData: FFTResponseData): SourceChannelToWebGLDataSourceMap
{
    const channelDataSources: Editable<SourceChannelToWebGLDataSourceMap> = {};

    const payloadMap = fftData.payload;

    for (const sourceChannel in payloadMap)
    {
        const payloads = payloadMap[sourceChannel];

        const sourceDataInfo = SourceChannelInfoManager.get(sourceChannel as SourceChannelId);
        const channelType = sourceDataInfo.base.channelType;
        const list = (channelDataSources[channelType] || (channelDataSources[channelType] = []));

        for (const payload of payloads)
        {
            addFFTResponseDataWebGL(fftData, payload, sourceChannel as SourceChannelId, payload.id, list);
        }
    }

    return channelDataSources;
}

export function processFFTResponseDatasWebGL(fftData: FFTResponseDataLookupMap, trimLengths: boolean, trimCutoffTime: MicroSeconds): SourceToSourceChannelToWebGLDataSourceMap
{
    const dataSources: Editable<SourceToSourceChannelToWebGLDataSourceMap> = {};

    for (const sourceIdString in fftData)
    {
        const fftDataList = fftData[sourceIdString];
        const channelDataSources: Editable<SourceChannelToWebGLDataSourceMap> = {};
        dataSources[sourceIdString] = channelDataSources;

        for (const rmsResult of fftDataList)
        {
            const payloadMap = rmsResult.payload;

            for (const sourceChannel in payloadMap)
            {
                const payloads = payloadMap[sourceChannel];

                const sourceDataInfo = SourceChannelInfoManager.get(sourceChannel as SourceChannelId);
                const channelType = sourceDataInfo.base.channelType;
                const list = (channelDataSources[channelType] || (channelDataSources[channelType] = []));

                for (const payload of payloads)
                {
                    addFFTResponseDataWebGL(rmsResult, payload, sourceChannel as SourceChannelId, payload.id, list);
                }
            }
        }
    }

    return combineSourceDataSourcesWebGL(dataSources, trimLengths, trimCutoffTime);
}

export function processFFTResponsePreviewWebGL(fftPreview: FFTResponsePreviewLookupResult)
{
    const result: Editable<SourceToSourceChannelToWebGLPreviewMap> = {};

    if (!fftPreview)
    {
        return result;
    }

    for (const sourceIdString in fftPreview.previews)
    {
        const sourceId = sourceIdString as SourceId;
        const channelDataSources: Editable<SourceChannelToWebGLPreviewMap> = {};
        result[sourceId] = channelDataSources;

        const previews = fftPreview.previews[sourceId];
        for (const preview of previews)
        {
            const payload = preview.data;

            for (const sourceChannelKey in payload)
            {
                const sourceChannel = sourceChannelKey as SourceChannelId;
                const sourceDataInfo = SourceChannelInfoManager.get(sourceChannel);
                const channelType = sourceDataInfo.base.channelType;
                const list = getChartDataPairWebGL(channelDataSources, channelType);

                const filterMap = payload[sourceChannel];
                for (const filterIdKey in filterMap)
                {
                    const filterId = filterIdKey as FFTResponseConfigId;

                    const ranges = filterMap[filterId];
                    addFFTResponsePreviewDataSourceWebGL(preview, sourceChannel, filterId, filterId, ranges, list);
                }
            }
        }
    }

    return result;
}

function addFFTResponseDataWebGL(fftData: FFTResponseData, payload: FFTResponseDataPayload, sourceChannel: SourceChannelId, fftConfigId: FFTResponseConfigId, output: WebGLDataSeries[])
{
    const dataInfo = SourceChannelInfoManager.get(sourceChannel);

    const startTime = fftData.time;

    const fftResponseConfig = monitoringStore.state().serverInfo.fftResponseConfigs[fftConfigId];
    const colour = applyColour(Color(dataInfo.base.mainColour), fftResponseConfig);
    const title = `${payload.name} ${dataInfo.nameSimple}`;
    const source = findSource(monitoringStore.state(), fftData.sourceId);

    const dataSeries = createLineDataSeries(sourceChannel, source.name, 10, title, startTime, payload.values, colour.hex(), undefined, payload.name);

    output.push(dataSeries);
}

function addFFTResponsePreviewDataSourceWebGL(preview: FFTResponsePreview, sourceChannel: SourceChannelId, filterName: string, fftConfigId: FFTResponseConfigId, data: MinMaxValue[], output: WebGLDataSeries[])
{
    if (!data)
    {
        return;
    }

    const maxData: number[] = new Array<number>(data.length);
    for (let i = 0; i < data.length; i++)
    {
        const range = data[i];
        if (typeof(range) === 'number')
        {
            maxData[i] = range;
        }
        else if (range.length === 0)
        {
            maxData[i] = ignoreValue;
        }
        else
        {
            maxData[i] = range[1];
        }
    }

    const sourceChannelInfo = SourceChannelInfoManager.get(sourceChannel);
    const name = sourceChannelInfo.nameSimple;

    const fftResponseConfig = monitoringStore.state().serverInfo.fftResponseConfigs[fftConfigId];
    const fftConfigName = fftResponseConfig ? fftResponseConfig.name : fftConfigId;

    const colour = applyColour(Color(sourceChannelInfo.base.mainColour), fftResponseConfig);
    const title = `${fftConfigName} Max ${name}`;
    const source = findSource(monitoringStore.state(), preview.host);

    const dataSeries = createLineDataSeries(sourceChannel, source.name, 1, title, preview.time, maxData, colour.hex(), undefined, fftConfigName);

    output.push(dataSeries);
}