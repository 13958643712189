import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {SourceId, State, StatusLookup, StatusLookupMap, StatusLookupResult} from "./monitoringStoreStates";

export default class SetStatusLookup
{
    public static setIsLoading (sourceId: SourceId): Modifier<State>
    {
        return this.execute(sourceId, true);
    }

    public static setResult(sourceId: SourceId, result: StatusLookupResult): Modifier<State>
    {
        return this.execute(sourceId, false, result);
    }

    private static execute (sourceId: SourceId, isLoading: boolean, result?: StatusLookupResult): Modifier<State>
    {
        return (state: State) =>
        {
            const newStatusLookupMap: Editable<StatusLookupMap> = {...state.statusLookupMap};
            const statusLookup = newStatusLookupMap[sourceId];
            const newStatusLookup: StatusLookup = {
                isLoading: isLoading,
                result: result || (statusLookup ? statusLookup.result : null)
            };

            newStatusLookupMap[sourceId] = newStatusLookup;

            return {statusLookupMap: newStatusLookupMap};
        }
    }
}