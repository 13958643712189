import {Modifier} from "simple-data-store";
import {modifyLocalState} from "./monitoringStore";
import {SourceEvent, State} from "./monitoringStoreStates";

export default class ShowEventSelectorModal
{
    public static show (sourceEvents: SourceEvent[]): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {eventsToSelect: sourceEvents});
    }

    public static close (): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {eventsToSelect: []});
    }
}