import moment = require("moment");
import cogoToast from 'cogo-toast';
import SetActiveUsers from "../store/admin/setActiveUsers";
import SetListAllSources from "../store/admin/setListAllSources";
import SetServerConfig from "../store/admin/setServerConfig";
import SetUserProfiles from "../store/admin/setUserProfiles";
import SetReprocessEventsModal from "../store/admin/showReprocessEventsModal";
import ChangeFFTProcessed from "../store/changeFFTProcessed";
import ChangeSourceDataReceived from "../store/changeSourceDataReceived";
import monitoringStore from "../store/monitoringStore";
import {ChannelTypeId, ChannelTypeInfo, ConnectionState, DataLookResultMap, DataLookupResult, DateRange, EventRuleResult, FFTProcessedStore, FFTResponseDataLookup as FFTResponseDataLookup, FFTResponsePreviewLookupResult, FullSource, GraphData, Group, GroupId, OnlineRmsPreviewLookupResult, OnlineRmsResultLookup, PreviewLookupResult, ReprocessEventJob, ServerInfo, Source, SourceActiveStatus, SourceChannelId, SourceChannelInfoBase, SourceDataReceived, SourceEvent, SourceEventId, SourceEventLevel, SourceEventStatus, SourceFullStatus, SourceId, SourceOccupied, SourceStatus, StartupStatsError, StatusLookupResult, UnknownGroupId, UserProfile, UserProfileWithPassword, UserView} from "../store/monitoringStoreStates";
import SelectGroup from "../store/selectGroup";
import SelectUserView from "../store/selectUserView";
import SetChannelTypeInfo from "../store/setChannelTypeInfo";
import SetConnectionState from "../store/setConnectionState";
import SetDataLookup from "../store/setDataLookup";
import SetEventRules from "../store/setEventRules";
import SetFFTProcessedStore from "../store/setFFTProcessedStore";
import SetFFTTime from "../store/setFFTTime";
import SetGraphState from "../store/setGraphState";
import SetGroupList from "../store/setGroupList";
import SetLoginProfile from "../store/setLoginProfile";
import SetLoginToken from "../store/setLoginToken";
import SetServerInfo from "../store/setServerInfo";
import SetSourceActiveStatus from "../store/setSourceActiveStatus";
import SetSourceChannelInfo from "../store/setSourceChannelInfo";
import SetSourceDataReceived from "../store/setSourceDataReceived";
import SetSourceEvent from "../store/setSourceEvent";
import SetSourceEvents from "../store/setSourceEvents";
import SetSourceList from "../store/setSourceList";
import SetSourceOccupied from "../store/setSourceOccupied";
import SetSourceStatus from "../store/setSourceStatus";
import SetStatusLookup from "../store/setStatusLookup";
import SetTimelineState from "../store/setTimelineState";
import ShowEventModal from "../store/showEventModal";
import ShowStartupErrors from "../store/showStartupErrors";
import UpdateSourceEvent, {PartialUpdateSourceEvent} from "../store/updateSourceEvent";
import {Editable, JsonObject} from "../utils/commonTypes";
import {dateToMicro, maxMicro, MaxMicro, MicroSeconds, minMicro, MinMicro, momentToMicro} from "../utils/timeTypes";
import {isEmptyObject, toMoment} from "../utils/utils";
import Socket from "./socket";

interface Message
{
    readonly type: string;
    readonly data: any;
}

interface SourceListResponse
{
    readonly groupId: GroupId;
    readonly sources: Source[];
}

interface SourceEventsResponse
{
    readonly sourceId: SourceId;
    readonly events: SourceEvent[];
}

interface SourceDataReceivedChangeResponse
{
    readonly sourceId: SourceId;
    readonly modifiedRange: DateRange;
}
interface SourceDataReceivedDeleteResponse
{
    readonly sourceId: SourceId;
    readonly rangeId: number;
}

interface FFTProcessedChangeResponse
{
    readonly sourceId: SourceId;
    readonly modifiedRange: DateRange;
}
interface FFTProcessedDeleteResponse
{
    readonly sourceId: SourceId;
    readonly rangeId: number;
}

interface TokenResult
{
    readonly success: boolean;
    readonly token: string;
    readonly validationResult: boolean;
}

interface LoginResult
{
    readonly id: string;
    readonly username: string;
    readonly displayName: string;
    readonly permissions: string[];
}

interface LogoutResult
{
    readonly success: boolean;
    readonly error: string;
}

interface LookupResult<T>
{
    readonly success: boolean;
    readonly data?: T;
    readonly error?: string;
}

interface ChannelTypeInfoResult
{
    readonly data: ChannelTypeInfo[];
    readonly merge: boolean;
}

interface SourceChannelInfoResult
{
    readonly data: SourceChannelInfoBase[];
    readonly merge: boolean;
}

interface ListAllSourcesResult
{
    readonly sources: FullSource[];
    readonly unknownSourceIds: SourceId[];
}

interface CommandError
{
    readonly commandName: string;
    readonly message: string;
}
interface CommandSuccess
{
    readonly commandName: string;
    readonly message: string;
}

function setConnectionState (state: ConnectionState)
{
    monitoringStore.execute(SetConnectionState.action(state));
}

function createSelection(startInMicro: MicroSeconds, endInMicro: MicroSeconds)
{
    if (startInMicro < 0 || endInMicro < 0)
    {
        const now = moment();
        return {start: now, end: now, enabled: false};
    }

    const start = toMoment(startInMicro);
    const end = toMoment(endInMicro);

    return {start, end, enabled: true};
}

export class Api
{
    public static hostname: string;
    public static useSecure: boolean;

    private static downloadToken: string;
    private static fetchArgs: RequestInit;

    public static connect (hostname: string, useSecure: boolean, onConnected: Function)
    {
        this.hostname = hostname;
        this.useSecure = useSecure;

        Socket.connect(hostname, useSecure, () => {
            setConnectionState('connected');
            onConnected();
        }, this.processMessage.bind(this));
        Socket.onClosed = () => setConnectionState('closed');
        Socket.onConnecting = () => setConnectionState('connecting');
        Socket.onReconnecting = () => setConnectionState('reconnecting');
        Socket.onError = () => setConnectionState('error');
    }

    public static reconnect ()
    {
        Socket.reconnect();
    }

    public static setDownloadToken(downloadToken: string)
    {
        this.downloadToken = downloadToken;
        this.fetchArgs = {
            headers: {
                'DownloadToken': this.downloadToken
            }
        }
    }

    public static changeGroup (groupId: GroupId)
    {
        if (groupId === UnknownGroupId)
        {
            return;
        }

        monitoringStore.execute(SelectGroup.action(groupId));
        monitoringStore.execute(SetSourceList.setLoading(true));
        Socket.sendData('setGroup', {groupId});
    }

    public static changeUserView (userView: UserView)
    {
        monitoringStore.execute(SelectUserView.action(userView));
        if (userView === 'fft')
        {
            monitoringStore.execute(SetTimelineState.isLoading('fft', true));
        }
        if (userView === 'dataLookup')
        {
            monitoringStore.execute(SetTimelineState.isLoading('datalookup', true));
        }
        Socket.sendData('setUserView', {userView});
    }

    public static updateEventStatus (sourceId: SourceId, sourceEventId: number, status: SourceEventStatus)
    {
        this.updateEvent({sourceId, id: sourceEventId, status});
    }

    public static updateEvent (updateSourceEvent: PartialUpdateSourceEvent)
    {
        Socket.sendData('updateEvent', updateSourceEvent);
    }

    public static createEvent (level: SourceEventLevel, message: string, timestamp: number, sourceChannel: SourceChannelId, sourceId: SourceId)
    {
        Socket.sendData('createEvent', {level, message, timestamp, sourceChannel, sourceId});
    }

    public static getEvent(eventId: SourceEventId)
    {
        Socket.sendData('getEvent', {eventId});
    }

    public static updateChannelTypeInfo(channelInfo: ChannelTypeInfo)
    {
        Socket.sendData('updateChannelInfo', channelInfo);
    }

    public static removeChannelTypeInfo(channelType: ChannelTypeId)
    {
        Socket.sendData('removeChannelInfo', {channelType});
    }

    public static updateSourceChannelInfo(sourceChannelInfo: SourceChannelInfoBase)
    {
        Socket.sendData('updateSourceChannel', sourceChannelInfo);
    }

    public static removeSourceChannelInfo(sourceChannel: SourceChannelId)
    {
        Socket.sendData('removeSourceChannel', {sourceChannel});
    }

    public static listUserProfiles()
    {
        Socket.sendData('listUserProfiles', {});
    }

    public static listAllSources()
    {
        Socket.sendData('listAllSources', {});
    }

    public static updateSource(source: FullSource)
    {
        Socket.sendData('updateSource', source);
    }

    public static removeSource(sourceId: SourceId)
    {
        Socket.sendData('removeSource', {sourceId});
    }

    public static updateGroup(group: Group)
    {
        Socket.sendData('updateGroup', group);
    }

    public static removeGroup(groupId: GroupId)
    {
        Socket.sendData('removeGroup', {groupId});
    }

    public static getSourceChannelsForFFT(sourceId: SourceId, timestamp: MicroSeconds)
    {
        Socket.sendData('sourceChannelsForFFT', {sourceId, timestamp});
    }

    public static doStatusLookup (sourceId: SourceId, startDate: Date, endDate: Date)
    {
        const startDateMirco = dateToMicro(startDate);
        const endDateMirco = dateToMicro(endDate);

        Socket.sendData('doStatusLookup', {sourceId, startDate: startDateMirco, endDate: endDateMirco});
    }

    public static login (username: string, password: string)
    {
        monitoringStore.execute(SetLoginToken.loginStatus('logging-in', true));
        Socket.sendData('login', { username, password });
    }

    public static logout ()
    {
        monitoringStore.execute(SetLoginToken.loginStatus('logging-out', false));
        Socket.sendData('logout', {});
    }

    public static validateToken (token: string)
    {
        monitoringStore.execute(SetLoginToken.loginStatus('logging-in', false));
        Socket.sendData('validateToken', { token });
    }

    public static resumeSession (token: string)
    {
        monitoringStore.execute(SetLoginToken.loginStatus('logging-in', false));
        Socket.sendData('resumeSession', { token });
    }

    public static createUser (userProfile: UserProfileWithPassword)
    {
        const send = {...userProfile};
        delete send.confirmPassword;
        Socket.sendData('createUser', send);
    }

    public static updateUser (userProfile: UserProfileWithPassword)
    {
        const send = {...userProfile};
        delete send.confirmPassword;
        Socket.sendData('updateUser', send);
    }

    public static removeUser (id: string)
    {
        Socket.sendData('removeUser', {id});
    }

    public static setPauseLiveGraphs (pause: boolean)
    {
        Socket.sendData('setPauseLiveGraphs', { pause });
    }

    public static getDownloadCSVUrl(sourceIds: SourceId[], start: moment.Moment, end: moment.Moment, sourceChannels: SourceChannelId[])
    {
        const startDateString = start.clone().utc().format('YYYY-MM-DD\\THHmmss');
        const endDateString = end.clone().utc().format('YYYY-MM-DD\\THHmmss');
        return `//${this.hostname}/api/lookup/csvdata/${this.downloadToken}/${sourceIds.join(',')}/${startDateString}/${endDateString}/${sourceChannels.join(',')}`;
    }

    public static reprocessAllRmsEvents(start: moment.Moment, end: moment.Moment)
    {
        const startMirco = momentToMicro(start);
        const endMirco = momentToMicro(end);
        Socket.sendData('reprocessRmsEvents', {start: startMirco, end: endMirco})
    }

    public static combineDataReceivedStores(json: JsonObject)
    {
        Socket.sendData('combineDataReceivedStore', {data: json});
    }

    public static getEventReportUrl(groupId: GroupId, start: moment.Moment, end: moment.Moment)
    {
        const startDateString = start.clone().utc().format('YYYY-MM-DD\\THHmmss');
        const endDateString = end.clone().utc().format('YYYY-MM-DD\\THHmmss');
        return `//${this.hostname}/api/eventReport/group/${this.downloadToken}/${groupId}/${startDateString}/${endDateString}`
    }

    public static doPreviewLookup (groupId: GroupId, start: moment.Moment, end: moment.Moment, resetViewport: boolean)
    {
        monitoringStore.execute(SetDataLookup.setIsLoading());
        monitoringStore.execute(SetDataLookup.clearDataLookup());
        monitoringStore.execute(SetTimelineState.select('datalookup', {start, end, enabled: true}));

        const startDateString = start.clone().utc().format('YYYY-MM-DD\\THH');
        const endDateString = end.clone().utc().format('YYYY-MM-DD\\THH');

        const url = `//${this.hostname}/api/lookup/preview/${groupId}/${startDateString}/${endDateString}`;
        fetch(url, this.fetchArgs)
            .then((response) => response.json())
            .then((json) => this.processPreviewLookup(json, resetViewport))
            .catch((reason) => processFailedPreviewLookup(reason));
    }

    public static doOnlineRmsPreviewLookup (groupId: GroupId, start: moment.Moment, end: moment.Moment, resetViewport: boolean)
    {
        monitoringStore.execute(SetDataLookup.setIsLoading());
        monitoringStore.execute(SetTimelineState.select('datalookup', {start, end, enabled: true}));

        const startDateString = start.clone().utc().format('YYYY-MM-DD\\THH');
        const endDateString = end.clone().utc().format('YYYY-MM-DD\\THH');

        const url = `//${this.hostname}/api/lookup/rmsPreview/${groupId}/${startDateString}/${endDateString}`;
        fetch(url, this.fetchArgs)
            .then((response) => response.json())
            .then((json) => this.processOnlineRmsPreviewLookup(json, resetViewport))
            .catch((reason) => processFailedPreviewLookup(reason));
    }

    public static async doOnlineRmsResultLookupForGroup (groupId: GroupId, start: moment.Moment, end: moment.Moment, resetViewport: boolean)
    {
        start = start.clone().utc().startOf('second');
        end = end.clone().utc().add(3, 'second').startOf('second');

        const startDateString = start.format('YYYY-MM-DD\\THHmmss');
        const endDateString = end.format('YYYY-MM-DD\\THHmmss');

        const url = `//${this.hostname}/api/lookup/rmsResults/${groupId}/${startDateString}/${endDateString}`;

        fetch(url, this.fetchArgs)
            .then((response) => response.json())
            .then((json) => this.processOnlineRmsResultLookup(json, resetViewport))
            .catch((reason) => processFailedPreviewLookup(reason));
    }

    public static doFFTResponsePreviewLookup (groupId: GroupId, start: moment.Moment, end: moment.Moment, resetViewport: boolean)
    {
        monitoringStore.execute(SetDataLookup.setIsLoading());
        monitoringStore.execute(SetTimelineState.select('datalookup', {start, end, enabled: true}));

        const startDateString = start.clone().utc().format('YYYY-MM-DD\\THH');
        const endDateString = end.clone().utc().format('YYYY-MM-DD\\THH');

        const url = `//${this.hostname}/api/lookup/fftPreview/${groupId}/${startDateString}/${endDateString}`;
        fetch(url, this.fetchArgs)
            .then((response) => response.json())
            .then((json) => this.processFFTResponsePreviewLookup(json, resetViewport))
            .catch((reason) => processFailedPreviewLookup(reason));
    }

    public static async doFFTResponseLookupForGroup (groupId: GroupId, start: moment.Moment, end: moment.Moment, resetViewport: boolean)
    {
        start = start.clone().utc().startOf('second');
        end = end.clone().utc().add(3, 'second').startOf('second');

        const startDateString = start.format('YYYY-MM-DD\\THHmmss');
        const endDateString = end.format('YYYY-MM-DD\\THHmmss');

        const url = `//${this.hostname}/api/lookup/fftData/${groupId}/${startDateString}/${endDateString}`;

        fetch(url, this.fetchArgs)
            .then((response) => response.json())
            .then((json) => this.processFFTResponseDataLookup(json, resetViewport))
            .catch((reason) => processFailedPreviewLookup(reason));
    }

    public static async doGraphLookup (sourceId: SourceId, timestamp: MicroSeconds)
    {
        const url = `//${this.hostname}/api/lookup/graphData/${sourceId}/${timestamp}`;
        const response = await fetch(url, this.fetchArgs);
        const json = await response.json();
        return json as GraphData;
    }

    public static async doSourceDataLookupForGroup (groupId: GroupId, start: moment.Moment, end: moment.Moment)
    {
        // moment values should already be in UTC
        const startDateString = start.format('YYYY-MM-DD\\THHmmss');
        const endDateString = end.format('YYYY-MM-DD\\THHmmss');

        const url = `//${this.hostname}/api/lookup/data/${groupId}/${startDateString}/${endDateString}`;

        const response = await fetch(url, this.fetchArgs);
        const json = await response.json();
        return json as LookupResult<DataLookupResult>;
    }

    public static async doStaggeredSourceDataLookupForGroup (groupId: GroupId, start: moment.Moment, end: moment.Moment, resetViewport: boolean)
    {
        monitoringStore.execute(SetDataLookup.setIsLoading());
        monitoringStore.execute(SetDataLookup.clearDataLookup());
        monitoringStore.execute(SetTimelineState.select('datalookup', {start, end, enabled: true}));

        start = start.clone().utc().startOf('second');
        end = end.clone().utc().add(1, 'second').startOf('second');

        let resultStart = MaxMicro;
        let resultEnd = MinMicro;
        const dataResultMap: Editable<DataLookResultMap> = {};

        let success = false;

        let current = start.clone();
        while (current.valueOf() < end.valueOf())
        {
            const currentStart = current.clone();
            const currentEnd = current.clone().add(4, 'second');
            const responseData = await Api.doSourceDataLookupForGroup(groupId, currentStart, currentEnd);

            current = currentEnd;

            if (!responseData.success)
            {
                processFailedPreviewLookup(responseData.error);
                break;
            }

            if (responseData.data.startDate > 0 && responseData.data.endDate > 0)
            {
                resultStart = minMicro(resultStart, responseData.data.startDate);
                resultEnd = maxMicro(resultEnd, responseData.data.endDate);
            }

            for (const sourceId in responseData.data.data)
            {
                let sourceList = dataResultMap[sourceId];
                if (!sourceList)
                {
                    sourceList = [];
                    dataResultMap[sourceId] = sourceList;
                }

                const responseSourceList = responseData.data.data[sourceId];
                if (responseSourceList && responseSourceList.length > 0)
                {
                    success = true;
                    sourceList.splice(sourceList.length, 0, ...responseData.data.data[sourceId]);
                }
            }
        }

        const result: LookupResult<DataLookupResult> = {
            success: success,
            data: {
                data: dataResultMap,
                endDate: resultEnd,
                startDate: resultStart,
                groupId
            }
        };

        this.processDataLookup(result, resetViewport);
    }

    public static listActiveUsers()
    {
        Socket.sendData('listActiveUsers', {});
    }

    public static listServerConfig()
    {
        Socket.sendData('listServerConfig', {});
    }

    public static processMessage (message: Message)
    {
        const handler = responseHandlers[message.type];
        if (typeof(handler) === 'function')
        {
            handler(message.data);
        }
        else
        {
            console.info('Nothing will process message', message.type, message);
        }
    }

    private static processPreviewLookup(data: LookupResult<PreviewLookupResult>, resetViewport: boolean)
    {
        const result = data.data;
        if (data.success && result)
        {
            monitoringStore.execute(SetDataLookup.setPreviewResult(result, resetViewport));
            monitoringStore.execute(SetTimelineState.select('datalookup', createSelection(result.startDate, result.endDate)));
        }
        else
        {
            processFailedPreviewLookup(data.error);
        }
    }

    private static processDataLookup(data: LookupResult<DataLookupResult>, resetViewport: boolean)
    {
        const result = data.data;
        if (data.success && result)
        {
            monitoringStore.execute(SetDataLookup.setDataResult(result, resetViewport));
            monitoringStore.execute(SetTimelineState.select('datalookup', createSelection(result.startDate, result.endDate)));
        }
        else
        {
            processFailedPreviewLookup(data.error);
        }
    }

    private static processOnlineRmsPreviewLookup(data: LookupResult<OnlineRmsPreviewLookupResult>, resetViewport: boolean)
    {
        const result = data.data;
        if (data.success && result)
        {
            monitoringStore.execute(SetDataLookup.setOnlineRmsPreviewResult(result, resetViewport));
        }
        else
        {
            processFailedPreviewLookup(data.error);
        }
    }

    private static processOnlineRmsResultLookup(data: LookupResult<OnlineRmsResultLookup>, resetViewport: boolean)
    {
        const result = data.data;
        if (data.success && result)
        {
            monitoringStore.execute(SetDataLookup.setOnlineRmsResult(result, resetViewport));
        }
        else
        {
            processFailedPreviewLookup(data.error);
        }
    }

    private static processFFTResponsePreviewLookup(data: LookupResult<FFTResponsePreviewLookupResult>, resetViewport: boolean)
    {
        const result = data.data;
        if (data.success && result)
        {
            monitoringStore.execute(SetDataLookup.setFFTResponsePreviewResult(result, resetViewport));
        }
        else
        {
            processFailedPreviewLookup(data.error);
        }
    }


    private static processFFTResponseDataLookup(data: LookupResult<FFTResponseDataLookup>, resetViewport: boolean)
    {
        const result = data.data;
        if (data.success && result)
        {
            monitoringStore.execute(SetDataLookup.setFFTResponseData(result, resetViewport));
        }
        else
        {
            processFailedPreviewLookup(data.error);
        }
    }
}

function processFailedPreviewLookup (reason: string)
{
    monitoringStore.execute(SetDataLookup.failed(reason));
}

function processGraphData (data: GraphData)
{
    monitoringStore.execute(SetGraphState.action(data));
}

const responseHandlers: { [messageType: string]: (data: any) => void; } = {
    info: (data: ServerInfo) =>
    {
        monitoringStore.execute(SetServerInfo.action(data));
    },
    groupList: (data: Group[]) =>
    {
        monitoringStore.execute(SetGroupList.action(data));
    },
    groupSources: (data: SourceListResponse) =>
    {
        monitoringStore.execute(SetSourceList.action(data.sources));
        if (monitoringStore.state().serverState.view === 'fft')
        {
            monitoringStore.execute(SetFFTTime.updateTimeForAllSources());
        }
    },
    sourceEvents: (data: SourceEventsResponse) =>
    {
        monitoringStore.execute(SetSourceEvents.action(data.sourceId, data.events));
    },
    getEvent: (data: SourceEvent) =>
    {
        monitoringStore.execute(ShowEventModal.show(data));
    },
    sourceEventUpdate: (data: PartialUpdateSourceEvent) =>
    {
        monitoringStore.execute(UpdateSourceEvent.action(data));
    },
    newSourceEvent: (data: SourceEvent) =>
    {
        monitoringStore.execute(SetSourceEvent.action(data));
    },
    sourceFullStatus: (data: SourceFullStatus) =>
    {
        responseHandlers.sourceOccupied(data.occupied);
        responseHandlers.sourceStatus(data.status);
        responseHandlers.sourceActiveStatus(data.activeStatus);
    },
    sourceOccupied: (data: SourceOccupied) =>
    {
        monitoringStore.execute(SetSourceOccupied.action(data));
    },
    sourceStatus: (data: SourceStatus) =>
    {
        monitoringStore.execute(SetSourceStatus.action(data));
    },
    sourceActiveStatus: (data: SourceActiveStatus) =>
    {
        monitoringStore.execute(SetSourceActiveStatus.action(data));
    },
    sourceDataReceived: async (data: {timestamp: MicroSeconds, host: string, sourceId: SourceId}) =>
    {
        const response = await Api.doGraphLookup(data.sourceId, data.timestamp);
        if (!isEmptyObject(response))
        {
            processGraphData(response);
        }
        else
        {
            console.error('Failed to get source data, empty response');
        }
    },
    startupErrors: (data: StartupStatsError[]) =>
    {
        monitoringStore.execute(ShowStartupErrors.setErrors(data));
    },
    statusLookup: (data: StatusLookupResult) =>
    {
        monitoringStore.execute(SetStatusLookup.setResult(data.sourceId, data));
    },
    dataReceivedStore: (data: SourceDataReceived) =>
    {
        monitoringStore.execute(SetTimelineState.isLoading('datalookup', false));
        monitoringStore.execute(SetSourceDataReceived.action(data));
    },
    dataReceivedChange: (data: SourceDataReceivedChangeResponse) =>
    {
        monitoringStore.execute(ChangeSourceDataReceived.modified(data.sourceId, data.modifiedRange));
    },
    dataReceivedDelete: (data: SourceDataReceivedDeleteResponse) =>
    {
        monitoringStore.execute(ChangeSourceDataReceived.removed(data.sourceId, data.rangeId));
    },
    fftProcessedStore: (data: FFTProcessedStore) =>
    {
        monitoringStore.execute(SetTimelineState.isLoading('fft', false));
        monitoringStore.execute(SetFFTProcessedStore.action(data));
    },
    fftProcessedChanged: (data: FFTProcessedChangeResponse) =>
    {
        monitoringStore.execute(ChangeFFTProcessed.modified(data.sourceId, data.modifiedRange));
    },
    fftProcessedDelete: (data: FFTProcessedDeleteResponse) =>
    {
        monitoringStore.execute(ChangeFFTProcessed.removed(data.sourceId, data.rangeId));
    },
    tokenResult: (data: TokenResult) =>
    {
        if (data.success)
        {
            monitoringStore.execute(SetLoginToken.token(data.token));
        }
        else
        {
            if (!data.validationResult)
            {
                cogoToast.warn('Invalid login details');
            }
            monitoringStore.execute(SetLoginToken.invalid());
        }
    },
    loginResult: (data: LoginResult) =>
    {
        monitoringStore.execute(SetLoginProfile.action(data.id, data.username, data.displayName, data.permissions));
    },
    logoutResult: (data: LogoutResult) =>
    {
        monitoringStore.execute(SetLoginToken.logout());
    },
    fftResult: (data: any) =>
    {
    },
    eventRules: (data: EventRuleResult) =>
    {
        monitoringStore.execute(SetEventRules.action(data));
    },
    sourceChannelInfo: (data: SourceChannelInfoResult) =>
    {
        monitoringStore.execute(SetSourceChannelInfo.action(data.merge, data.data));
    },
    removeSourceChannelInfo: (data: {id: SourceChannelId}) =>
    {
        monitoringStore.execute(SetSourceChannelInfo.remove(data.id));
    },
    channelTypeInfo: (data: ChannelTypeInfoResult) =>
    {
        monitoringStore.execute(SetChannelTypeInfo.action(data.merge, data.data));
    },
    removeChannelTypeInfo: (data: {id: ChannelTypeId}) =>
    {
        monitoringStore.execute(SetChannelTypeInfo.remove(data.id));
    },
    listUserProfiles: (data: UserProfile[]) =>
    {
        monitoringStore.execute(SetUserProfiles.action(data));
    },
    listActiveUsers: (data: {summary: string}) =>
    {
        monitoringStore.execute(SetActiveUsers.update(data.summary));
    },
    listServerConfig: (data: {summary: string}) =>
    {
        monitoringStore.execute(SetServerConfig.update(data.summary));
    },
    listAllSources: (data: ListAllSourcesResult) =>
    {
        monitoringStore.execute(SetListAllSources.action(data.sources, data.unknownSourceIds));
    },
    updateSource: (data: Source) =>
    {
        monitoringStore.execute(SetSourceList.mergeSource(data));
        monitoringStore.execute(SetListAllSources.updateSource(data));
    },
    removeSource: (data: {sourceId: SourceId}) =>
    {
        monitoringStore.execute(SetSourceList.removeSource(data.sourceId));
        monitoringStore.execute(SetListAllSources.removeSource(data.sourceId));
    },
    updateGroup: (data: Group) =>
    {
        monitoringStore.execute(SetGroupList.updateGroup(data));
    },
    removeGroup: (data: {groupId: GroupId}) =>
    {
        monitoringStore.execute(SetGroupList.removeGroup(data.groupId));
    },
    sourceChannelsForFFT: (data: {sourceChannels: SourceChannelId[], sourceId: SourceId}) =>
    {
        monitoringStore.execute(SetFFTTime.setSourceChannels(data.sourceId, data.sourceChannels));
    },
    reprocessJobUpdate: (data: {job: ReprocessEventJob}) =>
    {
        monitoringStore.execute(SetReprocessEventsModal.updateState(data.job));
    },
    downloadToken: (data: {token: string}) =>
    {
        Api.setDownloadToken(data.token);
    },
    error: (data: any) =>
    {
        console.error('Error:', data);
        const { hide } = cogoToast.error('Error: ' + data, {
            hideAfter: 0,
            onClick: () => hide()
        });
    },
    commandError: (data: CommandError) =>
    {
        console.error('Command error:', data);
        const { hide } = cogoToast.error(`Command error [${data.commandName}]: ${data.message}`, {
            hideAfter: 0,
            onClick: () => hide()
        });
    },
    commandSuccess: (data: CommandSuccess) =>
    {
        console.info('Command success: ', data);
        cogoToast.info(`Command success: ${data.message}`);
    }
}