import React from 'react';
import monitoringStore from '../store/monitoringStore';
import {SourceEvent} from '../store/monitoringStoreStates';
import ShowEventSelectorModal from '../store/showEventSelectorModal';
import {formatDateTime} from '../utils/utils';
import {ModalBase} from './modalBase';
import './sourceEventSelectorModal.scss';
import SourceEventView from './sourceEventView';

interface Props
{
    readonly sourceEvents: SourceEvent[];
}

export default class SourceEventSelectorModal extends React.PureComponent<Props>
{
    public render ()
    {
        const { sourceEvents } = this.props;

        const className = `source-event-selector-modal`;

        return (
            <ModalBase isOpen={true}
                className={className}
                onRequestClose={() => this.close()}
                >
                <h1>Select Event</h1>

                {
                    sourceEvents.map(sourceEvent =>
                    {
                        const timestamp = formatDateTime(sourceEvent.timestamp);

                        return <div key={sourceEvent.id} className="source-event-selector-modal__row">
                            <span>{timestamp}</span>
                            <SourceEventView sourceEvent={sourceEvent} fullWidth={true} />
                        </div>
                    })
                }

                <div className="source-event-modal__buttons">
                    <button onClick={() => this.close()}>Close</button>
                </div>
            </ModalBase>
        );
    }

    private close ()
    {
        monitoringStore.execute(ShowEventSelectorModal.close());
    }
}