import cogoToast from 'cogo-toast';
import React from 'react';
import {Api} from '../../api/api';
import SetUpdateChannelTypeInfo from '../../store/admin/setUpdateChannelTypeInfoState';
import monitoringStore from '../../store/monitoringStore';
import {ChannelTypeId, ChannelTypeInfo} from '../../store/monitoringStoreStates';
import ButtonGroup from '../buttonGroup';
import {ModalBase} from '../modalBase';
import './updateChannelTypeInfoModal.scss';

interface Props
{
    createNew: boolean;
    channelTypeInfo: ChannelTypeInfo;
}

export default class UpdateChannelTypeInfoModal extends React.PureComponent<Props>
{
    private channelType: React.RefObject<HTMLInputElement>;
    private displayName: React.RefObject<HTMLInputElement>;
    private prefix: React.RefObject<HTMLInputElement>;
    private chartTitle: React.RefObject<HTMLInputElement>;
    private chartPriority: React.RefObject<HTMLInputElement>;

    constructor (props: Props)
    {
        super(props);

        this.channelType = React.createRef();
        this.displayName = React.createRef();
        this.prefix = React.createRef();
        this.chartTitle = React.createRef();
        this.chartPriority = React.createRef();
    }

    public render()
    {
        const createNew = this.props.createNew;
        const info = this.props.channelTypeInfo;

        return (
            <ModalBase isOpen={true}
                className="update-channel-info-modal"
                onRequestClose={() => this.close()}>

                <h1>{createNew ? 'Create' : 'Update'} ChannelTypeInfo</h1>
                <div>
                    <strong>Channel Type Code: </strong>
                    { createNew ?
                        <input type="text" ref={this.channelType} data-rh='The channel type code, once set it cannot be changed.' defaultValue={info.channelType} /> :
                        <span data-rh='The channel type code for this channel.'>{info.channelType}</span>
                    }
                </div>
                <div>
                    <strong>Display Name: </strong>
                    <input type="text" ref={this.displayName} data-rh='Display name of the channel.' defaultValue={info.displayName} />
                </div>
                <div>
                    <strong>Prefix: </strong>
                    <input type="text" ref={this.prefix} data-rh='Unit prefix for values in this channel.' defaultValue={info.prefix} />
                </div>
                <div>
                    <strong>Chart Title: </strong>
                    <input type="text" ref={this.chartTitle} data-rh='Title for charts displaying this channel data.' defaultValue={info.chartTitle} />
                </div>
                <div>
                    <strong>Chart Priority: </strong>
                    <input type="number" step="1" ref={this.chartPriority} data-rh='This sets the ordering when displaying multiple charts. A higher number means this channel data is displayed higher up.' defaultValue={info.chartPriority.toString(10)} />
                </div>

                <div className="update-channel-info-modal__buttons">
                    <ButtonGroup>
                        <button onClick={() => this.update()}>{createNew ? 'Create' : 'Update'}</button>
                        <button onClick={() => this.remove()}>Delete</button>
                    </ButtonGroup>
                    <button onClick={() => this.close()}>Close</button>
                </div>

            </ModalBase>
        )
    }

    private close()
    {
        monitoringStore.execute(SetUpdateChannelTypeInfo.close());
    }

    private update()
    {
        let channelType = this.props.channelTypeInfo.channelType;
        const displayName = this.displayName.current.value.trim();
        const prefix = this.prefix.current.value.trim();
        const chartTitle = this.chartTitle.current.value.trim();

        if (displayName.length === 0 || prefix.length === 0 || chartTitle.length === 0)
        {
            cogoToast.warn('All fields need to be filled');
            return;
        }

        const chartPriority = parseInt(this.chartPriority.current.value);
        if (isNaN(chartPriority))
        {
            cogoToast.warn('Chart priority needs to be a number');
            return;
        }

        if (this.props.createNew)
        {
            channelType = this.channelType.current.value.trim() as ChannelTypeId;
            if (channelType.length === 0)
            {
                cogoToast.warn('Channel type needs to be filled');
                return;
            }
        }

        Api.updateChannelTypeInfo({channelType, displayName, prefix, chartTitle, chartPriority});
    }

    private remove()
    {
        const { channelType } = this.props.channelTypeInfo;
        if (confirm(`Are you sure you want to delete ${channelType}?\nThis will only remove the metadata about this channel, no signal data will be affected.`))
        {
            Api.removeChannelTypeInfo(channelType);
        }
    }
}