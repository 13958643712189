import {Modifier} from "simple-data-store";
import {modifyLocalState} from "./monitoringStore";
import {StartupStatsError, State} from "./monitoringStoreStates";

export default class ShowStartupErrors
{
    public static setErrors (errors: StartupStatsError[]): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {startupErrors: errors, showStartupErrors: true});
    }

    public static show (): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {showStartupErrors: true});
    }

    public static close (): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {showStartupErrors: false});
    }
}