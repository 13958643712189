import moment = require("moment");
import React, {ChangeEvent, CSSProperties} from 'react';
import {RemoveSubscription} from "simple-data-store";
import monitoringStore from "../../store/monitoringStore";
import {Source, SourceToEventMap, State as MonitoringState} from '../../store/monitoringStoreStates';
import {Editable} from "../../utils/commonTypes";
import {isEmptyObject} from "../../utils/utils";
import ButtonGroup from "../buttonGroup";
import LoadingBar from "../loadingBar";
import './timeline.scss';
import {EmptyRenderContext, EmptyTimelineState, getZoomInfo, MaxTimePerPixel, MinTimePerPixel, RenderContext, TimelineEventMap, TimelineGroup, TimelineSelect, TimelineSelectionHandler, TimelineState, TimelineViewChangeHandler, ZoomLevelType} from "./timelineCommon";
import TimelineEvents from "./timelineEvents";
import TimelineNow from "./timelineNow";
import TimelineSourceEvents from "./timelineSourceEvents";
import TimelineTopRail from "./timelineTopRail";

export interface Props
{
    readonly timelineState: TimelineState;
    readonly groups: TimelineGroup[];
    readonly events: TimelineEventMap;
    readonly mouseScrollFactor: number;
    readonly maxSelectionDuration: moment.Duration;
    readonly onTimeSelect?: TimelineSelectionHandler;
    readonly onChangeView: TimelineViewChangeHandler;
    readonly selectMode: 'select-box' | 'fixed-box';
    readonly selectFixedBoxWidth: moment.Duration;
    readonly restrictSelectToEvents: boolean;
    readonly sources?: Source[];
    readonly sourceToEventMap?: SourceToEventMap;
    readonly rowHeight: number;
}

interface State
{
    readonly mouseSelect: TimelineSelect;
    readonly serverConnected: boolean;
}

function isConnected(state: MonitoringState)
{
    return state.connectionState !== 'closed' && state.connectionState !== 'error';
}

export default class Timeline extends React.PureComponent<Props, State>
{
    public static defaultProps: Partial<Props> = {
        mouseScrollFactor: 1.15,
        maxSelectionDuration: moment.duration(1, 'days'),
        timelineState: EmptyTimelineState,
        selectMode: 'select-box',
        selectFixedBoxWidth: moment.duration(1, 'second'),
        restrictSelectToEvents: false,
        rowHeight: 25
    };

    public rootRef: React.RefObject<HTMLDivElement>;
    public eventsRef: React.RefObject<HTMLDivElement>;
    public buttonGroupRef: React.RefObject<HTMLDivElement>;
    public dataContainerRef: React.RefObject<HTMLDivElement>;
    public renderContext: RenderContext = EmptyRenderContext;
    public mouseDownClient: {readonly x: number, readonly y: number};

    private isMouseDown: 'false' | 'move' | 'select' = 'false';
    private mouseX: number;
    private mouseDownTime: moment.Moment;
    private removeSubscription: RemoveSubscription;
    private documentMouseHandler: EventListener;
    private mouseWheelBound: EventListener;
    private mouseDownHandler: EventListener;
    private mouseMoveHandler: EventListener;
    private touchStartHandler: EventListener;
    private touchMoveHandler: EventListener;

    constructor (props: Props)
    {
        super(props);

        this.state = {
            serverConnected: isConnected(monitoringStore.state()),
            mouseSelect: {
                start: moment(),
                end: moment(),
                enabled: false
            }
        };

        this.rootRef = React.createRef();
        this.eventsRef = React.createRef();
        this.buttonGroupRef = React.createRef();
        this.dataContainerRef = React.createRef();
    }

    public setMouseSelection (start: moment.Moment, end: moment.Moment)
    {
        const startValue = start.valueOf();
        const endValue = end.valueOf();
        const diff = end.diff(start);
        const maxDurationMs = this.props.maxSelectionDuration.asMilliseconds();

        if (startValue > endValue)
        {
            const temp = start;
            start = end;
            end = temp;

            if (-diff > maxDurationMs)
            {
                start = end.clone().subtract(maxDurationMs);
            }
        }
        else
        {
            if (diff > maxDurationMs)
            {
                end = start.clone().add(maxDurationMs);
            }
        }

        const mouseSelect = {
            start, end, enabled: true
        };

        this.setState({mouseSelect});
    }

    public componentDidMount ()
    {
        this.forceUpdate();

        this.documentMouseHandler = (e) =>
        {
            if (this.isMouseDown !== 'false' &&
                this.state.mouseSelect.enabled &&
                typeof (this.props.onTimeSelect) === 'function')
            {
                this.props.onTimeSelect(this.state.mouseSelect.start, this.state.mouseSelect.end);
            }
            this.isMouseDown = 'false';
        };

        document.addEventListener('mouseup', this.documentMouseHandler);
        document.addEventListener('touchend', this.documentMouseHandler);

        this.mouseWheelBound = this.onMouseWheel.bind(this);
        this.rootRef.current.addEventListener('wheel', this.mouseWheelBound, {passive: false});

        this.removeSubscription = monitoringStore.subscribe((state) => state.connectionState, (state) =>
            {
                const connected = isConnected(state);
                if (connected !== this.state.serverConnected)
                {
                    this.setState({serverConnected: connected});
                }
            }, undefined, 'timelineCheckForConnectionChange');

        this.mouseDownHandler = this.onMouseDown.bind(this);
        this.mouseMoveHandler = this.onMouseMove.bind(this);
        this.touchStartHandler = this.onTouchStart.bind(this);
        this.touchMoveHandler = this.onTouchMove.bind(this);

        const dataContainerEl = this.dataContainerRef.current;
        dataContainerEl.addEventListener('mousedown', this.mouseDownHandler);
        dataContainerEl.addEventListener('mousemove', this.mouseMoveHandler);
        dataContainerEl.addEventListener('touchstart', this.touchStartHandler);
        dataContainerEl.addEventListener('touchmove', this.touchMoveHandler);
    }

    public componentWillUnmount ()
    {
        document.removeEventListener('mouseup', this.documentMouseHandler);
        document.removeEventListener('touchend', this.documentMouseHandler);
        if (this.rootRef.current)
        {
            this.rootRef.current.removeEventListener('wheel', this.mouseWheelBound);
        }

        this.removeSubscription();

        const dataContainerEl = this.dataContainerRef.current;
        if (dataContainerEl)
        {
            dataContainerEl.removeEventListener('mousedown', this.mouseDownHandler);
            dataContainerEl.removeEventListener('mousemove', this.mouseMoveHandler);
            dataContainerEl.removeEventListener('touchstart', this.touchStartHandler);
            dataContainerEl.removeEventListener('touchmove', this.touchMoveHandler);
        }
    }

    public render()
    {
        this.createRenderContext();

        const { bounds, buttonGroupBounds, eventsBounds } = this.renderContext;

        const groupStyle: CSSProperties = {
            top: buttonGroupBounds.height + (buttonGroupBounds.top - bounds.top),
            paddingTop: eventsBounds.top - buttonGroupBounds.bottom
        };

        const minTime = Math.log(MinTimePerPixel.asMilliseconds());
        const maxTime = Math.log(MaxTimePerPixel.asMilliseconds());
        const currentTime = Math.log(this.props.timelineState.timePerPixel.asMilliseconds());

        let mainHeight = 120 + (this.props.groups.length + 0.5) * this.props.rowHeight;
        if (!isEmptyObject(this.props.sourceToEventMap))
        {
            mainHeight += this.props.rowHeight;
        }

        const mainStyle: CSSProperties = {
            height: mainHeight
        }

        return (
            <div className="timeline" ref={this.rootRef} style={mainStyle}>

                <LoadingBar show={this.props.timelineState.loading && this.state.serverConnected} />

                <div className="timeline__button-row" ref={this.buttonGroupRef}>
                    <ButtonGroup>
                        { this.props.timelineState.selection.enabled &&
                        <button onClick={this.gotoSelection} data-rh='Zoom to selected time range.'>Selection</button> }
                        <button onClick={this.gotoNow} data-rh='Zoom to current time.'>Now</button>
                    </ButtonGroup>

                    <ButtonGroup className="timeline__time-buttons">
                        <button onClick={this.changeZoomMinute} data-rh='Zoom to minutes'>Minute</button>
                        <button onClick={this.changeZoomHour} data-rh='Zoom to hours'>Hour</button>
                        <button onClick={this.changeZoomDay} data-rh='Zoom to days'>Day</button>
                        <button onClick={this.changeZoomMonth} data-rh='Zoom to months'>Month</button>
                    </ButtonGroup>

                    <label className="timeline__zoom-slider">
                        <strong>Zoom: </strong>
                        <input type="range" step="0.01" min={minTime} max={maxTime} value={currentTime} onChange={this.onSliderChange}/>
                    </label>
                </div>

                <div className="timeline__data-container" ref={this.dataContainerRef}>

                    <TimelineTopRail parent={this}
                        renderContext={this.renderContext}
                        timelineState={this.props.timelineState}
                        onChangeView={this.props.onChangeView} />

                    <TimelineSourceEvents renderContext={this.renderContext}
                        sources={this.props.sources}
                        sourceToEventMap={this.props.sourceToEventMap} />

                    <div className="timeline__events" ref={this.eventsRef}>
                        <TimelineEvents
                            events={this.props.events}
                            groups={this.props.groups}
                            renderContext={this.renderContext}
                            restrictSelectToEvents={this.props.restrictSelectToEvents} />
                    </div>

                    <TimelineNow renderContext={this.renderContext} />

                    { this.renderSelection() }
                </div>
                <div className="timeline__groups" style={groupStyle}>
                    { this.renderGroups() }
                </div>
            </div>
        )
    }

    private changeZoom(zoomLevel: ZoomLevelType)
    {
        const zoomLevelInfo = getZoomInfo(zoomLevel);
        this.props.onChangeView(this.props.timelineState.centerTime, zoomLevelInfo.timePerPixel);
    }

    private changeZoomMinute = () => this.changeZoom('minute');
    private changeZoomHour = () => this.changeZoom('hour');
    private changeZoomDay = () => this.changeZoom('day');
    private changeZoomMonth = () => this.changeZoom('month');

    private onSliderChange = (e: ChangeEvent<HTMLInputElement>) =>
    {
        const logTime = parseFloat(e.target.value);
        const time = Math.pow(Math.E, logTime);
        const timePerPixel = moment.duration(time, 'milliseconds');
        this.props.onChangeView(this.props.timelineState.centerTime, timePerPixel);
    }

    private gotoSelection = () =>
    {
        const selection = this.props.timelineState.selection;
        const { centerTime, timePerPixel } = this.renderContext.calculateViewFromStartEnd(selection.start, selection.end);

        this.props.onChangeView(centerTime, moment.duration(timePerPixel * 1.2, 'milliseconds'));
    }

    private gotoNow = () =>
    {
        this.props.onChangeView(moment(new Date()), this.props.timelineState.timePerPixel);
    }

    private renderSelection()
    {
        const selection = this.isMouseDown === 'select' && this.state.mouseSelect.enabled ? this.state.mouseSelect : this.props.timelineState.selection;
        if (!selection.enabled || !this.renderContext)
        {
            return null;
        }
        const startXpos = this.renderContext.calculateXposFromTime(selection.start, true);
        const endXpos = this.renderContext.calculateXposFromTime(selection.end, true);
        const width = Math.max(1, endXpos - startXpos);

        const style: CSSProperties = {
            transform: `translateX(${startXpos}px)`,
            width: `${width}px`,
            height: `${this.renderContext.bounds.height}px`
        }

        return (
            <span className="timeline__selection" style={style}>
            </span>
        )
    }

    private renderGroups()
    {
        const result: JSX.Element[] = [];
        const style: CSSProperties = {
            height: `${this.props.rowHeight}px`
        }

        for (const group of this.props.groups)
        {
            result.push(<div key={`group_${group.id}`} className="timeline__group" style={style}>{group.name}</div>);
        }

        return result;
    }

    private createRenderContext ()
    {
        if (!this.rootRef.current)
        {
            return;
        }

        const state = this.props.timelineState;

        const bounds: Editable<ClientRect> = this.rootRef.current.getBoundingClientRect();
        const eventsBounds: Editable<ClientRect> = this.eventsRef.current.getBoundingClientRect();
        const buttonGroupBounds: Editable<ClientRect> = this.buttonGroupRef.current.getBoundingClientRect();

        const hasSourceEvents = !!this.props.sourceToEventMap && !!this.props.sources;

        this.renderContext = new RenderContext(bounds, eventsBounds, buttonGroupBounds, state.centerTime, state.timePerPixel, hasSourceEvents);
    }

    private onMouseDown (e: MouseEvent)
    {
        e.stopPropagation();
        e.preventDefault();

        this.mouseDownClient = {x: e.clientX, y: e.clientY};
        this.doPointerDown(e.clientX, e.target);
    }

    private onMouseMove (e: MouseEvent)
    {
        e.stopPropagation();
        e.preventDefault();

        this.doPointerMove(e.clientX);
    }

    private onTouchStart (e: TouchEvent)
    {
        e.stopPropagation();
        e.preventDefault();

        const touch = e.touches[0];
        this.mouseDownClient = {x: touch.clientX, y: touch.clientY};
        this.doPointerDown(touch.clientX, e.target);
    }

    private onTouchMove (e: TouchEvent)
    {
        e.stopPropagation();
        e.preventDefault();

        this.doPointerMove(e.touches[0].clientX);
    }

    private doPointerDown(clientX: number, target: EventTarget)
    {
        const htmlTarget = target as HTMLElement;
        if (htmlTarget.classList.contains('timeline__event-select-box') || htmlTarget.classList.contains('timeline__event'))
        {
            this.isMouseDown = 'select';
        }
        else
        {
            this.isMouseDown = 'move';
        }

        this.mouseX = this.renderContext.getRelativeXpos(clientX);
        this.mouseDownTime = this.renderContext.getTimeAtPosition(this.mouseX);

        if (this.isMouseDown === 'select' && this.props.selectMode === 'fixed-box')
        {
            const left = this.mouseDownTime.clone().subtract(this.props.selectFixedBoxWidth);
            const right = this.mouseDownTime.clone().add(this.props.selectFixedBoxWidth);
            this.setMouseSelection(left, right);
        }
    }

    private doPointerMove(clientX: number)
    {
        if (this.isMouseDown === 'false')
        {
            return;
        }

        const xpos = this.renderContext.getRelativeXpos(clientX);
        if (this.isMouseDown === 'select')
        {
            if (this.props.selectMode === 'fixed-box')
            {
                const timeAtMouse = this.renderContext.getTimeAtPosition(xpos);
                const left = timeAtMouse.clone().subtract(this.props.selectFixedBoxWidth);
                const right = timeAtMouse.clone().add(this.props.selectFixedBoxWidth);
                this.setMouseSelection(left, right);
            }
            else
            {
                this.doSelect(xpos);
            }
        }
        else
        {
            this.doMove(xpos);
        }
    }

    private onMouseWheel (event: MouseWheelEvent)
    {
        event.preventDefault();

        const xpos = this.renderContext.getRelativeXpos(event.clientX);
        const timeAtPoint = this.renderContext.getTimeAtPosition(xpos);

        const wheelRatio = event.deltaY > 0 ? this.props.mouseScrollFactor : 1 / this.props.mouseScrollFactor;
        const newTimePerPixel = this.props.timelineState.timePerPixel.asMilliseconds() * wheelRatio;

        let timePerPixel = moment.duration(newTimePerPixel, 'millisecond');
        if (timePerPixel.asMilliseconds() < MinTimePerPixel.asMilliseconds())
        {
            timePerPixel = MinTimePerPixel.clone();
        }
        else if (timePerPixel.asMilliseconds() > MaxTimePerPixel.asMilliseconds())
        {
            timePerPixel = MaxTimePerPixel.clone();
        }

        let centerTime = this.props.timelineState.centerTime;
        if (this.renderContext.isClientXInView(event.clientX))
        {
            const tempRenderContext = new RenderContext(this.renderContext.bounds, this.renderContext.eventsBounds, this.renderContext.buttonGroupBounds, centerTime, timePerPixel, false);

            const timeAtNewPoint = tempRenderContext.getTimeAtPosition(xpos);
            const diff = timeAtNewPoint.diff(timeAtPoint);
            centerTime.subtract(diff);
        }

        this.props.onChangeView(centerTime, timePerPixel);
    }

    private doMove (newX: number)
    {
        const dx = newX - this.mouseX;
        const timeMove = dx * -this.renderContext.timePerPixelMs;

        const newTime = this.props.timelineState.centerTime.clone().add(timeMove, 'milliseconds');
        this.props.onChangeView(newTime, this.props.timelineState.timePerPixel);

        this.mouseX = newX;
    }

    private doSelect (mouseX: number)
    {
        const timeAtMouse = this.renderContext.getTimeAtPosition(mouseX);
        this.setMouseSelection(this.mouseDownTime, timeAtMouse);
    }
}