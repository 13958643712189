import React from 'react';
import './debugConsole.scss';

interface Props
{
    items: string[];
}

export default class DebugConsole extends React.PureComponent<Props>
{
    public render ()
    {
        return (
            <div className="debug-console">
                <strong>Debug Console</strong>
                <ul>
                    { this.props.items.map((i) => <li>{i}</li>)}
                </ul>
            </div>
        );
    }
}
