import React from 'react';
import {Api} from '../../api/api';
import SetCombineDataReceived from '../../store/admin/setCombineDataReceived';
import monitoringStore from '../../store/monitoringStore';
import {ModalBase} from '../modalBase';
import './combineDataReceivedModal.scss';

interface Props
{
    readonly show: boolean;
}

export default class CombineDataReceivedStoreModal extends React.PureComponent<Props>
{
    private textareaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

    public render()
    {
        return (
            <ModalBase isOpen={this.props.show}
                className='combine-data-received-modal'
                onRequestClose={this.close}>
                    <h2>Combine Data Received Store</h2>
                    <h3>THIS IS AN ADVANCED FEATURE</h3>

                    <textarea ref={this.textareaRef} placeholder='Json for Source Data Received Store'></textarea>

                    <div className='combine-data-received-modal__buttons'>
                        <button onClick={this.sendData}>Send Data</button>
                        <button onClick={this.close}>Close</button>
                    </div>
                </ModalBase>
        )
    }

    private close = () =>
    {
        monitoringStore.execute(SetCombineDataReceived.close());
    }

    private sendData = () =>
    {
        try
        {
            const text = this.textareaRef.current.value;
            const json = JSON.parse(text);
            Api.combineDataReceivedStores(json);
        }
        catch (error)
        {
            alert('Invalid JSON ' + error);
            console.error('Invalid JSON for combine data store', error);
        }
    }
}