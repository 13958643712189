import {Modifier} from "simple-data-store";
import {modifyLocalState} from "./monitoringStore";
import {State} from "./monitoringStoreStates";

export default class ShowScriptVersionErrorModal
{
    public static show (): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {showScriptVersionErrorModal: true});
    }

    public static close (): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {showScriptVersionErrorModal: false});
    }
}