import moment = require("moment");
import {Modifier} from "simple-data-store";
import {Api} from "../api/api";
import {momentToMicro} from "../utils/timeTypes";
import {SourceChannelId, SourceId, State} from "./monitoringStoreStates";

interface UpdateCache
{
    [sourceId: string]: 'loading' | number;
}
const updateCache: UpdateCache = {};
const cacheTimeout = moment.duration(2, 'minutes').asMilliseconds();

export default class SetFFTTime
{
    public static time (time: moment.Moment): Modifier<State>
    {
        return (state: State) => ({fftState: {...state.fftState, time}});
    }

    public static zoom (zoomLevel: number): Modifier<State>
    {
        return (state: State) => ({fftState: {...state.fftState, zoomLevel}});
    }

    public static setSourceChannels (sourceId: SourceId, sourceChannels: SourceChannelId[]): Modifier<State>
    {
        updateCache[sourceId] = Date.now();

        return (state: State) => {
            const sourceChannelsMap = {
                ...state.fftState.sourceChannelsMap,
                [sourceId]: sourceChannels
            };

            return {fftState: {...state.fftState, sourceChannelsMap}}
        };
    }

    public static updateTimeForAllSources(time?: moment.Moment): Modifier<State>
    {
        return (state: State) =>
        {
            if (!time)
            {
                time = state.fftState.time;
            }

            for (const source of state.sources)
            {
                this.updateSourceChannels(time, source.id);
            }

            return this.time(time)(state);
        }
    }

    public static updateSourceChannels (time: moment.Moment, sourceId: SourceId)
    {
        const currentCache = updateCache[sourceId];
        if (currentCache === 'loading')
        {
            return;
        }

        if (!isNaN(currentCache))
        {
            const diff = Date.now() - currentCache;
            if (diff < cacheTimeout)
            {
                return;
            }
        }

        updateCache[sourceId] = 'loading';

        Api.getSourceChannelsForFFT(sourceId, momentToMicro(time));
    }
}