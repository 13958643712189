import moment = require("moment");
import {MicroSeconds, ZeroMicro} from "../../utils/timeTypes";
import {padZero, toMoment} from "../../utils/utils";
import {TimeResolution, Unit} from "./lineChartTypes";

export const ignoreValue = 1e21;
export const ignoreUpper = 1e20;
export const ignoreLower = -1e20;

export function isValidNumber(input: number)
{
    return !isNaN(input) && input > ignoreLower && input < ignoreUpper;
}

export function getUnit (input: number): Unit
{
    let multiplier = 1;
    let prefix = 'ms';

    if (input > 5000)
    {
        multiplier *= 1000;
        prefix = 's';
    }

    if (input > 100000)
    {
        multiplier *= 60;
        prefix = 'm';
    }

    if (input > 500000)
    {
        multiplier *= 60;
        prefix = 'h';
    }

    return {multiplier, prefix};
}

export function getTimeResolution (input: MicroSeconds): TimeResolution
{
    let includeMicro = false;
    let timeFormat = 'HH:mm';

    if (input < 10000000000)
    {
        timeFormat += ':ss';
    }

    if (input < 100000000)
    {
        timeFormat += '.SSS';
    }

    let axisLabel = timeFormat;
    if (input < 100000)
    {
        includeMicro = true;
        axisLabel += '.FFF';
    }

    return {timeFormat, includeMicro, axisLabel};
}
export const MaxTimeResolution = getTimeResolution(ZeroMicro);

export function formatForTimeResolution (resolution: TimeResolution, timeInMicro: MicroSeconds)
{
    let result = toMoment(timeInMicro).format(resolution.timeFormat);
    if (resolution.includeMicro)
    {
        result += Math.floor((timeInMicro / 100) % 10);
    }
    return result;
}