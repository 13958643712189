import {Modifier} from "simple-data-store";
import {modifyLocalState} from "./monitoringStore";
import {State} from "./monitoringStoreStates";

export default class SetPauseLiveGraphs
{
    public static action (pause: boolean): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {pauseLiveGraphs: pause});
    }
}