import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {router} from '../../router';
import monitoringStore from '../../store/monitoringStore';
import {Group, State, UserView} from '../../store/monitoringStoreStates';
import ReprocessEventsModal from '../adminComponents/reprocessEventsModal';
import ShowEventReportsModal from '../adminComponents/showEventReportsModal';
import CreateEventModal from '../createEventModal';
import DownloadCSVModal from '../downloadCSVModal';
import SourceEventModal from '../sourceEventModal';
import SourceEventSelectorModal from '../sourceEventSelectorModal';
import AdminTab from '../tabs/adminTab';
import DataLookupTab from '../tabs/dataLookupTab';
import FFTTab from '../tabs/fftTab';
import GraphsTab from '../tabs/graphsTab';
import OverviewTab from '../tabs/overviewTab';
import SourceInfoTab from '../tabs/sourceInfoTab';

interface Props
{
    state: State;
}

function onViewChange (index: number)
{
    let view: UserView = 'overview';
    switch (index)
    {
        case 1: view = 'graphs'; break;
        case 2: view = 'dataLookup'; break;
        case 3: view = 'fft'; break;
        case 4: view = 'sourceInfo'; break;
        case 5: view = 'admin'; break;
    }

    router.changeView(view);
}

function getTabIndex (view: UserView)
{
    switch (view)
    {
        default:
        case 'overview': return 0;
        case 'graphs': return 1;
        case 'dataLookup': return 2;
        case 'fft': return 3;
        case 'sourceInfo': return 4;
        case 'admin': return 5;
    }
}

export default class MainAppState extends React.PureComponent<Props>
{
    public render ()
    {
        const {state} = this.props;
        const isAdmin = state.loginState.userProfile.permissions.indexOf('admin') >= 0;

        return (
            <main className="app__main">
                <Tabs onSelect={onViewChange} selectedIndex={getTabIndex(state.serverState.view)}>
                    <TabList>
                        <Tab>Overview</Tab>
                        <Tab>Graphs</Tab>
                        <Tab>Data Lookup</Tab>
                        <Tab>FFT</Tab>
                        <Tab>Source Info</Tab>
                        {isAdmin && <Tab>Admin</Tab>}
                    </TabList>

                    <TabPanel>
                        <OverviewTab state={state} />
                    </TabPanel>

                    <TabPanel>
                        <GraphsTab state={state} />
                    </TabPanel>

                    <TabPanel>
                        <DataLookupTab state={state} />
                    </TabPanel>

                    <TabPanel>
                        <FFTTab state={state} />
                    </TabPanel>

                    <TabPanel>
                        <SourceInfoTab state={state} />
                    </TabPanel>

                    {isAdmin &&
                        <TabPanel>
                            <AdminTab state={state} />
                        </TabPanel>
                    }
                </Tabs>

                {state.localState.selectedEvent &&
                    <SourceEventModal key={state.localState.selectedEvent.id} sourceEvent={state.localState.selectedEvent} open={state.localState.showSourceEventModal} />
                }

                {state.localState.eventsToSelect.length > 0 &&
                    <SourceEventSelectorModal sourceEvents={state.localState.eventsToSelect} />
                }

                <CreateEventModal createEventState={state.createEventState} />

                {state.downloadCSVState.isOpen &&
                    <DownloadCSVModal sourceChannels={state.dataLookup.sourceChannels}
                        sourceDataEnabled={state.sourceDataEnabledState}
                        sources={state.sources}
                        downloadState={state.downloadCSVState} />}

                {state.adminState.reprocessEventsState.isOpen &&
                    <ReprocessEventsModal reprocessOpenState={state.adminState.reprocessEventsState} />
                }

                {state.adminState.eventReportsState.isOpen &&
                    <ShowEventReportsModal groupId={state.serverState.selectedGroupId} eventReportsState={state.adminState.eventReportsState} />
                }

            </main>
        );
    }
}

monitoringStore.subscribe((state) => state.groups, (state, groups: Group[]) =>
    {
        if (groups.length === 1)
        {
            router.changeGroup(groups[0].id);
        }
    }, undefined, 'checkForGroupChange');
