import moment from 'moment';
import React from 'react';
import monitoringStore from '../store/monitoringStore';
import {Source, SourceDataReceivedMap, SourceToEventMap} from '../store/monitoringStoreStates';
import SetTimelineState from '../store/setTimelineState';
import {toMoment} from '../utils/utils';
import Timeline from './timeline/timeline';
import {TimelineEvent, TimelineEventMap, TimelineGroup, TimelineSelectionHandler, TimelineState} from './timeline/timelineCommon';

interface Props
{
    readonly sources: Source[];
    readonly sourceToEventMap: SourceToEventMap;
    readonly sourceDataReceived: SourceDataReceivedMap;
    readonly onTimeSelect: TimelineSelectionHandler;
    readonly timelineState: TimelineState;
}

export default class SourceDataReceivedView extends React.PureComponent<Props>
{
    private windowResizeHandler: EventListener;

    public componentDidMount ()
    {
        this.windowResizeHandler = (e) =>
        {
            this.forceUpdate();
        };
        window.addEventListener('resize', this.windowResizeHandler);
    }

    public componentWillUnmount ()
    {
        window.removeEventListener('resize', this.windowResizeHandler);
    }

    public render ()
    {
        const groups: TimelineGroup[] = this.props.sources.map((s) =>
            {
                return {
                     name: s.name,
                     id: '' + s.id
                };
            });

        const events: TimelineEventMap = {};
        for (const sourceId in this.props.sourceDataReceived)
        {
            const sourceMap = this.props.sourceDataReceived[sourceId];
            const rangeEvents: TimelineEvent[] = [];
            for (const rangeId in sourceMap)
            {
                const range = sourceMap[rangeId];

                rangeEvents.push({
                    title: rangeId,
                    start: toMoment(range.start),
                    end: toMoment(range.end)
                });
            }

            events[sourceId] = rangeEvents;
        }

        return (
            <Timeline
                timelineState={this.props.timelineState}
                events={events}
                groups={groups}
                sources={this.props.sources}
                sourceToEventMap={this.props.sourceToEventMap}
                onTimeSelect={this.props.onTimeSelect}
                onChangeView={this.onChangeView}
                />
            );
    }

    private onChangeView = (centerTime: moment.Moment, timePerPixel: moment.Duration) =>
    {
        monitoringStore.execute(SetTimelineState.view('datalookup', centerTime, timePerPixel));
    }
}
