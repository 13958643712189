import React, {ReactNode} from 'react';

const chartTooltipRegex = /(\[[^:]+:[^\]]+\])/g;
const chartTooltipTokenRegex = /\[([^:]+):([^[^\]]+)\]/;
export function renderChartTooltipContentCharts (target: ReactNode, content: string)
{
    if (!content)
    {
        return;
    }

    const contentResult = content.split('\n');
    const jsxResult: JSX.Element[] = [];

    for (let i = 0; i < contentResult.length; i++)
    {
        const line = contentResult[i];
        const split = line.split(chartTooltipRegex);
        const children: JSX.Element[] = [];

        for (const s of split)
        {
            if (s.length === 0)
            {
                continue;
            }

            if (s[0] === '[')
            {
                const token = s.match(chartTooltipTokenRegex);
                children.push(<span key={`charttooltip_colour_${i}`} className="webgl-chart-tooltip__colour" style={{backgroundColor: token[1]}} />);
                children.push(<span key={`charttooltip_token_${i}`} className="webgl-chart-tooltip__token">{token[2]}</span>);
            }
            else if (s[0] === '{')
            {
                const endBracket = s.indexOf('}');
                children.push(<span key={`charttooltip_token_${i}`} className="webgl-chart-tooltip__token">
                    {s.substring(1, endBracket)}
                </span>);
                children.push(<span key={`charttooltip_${i}`}>{s.substr(endBracket + 1)}</span>);
            }
            else
            {
                children.push(<span key={`charttooltip_${i}`}>{s}</span>);
            }
        }
        jsxResult.push(<div key={i}>{children}</div>);
    }

    return <div className="react-hint__content">{jsxResult}</div>;
}

export function renderTooltipContent (target: ReactNode, content: string)
{
    if (!content)
    {
        return;
    }

    const contentResult = content.split('\n').map((s) => s.trim());
    const jsxResult: JSX.Element[] = [];

    for (let i = 0; i < contentResult.length; i++)
    {
        jsxResult.push(<div key={`tooltip_${i}`}>{contentResult[i]}</div>);
    }

    return <div className="react-hint__content">{jsxResult}</div>;
}

export function renderTooltipContentLarge (target: ReactNode, content: string)
{
    if (!content)
    {
        return;
    }

    const contentResult = content.split('\n').map((s) => s.trim());
    const jsxResult: JSX.Element[] = [];

    for (let i = 0; i < contentResult.length; i++)
    {
        jsxResult.push(<div key={`tooltip_${i}`}>{contentResult[i]}</div>);
    }

    return <div className="react-hint__content is--large">{jsxResult}</div>;
}