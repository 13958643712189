import React, {ChangeEvent} from 'react';
import {shallowEqualsArray} from '../utils/utils';
import './tagInput.scss';

export interface TagValue
{
    readonly label: string;
    readonly value: string;
    readonly tooltip?: string;
}

export type TagInputChangeHandler = (values: string[]) => void;

interface Props
{
    readonly placeholder: string;
    readonly noPlaceholder: string;
    readonly values: string[];
    readonly allSuggestion?: TagValue;
    readonly suggestions: TagValue[];
    readonly onChange: TagInputChangeHandler
    readonly tooltip?: string;
}

interface TagProps
{
    readonly value: TagValue;
    readonly onClick: (value: TagValue) => void;
}

class TagRender extends React.PureComponent<TagProps>
{
    public render()
    {
        const { tooltip, label } = this.props.value;
        return <button className='button-outline tag-value' onClick={this.onClick} data-rh={tooltip}>
            {label}
        </button>
    }

    private onClick = () =>
    {
        this.props.onClick(this.props.value);
    }
}

export default class TagInput extends React.Component<Props>
{
    public render()
    {
        const { tooltip, values } = this.props;
        return (
            <div>
                <select value='' className='tag-input__select' onChange={this.onChange} data-rh={tooltip}>
                    {this.renderSelectOptions()}
                </select>

                {values.map(
                    v => (<TagRender key={v} value={this.getSuggestionFromValue(v)} onClick={this.removeTag}/>))}
            </div>
        )
    }

    public shouldComponentUpdate(newProps: Props)
    {
        const { props } = this;
        if (!shallowEqualsArray(props.values, newProps.values))
        {
            return true;
        }
        if (!shallowEqualsArray(props.suggestions, newProps.suggestions))
        {
            return true;
        }

        return props.noPlaceholder !== newProps.noPlaceholder ||
            props.onChange !== newProps.onChange ||
            props.placeholder !== newProps.placeholder ||
            props.tooltip !== newProps.tooltip;
    }

    private getSuggestionFromValue(value: string): TagValue
    {
        const { suggestions, allSuggestion } = this.props;
        if (allSuggestion && allSuggestion.value === value)
        {
            return { value, label: allSuggestion.label }
        }

        for (let i = 0; i < suggestions.length; i++)
        {
            if (suggestions[i].value === value)
            {
                return suggestions[i];
            }
        }

        return {value, label: value};
    }

    private removeTag = (tag: TagValue) =>
    {
        const newValues = this.props.values.filter(v => v !== tag.value);
        this.props.onChange(newValues);
    }

    private onChange = (e: ChangeEvent<HTMLSelectElement>) =>
    {
        const value = e.target.value;
        if (value === '')
        {
            return;
        }

        const { allSuggestion, suggestions, values, onChange } = this.props;

        if (allSuggestion && allSuggestion.value === value)
        {
            onChange([allSuggestion.value]);
        }
        else
        {
            const newValue = suggestions.find(s => s.value === e.target.value);
            if (!newValue)
            {
                return;
            }

            const newValues = [...values, newValue.value];
            onChange(newValues);
        }
    }

    private renderSelectOptions(): JSX.Element[] | JSX.Element
    {
        const { values, suggestions, noPlaceholder, placeholder, allSuggestion } = this.props;

        if (allSuggestion && values.find(v => v === allSuggestion.value))
        {
            return <option disabled value=''>All Selected</option>;
        }

        const filteredSuggestions = suggestions.filter(s => !values.find(v => v === s.value));
        if (filteredSuggestions.length === 0)
        {
            return [<option key='no-options' value=''>{noPlaceholder}</option>]
        }

        const results: JSX.Element[] = [<option key='disabled' value=''>{placeholder}</option>];
        if (allSuggestion)
        {
            results.push(<option key={allSuggestion.value} value={allSuggestion.value}>{allSuggestion.label}</option>);
        }

        for (const tag of filteredSuggestions)
        {
            results.push(<option key={tag.value} value={tag.value}>{tag.label}</option>);
        }

        return results;
    }
}