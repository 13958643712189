import React, {ChangeEvent} from 'react';
import {Api} from '../../api/api';
import ShowReprocessEventsModal from '../../store/admin/showReprocessEventsModal';
import monitoringStore from '../../store/monitoringStore';
import {ReprocessEventsState} from '../../store/monitoringStoreStates';
import {formatDateTime, formatDateTimeInput, parseDateTimeInput} from '../../utils/utils';
import {ModalBase} from '../modalBase';
import './reprocessEventsModal.scss';

interface Props
{
    readonly reprocessOpenState: ReprocessEventsState;
}

interface State
{
}

export default class ReprocessEventsModal extends React.PureComponent<Props, State>
{
    public render ()
    {
        const {isOpen, start, end, updateState} = this.props.reprocessOpenState;
        if (!isOpen)
        {
            return null;
        }

        return (
            <ModalBase isOpen={true}
                className="reprocess-event-modal"
                onRequestClose={() => this.close()}
                >
                <h1>Reprocess RMS Events</h1>
                <p>
                    Enter a start and end date to reprocess RMS events. This will remove all RMS events between those dates before creating new ones.
                </p>
                <div>
                    <strong>From Time: </strong> <input type="datetime-local" onChange={(e) => this.onStartChange(e)} value={formatDateTimeInput(start)}/>
                </div>
                <div>
                    <strong>To Time: </strong> <input type="datetime-local" onChange={(e) => this.onEndChange(e)} value={formatDateTimeInput(end)}/>
                </div>

                <div>
                    <strong>Current state: </strong>
                    { updateState && <div>
                        <div><strong>From: </strong> {formatDateTime(updateState.from)}</div>
                        <div><strong>To: </strong> {formatDateTime(updateState.to)}</div>
                        <div><strong>Current: </strong> {formatDateTime(updateState.current)}</div>
                        <div><strong>SourceId: </strong> {JSON.stringify(updateState.sourceIds)}</div>
                        <div><strong>NumEvents: </strong> {updateState.numberOfEventsCreated}</div>
                        <div><strong>SourceIdIndex: </strong> {updateState.currentSourceIdIndex}</div>
                        <div><strong>Error: </strong> {updateState.error}</div>
                    </div> }
                </div>

                <div className="reprocess-event-modal__buttons">
                    <button onClick={() => this.reprocessEvents()}>Reprocess</button>
                    <button onClick={() => this.close()}>Close</button>
                </div>
            </ModalBase>
        );
    }

    private onStartChange(event: ChangeEvent<HTMLInputElement>)
    {
        const parsed = parseDateTimeInput(event.target.value);
        if (parsed.isValid())
        {
            monitoringStore.execute(ShowReprocessEventsModal.start(parsed));
        }
    }

    private onEndChange(event: ChangeEvent<HTMLInputElement>)
    {
        const parsed = parseDateTimeInput(event.target.value);
        if (parsed.isValid())
        {
            monitoringStore.execute(ShowReprocessEventsModal.end(parsed));
        }
    }

    private reprocessEvents()
    {
        const {start, end} = this.props.reprocessOpenState;
        Api.reprocessAllRmsEvents(start, end);
    }

    private close ()
    {
        monitoringStore.execute(ShowReprocessEventsModal.close());
    }
}