import moment from "moment";
import DataStore from "simple-data-store";
import {DefaultWebGLChartState} from "../components/webglChart/webglChartStore";
import {Seconds, secondsToMicro, ZeroMicro} from "../utils/timeTypes";
import {AdminModalState, AdminState, EmptyTimeSpan, LocalState, ServerState, SourceId, State, UnknownGroupId} from "./monitoringStoreStates";

export function modifyAdminState(state: State, adminState: Partial<AdminState>)
{
    return {adminState: Object.assign({}, state.adminState, adminState)};
}

export function modifyServerState(state: State, serverState: Partial<ServerState>)
{
    return {serverState: Object.assign({}, state.serverState, serverState)};
}

export function modifyLocalState(state: State, localState: Partial<LocalState>)
{
    return {localState: Object.assign({}, state.localState, localState)};
}

// Default store
const emptyAdminModalState: AdminModalState = {
    show: false,
    createNew: false
};

const defaultStore: State = {
    serverInfo: {
        buildNumber: 'unknown',
        startingGroupId: UnknownGroupId,
        fftScalingFactor: 1,
        startingUserView: 'overview',
        fftTileHostname: '',
        scriptFilename: ['local'],
        serverStartTime: ZeroMicro,
        rmsFilters: {},
        fftResponseConfigs: {}
    },
    groups: [],
    sources: [],
    connectionState: 'closed',
    sourceEvents: {},
    serverState: {
        selectedGroupId: UnknownGroupId,
        view: 'overview',
        loginToken: '',
        darkTheme: false,
        autoResetYZoomOnSourceToggle: true
    },
    localState: {
        selectedEvent: null,
        showSourceEventModal: false,
        eventsToSelect: [],
        showStartupErrors: false,
        startupErrors: [],
        showLoginModal: false,
        showScriptVersionErrorModal: false,
        pauseLiveGraphs: false,
        sourcesLoading: false
    },
    statusLookupMap: {},
    sourceChannelInfo: {},
    channelTypeInfo: {},
    graphState: {
        sourceChannels: [],
        sourceDataTimespan: EmptyTimeSpan,
        webglChartIds: {},
        startTimeOffset: secondsToMicro(1 as Seconds),
        viewportWidth: secondsToMicro(5 as Seconds),
        refreshRate: '1hz',
        showExtraButtons: false
    },
    dataLookup: {
        error: '',
        sourceChannels: [],

        isLoading: false,
        isLoadingRMS: false,
        isDataLookup: false,

        enableRMS: true,
        previewWebGLChartIds: {},
        dataLookupWebGLChartIds: {}
    },

    sourceOccupancies: {},
    sourceStatuses: {},
    sourceActiveStatuses: {},
    sourceDataReceived: {},
    fftState: {
        time: moment(Date.now()),
        zoomLevel: 0,
        sourceChannelsMap: {}
    },
    debugState: {
        items: [],
        enabled: false
    },
    loginState: {
        tokenStatus: 'unknown',
        loginStatus: 'not-logged-in',
        isNewLogin: false,
        userProfile: {
            id: '',
            username: '',
            displayName: '',
            permissions: [],
            whitelistedSourceChannels: [],
            whitelistedSources: []
        }
    },

    eventRules: {
        eventRuleResult: {
            rules: [],
            fftRules: [],
            rmsRules: []
        },
        thresholds: {},
        fftThresholds: {},
        rmsThresholds: {}
    },
    sourceDataEnabledState: {},
    fftProcessedStore: {},
    timelineStateMap: {},
    createEventState: {
        show: false,
        sourceId: "unknown" as SourceId,
        sourceChannels: [],
        time: ZeroMicro
    },
    downloadCSVState: {
        isOpen: false,
        end: moment(),
        start: moment()
    },

    webglChartState: DefaultWebGLChartState,

    // Admin related states
    adminState: {
        showActiveUsers: false,
        showConfig: false,
        showCombineDataReceived: false,
        activeUsersSummary: '',
        configSummary: '',
        allSources: [],
        unknownSources: [],

        updateChannelTypeInfoState: emptyAdminModalState,
        updateSourceChannelInfoState: emptyAdminModalState,
        updateUserProfileState: emptyAdminModalState,
        updateSourceState: emptyAdminModalState,
        updateGroupState: emptyAdminModalState,
        userProfiles: [],

        reprocessEventsState: {
            isOpen: false,
            end: moment(),
            start: moment(),
            updateStatus: 'unknown'
        },
        eventReportsState: {
            isOpen: false,
            end: moment(),
            start: moment(),
        },
    }
};

// Load initial store from localStorage
let initialStore = defaultStore;
const currentServerState = localStorage.serverState;
if (currentServerState)
{
    const parsedServerState = JSON.parse(currentServerState);
    const newServerState = {...initialStore.serverState};
    for (const prop in parsedServerState)
    {
        (newServerState as any)[prop] = parsedServerState[prop];
    }
    newServerState.autoResetYZoomOnSourceToggle = true; // XXX hack to force enable auto Y scaling
    
    initialStore = {...initialStore, serverState: newServerState};
}

const existingStore = (window as any).__monitoringStore;
if (existingStore)
{
    (existingStore as DataStore<State>).unsubscribeAll();
}

// Create store
const monitoringStore = new DataStore<State>(initialStore);
(window as any).__monitoringStore = monitoringStore;

function saveStateSelector(state: State)
{
    return state.serverState;
}

function saveStateToLocalStorage(state: State, serverState: ServerState)
{
    localStorage.serverState = JSON.stringify(serverState);
}
// Save to localStorage on serverState change
monitoringStore.subscribe(saveStateSelector, saveStateToLocalStorage, undefined, 'saveStateToLocalStorage');

export default monitoringStore;
