import React from 'react';
import SourceDetailedInfoModal from '../sourceDetailedInfoModal';
import SourceInfosView from '../sourceInfosView';
import BaseTab from './baseTab';

export default class SourceInfoTab extends BaseTab
{
    public render()
    {
        const state = this.props.state;
        
        const currentStatusLookup = state.localState.showDetailedSourceInfo ?
            state.statusLookupMap[state.localState.showDetailedSourceInfo.id] : null;

        return (<>
            <SourceInfosView
                sources={state.sources}
                sourceStatuses={state.sourceStatuses}
                sourceActiveStatuses={state.sourceActiveStatuses} />

            <SourceDetailedInfoModal
                source={state.localState.showDetailedSourceInfo}
                statusLookup={currentStatusLookup} />
        </>);
    }
}