import {Modifier} from "simple-data-store";
import {compareStrings} from "../utils/utils";
import {Group, GroupId, State} from "./monitoringStoreStates";

export default class SetGroupList
{
    public static action (groups: Group[]): Modifier<State>
    {
        return (state: State) =>
        {
            groups = [...groups];
            groups.sort((x, y) =>
            {
                if (x.sortOrder === y.sortOrder)
                {
                    return compareStrings(x.name, y.name);
                }

                return y.sortOrder - x.sortOrder;
            });

            return {groups};
        }
    }

    public static updateGroup (group: Group): Modifier<State>
    {
        return (state: State) =>
        {
            const groups = [...state.groups];
            const existingIndex = groups.findIndex(g => g.id === group.id);
            if (existingIndex < 0)
            {
                groups.push(group);
            }
            else
            {
                groups[existingIndex] = group;
            }

            return {groups};
        }
    }

    public static removeGroup (groupId: GroupId): Modifier<State>
    {
        return (state: State) =>
        {
            const existingIndex = state.groups.findIndex(g => g.id === groupId);
            if (existingIndex < 0)
            {
                return null;
            }

            const groups = [...state.groups];
            groups.splice(existingIndex, 1);

            return {groups};
        }
    }
}