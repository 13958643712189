import React from 'react';
import SetUpdateChannelTypeInfo from '../../store/admin/setUpdateChannelTypeInfoState';
import monitoringStore from '../../store/monitoringStore';
import {ChannelTypeInfo} from '../../store/monitoringStoreStates';

interface Props
{
    channelTypeInfo: ChannelTypeInfo;
}

export default class ChannelTypeInfoView extends React.PureComponent<Props>
{
    public render()
    {
        const info = this.props.channelTypeInfo;

        return (
           <tr>
               <td><strong>{info.displayName}</strong></td>
               <td>{info.channelType}</td>
               <td>{info.chartTitle}</td>
               <td>{info.prefix}</td>
               <td>{info.chartPriority}</td>
               <td><button onClick={this.showUpdateModal}>Edit</button></td>
           </tr>
       )
    }

    private showUpdateModal = () =>
    {
        monitoringStore.execute(SetUpdateChannelTypeInfo.showUpdate(this.props.channelTypeInfo));
    }
}