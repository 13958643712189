import React from 'react';
import {Api} from '../api/api';
import {router} from '../router';
import {Group, GroupId, LoginState, ServerState} from '../store/monitoringStoreStates';
import GroupsView from './groupsView';
import Header, {Props as HeaderProps} from './header';

interface Props extends HeaderProps
{
    readonly serverState: ServerState;
    readonly groups: Group[];
    readonly loginState: LoginState;
}

export default class HeaderMain extends React.PureComponent<Props>
{
    public render ()
    {
        const { serverState, groups, loginState } = this.props;
        return <Header {...this.props}>
                <GroupsView selectedGroupId={serverState.selectedGroupId} groups={groups} onGroupChange={this.onGroupChange} />

                { loginState.loginStatus === 'logged-in' ?
                    <button data-rh="Logout of session" className="header__user" onClick={this.onClickUser}>{loginState.userProfile.displayName}</button>
                     : null
                }
        </Header>;
    }

    private onGroupChange = (id: GroupId) =>
    {
        router.changeGroup(id);
    }

    private onClickUser = () =>
    {
        if (confirm("Logout?"))
        {
            Api.logout();
        }
    }
}