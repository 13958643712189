import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {SourceEvent, SourceEventMap, State} from "./monitoringStoreStates";

export default class SetSourceEvent
{
    public static action (sourceEvent: SourceEvent): Modifier<State>
    {
        return (state: State) =>
        {
            const currentSourceMap = state.sourceEvents[sourceEvent.sourceId];
            if (currentSourceMap == null)
            {
                console.error('Attempting to update source event for a source we do not know about');
                return null;
            }

            const newSourceMap: Editable<SourceEventMap> = {...currentSourceMap};
            if (sourceEvent.status === 'dismissed')
            {
                delete newSourceMap[sourceEvent.id];
            }
            else
            {
                newSourceMap[sourceEvent.id] = sourceEvent;
            }

            const newSourceToEventsMap = {...state.sourceEvents, [sourceEvent.sourceId]: newSourceMap};

            return {sourceEvents: newSourceToEventsMap};
        }
    }
}