import {Modifier} from "simple-data-store";
import {modifyAdminState} from "../monitoringStore";
import {State} from "../monitoringStoreStates";

export default class SetServerConfig
{
    public static update (summary: string): Modifier<State>
    {
        return (state: State) => modifyAdminState(state, {configSummary: summary});
    }

    public static show (): Modifier<State>
    {
        return (state: State) => modifyAdminState(state, {showConfig: true});
    }

    public static close (): Modifier<State>
    {
        return (state: State) => modifyAdminState(state, {showConfig: false});
    }
}