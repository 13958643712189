import React, {CSSProperties} from 'react';
import {SourceDataEnabledState} from '../../store/monitoringStoreStates';
import {WebGLMouseChartPoint, WebGLTimeRange, WebGLValueRange} from './webglChartStore';

interface Props
{
    readonly timeViewport: WebGLTimeRange;
    readonly valueViewport: WebGLValueRange;

    readonly chartPoints: WebGLMouseChartPoint[];
    readonly sourceDataEnabled: SourceDataEnabledState;
}

export default class WebGLMouseChartPoints extends React.PureComponent<Props>
{
    public render()
    {
        if (!this.props.chartPoints || this.props.chartPoints.length === 0)
        {
            return null;
        }

        return <div className='webgl-chart__mouse-chart-points'>
            { this.renderPoints() }
        </div>
    }

    private renderPoints(): JSX.Element[]
    {
        const { timeViewport, valueViewport, chartPoints, sourceDataEnabled } = this.props;
        const result: JSX.Element[] = [];

        for (const chartPoint of chartPoints)
        {
            if (sourceDataEnabled[chartPoint.dataSeries.sourceChannelId] === false)
            {
                continue;
            }

            const { time, value } = chartPoint;
            if (time < timeViewport.minTime || time > timeViewport.maxTime ||
                value < valueViewport.minValue || value > valueViewport.maxValue)
            {
                continue;
            }

            const percentX = (time - timeViewport.minTime) / timeViewport.width;
            const percentY = (value - valueViewport.minValue) / valueViewport.height;

            const style: CSSProperties = {
                left: `${percentX * 100}%`,
                top: `${(1 - percentY) * 100}%`,
                backgroundColor: chartPoint.colour
            }

            const key = `${chartPoint.chartId}_${chartPoint.dataSeries.sourceChannelId}_${chartPoint.pointKey}`;
            result.push(<div key={key} style={style}></div>);
        }

        return result;
    }
}