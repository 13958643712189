import React from 'react';
import '../icons.scss';
import {Source, SourceActiveStatus, SourceEventMap, SourceOccupied, SourceStatus} from '../store/monitoringStoreStates';
import {dateToMicro, Seconds, secondsToMicro} from '../utils/timeTypes';
import {isEmptyObject} from '../utils/utils';
import Icon from './icon';
import SourceEventView from './sourceEventView';
import './sourceView.scss';

interface Props
{
    readonly source: Source;
    readonly sourceEvents: SourceEventMap;
    readonly occupied: SourceOccupied;
    readonly status: SourceStatus;
    readonly activeStatus: SourceActiveStatus;
}
interface State
{
    readonly noHeartbeat: boolean;
    readonly noData: boolean;
}

function getNewState (props: Props, prevState: State): Partial<State>
{
    const { activeStatus } = props;

    const now = dateToMicro(new Date());
    const deltaHeartbeat = now - activeStatus.lastHeartbeatTime;
    const deltaData = now - activeStatus.lastDataReceivedTime;

    const noHeartbeat = deltaHeartbeat > secondsToMicro(120 as Seconds);
    const noData = deltaData > secondsToMicro(30 as Seconds);
    let newState: Partial<State> = {};

    if (prevState.noData !== noData || prevState.noHeartbeat !== noHeartbeat)
    {
         newState = {noData, noHeartbeat};
    }

    return newState;
}

export default class SourceView extends React.PureComponent<Props, State>
{
    public static getDerivedStateFromProps (nextProps: Props, prevState: State)
    {
        return getNewState(nextProps, prevState);
    }

    private stateChangeInterval: number;

    constructor (props: Props)
    {
        super(props);

        this.state = {
            noData: true,
            noHeartbeat: true
        };
    }

    public componentDidMount ()
    {
        this.stateChangeInterval = window.setInterval(() =>
        {
            const newState = getNewState(this.props, this.state);
            if (!isEmptyObject(newState))
            {
                this.setState(newState as State);
            }
        }, 5000);
    }

    public componentWillUnmount ()
    {
        clearInterval(this.stateChangeInterval);
    }

    public render ()
    {
        const { source, status, occupied, activeStatus } = this.props;

        const activeStr = activeStatus.isWebsocketConnected ? 'active' : 'inactive';
        const occupiedStr = occupied.status;
        const occupiedTooltip = `The track around this signal box is ${occupied.status} as of ${occupied.timestamp}`;
        const onFire = status.systemLoadInfo.coreTemp >= 60;

        let nameTitle = `Location: ${source.location} (${source.sourceType})`;
        if (activeStatus.isWebsocketConnected)
        {
            nameTitle += ', this signal box is active.';
        }
        else
        {
            nameTitle += ', this signal box is not active!';
        }

        return (
            <div className="source">
                <span data-rh={nameTitle} className={`source__name is--${activeStr} ${onFire ? 'is--highlighted-fire' : ''}`}>
                    <strong>{source.name}</strong>
                    { this.state.noHeartbeat && <Icon type='no-heartbeat' data-rh='No heart beat signal received from this box in 2 minutes.' /> }

                    { this.state.noData && <Icon type='no-data-signal' data-rh='No signal data received from this box in 30 seconds.' /> }
                    { onFire && <Icon type='fire' data-rh='The core temperature is over 60 degrees.' /> }
                </span>

                { source.sourceType === 'signalbox' &&
                <span className={`source__occupied is--${occupiedStr}`} data-rh={occupiedTooltip}>
                    {occupiedStr}
                </span> }

                <div className="source__events">
                    { this.renderSourceEvents() }
                </div>
            </div>
            );
    }

    private renderSourceEvents (): JSX.Element[]
    {
        const result: JSX.Element[] = [];

        const events = Object.values(this.props.sourceEvents).filter(e => e.status !== 'dismissed');

        for (let i = Math.max(0, events.length - 100); i < events.length; i++)
        {
            const sourceEvent = events[i];
            result.push(<SourceEventView key={sourceEvent.id} sourceEvent={sourceEvent} />);
        }

        if (events.length > 100)
        {
            result.push(<div key='too-many-events'>
                <strong>There are {events.length} active events in total, only showing the 100 most recent.</strong>
            </div>)
        }

        return result;
    }
}
