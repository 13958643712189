// Taken from https://stackoverflow.com/a/8486188 and then ported to Typescript and cleaned up a bit.
export interface QueryObject { [key: string]: any; }
export function parseUrlQuery (query: string): QueryObject
{
    const result: QueryObject = {};
    const split = query.split('&');
    for (let part of split)
    {
        if (!part)
        {
            continue;
        }

        part = part.split('+').join(' '); // replace every + with space, regexp-free version

        const eq = part.indexOf('=');
        let key = eq > -1 ? part.substr(0, eq) : part;
        const val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : '';
        const from = key.indexOf('[');

        if (from === -1)
        {
            result[decodeURIComponent(key)] = val;
        }
        else
        {
            const to = key.indexOf(']', from);
            const index = decodeURIComponent(key.substring(from + 1, to));
            key = decodeURIComponent(key.substring(0, from));

            const resultList = result[key] || (result[key] = []);
            if (!index)
            {
                resultList.push(val);
            }
            else
            {
                resultList[index] = val;
            }
        }
    }

    return result;
}

export function createUrlQuery(obj: QueryObject): string
{
    let result = '';

    for (const prop in obj)
    {
        const value = obj[prop];
        if (typeof(value) === 'object')
        {
            throw new Error('Query with object value not supported');
        }

        if (result.length > 0)
        {
            result += '&';
        }

        result += encodeURIComponent(prop);
        result += '=';
        result += encodeURIComponent(value);
    }

    return result;
}
