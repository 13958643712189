import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {SourceEvent, SourceEventMap, SourceId, State} from "./monitoringStoreStates";

export default class SetSourceEvents
{
    public static action (sourceId: SourceId, sourceEvents: SourceEvent[]): Modifier<State>
    {
        return (state: State) =>
        {
            const currentSourceMap = state.sourceEvents[sourceId];
            if (currentSourceMap == null)
            {
                console.error('Attempting to update source event for a source we do not know about');
                return null;
            }

            const newSourceMap: Editable<SourceEventMap> = {};
            for (const sourceEvent of sourceEvents)
            {
                newSourceMap[sourceEvent.id] = sourceEvent;
            }

            const newSourceToEventsMap = {...state.sourceEvents, [sourceId]: newSourceMap};

            return {sourceEvents: newSourceToEventsMap};
        }
    }
}