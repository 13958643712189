import {Modifier} from "simple-data-store";
import {SourceOccupied, State} from "./monitoringStoreStates";

export default class SetSourceOccupied
{
    public static action (sourceOccupied: SourceOccupied): Modifier<State>
    {
        return (state: State) =>
        {
            const newSourceOccupancies = {...state.sourceOccupancies, [sourceOccupied.sourceId]: sourceOccupied};

            return {sourceOccupancies: newSourceOccupancies};
        }
    }
}