import React from 'react';
import {MicroSeconds} from '../../utils/timeTypes';
import WebGLBaseReactChart, {BaseProps} from './webglBaseReactChart';
import WebGLChartBottomAxis from './webglChartBottomAxis';
import WebGLChartCanvas from './webglChartCanvas';
import WebGLChartLeftAxis from './webglChartLeftAxis';
import WebGLChartSelection from './webglChartSelection';
import WebGLLineChart from './webglLineChart';

export default class WebGLChartPreview extends WebGLBaseReactChart<BaseProps>
{
    public render()
    {
        const { timeViewport, timeSelection, chartState, valueSelection, valueViewport, sourceDataEnabled } = this.props;
        const { originalValueViewport, originalTimeViewport, leftAxis } = chartState;

        // This sets if the selection box has selector handles on it.
        // If we're currently doing a selection then we set it to null.
        const reselectableParent = !!timeSelection ? null : this;

        return <div className="webgl-chart is--preview">
            <div className="webgl-chart__body">
                <WebGLChartLeftAxis valueViewport={originalValueViewport} axis={leftAxis} getCanvasWidth={this.getCanvasWidth} />

                <div className='webgl-chart__canvas-holder' onMouseDown={this.onCanvasMouseDown} onTouchStart={this.onCanvasTouchStart}>
                    <WebGLChartCanvas ref={this.canvasRef} chartState={chartState} timeViewport={timeViewport} valueViewport={valueViewport} sourceDataEnabled={sourceDataEnabled} onWebGLRender={this.doWebGLRender} />

                    <WebGLChartSelection timeViewport={originalTimeViewport} timeSelect={timeSelection} enableTimeSelect={true} enableValueSelect={true} valueSelect={valueSelection} valueViewport={originalValueViewport} />

                    <WebGLChartSelection timeViewport={originalTimeViewport} timeSelect={timeViewport} enableTimeSelect={true} enableValueSelect={true} valueSelect={valueViewport} valueViewport={originalValueViewport} reselectableParent={reselectableParent} />
                </div>
            </div>
            <div className="webgl-chart__footer has--no-label">
                <div className="webgl-chart__left-axis-bumper"></div>
                <WebGLChartBottomAxis timeViewport={originalTimeViewport} enableAxis={false} />
            </div>
        </div>;
    }

    private doWebGLRender = (webgl: WebGLLineChart) =>
    {
        const { originalTimeViewport, originalValueViewport, minStartTime } = this.props.chartState;
        webgl.render(originalTimeViewport, originalValueViewport, minStartTime, this.props.chartState.id);
    }

    protected getTimeAtLocation(percentX: number): MicroSeconds
    {
        const timeViewport = this.props.chartState.originalTimeViewport;
        return (percentX * timeViewport.width + timeViewport.minTime) as MicroSeconds;
    }

    protected getValueAtLocation(percentY: number)
    {
        const valueViewport = this.props.chartState.originalValueViewport;
        return (1 - percentY) * valueViewport.height + valueViewport.minValue;
    }
}