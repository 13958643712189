import moment = require("moment");

export interface ZoomLevelInfo
{
    tileWidth: number;
    timePerTile: moment.Duration;
    zoomValue: number;
}

export const zoomLevelInfos: ZoomLevelInfo[] = [
    {
        tileWidth: 120,
        timePerTile: moment.duration(1, 'minute'),
        zoomValue: 1
    },
    {
        tileWidth: 120,
        timePerTile: moment.duration(5, 'minute'),
        zoomValue: 5
    },
    {
        tileWidth: 120,
        timePerTile: moment.duration(20, 'minute'),
        zoomValue: 20
    },
    {
        tileWidth: 120,
        timePerTile: moment.duration(60, 'minute'),
        zoomValue: 60
    }
];
