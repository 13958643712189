import React from 'react';
import './loadingBar.scss';

interface Props
{
    show: boolean;
}

export default class LoadingBar extends React.PureComponent<Props>
{
    public render ()
    {
        return <span className="loading-bar">
            <span className={`loading-bar__graphic inc ${this.props.show ? 'show' : ''}`}></span>
            <span className={`loading-bar__graphic dec ${this.props.show ? 'show' : ''}`}></span>
        </span>;
    }
}
