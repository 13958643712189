import React, {CSSProperties} from 'react';
import SetUpdateSourceChannelInfo from '../../store/admin/setUpdateSourceChannelInfoState';
import monitoringStore from '../../store/monitoringStore';
import {SourceChannelInfoBase} from '../../store/monitoringStoreStates';
import './sourceChannelInfoView.scss';

interface Props
{
    sourceChannelInfo: SourceChannelInfoBase;
}

export default class SourceChannelInfoView extends React.PureComponent<Props>
{
    public render()
    {
        const info = this.props.sourceChannelInfo;
        const colourStyle: CSSProperties = {
            backgroundColor: info.mainColour
        }

        return (
           <tr>
               <td><strong>{info.mainName} {info.subscriptName}</strong></td>
               <td>{info.sourceChannel}</td>
               <td>{info.channelType}</td>
               <td><span className='source-channel-info__main-colour' style={colourStyle}></span></td>
               <td><button onClick={this.showUpdateModal}>Edit</button></td>
           </tr>
       )
    }

    private showUpdateModal = () =>
    {
        monitoringStore.execute(SetUpdateSourceChannelInfo.showUpdate(this.props.sourceChannelInfo));
    }
}