import moment = require("moment");
import {MilliSeconds} from "../../utils/timeTypes";

export type ZoomLevelType = 'second' | '10second' | 'minute' | '5minute' | 'hour' | 'day' | 'month';
export type RoundingTime = moment.unitOfTime.StartOf | '5minute' | '10second';
type Time = moment.Moment | MilliSeconds;

export type TimelineSelectionHandler = (start: moment.Moment, end: moment.Moment) => void;
export type TimelineViewChangeHandler = (centerTime: moment.Moment, timePerPixel: moment.Duration) => void;

export interface TimelineEvent
{
    title: string;
    start: moment.Moment;
    end: moment.Moment;
}
export interface TimelineGroup
{
    name: string;
    id: string | number;
}
export interface TimelineEventMap { [groupId: string]: TimelineEvent[]; }

export interface TimelineSelect
{
    start: moment.Moment;
    end: moment.Moment;
    enabled: boolean;
}
export interface TimelineState
{
    readonly selection: TimelineSelect;
    readonly centerTime: moment.Moment;
    readonly timePerPixel: moment.Duration;
    readonly loading: boolean;
}

export interface TimelineZoomLevelInfo
{
    readonly displayName: string;
    readonly lowerFormat: string;
    readonly upperFormat: string;
    readonly timePerPixel: moment.Duration;
    readonly upperRound: RoundingTime;
    readonly lowerRound: RoundingTime;
}

export const MaxTimePerPixel = moment.duration(18, 'hours');
export const MinTimePerPixel = moment.duration(10, 'milliseconds');

export const EmptyTimelineSelect: TimelineSelect = {
    start: moment(),
    end: moment(),
    enabled: false
}

const zoomLevelInfos: { [key: string]: TimelineZoomLevelInfo } = {
    'second': {
        lowerFormat: 'HH:mm:ss',
        upperFormat: 'YYYY-MM-DD dddd HH:mm:ss',
        displayName: 'Second',
        upperRound: 'minute',
        lowerRound: 'second',
        timePerPixel: moment.duration(1 / 140, 'second'),
    },
    '10second': {
        lowerFormat: 'HH:mm:ss',
        upperFormat: 'YYYY-MM-DD dddd HH:mm:ss',
        displayName: 'Second',
        upperRound: '5minute',
        lowerRound: '10second',
        timePerPixel: moment.duration(1 / 15, 'second'),
    },
    'minute': {
        lowerFormat: 'HH:mm',
        upperFormat: 'YYYY-MM-DD dddd HH:mm:ss',
        displayName: 'Minute',
        upperRound: 'hour',
        lowerRound: 'minute',
        timePerPixel: moment.duration(1 / 95, 'minute')
    },
    '5minute': {
        lowerFormat: 'HH:mm',
        upperFormat: 'YYYY-MM-DD dddd HH:mm:ss',
        displayName: 'Minute',
        upperRound: 'hour',
        lowerRound: '5minute',
        timePerPixel: moment.duration(1 / 20, 'minute')
    },
    'hour': {
        lowerFormat: 'HH[h]',
        upperFormat: 'YYYY-MM-DD dddd',
        displayName: 'Hour',
        upperRound: 'day',
        lowerRound: 'hour',
        timePerPixel: moment.duration(1 / 80, 'hour')
    },
    'day': {
        lowerFormat: 'DD',
        upperFormat: 'YYYY-MM-DD dddd',
        displayName: 'Day',
        upperRound: 'month',
        lowerRound: 'day',
        timePerPixel: moment.duration(1 / 55, 'day')
    },
    'month': {
        lowerFormat: 'MMM',
        upperFormat: 'YYYY',
        displayName: 'Month',
        upperRound: 'year',
        lowerRound: 'month',
        timePerPixel: moment.duration(1 / 4, 'day')
    }
};

export const EmptyTimelineState: TimelineState = {
    centerTime: moment(new Date()),
    timePerPixel: getZoomInfo('hour').timePerPixel,
    selection: EmptyTimelineSelect,
    loading: false
}

export class RenderContext
{
    readonly leftMs: MilliSeconds;
    readonly rightMs: MilliSeconds;
    readonly centerMs: MilliSeconds;
    readonly timePerPixelMs: MilliSeconds;
    readonly zoomLevelInfo: TimelineZoomLevelInfo;
    readonly bounds: ClientRect;
    readonly eventsBounds: ClientRect;
    readonly buttonGroupBounds: ClientRect;
    readonly hasSourceEvents: boolean;

    readonly isEmpty: boolean;

    constructor(bounds: ClientRect, eventsBounds: ClientRect, buttonGroupBounds: ClientRect, centerTime: moment.Moment, timePerPixel: moment.Duration, hasSourceEvents: boolean)
    {
        this.bounds = bounds;
        this.eventsBounds = eventsBounds;
        this.buttonGroupBounds = buttonGroupBounds;

        this.centerMs = centerTime.valueOf() as MilliSeconds;
        this.timePerPixelMs = timePerPixel.asMilliseconds() as MilliSeconds;

        this.leftMs = (this.centerMs - this.timePerPixelMs * 0.5 * eventsBounds.width) as MilliSeconds;
        this.rightMs = this.centerMs + (this.centerMs - this.leftMs) as MilliSeconds;

        this.zoomLevelInfo = findZoomLevel(this.timePerPixelMs);
        this.hasSourceEvents = hasSourceEvents;

        this.isEmpty = bounds.width <= 0;
    }

    public calculateViewFromStartEnd(startTime: Time, endTime: Time)
    {
        startTime = getMs(startTime);
        endTime = getMs(endTime);

        const timeWidth = endTime - startTime;
        const centerTime = moment(new Date((startTime + endTime) / 2));
        const timePerPixel = Math.max(timeWidth / this.eventsBounds.width, MinTimePerPixel.asMilliseconds());

        return {centerTime, timePerPixel};
    }

    public calculateXposFromTime(time: Time, clamp: boolean): number
    {
        time = getMs(time);

        const diff = time - this.leftMs;
        let result = diff / this.timePerPixelMs;
        if (clamp)
        {
            result = Math.max(-1, result);
            result = Math.min(this.eventsBounds.width + 1, result);
        }
        return Math.round(result);
    }

    public getTimeAtPosition(xpos: number): moment.Moment
    {
        const time = this.timePerPixelMs * xpos + this.leftMs;
        return moment(new Date(time));
    }

    public isTimeInView(time: MilliSeconds)
    {
        return time < this.leftMs || time > this.rightMs;
    }

    public isClientXInView(clientX: number)
    {
        return clientX >= this.eventsBounds.left && clientX <= this.eventsBounds.right;
    }

    public getRelativeXpos(clientX: number)
    {
        return clientX - this.eventsBounds.left;
    }

}

const EmptyBounds: ClientRect = {bottom: 0, left: 0, top: 0, right: 0, width: 0, height: 0};
export const EmptyRenderContext = new RenderContext(EmptyBounds, EmptyBounds, EmptyBounds, moment(), moment.duration(0), false);

export function getZoomInfo(zoomLevel: ZoomLevelType)
{
    return zoomLevelInfos[zoomLevel];
}

export function endOf(time: moment.Moment, rounding: RoundingTime)
{
    time = time.clone();
    if (rounding === '5minute')
    {
        time = time.endOf('minute');
        const extraMinutes = time.get('minute') % 5;
        return time.add((4 - extraMinutes), 'minutes');
    }
    else if (rounding === '10second')
    {
        time = time.endOf('second');
        const extraSeconds = time.get('second') % 10;
        return time.add((9 - extraSeconds), 'seconds');

    }

    return time.endOf(rounding);
}

export function startOf(time: moment.Moment, rounding: RoundingTime)
{
    time = time.clone();
    if (rounding === '5minute')
    {
        time = time.startOf('minute');
        const extraMinutes = time.get('minute') % 5;
        return time.subtract(extraMinutes, 'minutes');
    }
    else if (rounding === '10second')
    {
        time = time.startOf('second');
        const extraSeconds = time.get('second') % 10;
        return time.subtract(extraSeconds, 'second');
    }

    return time.startOf(rounding);
}

export function findZoomLevel(timePerPixel: MilliSeconds)
{
    let lowestFound = getZoomInfo('month');

    for (const prop in zoomLevelInfos)
    {
        const zoomLevelInfo = zoomLevelInfos[prop];
        const threshold = zoomLevelInfo.timePerPixel.asMilliseconds() * 2;
        if (timePerPixel < threshold && threshold < lowestFound.timePerPixel.asMilliseconds())
        {
            lowestFound = zoomLevelInfo;
        }
    }

    return lowestFound;
}

export function findZoomLevelAbove(timePerPixel: MilliSeconds)
{
    const lowestFound = findZoomLevel(timePerPixel);
    let smallestDiffProp: ZoomLevelType = 'month';
    let smallestDiff = zoomLevelInfos[smallestDiffProp];

    for(const prop in zoomLevelInfos)
    {
        const zoomLevelInfo = zoomLevelInfos[prop];
        if (zoomLevelInfo.timePerPixel.asMilliseconds() > lowestFound.timePerPixel.asMilliseconds() &&
            zoomLevelInfo.timePerPixel.asMilliseconds() < smallestDiff.timePerPixel.asMilliseconds())
        {
            smallestDiff = zoomLevelInfo;
            smallestDiffProp = prop as ZoomLevelType;
        }
    }

    return { info: smallestDiff, prop: smallestDiffProp };
}

export function findZoomLevelBelow(timePerPixel: MilliSeconds)
{
    const lowestFound = findZoomLevel(timePerPixel);
    let smallestDiffProp: ZoomLevelType = 'second';
    let smallestDiff = zoomLevelInfos[smallestDiffProp];

    for(const prop in zoomLevelInfos)
    {
        const zoomLevelInfo = zoomLevelInfos[prop];
        if (zoomLevelInfo.timePerPixel.asMilliseconds() < lowestFound.timePerPixel.asMilliseconds() &&
            zoomLevelInfo.timePerPixel.asMilliseconds() > smallestDiff.timePerPixel.asMilliseconds())
        {
            smallestDiff = zoomLevelInfo;
            smallestDiffProp = prop as ZoomLevelType;
        }
    }

    return { info: smallestDiff, prop: smallestDiffProp };
}

export function canZoomOut(zoomLevelInfo: TimelineZoomLevelInfo)
{
    const timePerPixelMs = zoomLevelInfo.timePerPixel.asMilliseconds();
    for (const prop in zoomLevelInfos)
    {
        const zoomLevelInfo = zoomLevelInfos[prop];
        const threshold = zoomLevelInfo.timePerPixel.asMilliseconds();
        if (timePerPixelMs < threshold)
        {
            return true;
        }
    }

    return false;
}

export function canZoomIn(zoomLevelInfo: TimelineZoomLevelInfo)
{
    const timePerPixelMs = zoomLevelInfo.timePerPixel.asMilliseconds();
    for (const prop in zoomLevelInfos)
    {
        const zoomLevelInfo = zoomLevelInfos[prop];
        const threshold = zoomLevelInfo.timePerPixel.asMilliseconds();
        if (timePerPixelMs > threshold)
        {
            return true;
        }
    }

    return false;
}

function getMs(time: Time): MilliSeconds
{
    if (moment.isMoment(time))
    {
        return time.valueOf() as MilliSeconds;
    }

    return time;
}
