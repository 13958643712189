import React from 'react';
import {SourceChannelId, SourceDataEnabledState} from '../../store/monitoringStoreStates';
import {SourceChannelInfoManager} from '../../store/sourceChannelInfo';
import {formatSIValue, getSIPrefix, SIPrefix} from '../../utils/siTypes';
import {distinct, groupBy, RMSText} from '../../utils/utils';
import {WebGLChartState, WebGLMouseChartPoint} from './webglChartStore';
import {toStringColour, calcLegendPriority} from './webglUtils';

interface Props
{
    readonly chartState: WebGLChartState;
    readonly chartPoints: WebGLMouseChartPoint[];
    readonly sourceDataEnabled: SourceDataEnabledState;
}

interface LegendProps
{
    readonly point?: WebGLMouseChartPoint;
    readonly prefix?: string;
    readonly suffix: string;
    readonly colour: string;
    readonly siPrefix?: SIPrefix;
}

class Legend extends React.PureComponent<LegendProps>
{
    public render()
    {
        const { colour, point, prefix, suffix, siPrefix } = this.props;
        const value = point ? `${formatSIValue(siPrefix, point.value)} ${siPrefix || ''}${suffix}` : '';

        return <div>
            <span className='webgl-chart__bottom-legend-colour' style={{backgroundColor: colour}} />
            {prefix ? <span className='webgl-chart__bottom-legend-prefix'> <strong>{prefix}</strong> </span> : ''}
            <span className='webgl-chart__bottom-legend-value'> {value} </span>
        </div>
    }
}

export default class WebGLBottomLegend extends React.PureComponent<Props>
{
    public render()
    {
        return <div className='webgl-chart__bottom-legends'>
            { this.renderLegends() }
        </div>
    }

    private renderEmptyLegends() : JSX.Element[]
    {
        const { chartState, sourceDataEnabled } = this.props;

        const result: JSX.Element[] = [];
        const axis = chartState.leftAxis;
        const unitSuffix = axis.unit;
        // const unitSuffix = axis.isRMS ? `${axis.unit}${RMSText}` : axis.unit;

        const grouped = groupBy(chartState.dataSeries, (ds) => ds.sourceChannelId);

        for (const sourceChannelId in grouped)
        {
            if (sourceDataEnabled[sourceChannelId] === false)
            {
                continue;
            }

            const sourceChannel = SourceChannelInfoManager.get(sourceChannelId as SourceChannelId);
            const group = grouped[sourceChannelId];
            let unique = distinct(group, ds => ds.title);
            unique.sort(
                (a, b) => (calcLegendPriority(a.prefix) - calcLegendPriority(b.prefix))
            )

            const values: JSX.Element[] = [];
            for (let i = 0; i < unique.length; i++)
            {
                const dataSeries = unique[i];

                let prefix = dataSeries.prefix;
                let colour = toStringColour(dataSeries.colour);
                if (!prefix && dataSeries.type === 'minmax')
                {
                    for (let j = 0; j < 3; j++)
                    {
                        prefix = j === 0 ? 'Mean' : (j === 1 ? 'Max' : 'Min');
                        colour = j === 0 ? colour : toStringColour(sourceChannel.minMaxColour.unitArray());
                        values.push(<Legend key={dataSeries.title + prefix} colour={colour} prefix={prefix} suffix={unitSuffix} />);
                    }

                    continue;
                }

                values.push(<Legend key={dataSeries.title} colour={colour} prefix={prefix} suffix={unitSuffix} />);
            }

            result.push(<span key={sourceChannelId} className='webgl-chart__bottom-legend'>
                <strong>{sourceChannel.nameSubscript}</strong>{values}
            </span>);
        }

        return result;
    }

    private renderLegends() : JSX.Element[]
    {
        const { chartState, chartPoints, sourceDataEnabled } = this.props;

        const result: JSX.Element[] = [];

        if (!chartPoints || chartPoints.length === 0)
        {
            return this.renderEmptyLegends();
        }

        const axis = chartState.leftAxis;
        const unitSuffix = axis.unit;
        // const unitSuffix = axis.isRMS ? `${axis.unit}${RMSText}` : axis.unit;

        const grouped = groupBy(chartPoints, (cp) => cp.dataSeries.sourceChannelId);

        for (const sourceChannelId in grouped)
        {
            if (sourceDataEnabled[sourceChannelId] === false)
            {
                continue;
            }

            const sourceChannel = SourceChannelInfoManager.get(sourceChannelId as SourceChannelId);
            let group = grouped[sourceChannelId];
            group.sort(
                (a, b) => (calcLegendPriority(a.dataSeries.prefix) - calcLegendPriority(b.dataSeries.prefix))
            )

            const siPrefix: SIPrefix = getSIPrefix(...group.map(g => g.value));

            const values: JSX.Element[] = [];
            for (let i = 0; i < group.length; i++)
            {
                const point = group[i];

                let prefix = point.dataSeries.prefix;
                if (!prefix && point.dataSeries.type === 'minmax')
                {
                    prefix = i === 0 ? 'Mean' : (i === 1 ? 'Max' : 'Min');
                }

                values.push(<Legend siPrefix={siPrefix} key={point.pointKey} colour={point.colour} prefix={prefix} point={point} suffix={unitSuffix} />);
            }

            result.push(<span key={sourceChannelId} className='webgl-chart__bottom-legend'>
                <strong>{sourceChannel.nameSubscript}</strong>{values}
            </span>);
        }

        return result;
    }
}