import React from 'react';
import ButtonGroup from '../buttonGroup';
import Icon from '../icon';
import WebGLBaseReactChart, {BaseProps} from './webglBaseReactChart';
import WebGLBottomLegend from './webglBottomLegend';
import WebGLChartBottomAxis from './webglChartBottomAxis';
import WebGLChartCanvas from './webglChartCanvas';
import WebGLChartLeftAxis from './webglChartLeftAxis';
import WebGLChartSelection from './webglChartSelection';
import './webglChartStyles.scss';
import WebGLMouseChartPoints from './webglMouseChartPoints';

export default class WebGLChartFFT extends WebGLBaseReactChart<BaseProps>
{
    public render()
    {
        const { valueViewport, timeViewport, valueSelection, timeSelection, chartState, tooltip, mouseChartPoints, sourceDataEnabled, extraButtons, enableButtons } = this.props;

        return <div className="webgl-chart is--fft">
            <div className="webgl-chart__header">
                <div className="webgl-chart__left-axis-bumper"></div>
                <h2 className="webgl-chart__mini-title">FFT: {this.props.chartState.title}</h2>
            </div>
            <div className="webgl-chart__body">
                <div className="webgl-chart__body-buttons">
                    { enableButtons &&
                    <ButtonGroup>
                        <button onClick={this.autoYZoom} data-rh='Auto zoom Y axis'><Icon type='fullscreen-axis-y' forceTheme='dark' /></button>
                        { extraButtons }
                    </ButtonGroup> }
                </div>

                <WebGLChartLeftAxis valueViewport={valueViewport} axis={chartState.leftAxis} getCanvasWidth={this.getCanvasWidth} />

                <div className='webgl-chart__canvas-holder' onMouseDown={this.onCanvasMouseDown} onTouchStart={this.onCanvasTouchStart} data-rh-chart={tooltip} >
                    <WebGLChartCanvas ref={this.canvasRef} chartState={chartState} timeViewport={timeViewport} valueViewport={valueViewport} sourceDataEnabled={sourceDataEnabled} />

                    <WebGLMouseChartPoints timeViewport={timeViewport} valueViewport={valueViewport} chartPoints={mouseChartPoints} sourceDataEnabled={sourceDataEnabled} />

                    <WebGLChartSelection valueViewport={valueViewport} timeViewport={timeViewport} timeSelect={timeSelection} valueSelect={valueSelection} enableTimeSelect={chartState.enableTimeSelect} enableValueSelect={chartState.enableValueSelect} />
                </div>
            </div>
            <div className="webgl-chart__footer has--no-label">
                <div className="webgl-chart__left-axis-bumper"></div>
                <WebGLChartBottomAxis timeViewport={timeViewport} enableAxis={false} />
            </div>

            <WebGLBottomLegend chartState={chartState} chartPoints={mouseChartPoints} sourceDataEnabled={sourceDataEnabled} />
        </div>;
    }

    protected resetZoom = () =>
    {
        const { chartState } = this.props;
        this.props.onResetTimeViewport(chartState.id, chartState.timeSelectionId);

        if (this.props.onResetValueViewport)
        {
            this.props.onResetValueViewport(chartState.id, chartState.valueSelectionId);
        }
    }
}