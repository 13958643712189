import React, {CSSProperties} from 'react';
import {MilliSeconds} from '../../utils/timeTypes';

interface Props
{
    readonly boundaryTime: MilliSeconds;
    readonly url: string;
    readonly style: CSSProperties;
}

export default class ImageZoomTile extends React.PureComponent<Props>
{
    private timeout: number;
    private ref: React.RefObject<HTMLImageElement> = React.createRef();
    private errorHandler: EventListener = this.onError.bind(this);

    public componentDidMount()
    {
        this.ref.current.addEventListener('error', this.errorHandler);
    }

    public componentWillUnmount()
    {
        clearTimeout(this.timeout);
        this.ref.current.removeEventListener('error', this.errorHandler);
    }

    public render()
    {
        const { url, style } = this.props;
        return <img crossOrigin="anonymous" ref={this.ref} src={url} style={style} />
    }

    private onError(e: ErrorEvent)
    {
        clearTimeout(this.timeout);

        const target = e.target as HTMLImageElement;
        target.style.display = 'none';

        const timeoutTime = Math.max(this.props.boundaryTime - Date.now(), 30000);

        this.timeout = window.setTimeout(() =>
        {
            target.src = this.props.url + '?r=' + Math.random();
            target.style.display = 'block';
        }, timeoutTime);
    }
}