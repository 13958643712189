import {Modifier} from "simple-data-store";
import {modifyAdminState} from "../monitoringStore";
import {State, UserProfile} from "../monitoringStoreStates";

export default class SetUserProfiles
{
    public static action (userProfiles: UserProfile[]): Modifier<State>
    {
        return (state: State) => modifyAdminState(state, {userProfiles});
    }
}