import Color from "color";
import {ignoreValue} from "../components/lineChart/lineChartCommon";
import {WebGLDataSeries} from "../components/webglChart/webglChartStore";
import {createLineDataSeries} from "../components/webglChart/webglUtils";
import {Editable} from "../utils/commonTypes";
import {combineSourceDataSourcesWebGL} from "../utils/dataSourceUtils";
import {addMicro, MicroSeconds, secondsToMicro} from "../utils/timeTypes";
import {findSource} from "../utils/utils";
import monitoringStore from "./monitoringStore";
import {IHasColour, MinMaxValue, OnlineRmsFilterId, OnlineRmsPayload, OnlineRmsPreview, OnlineRmsPreviewLookupResult, OnlineRmsResult, OnlineRmsResultLookupMap, SourceChannelId, SourceChannelToWebGLDataSourceMap, SourceChannelToWebGLPreviewMap, SourceId, SourceToSourceChannelToWebGLDataSourceMap, SourceToSourceChannelToWebGLPreviewMap} from "./monitoringStoreStates";
import {SourceChannelInfoManager} from "./sourceChannelInfo";

export function processOnlineRmsResultWebGL(rmsResult: OnlineRmsResult): SourceChannelToWebGLDataSourceMap
{
    const channelDataSources: Editable<SourceChannelToWebGLDataSourceMap> = {};

    const payloadMap = rmsResult.payload;

    for (const sourceChannel in payloadMap)
    {
        const payload = payloadMap[sourceChannel];

        const sourceDataInfo = SourceChannelInfoManager.get(sourceChannel as SourceChannelId);
        const channelType = sourceDataInfo.base.channelType;
        const list = (channelDataSources[channelType] || (channelDataSources[channelType] = []));

        for (const filterId in payload.data)
        {
            addOnlineRmsResultWebGL(rmsResult, payload, sourceChannel as SourceChannelId, filterId as OnlineRmsFilterId, list, false);
        }
    }

    return channelDataSources;
}


export function processOnlineRmsResultsWebGL(rmsResults: OnlineRmsResultLookupMap, trimLengths: boolean, trimCutoffTime: MicroSeconds): SourceToSourceChannelToWebGLDataSourceMap
{
    const dataSources: Editable<SourceToSourceChannelToWebGLDataSourceMap> = {};

    for (const sourceIdString in rmsResults)
    {
        const rmsResultList = rmsResults[sourceIdString];
        const channelDataSources: Editable<SourceChannelToWebGLDataSourceMap> = {};
        dataSources[sourceIdString] = channelDataSources;

        for (const rmsResult of rmsResultList)
        {
            const payloadMap = rmsResult.payload;

            for (const sourceChannel in payloadMap)
            {
                const payload = payloadMap[sourceChannel];

                const sourceDataInfo = SourceChannelInfoManager.get(sourceChannel as SourceChannelId);
                const channelType = sourceDataInfo.base.channelType;
                const list = (channelDataSources[channelType] || (channelDataSources[channelType] = []));

                for (const filterId in payload.data)
                {
                    addOnlineRmsResultWebGL(rmsResult, payload, sourceChannel as SourceChannelId, filterId as OnlineRmsFilterId, list, false);
                }
            }
        }
    }

    return combineSourceDataSourcesWebGL(dataSources, trimLengths, trimCutoffTime);
}



export function processOnlineRmsPreviewWebGL(rmsPreview: OnlineRmsPreviewLookupResult)
{
    const result: Editable<SourceToSourceChannelToWebGLPreviewMap> = {};

    if (!rmsPreview)
    {
        return result;
    }

    for (const sourceIdString in rmsPreview.previews)
    {
        const sourceId = sourceIdString as SourceId;
        const channelDataSources: Editable<SourceChannelToWebGLPreviewMap> = {};
        result[sourceId] = channelDataSources;

        const previews = rmsPreview.previews[sourceId];
        for (const preview of previews)
        {
            const payload = preview.data;

            for (const sourceChannelKey in payload)
            {
                const sourceChannel = sourceChannelKey as SourceChannelId;
                const sourceDataInfo = SourceChannelInfoManager.get(sourceChannel);
                const channelType = sourceDataInfo.base.channelType;
                const list = getChartDataPairWebGL(channelDataSources, channelType);

                const filterMap = payload[sourceChannel];
                for (const filterIdKey in filterMap)
                {
                    const filterId = filterIdKey as OnlineRmsFilterId;

                    const ranges = filterMap[filterId];
                    addOnlineRmsPreviewDataSourceWebGL(preview, sourceChannel, filterId, ranges, list);
                }
            }
        }
    }

    return result;
}

export function getChartDataPairWebGL(channelDataSources: Editable<SourceChannelToWebGLPreviewMap>, channelType: string)
{
    return channelDataSources[channelType] || (channelDataSources[channelType] = []);
}

function addOnlineRmsResultWebGL(rmsResult: OnlineRmsResult, payload: OnlineRmsPayload, sourceChannel: SourceChannelId, filterId: OnlineRmsFilterId, output: WebGLDataSeries[], ignoreTimeOffset: boolean)
{
    const keyData = payload.data[filterId];
    if (!keyData)
    {
        return;
    }

    const dataInfo = SourceChannelInfoManager.get(sourceChannel);

    if (!keyData || keyData.values.length === 0)
    {
        return;
    }

    let startTime = rmsResult.time;
    if (!ignoreTimeOffset)
    {
        startTime = addMicro(rmsResult.time, secondsToMicro(keyData.timeOffsets[0]));
    }

    const filter = monitoringStore.state().serverInfo.rmsFilters[filterId];
    const filterName = filter ? filter.displayName : filterId;
    const colour = applyColour(Color(dataInfo.base.mainColour), filter);
    const title = `${filterName} ${dataInfo.nameSimple}`;
    const source = findSource(monitoringStore.state(), rmsResult.sourceId);

    const dataSeries = createLineDataSeries(sourceChannel, source.name, 10, title, startTime, keyData.values, colour.hex(), undefined, filterName);

    output.push(dataSeries);
}

function addOnlineRmsPreviewDataSourceWebGL(preview: OnlineRmsPreview, sourceChannel: SourceChannelId, filterId: OnlineRmsFilterId, data: MinMaxValue[], output: WebGLDataSeries[])
{
    if (!data)
    {
        return;
    }

    const maxData: number[] = new Array<number>(data.length);
    for (let i = 0; i < data.length; i++)
    {
        const range = data[i];
        if (typeof(range) === 'number')
        {
            maxData[i] = range;
        }
        else if (range.length === 0)
        {
            maxData[i] = ignoreValue;
        }
        else
        {
            maxData[i] = range[1];
        }
    }

    const sourceChannelInfo = SourceChannelInfoManager.get(sourceChannel);
    const name = sourceChannelInfo.nameSimple;

    const filter = monitoringStore.state().serverInfo.rmsFilters[filterId];
    const filterName = filter ? filter.displayName : filterId;
    const colour = applyColour(Color(sourceChannelInfo.base.mainColour), filter);
    const title = `${filterName} Max ${name}`;
    const source = findSource(monitoringStore.state(), preview.host);

    const dataSeries = createLineDataSeries(sourceChannel, source.name, 1, title, preview.time, maxData, colour.hex(), undefined, filterName);

    output.push(dataSeries);
}


export function applyColour(baseColour: Color, colours: IHasColour)
{
    if (!colours)
    {
        return baseColour;
    }

    const { colour } = colours;

    return baseColour.rotate(colour.hueRotate).lighten(colour.lighten).saturate(colour.saturate);
}
