import monitoringStore from "./monitoringStore";
import {ChannelTypeId, ChannelTypeInfo, State} from "./monitoringStoreStates";

interface UnknownCache
{
    [channelType: string]: ChannelTypeInfo;
}

export class ChannelTypeInfoManager
{
    private static unknownCache: UnknownCache = {};

    public static get(channelType: ChannelTypeId, state: State = monitoringStore.state()): ChannelTypeInfo
    {
        return state.channelTypeInfo[channelType] || this.createUnknown(channelType);
    }

    public static sortTypes(channelTypes: ChannelTypeId[]): ChannelTypeInfo[]
    {
        return channelTypes.map(c => ChannelTypeInfoManager.get(c))
            .sort((x, y) => {
                // Sort by chart priority
                const diff = y.chartPriority - x.chartPriority;
                if (diff === 0)
                {
                    // If chart priority is the same then sort by chart title lexicographically.
                    return x.chartTitle.localeCompare(x.chartTitle);
                }

                return diff;
            });
    }

    public static sortMapKeys(obj: {[key: string]: any})
    {
        return this.sortTypes(Object.keys(obj) as ChannelTypeId[]);
    }

    private static createUnknown(channelType: ChannelTypeId): ChannelTypeInfo
    {
        let result = this.unknownCache[channelType];
        if (result)
        {
            return result;
        }

        result = {
            channelType: channelType,
            displayName: channelType,
            prefix: '?',
            chartTitle: channelType,
            chartPriority: -1
        }

        this.unknownCache[channelType] = result;
        return result;
    }
}