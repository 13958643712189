import cogoToast from 'cogo-toast';
import React from 'react';
import {Api} from '../../api/api';
import SetUpdateSourceState from '../../store/admin/setUpdateSourceState';
import monitoringStore from '../../store/monitoringStore';
import {FullSource, Group, GroupId, Source, SourceId, SourceType} from '../../store/monitoringStoreStates';
import CachedValue from '../../utils/cachedValue';
import {InputChangeEvent, SelectChangeEvent} from '../../utils/commonTypes';
import ButtonGroup from '../buttonGroup';
import {ModalBase} from '../modalBase';
import TagInput, {TagValue} from '../tagInput';
import './updateChannelTypeInfoModal.scss';

interface Props
{
    readonly createNew: boolean;
    readonly fullSource: FullSource;
    readonly groups: Group[];
}

export default class UpdateFullSourceModal extends React.PureComponent<Props>
{
    // Why is sourceId done as a ref compared to the other inputs? Because I could not figure out why setting the source id the same as the
    // others using the value tag and onChange the same as display name was causing a full render of the React tree and thus loosing
    // focus on the input. Wasted a decent amount of time on it and decided it wasn't worth further investigation because I could see no
    // different between source id and display name.
    private sourceId: React.RefObject<HTMLInputElement> = React.createRef();

    private cachedAllGroupTags: CachedValue<Group[], TagValue[]> = new CachedValue<Group[], TagValue[]>((groups) =>
    {
        return groups.map(g => ({value: g.id, label: g.name, tooltip: 'Click to remove'}));
    });

    public render()
    {
        const { createNew, groups, fullSource } = this.props;
        const { source, groupIds } = fullSource;

        const groupTags = this.cachedAllGroupTags.get(groups);

        return (
            <ModalBase isOpen={true}
                className='update-channel-info-modal'
                onRequestClose={this.close}>

                <h1>{createNew ? 'Create' : 'Update'} Source</h1>
                <div>
                    <strong>Source Id: </strong>
                    { createNew ?
                        <input type='text' ref={this.sourceId} data-rh='The source id code, once set it cannot be changed.' defaultValue={source.id} />:
                        <span data-rh='The source id code.'>{source.id}</span>
                    }

                </div>
                <div>
                    <strong>Display Name: </strong>
                    <input type='text' data-rh='Display name of the source.' defaultValue={source.name}
                        onChange={this.updateDisplayName} />
                </div>
                <div>
                    <strong>Source Type: </strong>
                    <select data-rh='The type of source, such as signalbox or substation.' value={source.sourceType}
                        onChange={this.updateSourceType}>
                        <option value='signalbox'>Signal Box</option>
                        <option value='substation'>Substation</option>
                    </select>
                </div>
                <div>
                    <strong>Sort Order: </strong>
                    <input type='number' data-rh='The sorting order for displaying this source. A higher number means higher up the list. Sources with the same will be sorted alphabetically by display name.' value={source.sortOrder.toString(10)}
                        onChange={this.updateSortOrder} />
                </div>
                <div>
                    <strong>Group Ids: </strong>
                    <TagInput values={groupIds} suggestions={groupTags}
                        tooltip='Groups that this source should appear in.'
                        placeholder='Select group' noPlaceholder='No groups'
                        onChange={this.updateGroupIds} />
                </div>

                <div className='update-channel-info-modal__buttons'>
                    <ButtonGroup>
                        <button onClick={this.update}>{createNew ? 'Create' : 'Update'}</button>
                        <button onClick={this.remove}>Delete</button>
                    </ButtonGroup>
                    <button onClick={this.close}>Close</button>
                </div>

            </ModalBase>
        )
    }

    private close = () =>
    {
        monitoringStore.execute(SetUpdateSourceState.close());
    }

    private update = () =>
    {
        const sourceId = !this.props.createNew ? this.props.fullSource.source.id : this.sourceId.current.value.trim() as SourceId;
        const source = {...this.props.fullSource.source, id: sourceId};
        const groupIds = this.props.fullSource.groupIds;
        source.name = source.name.trim();

        if (source.name.length === 0 || source.sourceType.length === 0 || groupIds.length === 0 || isNaN(source.sortOrder))
        {
            cogoToast.warn('All fields need to be filled');
            return;
        }

        if (this.props.createNew)
        {
            if (source.id.length === 0)
            {
                cogoToast.warn('Source Id needs to be filled');
                return;
            }
        }

        Api.updateSource({source, groupIds});
    }

    private remove = () =>
    {
        const sourceId = this.props.fullSource.source.id;
        if (confirm ('Are you sure you want to remove this source?\n\nThis will only remove the metadata associated with this source and no signal data.'))
        {
            Api.removeSource(sourceId);
        }
    }

    private updateDisplayName = (e: InputChangeEvent) => this.updateSource({name: e.target.value});
    private updateSourceType = (e: SelectChangeEvent) => this.updateSource({sourceType: e.target.value as SourceType});
    private updateSortOrder = (e: InputChangeEvent) => this.updateSource({sortOrder: parseInt(e.target.value)});
    private updateSource = (data: Partial<Source>) =>
    {
        monitoringStore.execute(SetUpdateSourceState.updateSource(data));
    }

    private updateGroupIds = (tags: string[]) =>
    {
        const groupIds = tags.map(t => t as GroupId);
        monitoringStore.execute(SetUpdateSourceState.updateGroupIds(groupIds));
    }
}