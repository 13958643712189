import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {DateRange, FFTProcessedStore, SourceId, State} from "./monitoringStoreStates";

export default class ChangeFFTProcessed
{
    public static modified(sourceId: SourceId, modifiedRange: DateRange): Modifier<State>
    {
        return (state: State) =>
        {
            const fftProcessedStore: Editable<FFTProcessedStore> = {...state.fftProcessedStore};
            const newSourceMap = fftProcessedStore[sourceId] ? {...fftProcessedStore[sourceId]} : {};
            fftProcessedStore[sourceId] = newSourceMap;

            newSourceMap[modifiedRange.id] = modifiedRange;

            return { fftProcessedStore };
        }
    }

    public static removed (sourceId: SourceId, removedId: number): Modifier<State>
    {
        return (state: State) =>
        {
            const fftProcessedStore: Editable<FFTProcessedStore> = {...state.fftProcessedStore};
            const sourceMap = fftProcessedStore[sourceId];
            if (!sourceMap)
            {
                return null;
            }

            const newSourceMap = { ...sourceMap };
            fftProcessedStore[sourceId] = newSourceMap;

            delete newSourceMap[removedId];

            return { fftProcessedStore };
        }
    }
}