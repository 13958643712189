import {Modifier} from "simple-data-store";
import {SourceActiveStatus, State} from "./monitoringStoreStates";

export default class SetSourceActiveStatus
{
    public static action (sourceActiveStatus: SourceActiveStatus): Modifier<State>
    {
        return (state: State) =>
        {
            const sourceActiveStatuses = {...state.sourceActiveStatuses, [sourceActiveStatus.sourceId]: sourceActiveStatus};

            return {sourceActiveStatuses};
        }
    }
}