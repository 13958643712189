import moment = require('moment');
import React, {MouseEvent, TouchEvent} from 'react';
import monitoringStore from '../../store/monitoringStore';
import {Source, SourceChannelId, SourceDataEnabledState} from '../../store/monitoringStoreStates';
import SetFFTTime from '../../store/setFFTTime';
import {SourceChannelInfoManager} from '../../store/sourceChannelInfo';
import ButtonGroup from '../buttonGroup';
import './imageZoom.scss';
import {zoomLevelInfos} from './imageZoomCommon';
import ImageZoomLayer from './imageZoomLayer';

interface Props
{
    fftTileHostname: string;
    fftScalingFactor: number;
    source: Source;
    sourceChannels: SourceChannelId[];
    sourceDataEnabledState: SourceDataEnabledState;
    centerTime: moment.Moment;
    zoomLevel: number;
}

export default class ImageZoom extends React.PureComponent<Props>
{
    public root: React.RefObject<HTMLDivElement>;
    private isMouseDown: boolean;
    private mouseX: number;
    private documentMouseHandler: EventListener;

    constructor (props: Props)
    {
        super(props);

        this.root = React.createRef();
    }

    public componentDidMount ()
    {
        this.forceUpdate();

        this.documentMouseHandler = (e) =>
        {
            this.isMouseDown = false;
        };

        document.addEventListener('mouseup', this.documentMouseHandler);
    }

    public componentWillUnmount ()
    {
        document.removeEventListener('mouseup', this.documentMouseHandler);
    }

    public render ()
    {
        return (
            <div className="image-zoom" ref={this.root}
                onMouseDown={this.onMouseDown}
                onMouseMove={this.onMouseMove}
                onTouchStart={this.onTouchStart}
                onTouchMove={this.onTouchMove}
                >
                <ButtonGroup activeValue={this.props.zoomLevel.toString()}>
                    <button data-value="0" onClick={this.setZoom0}>1 Minute</button>
                    <button data-value="1" onClick={this.setZoom1}>5 Minute</button>
                    <button data-value="2" onClick={this.setZoom2}>20 Minute</button>
                    <button data-value="3" onClick={this.setZoom3}>60 Minute</button>
                </ButtonGroup>
                <div>
                    <strong> Source: </strong> {this.props.source.id}
                    <strong> Time: </strong> {this.props.centerTime.format('YYYY-MM-DD HH:mm:ss')}
                </div>
                {this.renderLayer()}
            </div>
        );
    }

    private renderLayer (): JSX.Element[]
    {
        if (!this.root.current)
        {
            return null;
        }

        const viewportWidth = this.root.current.clientWidth;
        const zoomLevelData = zoomLevelInfos[this.props.zoomLevel];
        const result: JSX.Element[] = [];

        for (const sourceChannel of this.props.sourceChannels)
        {
            const enabled = this.props.sourceDataEnabledState[sourceChannel] !== false;
            if (!enabled)
            {
                continue;
            }

            const sourceChannelInfo = SourceChannelInfoManager.get(sourceChannel);
            result.push(<div key={`imagezoom_title_${sourceChannel}`}><strong>{sourceChannelInfo.nameSubscript}</strong></div>);
            result.push(<ImageZoomLayer
                key={`imagezoom_layer_${sourceChannel}`}
                fftTileHostname={this.props.fftTileHostname}
                fftScalingFactor={this.props.fftScalingFactor}
                sourceHostname={this.props.source.id}
                centerTime={this.props.centerTime}
                viewportWidth={viewportWidth}
                sourceChannel={sourceChannel}
                zoomLevelInfo={zoomLevelData}
            />);
        }

        return result;
    }

    private onMouseDown = (e: MouseEvent) =>
    {
        this.isMouseDown = true;
        this.mouseX = e.clientX;
        e.stopPropagation();
        e.preventDefault();
    }

    private onMouseMove = (e: MouseEvent) =>
    {
        if (!this.isMouseDown)
        {
            return;
        }

        e.stopPropagation();
        e.preventDefault();

        this.doMove(e.clientX);
    }

    private onTouchStart = (e: TouchEvent) =>
    {
        this.isMouseDown = true;
        this.mouseX = e.touches[0].clientX;
        e.stopPropagation();
        e.preventDefault();
    }

    private onTouchMove = (e: TouchEvent) =>
    {
        if (!this.isMouseDown)
        {
            return;
        }

        e.stopPropagation();
        e.preventDefault();

        this.doMove(e.touches[0].clientX);
    }

    private doMove (newX: number)
    {
        const zoomLevelInfo = zoomLevelInfos[this.props.zoomLevel];
        const timePerPixel = zoomLevelInfo.timePerTile.asMilliseconds() / zoomLevelInfo.tileWidth;

        const dx = newX - this.mouseX;
        const timeMove = dx * -timePerPixel;

        const newTime = this.props.centerTime.clone().add(timeMove, 'milliseconds');
        monitoringStore.execute(SetFFTTime.updateTimeForAllSources(newTime));

        this.mouseX = newX;
    }

    private setZoom (zoom: number)
    {
        monitoringStore.execute(SetFFTTime.zoom(zoom));
    }

    private setZoom0 = () => this.setZoom(0);
    private setZoom1 = () => this.setZoom(1);
    private setZoom2 = () => this.setZoom(2);
    private setZoom3 = () => this.setZoom(3);
}
