export type CachedValueChange<TKey, TValue> = (newKey: TKey) => TValue;

export default class CachedValue<TKey, TValue>
{
    private readonly calcNewValue: CachedValueChange<TKey, TValue>;
    private prevKey: TKey;
    private prevValue: TValue;

    constructor(calcNewValue: CachedValueChange<TKey, TValue>)
    {
        this.calcNewValue = calcNewValue;
    }

    public get(key: TKey): TValue
    {
        if (this.prevKey !== key)
        {
            this.prevValue = this.calcNewValue(key);
            this.prevKey = key;
        }

        return this.prevValue;
    }

    public value()
    {
        return this.prevValue;
    }
}