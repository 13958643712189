import moment = require('moment');
import React from 'react';
import {router} from '../../router';
import SetEventReportsModal from '../../store/admin/showEventReportsModal';
import SetReprocessEventsModal from '../../store/admin/showReprocessEventsModal';
import {ChannelTypeInfoManager} from '../../store/channelTypeInfoManager';
import monitoringStore from '../../store/monitoringStore';
import {ChannelTypeId} from '../../store/monitoringStoreStates';
import SetDataLookup, {SharedPreviewTimeId, SharedTimeId} from '../../store/setDataLookup';
import SetDownloadCSVState from '../../store/setDownloadCSVState';
import SetSourceDataEnabled from '../../store/setSourceDataEnabled';
import {MicroSeconds, microToMoment, Seconds, secondsToMicro} from '../../utils/timeTypes';
import ButtonGroup from '../buttonGroup';
import Icon from '../icon';
import LoadingBar from '../loadingBar';
import SourceDataReceivedView from '../sourceDataReceivedView';
import SourceDataTypeSelectors from '../sourceDataTypeSelector/sourceDataTypeSelectors';
import {EmptyTimelineState} from '../timeline/timelineCommon';
import ToggleButton from '../toggleButton';
import {WebGLTimeRange} from '../webglChart/webglChartStore';
import WebGLControlledChart from '../webglChart/webglControlledChart';
import BaseTab from './baseTab';

export function canLoadFullData(timeRange: WebGLTimeRange)
{
    return !!timeRange && timeRange.width < secondsToMicro(60 * 5 as Seconds);
}

function makeMomentPair(startTime: MicroSeconds, endTime: MicroSeconds)
{
    const start = microToMoment(startTime);
    const end = microToMoment(endTime);

    return {start, end}
}

export default class SourceDataLookup extends BaseTab
{
    private prevStartSelect: number = -1;
    private prevEndSelect: number = -1;

    public render ()
    {
        const { dataLookup, timelineStateMap, sources, sourceDataReceived, sourceEvents, serverState, sourceDataEnabledState } = this.props.state;
        const isAdmin = monitoringStore.state().loginState.userProfile.permissions.indexOf('admin') >= 0;
        const canLoadFull = canLoadFullData(this.getZoomX());
        const showLoadFullData = canLoadFull && !dataLookup.isDataLookup;

        return (
            <>
                <SourceDataReceivedView
                    timelineState={timelineStateMap['datalookup'] || EmptyTimelineState}
                    sources={sources}
                    sourceToEventMap={sourceEvents}
                    sourceDataReceived={sourceDataReceived}
                    onTimeSelect={this.onTimeSelect}
                    />

                <LoadingBar show={dataLookup.isLoading} />

                <SourceDataTypeSelectors
                    forMapId='main'
                    sourceChannels={dataLookup.sourceChannels}
                    sourceDataEnabled={sourceDataEnabledState}
                    autoResetYZoom={serverState.autoResetYZoomOnSourceToggle}
                    />

                <div>
                    <ButtonGroup>
                        { isAdmin &&
                            <>
                            <button onClick={this.loadCSV}><Icon type='load' forceTheme='dark' />Download as CSV</button>
                            <button onClick={this.reprocessEvents}>Reprocess Events</button>
                            <button onClick={this.showEventReports}>Show Event Report</button>
                            </>
                        }

                        { (!isAdmin && canLoadFull) &&
                            <button onClick={this.loadCSV}><Icon type='load' forceTheme='dark' /> Download as CSV</button>
                        }

                        <ToggleButton toggled={dataLookup.enableRMS} onClick={this.onChangeEnableRMS}>
                            { dataLookup.enableRMS ? <><Icon type='hide' forceTheme='dark'/> Hide RMS Charts</> : <><Icon type='show' forceTheme='dark'/> Show RMS Charts</>}
                        </ToggleButton>
                    </ButtonGroup>
                </div>

                { this.renderError() }
                { this.renderCharts(showLoadFullData) }
            </>
        );
    }

    private getZoomX(): WebGLTimeRange
    {
        const { dataLookup, webglChartState } = this.props.state;
        const timeId = dataLookup.isDataLookup ? SharedTimeId : SharedPreviewTimeId;
        return webglChartState.timeViewports[timeId];
    }

    private getStartEnd()
    {
        const zoomX = this.getZoomX();
        return makeMomentPair(zoomX.minTime, zoomX.maxTime);
    }

    private loadFullData = () =>
    {
        const zoomX = this.getZoomX();
        if (!canLoadFullData(zoomX))
        {
            return;
        }

        const timePair = makeMomentPair(zoomX.minTime, zoomX.maxTime);
        router.changeToAndDoDataLookup(timePair.start, timePair.end);
    }

    private loadCSV = () =>
    {
        const sourceDataEnabled = SetSourceDataEnabled.getEnabled('main', this.props.state.sourceDataEnabledState);
        const {start, end} = this.getStartEnd();

        monitoringStore.execute(SetSourceDataEnabled.setMultiple('download', sourceDataEnabled))
        monitoringStore.execute(SetDownloadCSVState.show(start, end));
    }

    private reprocessEvents = () =>
    {
        const {start, end} = this.getStartEnd();
        monitoringStore.execute(SetReprocessEventsModal.show(start, end));
    }

    private showEventReports = () =>
    {
        const {start, end} = this.getStartEnd();
        monitoringStore.execute(SetEventReportsModal.show(start, end));
    }

    private onChangeEnableRMS = () =>
    {
        const enableRMS = !this.props.state.dataLookup.enableRMS;
        monitoringStore.execute(SetDataLookup.setEnableRMS(enableRMS));

        const timelineState = this.props.state.timelineStateMap['datalookup'];
        if (!timelineState || !timelineState.selection.enabled)
        {
            return;
        }

        if (enableRMS)
        {
            router.doOnlineRmsDataLookup(timelineState.selection.start, timelineState.selection.end, true);
        }
    }

    private onTimeSelect = (start: moment.Moment, end: moment.Moment) =>
    {
        const startMs = start.valueOf();
        const endMs = end.valueOf();

        if (this.prevStartSelect === startMs && this.prevEndSelect === endMs)
        {
            return;
        }

        this.prevStartSelect = startMs;
        this.prevEndSelect = endMs;

        router.doDataLookup(start, end, this.props.state.dataLookup.enableRMS, true);
    }

    private renderError ()
    {
        const { dataLookup } = this.props.state;

        if (dataLookup.error)
        {
            return <span><strong>Error: </strong> {dataLookup.error}</span>;
        }
        return null;
    }

    private renderCharts (showLoadFullData: boolean)
    {
        const { dataLookup, webglChartState, sources, sourceDataEnabledState } = this.props.state;
        if (dataLookup.error)
        {
            return null;
        }

        const sourceDataEnabled = SetSourceDataEnabled.getEnabled('main', sourceDataEnabledState);
        const loadFullData = showLoadFullData && [
            <button key='loadFullData' onClick={this.loadFullData} data-rh='Load Full Resolution Data'>
                <Icon type='zoom' forceTheme='dark' />
            </button>];

        const { isDataLookup, dataLookupWebGLChartIds, previewWebGLChartIds, enableRMS } = dataLookup;

        const chartIds = isDataLookup ? dataLookupWebGLChartIds : previewWebGLChartIds;

        const result: JSX.Element[] = [];

        for (let source of sources)
        {
            const channelTypeIds = chartIds[source.id];
            if (!channelTypeIds)
            {
                continue;
            }

            const sortedChannelTypeInfos = ChannelTypeInfoManager.sortTypes(Object.keys(channelTypeIds) as ChannelTypeId[]);

            for (let channelTypeInfo of sortedChannelTypeInfos)
            {
                const pair = channelTypeIds[channelTypeInfo.channelType];
                if (!pair || !pair.mainChartId)
                {
                    continue;
                }

                result.push(<WebGLControlledChart type='main' key={pair.mainChartId} chartId={pair.mainChartId} webglChartState={webglChartState} sourceDataEnabled={sourceDataEnabled} extraButtons={loadFullData} />);

                if (pair.rmsChartId && enableRMS)
                {
                    result.push(<WebGLControlledChart type='rms' key={pair.rmsChartId} chartId={pair.rmsChartId} webglChartState={webglChartState} sourceDataEnabled={sourceDataEnabled} timeChartId={pair.mainChartId} />);
                }

                if (pair.fftChartId)
                {
                    result.push(<WebGLControlledChart type='fft' key={pair.fftChartId} chartId={pair.fftChartId} webglChartState={webglChartState} sourceDataEnabled={sourceDataEnabled}timeChartId={pair.mainChartId} />);
                }

                if (pair.previewChartId)
                {
                    result.push(<WebGLControlledChart type='preview' key={pair.previewChartId} chartId={pair.mainChartId} webglChartState={webglChartState} sourceDataEnabled={sourceDataEnabled} />);
                }
            }
        }

        return result;
    }
}