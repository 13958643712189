import {Modifier} from "simple-data-store";
import {Editable} from "../../utils/commonTypes";
import {modifyAdminState} from "../monitoringStore";
import {FullSource, GroupId, Source, State, UpdateSourceState} from "../monitoringStoreStates";

export default class SetUpdateSourceState
{
    public static showCreate (source: FullSource): Modifier<State>
    {
        return this.execute(true, true, source);
    }
    public static showUpdate (source: FullSource): Modifier<State>
    {
        return this.execute(true, false, source);
    }
    public static close (): Modifier<State>
    {
        return this.execute(false, false);
    }

    public static updateSource(source: Partial<Source>)
    {
        return (state: State) =>
        {
            const newSource = {...state.adminState.updateSourceState.fullSource.source, ...source}
            const updateSourceState: UpdateSourceState = {
                ...state.adminState.updateSourceState,
                fullSource: {...state.adminState.updateSourceState.fullSource, source: newSource}
            }

            return modifyAdminState(state, {updateSourceState});
        }
    }

    public static updateGroupIds(groupIds: GroupId[])
    {
        return (state: State) =>
        {
            const updateSourceState: UpdateSourceState = {
                ...state.adminState.updateSourceState,
                fullSource: {...state.adminState.updateSourceState.fullSource, groupIds}
            }

            return modifyAdminState(state, {updateSourceState});
        }
    }

    private static execute (show: boolean, createNew: boolean, source?: FullSource): Modifier<State>
    {
        return (state: State) =>
        {
            const updateSourceState: Editable<UpdateSourceState> = {
                ...state.adminState.updateSourceState, show, createNew
            }

            if (source)
            {
                updateSourceState.fullSource = source;
            }

            return modifyAdminState(state, {updateSourceState});
        }
    }
}