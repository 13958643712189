import {Modifier} from "simple-data-store";
import {modifyLocalState} from "./monitoringStore";
import {SourceEvent, State} from "./monitoringStoreStates";

export default class ShowEventModal
{
    public static show (sourceEvent: SourceEvent): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {showSourceEventModal: true, selectedEvent: sourceEvent});
    }

    public static close (): Modifier<State>
    {
        return (state: State) => modifyLocalState(state, {showSourceEventModal: false, selectedEvent: null});
    }
}