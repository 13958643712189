import React, {ChangeEvent} from 'react';
import {Api} from '../api/api';
import monitoringStore from '../store/monitoringStore';
import {Source, StatusLookup} from '../store/monitoringStoreStates';
import SetStatusLookup from '../store/setStatusLookup';
import ShowDetailedInfoModal from '../store/showDetailedInfoModal';
import {parseInputDate} from '../utils/utils';
import {ModalBase} from './modalBase';
import SourceDetailedCharts from './sourceDetailedCharts';
import './sourceDetailedInfoModal.scss';

interface Props
{
    source?: Source;
    statusLookup?: StatusLookup;
}
interface State
{
    date: string;
}

export default class SourceDetailedInfoModal extends React.PureComponent<Props, State>
{
    private inputId: string = `id_${Date.now}`;

    constructor (props: Props)
    {
        super(props);

        this.state = {
            date: ''
        };
    }

    public render ()
    {
        if (this.props.source == null)
        {
            return null;
        }

        return (
            <ModalBase isOpen={true}
                className='source-detailed-info-modal'
                onRequestClose={() => this.close()}
                >
                <h1>Detailed Info: {this.props.source.id}</h1>

                <div className="source-detailed-info-modal__controls">
                    <div className="source-detailed-info-modal__date">
                        <label htmlFor={this.inputId}>Date:</label>
                        <input id={this.inputId} type="date" placeholder="YYYY-MM-DD" value={this.state.date} onChange={(e) => this.changeDate(e)} />
                    </div>
                    <div className="source-detailed-info-modal__control-buttons">
                        <button className="source-detailed-info-modal__search" onClick={() => this.doSearch()}>Search</button>
                    </div>
                </div>

                <div>
                    { !this.props.statusLookup && <span>Do a search!</span>}
                    { this.props.statusLookup && (
                        this.props.statusLookup.isLoading ?
                            <span>Loading</span> :
                            <SourceDetailedCharts statusLookupResult={this.props.statusLookup.result} />
                    )}
                </div>

                <div className="source-detailed-info-modal__buttons">
                    <button onClick={() => this.close()}>Close</button>
                </div>
            </ModalBase>
        );
    }

    private close ()
    {
        monitoringStore.execute(ShowDetailedInfoModal.close());
    }

    private changeDate (event: ChangeEvent<HTMLInputElement>)
    {
        this.setState({...this.state, date: event.target.value});
    }

    private doSearch ()
    {
        const startDate = parseInputDate(this.state.date, true);
        const endDate = parseInputDate(this.state.date, false);

        monitoringStore.execute(SetStatusLookup.setIsLoading(this.props.source.id));
        Api.doStatusLookup(this.props.source.id, startDate, endDate);
    }
}
