import React from 'react';
import {Api} from '../../api/api';
import SetActiveUsers from '../../store/admin/setActiveUsers';
import SetCombineDataReceived from '../../store/admin/setCombineDataReceived';
import SetServerConfig from '../../store/admin/setServerConfig';
import monitoringStore from '../../store/monitoringStore';
import ChannelTypeInfoViews from '../adminComponents/channelTypeInfoViews';
import CombineDataReceivedStoreModal from '../adminComponents/combineDataReceivedStoreModal';
import FullSourceViews from '../adminComponents/fullSourceViews';
import GroupViews from '../adminComponents/groupViews';
import ListActiveUsersModal from '../adminComponents/listActiveUsersModal';
import ServerConfigModal from '../adminComponents/serverConfigModal';
import SourceChannelInfoViews from '../adminComponents/sourceChannelInfoViews';
import UpdateChannelTypeInfoModal from '../adminComponents/updateChannelTypeInfoModal';
import UpdateFullSourceModal from '../adminComponents/updateFullSourceModal';
import UpdateGroupModal from '../adminComponents/updateGroupModal';
import UpdateSourceChannelInfoModal from '../adminComponents/updateSourceChannelInfoModal';
import UpdateUserProfileModal from '../adminComponents/updateUserProfileModal';
import UserProfileViews from '../adminComponents/userProfileViews';
import ButtonGroup from '../buttonGroup';
import BaseTab from './baseTab';

export default class AdminTab extends BaseTab
{
    public render()
    {
        const state = this.props.state;
        const adminState = state.adminState;

        return (
            <div>
                <h2>Admin Page</h2>

                <ButtonGroup>
                    <button onClick={this.showActiveUsers}>Show Active Users</button>
                    <button onClick={this.showServerConfig}>Show Server Config</button>
                </ButtonGroup>

                <ChannelTypeInfoViews channelTypeInfos={state.channelTypeInfo} />
                <SourceChannelInfoViews sourceChannelInfos={state.sourceChannelInfo} />
                <FullSourceViews
                    fullSources={adminState.allSources}
                    unknownSources={adminState.unknownSources} />
                <GroupViews groups={state.groups} />
                <UserProfileViews userProfiles={adminState.userProfiles} />

                { adminState.updateChannelTypeInfoState.show &&
                    <UpdateChannelTypeInfoModal
                        key={adminState.updateChannelTypeInfoState.channelTypeInfo.channelType}
                        createNew={adminState.updateChannelTypeInfoState.createNew}
                        channelTypeInfo={adminState.updateChannelTypeInfoState.channelTypeInfo} />
                }

                { adminState.updateSourceChannelInfoState.show &&
                    <UpdateSourceChannelInfoModal
                        key={adminState.updateSourceChannelInfoState.sourceChannelInfo.sourceChannel}
                        createNew={adminState.updateSourceChannelInfoState.createNew}
                        channelTypeInfo={state.channelTypeInfo}
                        sourceChannelInfo={adminState.updateSourceChannelInfoState.sourceChannelInfo} />
                }

                { adminState.updateUserProfileState.show &&
                    <UpdateUserProfileModal
                        key={adminState.updateUserProfileState.userProfile.id}
                        createNew={adminState.updateUserProfileState.createNew}
                        sources={adminState.allSources}
                        sourceChannels={state.sourceChannelInfo}
                        userProfile={adminState.updateUserProfileState.userProfile} />
                }

                { adminState.updateSourceState.show &&
                    <UpdateFullSourceModal
                        key={adminState.updateSourceState.fullSource.source.id}
                        groups={state.groups}
                        createNew={adminState.updateSourceState.createNew}
                        fullSource={adminState.updateSourceState.fullSource} />
                }

                { adminState.updateGroupState.show &&
                    <UpdateGroupModal
                        key={adminState.updateGroupState.group.id}
                        createNew={adminState.updateGroupState.createNew}
                        group={adminState.updateGroupState.group} />
                }

                <ListActiveUsersModal
                    show={adminState.showActiveUsers}
                    summary={adminState.activeUsersSummary} />

                <ServerConfigModal
                    show={adminState.showConfig}
                    summary={adminState.configSummary} />

                <CombineDataReceivedStoreModal
                    show={adminState.showCombineDataReceived} />
            </div>
        );
    }

    private showActiveUsers = () =>
    {
        monitoringStore.execute(SetActiveUsers.show());
        Api.listActiveUsers();
    }

    private showServerConfig = () =>
    {
        monitoringStore.execute(SetServerConfig.show());
        Api.listServerConfig();
    }

    private showCombineDataReceived = () =>
    {
        monitoringStore.execute(SetCombineDataReceived.show());
    }
}