import {Modifier} from "simple-data-store";
import {Editable} from "../../utils/commonTypes";
import {modifyAdminState} from "../monitoringStore";
import {SourceChannelInfoBase, State, UpdateSourceChannelInfoState} from "../monitoringStoreStates";

export default class SetUpdateSourceChannelInfo
{
    public static showCreate (sourceChannelInfo: SourceChannelInfoBase): Modifier<State>
    {
        return this.execute(true, true, sourceChannelInfo);
    }
    public static showUpdate (sourceChannelInfo: SourceChannelInfoBase): Modifier<State>
    {
        return this.execute(true, false, sourceChannelInfo);
    }
    public static close (): Modifier<State>
    {
        return this.execute(false, false);
    }

    private static execute (show: boolean, createNew: boolean, sourceChannelInfo?: SourceChannelInfoBase): Modifier<State>
    {
        return (state: State) =>
        {
            const updateSourceChannelInfoState: Editable<UpdateSourceChannelInfoState> = {
                ...state.adminState.updateChannelTypeInfoState, show, createNew
            }

            if (sourceChannelInfo)
            {
                updateSourceChannelInfoState.sourceChannelInfo = sourceChannelInfo;
            }

            return modifyAdminState(state, {updateSourceChannelInfoState});
        }
    }
}