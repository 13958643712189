import {Modifier} from "simple-data-store";
import {Editable} from "../utils/commonTypes";
import {DateRange, SourceDataReceivedMap, SourceId, State} from "./monitoringStoreStates";

export default class ChangeSourceDataReceived
{
    public static modified(sourceId: SourceId, modifiedRange: DateRange): Modifier<State>
    {
        return (state: State) =>
        {
            const sourceDataReceived: Editable<SourceDataReceivedMap> = {...state.sourceDataReceived};
            const newSourceMap = sourceDataReceived[sourceId] ? {...sourceDataReceived[sourceId]} : {};
            sourceDataReceived[sourceId] = newSourceMap;

            newSourceMap[modifiedRange.id] = modifiedRange;

            return { sourceDataReceived };
        }
    }

    public static removed(sourceId: SourceId, removedId: number): Modifier<State>
    {
        return (state: State) =>
        {
            const sourceDataReceived: Editable<SourceDataReceivedMap> = {...state.sourceDataReceived};
            const sourceMap = sourceDataReceived[sourceId];
            if (!sourceMap)
            {
                return null;
            }

            const newSourceMap = { ...sourceMap };
            sourceDataReceived[sourceId] = newSourceMap;

            delete newSourceMap[removedId];

            return { sourceDataReceived: sourceDataReceived };
        }
    }
}