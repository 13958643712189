import {Modifier} from "simple-data-store";
import {compareStrings} from "../../utils/utils";
import {modifyAdminState} from "../monitoringStore";
import {FullSource, Source, SourceId, State} from "../monitoringStoreStates";

export default class SetListAllSources
{
    public static action (sources: FullSource[], unknownSources: SourceId[]): Modifier<State>
    {
        sources = [...sources];
        sources.sort((x, y) =>
        {
            if (x.source.sortOrder === y.source.sortOrder)
            {
                return compareStrings(x.source.name, y.source.name);
            }

            return y.source.sortOrder - x.source.sortOrder;
        });

        return (state: State) => modifyAdminState(state, {allSources: sources, unknownSources});
    }

    public static updateSource (source: Source): Modifier<State>
    {
        return (state: State) =>
        {
            const existingIndex = state.adminState.allSources.findIndex(s => s.source.id === source.id);
            if (existingIndex < 0)
            {
                return null;
            }

            const sources = [...state.adminState.allSources];
            sources[existingIndex] = {...sources[existingIndex], source};

            let unknownSources = state.adminState.unknownSources;
            const existingUnknownIndex = unknownSources.findIndex(id => id === source.id);
            if (existingUnknownIndex >= 0)
            {
                unknownSources = [...unknownSources];
                unknownSources.splice(existingUnknownIndex);
            }

            return SetListAllSources.action(sources, unknownSources)(state);
        }
    }

    public static removeSource (sourceId: SourceId): Modifier<State>
    {
        return (state: State) =>
        {
            const existingIndex = state.adminState.allSources.findIndex(s => s.source.id === sourceId);
            if (existingIndex < 0)
            {
                return null;
            }

            const sources = [...state.adminState.allSources];
            sources.splice(existingIndex, 1);

            return SetListAllSources.action(sources, state.adminState.unknownSources)(state);
        }
    }
}