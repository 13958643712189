import moment = require('moment');
import React, {CSSProperties} from 'react';
import {SourceChannelId} from '../../store/monitoringStoreStates';
import {MilliSeconds} from '../../utils/timeTypes';
import {ImageZoomCache} from './imageZoomCache';
import {ZoomLevelInfo} from './imageZoomCommon';
import ImageZoomTile from './imageZoomTile';

interface Props
{
    readonly centerTime: moment.Moment;
    readonly viewportWidth: number;
    readonly sourceHostname: string;
    readonly sourceChannel: SourceChannelId;
    readonly zoomLevelInfo: ZoomLevelInfo;
    readonly fftTileHostname: string;
    readonly fftScalingFactor: number;
}

interface State
{
    readonly tooltip: string;
}

interface RenderContext
{
    readonly leftMs: MilliSeconds;
    readonly centerMs: MilliSeconds;
    readonly timePerTileMs: number;
    readonly timePerPixelMs: number;
    readonly timeDiff: number;
    readonly timeOffset: number;
}

export default class ImageZoomLayer extends React.PureComponent<Props, State>
{
    public renderContext?: RenderContext = undefined;
    private layerRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props)
    {
        super(props);

        this.layerRef = React.createRef();

        this.state = {
            tooltip: ''
        }
    }

    public componentDidMount()
    {
        this.layerRef.current.addEventListener('mousemove', (e) =>
        {
            const target = e.target as HTMLImageElement;
            if (target.nodeName !== 'IMG')
            {
                this.setState({tooltip: 'No Data'});
                return;
            }

            const bounds = target.getBoundingClientRect();

            const xpos = e.clientX - bounds.left;
            const ypos = e.clientY - bounds.top;

            const layerBounds = this.layerRef.current.getBoundingClientRect();
            const layerXpos = e.clientX - layerBounds.left;

            const pixels = ImageZoomCache.getPixelValues(target, xpos, ypos);
            this.createTooltip(pixels, layerXpos, ypos);
        });
    }

    public render ()
    {
        this.createRenderContext();

        return (
            <div className="image-zoom__layer" ref={this.layerRef} data-rh-chart={this.state.tooltip}>
                { this.renderTiles() }
            </div>
        );
    }

    private createTooltip(pixels: Uint8ClampedArray, viewportXpos: number, ypos: number)
    {
        if (pixels[3] < 100)
        {
            this.setState({tooltip: 'No Data'});
            return;
        }

        // const sourceChannelInfo = SourceChannelInfoManager.get(this.props.sourceChannel);
        // const channelInfo = ChannelTypeInfoManager.get(sourceChannelInfo.base.channelType);

        // const isVoltage = channelInfo.channelType === 'Voltage';

        const frequency = this.calculateFrequency(ypos);
        const frequencyString = frequency.lower !== frequency.upper ?
            `${frequency.lower} - ${frequency.upper}` :
            `${frequency.lower}`;

        // const originalValue = Math.pow(10, this.calculateOrigValue(pixels));
        // const scaledValue = originalValue * this.props.fftScalingFactor;
        // const max = isVoltage ? 100 : 3;
        // const min = isVoltage ? 1e-3 : 1e-2;
        // const diff = max - min;
        // const value = scaledValue * diff - min;

        const xTime = moment(this.getTimeFromXPos(viewportXpos));

        const tooltip = `Time: ${xTime.format('YYYY-MM-DD HH:mm:ss')}
            Frequency: ${frequencyString} Hz`;

        this.setState({tooltip});
    }

    private calculateOrigValue(pixels: Uint8ClampedArray)
    {
        // Taken from https://github.com/matplotlib/matplotlib/blob/a55909ae86049fc2e437c58dbfe7550b7990b687/lib/matplotlib/_cm.py
        // Using _gnuplot2_data with red, green and blue functions _g30, _g31 and _g32
        const r = pixels[0];
        const g = pixels[1];
        const b = pixels[2];

        if (r == 255 && g == 255 && b > 0)
        {
            const B = b / 255;
            return (B + 11.5) * 0.08;
        }

        if (g > 0)
        {
            const G = g / 255;
            return (G + 0.84) * 0.5;
        }

        if (r > 0)
        {
            const R = r / 255;
            return (R + 0.78125) * 0.32;
        }
        else
        {
            const B = b / 255;
            return B / 4;
        }
    }

    private calculateFrequency(ypos: number): {lower: number, upper: number}
    {
        ypos = Math.floor(ypos);

        const pos = 499 - ypos;
        if (pos < 120)
        {
            return {lower: pos, upper: pos};
        }
        if (pos < 120 + 65)
        {
            const lower = (pos - 120) * 2 + 120;
            return {lower: lower, upper: lower + 1};
        }
        if (pos < 120 + 65 + 180)
        {
            const lower = (pos - 120 - 65) * 5 + 250;
            return {lower: lower, upper: lower + 4}
        }
        if (pos < 120 + 65 + 180 + 135)
        {
            const lower = (pos - 120 - 65 - 180) * 10 + 1150;
            return {lower: lower, upper: lower + 9};

        }
        return {lower: 2500, upper: 2500};
    }

    private getTimeFromXPos(viewportXpos: number): MilliSeconds
    {
        return (viewportXpos * this.renderContext.timePerPixelMs + this.renderContext.leftMs) as MilliSeconds;
    }

    private renderTiles (): JSX.Element[]
    {
        const result: JSX.Element[] = [];
        if (this.renderContext == undefined)
        {
            return result;
        }

        const { zoomLevelInfo, fftTileHostname, sourceHostname: hostname, sourceChannel } = this.props;

        let xpos = 0;
        const currentTime = moment(Math.floor(this.renderContext.timeDiff) * this.renderContext.timePerTileMs).utc();
        const tileWidthPx = `${zoomLevelInfo.tileWidth}px`;
        const offset = Math.floor(this.renderContext.timeOffset / this.renderContext.timePerPixelMs);
        const zoomLevel = zoomLevelInfo.zoomValue + 'm';

        while (xpos <= this.props.viewportWidth + zoomLevelInfo.tileWidth)
        {
            const year = currentTime.format('YYYY');
            const month = currentTime.format('MM');
            const day = currentTime.format('DD');
            const timeFormatted = currentTime.format('YYYYMMDD.HHmm');
            const style: CSSProperties = {
                transform: `translateX(${xpos - offset}px)`,
                width: tileWidthPx
            };

            const folderUrl = `//${fftTileHostname}/fft-tiles/${hostname}/${year}${month}${day}/${sourceChannel}/`;
            const filename = `fft-${zoomLevel}.${hostname}.${sourceChannel}.${timeFormatted}.png`;
            const url = folderUrl + filename;

            currentTime.add(zoomLevelInfo.timePerTile);
            result.push(<ImageZoomTile key={url} boundaryTime={currentTime.valueOf() as MilliSeconds} url={url} style={style} />);
            xpos += zoomLevelInfo.tileWidth;
        }

        return result;
    }

    private createRenderContext ()
    {
        const centerMs = this.props.centerTime.valueOf() as MilliSeconds;
        const timePerTileMs = this.props.zoomLevelInfo.timePerTile.asMilliseconds();
        const timePerPixelMs = timePerTileMs / this.props.zoomLevelInfo.tileWidth;

        const leftMs = (centerMs - timePerTileMs * (this.props.viewportWidth * 0.5 / this.props.zoomLevelInfo.tileWidth)) as MilliSeconds;
        const timeDiff = leftMs / timePerTileMs;
        const timeOffset = (leftMs % timePerTileMs);

        this.renderContext = { leftMs, centerMs, timePerTileMs, timeDiff, timeOffset, timePerPixelMs };
    }
}
