import React from 'react';
import monitoringStore from '../store/monitoringStore';
import {ConnectionState, LocalState, ServerInfo} from '../store/monitoringStoreStates';
import SetDarkTheme from '../store/setDarkTheme';
import SetDebugState from '../store/setDebugState';
import ShowStartupErrors from '../store/showStartupErrors';
import {ClickHandler} from '../utils/commonTypes';
import HeaderBase from './headerBase';
import StartupErrorsModal from './startupErrorsModal';

export interface Props
{
    serverInfo: ServerInfo;
    connectionStatus: ConnectionState;
    onClickConnectIndicator?: ClickHandler;
    localState: LocalState;
}

function showStartupErrors()
{
    monitoringStore.execute(ShowStartupErrors.show());
}

function toggleDebug()
{
    monitoringStore.execute(SetDebugState.enabled(!monitoringStore.state().debugState.enabled));
}

function toggleDarkTheme()
{
    monitoringStore.execute(SetDarkTheme.action(!monitoringStore.state().serverState.darkTheme));
}

export default class Header extends React.Component<Props>
{
    public render ()
    {
        const { serverInfo, connectionStatus, onClickConnectIndicator } = this.props;
        return (
            <HeaderBase serverInfo={serverInfo}
                connectionStatus={connectionStatus}
                onClickConnectIndicator={onClickConnectIndicator}
                onClickLogo={toggleDarkTheme}>
                { this.props.localState.startupErrors.length > 0 &&
                    <button
                    className="button button-outline header__startup-errors"
                        onClick={showStartupErrors}>
                        Show Startup Errors
                    </button> }

                <StartupErrorsModal
                    errors={this.props.localState.startupErrors}
                    open={this.props.localState.showStartupErrors} />

                { this.props.children }

            </HeaderBase>
        );
    }
}
