import { SourceDataEnabledState } from "../../store/monitoringStoreStates";
import { MicroSeconds } from "../../utils/timeTypes";
import Matrix4x4 from "./matrix4x4";
import Vector4 from "./vector4";
import { WebGLDataSeries, WebGLDataSeriesMinMax, WebGLTimeRange, WebGLValueRange } from "./webglChartStore";
import WebGLMesh from "./webglMesh";
import { createLineMesh, createMinMaxMesh, initShaderProgram } from "./webglUtils";

interface DataSeriesBufferPair
{
    readonly data: WebGLDataSeries;
    readonly meshes: WebGLMesh[];
}

function equalTimeRange(range1: WebGLTimeRange, range2: WebGLTimeRange)
{
    if (range1 === range2) return true;
    if (range1 == null || range2 == null) return false;

    return range1.maxTime === range2.maxTime && range1.minTime === range2.minTime;
}
function equalValueRange(range1: WebGLValueRange, range2: WebGLValueRange)
{
    if (range1 === range2) return true;
    if (range1 == null || range2 == null) return false;

    return range1.maxValue === range2.maxValue && range1.minValue === range2.minValue;
}

export default class WebGLLineChart
{
    public gl: WebGLRenderingContext;
    private canvas: HTMLCanvasElement;
    private shaderProgram: WebGLProgram;
    private cameraMatrix: Matrix4x4 = new Matrix4x4();

    private pointSizeUniform: WebGLUniformLocation;
    private viewCameraMatrixUniform: WebGLUniformLocation;
    private offsetUniform: WebGLUniformLocation;
    private fragColourUniform: WebGLUniformLocation;

    private meshes: DataSeriesBufferPair[] = [];
    private prevCharts: WebGLDataSeries[] = null;
    private offset: Vector4 = new Vector4();
    private sourceDataEnabled: SourceDataEnabledState = {};

    private sourceDataChanged: boolean = false;
    private dataSeriesChanged: boolean = false;

    private prevTimeViewport: WebGLTimeRange = null;
    private prevValueViewport: WebGLValueRange = null;
    private prevMinStartTime: MicroSeconds = null;

    constructor(canvas: HTMLCanvasElement)
    {
        this.canvas = canvas;

        // Premultiplied Alpha fixes an issue in Firefox where transparent
        // chart colours render weirdly.
        this.gl = this.canvas.getContext('webgl',
        {
            premultipliedAlpha: false
        });

        const width = this.canvas.offsetWidth;
        const height = this.canvas.offsetHeight;
        this.canvas.width = width * window.devicePixelRatio;
        this.canvas.height = height * window.devicePixelRatio;
        this.canvas.style.width = `${width}px`;
        this.canvas.style.height = `${height}px`;
    }

    public init()
    {
        this.shaderProgram = initShaderProgram(this.gl);

        this.gl.useProgram(this.shaderProgram);

        this.gl.clearColor(1.0, 1.0, 1.0, 0.0);
        this.gl.disable(this.gl.CULL_FACE);
        this.gl.blendFunc(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA);
        this.gl.enable(this.gl.BLEND);

        this.gl.viewport(0, 0, this.canvas.width, this.canvas.height);

        this.pointSizeUniform = this.gl.getUniformLocation(this.shaderProgram, 'pointSize');
        this.viewCameraMatrixUniform = this.gl.getUniformLocation(this.shaderProgram, 'viewCameraMatrix');
        this.offsetUniform = this.gl.getUniformLocation(this.shaderProgram, 'offset');
        this.fragColourUniform = this.gl.getUniformLocation(this.shaderProgram, 'fragColour');

        this.gl.bindAttribLocation(this.shaderProgram, 0, 'vertexPos');
        this.gl.enableVertexAttribArray(0);
    }

    public resizeCanvas()
    {
        const parent = this.canvas.parentNode as HTMLElement;

        const width = parent.offsetWidth;
        const height = parent.offsetHeight;
        this.canvas.width = width * window.devicePixelRatio;
        this.canvas.height = height * window.devicePixelRatio;
        this.canvas.style.width = `${width}px`;
        this.canvas.style.height = `${height}px`;

        this.gl.viewport(0, 0, this.canvas.width, this.canvas.height);

        this.forceRender();
    }

    public setClearColor(red: number, green: number, blue: number)
    {
        this.gl.clearColor(red, green, blue, 0.0);
    }

    public destroy()
    {
        for (let pair of this.meshes)
        {
            for (let mesh of pair.meshes)
            {
                this.gl.deleteBuffer(mesh.buffer);
            }
        }
        this.gl.deleteProgram(this.shaderProgram);
        this.meshes = [];
        this.prevCharts = [];
    }

    public drawMesh(mesh: WebGLMesh, minStartTime: MicroSeconds)
    {
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, mesh.buffer);
        this.gl.vertexAttribPointer(0, 2, this.gl.FLOAT, false, 0, 0);
        this.gl.uniform1f(this.pointSizeUniform, mesh.pointSize);
        this.gl.uniform4fv(this.fragColourUniform, mesh.colour);
        this.gl.lineWidth(2);

        this.offset.data[0] = (mesh.offset - minStartTime) * 0.001;
        this.gl.uniform4fv(this.offsetUniform, this.offset.data);
        this.gl.drawArrays(mesh.mode, 0, mesh.length);
    }

    private forceRender()
    {
        if (this.prevMinStartTime == null || this.prevTimeViewport == null || this.prevValueViewport == null)
        {
            console.error('Cannot force a render without a render happening once first');
            return;
        }

        this.dataSeriesChanged = true;
        this.render(this.prevTimeViewport, this.prevValueViewport, this.prevMinStartTime, 'forceRender', true);
    }

    public render(timeViewport: WebGLTimeRange, valueViewport: WebGLValueRange, minStartTime: MicroSeconds, debugName: string, forceRender: boolean = false)
    {
        // If you're wondering why this check is here and cannot simply rely on the React PureComponents from
        // preventing multiple renders with the same inputs then I am with you.
        // I encountered a situation where after a mouse wheel scroll the canvas was rendering twice
        // and from the sequence of events it appeared that the WebGLCanvasChart was being rendered before
        // the parent, then the parent was rendered and then parent again. I don't know how this happened, but it
        // showed in the componentDidUpdate that the `this.props` value had not changed in the WebGLCanvasChart so
        // it thought that the timeViewport had changed both times and was triggering two renders for the one update.
        // So this is a somewhat brute force solution to prevent multiple renders.

        // If either of these have changed then no need to check if the viewports are different.
        if (!forceRender)
        {
            if (!this.sourceDataChanged && !this.dataSeriesChanged)
            {
                if (equalTimeRange(this.prevTimeViewport, timeViewport) &&
                    equalValueRange(this.prevValueViewport, valueViewport) &&
                    this.prevMinStartTime === minStartTime)
                {
                    return;
                }
            }
        }

        this.prevTimeViewport = timeViewport;
        this.prevValueViewport = valueViewport;
        this.prevMinStartTime = minStartTime;

        this.gl.clear(this.gl.COLOR_BUFFER_BIT);

        this.renderMeshes(timeViewport, valueViewport, minStartTime);
    }

    public checkForSourceDataEnabledChanges(sourceDataEnabled: SourceDataEnabledState): boolean
    {
        this.sourceDataChanged = false;
        if (this.sourceDataEnabled !== sourceDataEnabled)
        {
            this.sourceDataEnabled = sourceDataEnabled;
            this.sourceDataChanged = true;
        }

        return this.sourceDataChanged;
    }

    public checkForDataSeriesChanges(chartDataSeries: WebGLDataSeries[]): boolean
    {
        this.dataSeriesChanged = false;
        if (this.prevCharts === chartDataSeries)
        {
            return false;
        }

        const removedMeshes = this.removeMeshesNotInNewData(chartDataSeries);
        const createdMeshes = this.createNewMeshes(chartDataSeries);

        this.dataSeriesChanged = removedMeshes || createdMeshes;

        this.prevCharts = chartDataSeries;
        return this.dataSeriesChanged;
    }

    private removeMeshesNotInNewData(chartDataSeries: WebGLDataSeries[]): boolean
    {
        let changed = false;
        for (let i = 0; i < this.meshes.length; i++)
        {
            const currentMesh = this.meshes[i];
            let found = false;
            for (let dataSeries of chartDataSeries)
            {
                if (currentMesh.data === dataSeries)
                {
                    found = true;
                    break;
                }
            }

            if (found)
            {
                continue;
            }

            changed = true;
            for (const mesh of currentMesh.meshes)
            {
                this.gl.deleteBuffer(mesh.buffer);
            }
            this.meshes.splice(i, 1);
            i--;
        }

        return changed;
    }

    private createNewMeshes(chartDataSeries: WebGLDataSeries[]): boolean
    {
        let changed = false;
        for (let dataSeries of chartDataSeries)
        {
            let found = false;
            for (let currentMesh of this.meshes)
            {
                if (currentMesh.data === dataSeries)
                {
                    found = true;
                    break;
                }
            }

            if (found)
            {
                continue;
            }

            changed = true;

            let newMesh: WebGLMesh[] = null;
            if (dataSeries.type === 'line')
            {
                newMesh = createLineMesh(this.gl, dataSeries, 'line', this.gl.LINE_STRIP, dataSeries.pointSize);
            }
            else if (dataSeries.type === 'minmax')
            {
                newMesh = createMinMaxMesh(this.gl, dataSeries as WebGLDataSeriesMinMax);
            }
            else if (dataSeries.type === 'dots')
            {
                newMesh = createLineMesh(this.gl, dataSeries, 'line', this.gl.POINTS, dataSeries.pointSize);
            }

            if (newMesh && newMesh.length > 0)
            {
                const pair = {meshes: newMesh, data: dataSeries};

                // Put partially transparent meshes to be drawn first.
                if (newMesh[0].colour[3] < 1)
                {
                    this.meshes.unshift(pair);
                }
                else
                {
                    this.meshes.push(pair);
                }
            }
        }

        return changed;
    }

    private renderMeshes(timeViewport: WebGLTimeRange, valueViewport: WebGLValueRange, minStartTime: MicroSeconds)
    {
        this.cameraMatrix.ortho((timeViewport.minTime - minStartTime) * 0.001, (timeViewport.maxTime - minStartTime) * 0.001, valueViewport.minValue, valueViewport.maxValue, -10, 50);
        this.gl.uniformMatrix4fv(this.viewCameraMatrixUniform, false, this.cameraMatrix.data);

        for (let dataPair of this.meshes)
        {
            if (this.sourceDataEnabled[dataPair.data.sourceChannelId] === false)
            {
                continue;
            }

            for (let mesh of dataPair.meshes)
            {
                this.drawMesh(mesh, minStartTime);
            }
        }
    }
}