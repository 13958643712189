import cogoToast from 'cogo-toast';
import React from 'react';
import {Api} from '../../api/api';
import SetUpdateGroupState from '../../store/admin/setUpdateGroupState';
import monitoringStore from '../../store/monitoringStore';
import {Group, GroupId} from '../../store/monitoringStoreStates';
import ButtonGroup from '../buttonGroup';
import {ModalBase} from '../modalBase';
import './updateChannelTypeInfoModal.scss';

interface Props
{
    createNew: boolean;
    group: Group;
}

export default class UpdateGroupModal extends React.PureComponent<Props>
{
    private groupId: React.RefObject<HTMLInputElement>;
    private name: React.RefObject<HTMLInputElement>;
    private sortOrder: React.RefObject<HTMLInputElement>;

    constructor (props: Props)
    {
        super(props);

        this.groupId = React.createRef();
        this.name = React.createRef();
        this.sortOrder = React.createRef();
    }

    public render()
    {
        const { createNew, group } = this.props;

        return (
            <ModalBase isOpen={true}
                className="update-channel-info-modal"
                onRequestClose={() => this.close()}>

                <h1>{createNew ? 'Create' : 'Update'} Group</h1>
                <div>
                    <strong>Group Id: </strong>
                    { createNew ?
                        <input type="text" ref={this.groupId} data-rh='The group id code, once set it cannot be changed.' defaultValue={group.id} /> :
                        <span data-rh='The source id code.'>{group.id}</span>
                    }
                </div>
                <div>
                    <strong>Display Name: </strong>
                    <input type="text" ref={this.name} data-rh='Display name of the source.' defaultValue={group.name} />
                </div>

                <div>
                    <strong>Sort Order: </strong>
                    <input type="number" ref={this.sortOrder} data-rh='The sorting order for displaying this source. A higher number means higher up the list. Sources with the same will be sorted alphabetically by display name.' defaultValue={group.sortOrder.toString(10)} />
                </div>

                <div className="update-channel-info-modal__buttons">
                    <ButtonGroup>
                        <button onClick={() => this.update()}>{createNew ? 'Create' : 'Update'}</button>
                        <button onClick={() => this.remove()}>Delete</button>
                    </ButtonGroup>
                    <button onClick={() => this.close()}>Close</button>
                </div>

            </ModalBase>
        )
    }

    private close()
    {
        monitoringStore.execute(SetUpdateGroupState.close());
    }

    private update()
    {
        let id = this.props.group.id;
        const name = this.name.current.value.trim();
        const sortOrder = parseInt(this.sortOrder.current.value);

        if (name.length === 0 || isNaN(sortOrder))
        {
            cogoToast.warn('All fields need to be filled');
            return;
        }

        if (this.props.createNew)
        {
            id = this.groupId.current.value.trim() as GroupId;
            if (id.length === 0)
            {
                cogoToast.warn('Group Id needs to be filled');
                return;
            }
        }

        Api.updateGroup({id, name, sortOrder});
    }

    private remove()
    {
        const groupId = this.props.group.id;
        if (confirm ('Are you sure you want to remove this group?\n\nThis will only remove the metadata associated with this group and no signal data.'))
        {
            Api.removeGroup(groupId);
        }
    }
}