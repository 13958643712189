import {Modifier} from "simple-data-store";
import {DownloadCSVState, State} from "./monitoringStoreStates";
import moment = require("moment");

function modifyDownloadCSV(state: State, downloadCSVState: Partial<DownloadCSVState>): Partial<State>
{
    return {downloadCSVState: Object.assign({}, state.downloadCSVState, downloadCSVState)};
}

export default class SetDownloadCSVState
{
    public static show (start: moment.Moment, end: moment.Moment): Modifier<State>
    {
        return (state: State) => modifyDownloadCSV(state, {isOpen: true, start, end});
    }

    public static close (): Modifier<State>
    {
        return (state: State) => modifyDownloadCSV(state, {isOpen: false});
    }
}