import React, {CSSProperties} from 'react';
import './sliderCheckbox.scss';

type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>;
interface Props extends InputProps
{
    readonly thumbColour?: string;
    readonly mini?: boolean;
}

export default class SliderCheckbox extends React.PureComponent<Props>
{
    public render()
    {
        const { thumbColour, checked, mini } = this.props;
        const style: CSSProperties = checked && thumbColour ? {backgroundColor: thumbColour} : {}

        const inputProps = {...this.props};
        delete inputProps['thumbColour'];
        delete inputProps['mini'];

        return <label className={`slider-checkbox ${mini ? 'mini' : ''}`}>
                <input type='checkbox' {...inputProps} />
                <span style={style} className='slider-checkbox__thumb' />
            </label>
    }
}