import cogoToast from 'cogo-toast';
import React from 'react';
import {Api} from '../../api/api';
import SetUpdateSourceChannelInfo from '../../store/admin/setUpdateSourceChannelInfoState';
import monitoringStore from '../../store/monitoringStore';
import {ChannelTypeId, ChannelTypeInfoMap, SourceChannelId, SourceChannelInfoBase} from '../../store/monitoringStoreStates';
import ButtonGroup from '../buttonGroup';
import {ModalBase} from '../modalBase';
import './updateChannelTypeInfoModal.scss';

interface Props
{
    readonly createNew: boolean;
    readonly sourceChannelInfo: SourceChannelInfoBase;
    readonly channelTypeInfo: ChannelTypeInfoMap;
}

export default class UpdateSourceChannelInfoModal extends React.PureComponent<Props>
{
    private sourceChannel: React.RefObject<HTMLInputElement> = React.createRef();
    private mainName: React.RefObject<HTMLInputElement> = React.createRef();
    private subscriptName: React.RefObject<HTMLInputElement> = React.createRef();
    private channelType: React.RefObject<HTMLSelectElement> = React.createRef();
    private mainColour: React.RefObject<HTMLInputElement> = React.createRef();

    public render()
    {
        const { createNew, channelTypeInfo, sourceChannelInfo } = this.props;
        const channelInfos = Object.values(channelTypeInfo);

        return (
            <ModalBase isOpen={true}
                className="update-channel-info-modal"
                onRequestClose={this.close}>

                <h1>{createNew ? 'Create' : 'Update'} SourceChannelInfo</h1>
                <div>
                    <strong>Source Channel Code: </strong>
                    { createNew ?
                        <input type="text" ref={this.sourceChannel} data-rh='The source channel code, once set this cannot be changed.' defaultValue={sourceChannelInfo.sourceChannel} /> :
                        <span data-rh='The source channel code used to refer to this source channel.'>{sourceChannelInfo.sourceChannel}</span>
                    }
                </div>
                <div>
                    <strong>Main Name: </strong>
                    <input type="text" ref={this.mainName} data-rh='The main name for this source channel.' defaultValue={sourceChannelInfo.mainName} />
                </div>
                <div>
                    <strong>Subscript Name: </strong>
                    <input type="text" ref={this.subscriptName} data-rh='The subscript name is for displaying a 'defaultValue={sourceChannelInfo.subscriptName} />
                </div>
                <div>
                    <strong>Channel Type: </strong>
                    <select ref={this.channelType} data-rh='The channel type code for this source channel.' defaultValue={sourceChannelInfo.channelType}>
                    { channelInfos.map(c => <option key={c.channelType} value={c.channelType}>{c.displayName}</option>) }
                    </select>
                </div>
                <div>
                    <strong>Main Colour: </strong>
                    <input type="color" ref={this.mainColour} data-rh='The main display colour for this source, other min/max colours and RMS frequency colours are dynamically calculated from this colour as well.' defaultValue={sourceChannelInfo.mainColour} />
                </div>

                <div className="update-channel-info-modal__buttons">
                    <ButtonGroup>
                        <button onClick={this.update}>{createNew ? 'Create' : 'Update'}</button>
                        <button onClick={this.remove}>Delete</button>
                    </ButtonGroup>
                    <button onClick={() => this.close()}>Close</button>
                </div>

            </ModalBase>
        )
    }

    private close = () =>
    {
        monitoringStore.execute(SetUpdateSourceChannelInfo.close());
    }

    private update = () =>
    {
        let sourceChannel = this.props.sourceChannelInfo.sourceChannel;
        const mainName = this.mainName.current.value.trim();
        const subscriptName = this.subscriptName.current.value.trim();
        const channelType = this.channelType.current.value.trim() as ChannelTypeId;
        const mainColour = this.mainColour.current.value.trim();

        if (mainName.length === 0 || subscriptName.length === 0 || channelType.length === 0 || mainColour.length === 0)
        {
            cogoToast.warn('All fields need to be filled');
            return;
        }

        if (this.props.createNew)
        {
            sourceChannel = this.sourceChannel.current.value.trim() as SourceChannelId;
            if (sourceChannel.length === 0)
            {
                cogoToast.warn('Source channel type needs to be filled');
                return;
            }
        }

        Api.updateSourceChannelInfo({sourceChannel, mainName, subscriptName, channelType, mainColour});
    }

    private remove = () =>
    {
        const { sourceChannel } = this.props.sourceChannelInfo;
        if (confirm(`Are you sure you want to delete ${sourceChannel}?\nThis will only remove the metadata about this source channel, no signal data will be affected.`))
        {
            Api.removeSourceChannelInfo(sourceChannel);
        }
    }
}