import React from 'react';
import {Api} from '../../api/api';
import SetUpdateSourceState from '../../store/admin/setUpdateSourceState';
import monitoringStore from '../../store/monitoringStore';
import {FullSource, SourceId} from '../../store/monitoringStoreStates';
import AdminListView from './adminListViews';
import FullSourceView from './fullSourceView';

interface Props
{
    fullSources: FullSource[];
    unknownSources: SourceId[];
}

export default class FullSourceViews extends React.PureComponent<Props>
{
    public render()
    {
        return (
            <AdminListView
                title={<h4>Sources:</h4>}
                extraButtons={<>
                    <button onClick={this.showCreateAdd}>Add Source</button>
                    <button onClick={Api.listAllSources}>List Sources</button>
                    </>
                }>
                    {
                        this.props.fullSources.length === 0 ?
                            <div>
                                <strong>No sources</strong>
                            </div>
                            :
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Source Id</th>
                                        <th>Location</th>
                                        <th>Source Type</th>
                                        <th>Sort Order</th>
                                        <th>Group Ids</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderFullSources()}
                                </tbody>
                            </table>
                    }

                    {
                        this.props.unknownSources.length > 0 &&
                        <div>
                            <div><strong>Unknown Source Ids: </strong></div>
                            <div>{this.props.unknownSources.join(', ')}</div>
                        </div>
                    }
                </AdminListView>

        )
    }

    private renderFullSources()
    {
        return this.props.fullSources.map(
            s => <FullSourceView key={s.source.id} fullSource={s}/>);
    }

    private showCreateAdd()
    {
        monitoringStore.execute(SetUpdateSourceState.showCreate({
            source: {
                id: '' as SourceId,
                location: '',
                name: '',
                sourceType: 'signalbox',
                sortOrder: 0
            },
            groupIds: []
        }));
    }
}