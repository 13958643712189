function doNothing () {}

type MessageHandler = (json: any) => void;

export default class Socket
{
    public static onClosed: Function = doNothing;
    public static onReconnecting: Function = doNothing;
    public static onConnecting: Function = doNothing;
    public static onError: Function = doNothing;

    public static connect (hostname: string, useSecure: boolean, onConnected: Function, onProcessData: MessageHandler)
    {
        this.hostname = hostname;
        this.useSecure = useSecure;
        this.onConnected = onConnected;
        this.onProcessData = onProcessData;

        this.reconnect();
    }

    public static reconnect ()
    {
        if (!this.hostname)
        {
            throw new Error('Cannot reconnect before being connected');
        }

        this.clear();

        if (this.socket != null)
        {
            if (this.socket.readyState !== this.socket.OPEN)
            {
                this.doConnect();
            }
            else
            {
                this.socket.onclose = () => this.doConnect();
                this.socket.close();
            }
        }
        else
        {
            this.doConnect();
        }
    }

    public static sendData (type: string, data: any)
    {
        if (data.password)
        {
            const temp = {...data};
            delete temp['password'];
            console.debug('Message sent', type, temp);
        }
        else
        {
            console.debug('Message sent', type, data);
        }
        this.socket.send(JSON.stringify({type, data}));
    }

    private static socket: WebSocket;
    private static hostname: string;
    private static useSecure: boolean;
    private static reconnectTimeout: number;
    private static numberOfReconnects: number = 0;
    private static hasBeenConnected: boolean = false;
    private static timeBetweenReconnect: number = 2000;
    private static maxNumberOfReconnects: number = 20;
    private static onConnected: Function;
    private static onProcessData: MessageHandler;

    private static clear ()
    {
        this.hasBeenConnected = false;
        this.numberOfReconnects = 0;
        clearTimeout(this.reconnectTimeout);
    }

    private static doConnect ()
    {
        clearTimeout(this.reconnectTimeout);

        const protocol = this.useSecure ? 'wss' : 'ws';
        const uri = `${protocol}://${this.hostname}/ws`;
        console.log('Connecting to ws: ' + uri);

        if (this.numberOfReconnects > 0)
        {
            this.onReconnecting();
        }
        else
        {
            this.onConnecting();
        }

        this.socket = new WebSocket(uri);

        this.socket.onopen = (event) =>
        {
            this.hasBeenConnected = true;
            this.numberOfReconnects = 0;
            this.onConnected();
        };
        this.socket.onclose = (event) =>
        {
            this.socket.onclose = function (){};

            if (!this.hasBeenConnected)
            {
                this.onClosed();
            }
            else
            {
                this.onReconnecting();
                this.numberOfReconnects++;

                if (this.numberOfReconnects < this.maxNumberOfReconnects)
                {
                    this.reconnectTimeout = window.setTimeout(() => this.doConnect(), this.timeBetweenReconnect);
                }
                else
                {
                    this.onClosed();
                    console.warn('Too many reconnects');
                }
            }
        };
        this.socket.onmessage = (event) =>
        {
            const jsonData = JSON.parse(event.data);
            console.debug('Message data:', jsonData);
            this.onProcessData(jsonData);
        };
        this.socket.onerror = (event) =>
        {
            this.onError();
            console.error('Socket error:', event);
        };
    }

}
