import React, {MouseEvent} from 'react';
import 'react-tabs/style/react-tabs.css';
import 'whatwg-fetch'; // Polyfill for IE
import {Api} from './api/api';
import LoginAppState from './components/appStates/loginAppState';
import MainAppState from './components/appStates/mainAppState';
import DebugConsole from './components/debugConsole/debugConsole';
import Header from './components/header';
import HeaderMain from './components/headerMain';
import LoadingSpinner from './components/loadingSpinner';
import {ModalBase} from './components/modalBase';
import ReactHint from './components/tooltip/tooltip';
import {renderChartTooltipContentCharts, renderTooltipContent, renderTooltipContentLarge} from './components/tooltip/tooltipContentRenderers';
import monitoringStore from './store/monitoringStore';
import {State} from './store/monitoringStoreStates';
import ShowScriptVersionErrorModal from './store/showScriptVersionErrorModal';

interface Props
{
    state: State;
}

function onClickConnectIndicator (event: MouseEvent<HTMLElement>)
{
    if (monitoringStore.state().connectionState === 'closed')
    {
        Api.reconnect();
    }
}

export default class App extends React.Component<Props>
{
    public render ()
    {
        const state = this.props.state;

        return (
            <div className="app">
                <ReactHint autoPosition delay={{show: 100, hide: 100}} onRenderContent={renderTooltipContent} />
                <ReactHint autoPosition delay={{show: 100, hide: 100}} attribute="data-rh-large" onRenderContent={renderTooltipContentLarge} />
                <ReactHint autoPosition delay={{show: 100, hide: 100}} followMouseX attribute="data-rh-chart" onRenderContent={renderChartTooltipContentCharts} />
                <ReactHint autoPosition delay={{show: 100, hide: 100}} persist attribute="data-rh-persist" onRenderContent={renderTooltipContent} />

                {state.debugState.enabled ? <DebugConsole items={state.debugState.items} /> : null}

                { this.renderState() }

                <ModalBase isOpen={state.localState.showScriptVersionErrorModal}>
                    <h1>Reload page</h1>
                    The server has updated, please reload the page.
                    <button onClick={this.closeScriptVersionErrorModal}>Ignore</button>
                </ModalBase>
            </div>
        );
    }

    private closeScriptVersionErrorModal = () =>
    {
        monitoringStore.execute(ShowScriptVersionErrorModal.close());
    }

    private renderState ()
    {
        const { state } = this.props;
        const { loginState, serverState } = state;

        if (loginState.tokenStatus !== 'invalid' && loginState.loginStatus !== 'logged-in' && serverState.loginToken)
        {
            return <LoadingSpinner show={true} center={true}/>;
        }
        else if (loginState.loginStatus === 'logged-in')
        {
            return (<>
                    <HeaderMain
                        connectionStatus={state.connectionState}
                        onClickConnectIndicator={onClickConnectIndicator}
                        serverInfo={state.serverInfo}
                        localState={state.localState}
                        loginState={state.loginState}
                        serverState={state.serverState}
                        groups={state.groups}
                    />

                    <MainAppState state={this.props.state} />
                </>);
        }

        return (<>
                <Header
                    connectionStatus={state.connectionState}
                    onClickConnectIndicator={onClickConnectIndicator}
                    serverInfo={state.serverInfo}
                    localState={state.localState}
                />
                <LoginAppState loginState={loginState} />
            </>);

    }
}