import React, {CSSProperties} from 'react';
import {MilliSeconds} from '../../utils/timeTypes';
import {RenderContext} from './timelineCommon';

interface Props
{
    readonly renderContext: RenderContext;
}

export default class TimelineNow extends React.Component<Props>
{
    private interval: number;
    private boundUpdate: () => void;

    public constructor(props: Props)
    {
        super(props);

        this.boundUpdate = this.forceUpdate.bind(this);
    }

    public componentDidMount()
    {
        this.interval = window.setInterval(this.boundUpdate, 1000);
    }

    public componentWillUnmount()
    {
        clearInterval(this.interval);
    }

    public render()
    {
        const { renderContext } = this.props;

        const now = Date.now() as MilliSeconds;

        if (renderContext.isEmpty || renderContext.isTimeInView(now))
        {
            return null;
        }

        const xpos = renderContext.calculateXposFromTime(now, true);

        const style: CSSProperties = {
            transform: `translateX(${xpos}px)`
        }

        return <span className="timeline__now" style={style} data-rh="Now indicator" />;
    }
}