interface LocalConfig
{
    readonly port: number;
    readonly isRunningLocally: boolean;
    readonly scriptFilenames: string[];
    readonly useSecure: boolean;
}

const useSecure = !window.location.protocol || window.location.protocol === 'https:';
const isRunningLocally = window.location.hostname.indexOf('localhost') >= 0 || window.location.hostname.indexOf('192.168') >= 0;

let localConfig: LocalConfig = null;
function createLocalConfig(): LocalConfig
{
    const port = getPort(useSecure);
    const scriptFilenames = getScriptFilenames();

    return { port, scriptFilenames, useSecure, isRunningLocally };
}

function getPort(secure: boolean)
{
    const hostname = window.location.hostname;
    if (isRunningLocally)
    {
        return 1236;
    }

    if (hostname.indexOf('direct.') >= 0)
    {
        return secure ? 5001 : 5000;
    }

    return secure ? 443 : 80;
}

function getScriptFilenames(): string[]
{
    let scriptFilenames = ['local'];
    if (!isRunningLocally)
    {
        scriptFilenames = [];
        const scripts = document.querySelectorAll('script');
        for (let i = 0; i < scripts.length; i++)
        {
            let src: string = scripts[i].src;
            src = src.substr(src.lastIndexOf('/') + 1);

            scriptFilenames.push(src);
        }

        scriptFilenames.sort();
    }

    return scriptFilenames;
}

export function getLocalConfig()
{
    if (localConfig == null)
    {
        localConfig = createLocalConfig();
    }

    return localConfig;
}