import React from 'react';

interface Props
{
    hidden: boolean;
}

export default class CollapsibleList extends React.PureComponent<Props>
{
    public render()
    {
        if (this.props.hidden)
        {
            return null;
        }

        return this.props.children;
    }
}