import React from 'react';
import {SourceStatus, StatusLookupResult} from '../store/monitoringStoreStates';
import {formatDateTime} from '../utils/utils';

interface Props
{
    statusLookupResult: StatusLookupResult;
}

function invalidTemperature (temp: number)
{
    return temp < -98;
}

function getSqs (statuses: SourceStatus[])
{
    return 1000000 / (statuses[1].timestamp - statuses[0].timestamp);
}

export default class SourceDetailedCharts extends React.PureComponent<Props>
{
    public render ()
    {
        const { startDate, endDate } = this.props.statusLookupResult;
        const startDateString = formatDateTime(startDate);
        const endDateString = formatDateTime(endDate);

        return (
            <div>
                <div>
                    <strong>Data range: </strong>{startDateString}<strong> - </strong>{endDateString}
                </div>
            </div>
        );
    }

    // private getTemperatureData (statuses: SourceStatus[], tempData: number[]): DataSource
    // {
    //     if (tempData.every(invalidTemperature))
    //     {
    //         return null;
    //     }

    //     return {
    //         data: tempData,
    //         startTime: statuses[0].timestamp,
    //         samplesPerSecond: getSqs(statuses),
    //         colour: 'black',
    //         title: 'Unknown'
    //     };
    // }

    // private getCoreTemps (statuses: SourceStatus[])
    // {
    //     const coreTempsData = statuses.map((s) => s.systemLoadInfo.coreTemp);
    //     if (coreTempsData.every(invalidTemperature))
    //     {
    //         return null;
    //     }

    //     const partialResult = this.getTemperatureData(statuses, coreTempsData);
    //     return {...partialResult, colour: 'red', title: 'Core'};
    // }
    // private getAmbientTemps1 (statuses: SourceStatus[])
    // {
    //     const ambientTempsData = statuses.map((s) => s.systemLoadInfo.ambientTemp1);
    //     if (ambientTempsData.every(invalidTemperature))
    //     {
    //         return null;
    //     }

    //     const partialResult = this.getTemperatureData(statuses, ambientTempsData);
    //     return {...partialResult, colour: 'green', title: 'Ambient 1'};
    // }
    // private getAmbientTemps2 (statuses: SourceStatus[]): DataSource
    // {
    //     const ambientTempsData = statuses.map((s) => s.systemLoadInfo.ambientTemp2);
    //     if (ambientTempsData.every(invalidTemperature))
    //     {
    //         return null;
    //     }

    //     const partialResult = this.getTemperatureData(statuses, ambientTempsData);
    //     return {...partialResult, colour: 'blue', title: 'Ambient 2'};
    // }

    // private renderTemperatures ()
    // {
    //     const { statuses } = this.props.statusLookupResult;
    //     if (statuses.length < 2)
    //     {
    //         return null;
    //     }

    //     const dataSources: DataSource[] = [
    //         this.getCoreTemps(statuses), this.getAmbientTemps1(statuses), this.getAmbientTemps2(statuses)
    //     ].filter((d) => d != null);
    //     if (dataSources.length === 0)
    //     {
    //         return <span>No Temperature Data</span>;
    //     }

    //     return <LineChart
    //         title="Temperature"
    //         dataSources={dataSources}
    //         leftAxis="Temperature (C°)"
    //         unitPrefix="°"
    //         mouseOverPrecision={0}
    //         limitYRange={{min: 0, max: 80}}
    //         />;
    // }
}
