import React from 'react';
import HeaderSVG from '../img/MTMLogo.svg';
import {ConnectionState, ServerInfo} from '../store/monitoringStoreStates';
import {ClickHandler} from '../utils/commonTypes';
import {formatDateTime, toMoment} from '../utils/utils';
import ConnectionIndicator from './connectionIndicator';
import './header.scss';

export interface Props
{
    serverInfo: ServerInfo;
    connectionStatus: ConnectionState;
    onClickConnectIndicator?: ClickHandler;
    onClickLogo?: ClickHandler;
}

export default class HeaderBase extends React.Component<Props>
{
    public render ()
    {
        const serverStartTime = toMoment(this.props.serverInfo.serverStartTime);

        const tooltip = `Build number: ${this.props.serverInfo.buildNumber}
            Server Start Time: ${formatDateTime(serverStartTime)}`;

        return (
            <header className="header">
                <h1 className="header__title" data-rh={tooltip}>Monitoring Server</h1>
                <ConnectionIndicator status={this.props.connectionStatus} onClick={this.props.onClickConnectIndicator}/>

                { this.props.children }

                <span className="header__logo" onClick={this.props.onClickLogo} > { HeaderSVG() } </span>
            </header>
        );
    }
}
