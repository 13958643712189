import { WebGLAxis, WebGLDataMap, WebGLDataSeries } from "../components/webglChart/webglChartStore";
import { ChannelTypeInfo, SourceChannelId, SourceChannelToWebGLDataSourceMap, SourceToSourceChannelToWebGLDataSourceMap, TimeSpan } from "../store/monitoringStoreStates";
import CachedValue from "./cachedValue";
import { Editable } from "./commonTypes";
import { addMicro, MicroSeconds, microToMoment, microToSeconds, Seconds, secondsToMicro, subMicro, ZeroMicro } from "./timeTypes";
import { groupBy } from "./utils";
import moment = require("moment");

const CombineThresholdUpper = secondsToMicro(0.1 as Seconds);
const CombineThresholdLower = secondsToMicro(-0.1 as Seconds);

export function combineSourceDataSourcesWebGL(input: SourceToSourceChannelToWebGLDataSourceMap, trimLengths: boolean, trimCutoffTime: MicroSeconds): SourceToSourceChannelToWebGLDataSourceMap
{
    const result: Editable<SourceToSourceChannelToWebGLDataSourceMap> = {};

    for (const sourceIdString in input)
    {
        const channelDataSources = input[sourceIdString];

        result[sourceIdString] = combineDataSourcesWebGL(channelDataSources, trimLengths, trimCutoffTime);
    }

    return result;
}

export function combineDataSourcesWebGL(channelDataSources: SourceChannelToWebGLDataSourceMap, trimLengths: boolean, trimCutoffTime: MicroSeconds)
{
    const newChannelDataSources: Editable<SourceChannelToWebGLDataSourceMap> = {};
    const mostRecentTime = getMostRecentTimeWebGL(channelDataSources);

    for (const sourceChannelString in channelDataSources)
    {
        const dataSources = channelDataSources[sourceChannelString];
        if (dataSources.length < 2)
        {
            newChannelDataSources[sourceChannelString] = dataSources;
            continue;
        }

        const newDataSources: WebGLDataSeries[] = [];
        newChannelDataSources[sourceChannelString] = newDataSources;

        const groupedDataSources = groupBy(dataSources, (ds) => ds.title);

        for (const key in groupedDataSources)
        {
            const group = groupedDataSources[key];
            group.sort((x, y) => x.startTime - y.startTime);

            let currentDataSource: Editable<WebGLDataSeries> = group[0];
            for (let i = 1; i < group.length; i++)
            {
                const dataSource = group[i];
                const diff = dataSource.startTime - group[i - 1].endTime;
                if (diff > CombineThresholdUpper || diff < CombineThresholdLower)
                {
                    newDataSources.push(currentDataSource);
                    currentDataSource = dataSource;

                    continue;
                }

                currentDataSource = combineDataSourceWebGL(currentDataSource, dataSource);

                if (trimLengths)
                {
                    const pastCutoffTime = (mostRecentTime - currentDataSource.startTime - trimCutoffTime) as MicroSeconds;
                    if (pastCutoffTime > 0)
                    {
                        const numberOfSamples = microToSeconds(pastCutoffTime) * currentDataSource.sps;
                        currentDataSource.startTime = addMicro(currentDataSource.startTime, pastCutoffTime);

                        for (const key in currentDataSource.data)
                        {
                            currentDataSource.data[key].splice(0, numberOfSamples);
                        }
                    }
                }
            }

            if (newDataSources.lastIndexOf(currentDataSource) < 0)
            {
                newDataSources.push(currentDataSource);
            }
        }
    }

    return newChannelDataSources;
}

export function combineDataSourceWebGL(dataSource1: WebGLDataSeries, dataSource2: WebGLDataSeries): WebGLDataSeries
{
    const newData: Editable<WebGLDataMap> = Object.assign({}, dataSource1.data);
    let dataLength = -1;
    for (const key in dataSource2.data)
    {
        const firstData = newData[key];
        const secondData = dataSource2.data[key];

        if (!firstData || !secondData)
        {
            continue;
        }

        newData[key] = [...firstData, ...secondData];

        if (dataLength >= 0 && newData[key].length !== dataLength)
        {
            console.error('Mismatch in length of combined new data!');
        }
        else
        {
            dataLength = newData[key].length;
        }
    }

    const result = {
        ...dataSource1,
        startTime: Math.min(dataSource1.startTime, dataSource2.startTime) as MicroSeconds,
        endTime: Math.max(dataSource1.endTime, dataSource2.endTime) as MicroSeconds,
        data: newData,
        dataLength
    };

    return result;
}

function getMostRecentTimeWebGL(dataSources: SourceChannelToWebGLDataSourceMap)
{
    let mostRecentTime = ZeroMicro;
    for (const sourceChannelType in dataSources)
    {
        if (dataSources[sourceChannelType])
        {
            for (const ds of dataSources[sourceChannelType])
            {
                mostRecentTime = mostRecentTime > ds.startTime ? mostRecentTime : ds.startTime;
            }
        }
    }
    return mostRecentTime;
}

export const CachedLeftAxis = new CachedValue<ChannelTypeInfo, WebGLAxis>((channelType) =>
{
    return {
        enabled: true,
        label: channelType.displayName,
        unit: channelType.prefix,
        isRMS: false
    }
});

export function expandTimeSpan(timespan: TimeSpan, time: moment.Moment): TimeSpan
{
    if (time.valueOf() > timespan.end.valueOf())
    {
        return {start: timespan.start, end: time}
    }

    if (time.valueOf() < timespan.start.valueOf())
    {
        return {start: time, end: timespan.end}
    }

    return timespan;
}

export function expandTimeSpanOverSeries(timespan: TimeSpan, webglDataSeries: WebGLDataSeries[])
{
    for (let dataSeries of webglDataSeries)
    {
        timespan = expandTimeSpan(timespan, microToMoment(dataSeries.startTime));
        timespan = expandTimeSpan(timespan, microToMoment(dataSeries.endTime));
    }

    return timespan;
}

export function addSourceChannels(set: Set<SourceChannelId>, webglDataSeries: WebGLDataSeries[])
{
    for (let dataSeries of webglDataSeries)
    {
        set.add(dataSeries.sourceChannelId);
    }
}

export function trimWebGLDataSeries(dataSeries: WebGLDataSeries, cutofftime: MicroSeconds): WebGLDataSeries
{
    const newStartTime = subMicro(dataSeries.endTime, cutofftime);
    if (newStartTime < dataSeries.startTime)
    {
        return dataSeries;
    }

    const timeDiff = subMicro(newStartTime, dataSeries.startTime);
    const offsetIndex = Math.floor(microToSeconds(timeDiff) * dataSeries.sps);
    const newDataMap: Editable<WebGLDataMap> = {};
    let newDataLength = 0;
    for (const key in dataSeries.data)
    {
        const currentData = dataSeries.data[key];
        const newData = currentData.slice(offsetIndex);
        newDataMap[key] = newData;
        newDataLength = newData.length;
    }

    return {
        ...dataSeries,
        data: newDataMap,
        dataLength: newDataLength,
        startTime: newStartTime
    }
}
