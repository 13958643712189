import React, {ChangeEvent} from 'react';
import {Group, GroupId, UnknownGroupId} from '../store/monitoringStoreStates';
import './groupsView.scss';

type GroupChangeHandler = (groupId: GroupId) => void;
interface Props
{
    readonly selectedGroupId: GroupId;
    readonly groups: Group[];
    readonly onGroupChange: GroupChangeHandler;
}

export default class GroupsView extends React.PureComponent<Props>
{
    public render ()
    {
        const value = this.props.selectedGroupId || 0;

        return (
            <span className="groups-view">
                <select className="groups-view__select" onChange={this.onSelectChange} value={value.toString()}>
                    <option value={UnknownGroupId}>Select a group</option>
                    {this.props.groups.map((group) =>
                        <option key={group.id} value={group.id}>{group.name}</option>
                    )}
                </select>
            </span>
        );
    }

    private onSelectChange = (event: ChangeEvent<HTMLSelectElement>) =>
    {
        let groupId = event.target.value as GroupId;
        if (!groupId)
        {
            groupId = UnknownGroupId;
        }

        this.props.onGroupChange(groupId);
    }
}
