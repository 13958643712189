import {Modifier} from "simple-data-store";
import {modifyAdminState} from "../monitoringStore";
import {ReprocessEventJob, ReprocessEventsState, State} from "../monitoringStoreStates";
import moment = require("moment");

function modifyReprocessEvents(state: State, reprocessEvent: Partial<ReprocessEventsState>): Partial<State>
{
    return modifyAdminState(state, {reprocessEventsState:
        {...state.adminState.reprocessEventsState, ...reprocessEvent}
    });
}

export default class ShowReprocessEventsModal
{
    public static show (start: moment.Moment, end: moment.Moment): Modifier<State>
    {
        return (state: State) => modifyReprocessEvents(state, {isOpen: true, start, end});
    }

    public static start (start: moment.Moment): Modifier<State>
    {
        return (state: State) => modifyReprocessEvents(state, {start});
    }

    public static end (end: moment.Moment): Modifier<State>
    {
        return (state: State) => modifyReprocessEvents(state, {end});
    }

    public static close (): Modifier<State>
    {
        return (state: State) => modifyReprocessEvents(state, {isOpen: false});
    }

    public static updateState (updateState?: ReprocessEventJob)
    {
        return (state: State) => modifyReprocessEvents(state, {updateState});
    }
}