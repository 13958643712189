import React, {CSSProperties} from 'react';

interface Props
{
    className?: string;
    activeValue?: string;
    disabled?: boolean;
    style?: CSSProperties;
}

export default class ButtonGroup extends React.PureComponent<Props>
{
    public render()
    {
        return <div className={`button-group ${this.props.className || ''} ${this.props.disabled ? 'disabled' : ''}`} style={this.props.style}>
                {this.props.activeValue === undefined ? this.props.children : this.renderChildren()}
            </div>;
    }

    private renderChildren()
    {
        return React.Children.map(this.props.children, (child: React.ReactElement<any>) => this.renderChild(child));
    }
    private renderChild(child: React.ReactElement<any>)
    {
        if (child.props['data-value'] === this.props.activeValue)
        {
            return React.cloneElement(child, {
                className: (child.props['className'] || '') + ' button-active'
            });
        }
        return child;
    }
}