import {hexToRgbaFloat} from "../../utils/utils";
import {WebGLColour} from "./webglChartStore";

export default class WebGLMesh
{
    public readonly buffer: WebGLBuffer;
    public readonly mode: GLenum;
    public readonly length: number;
    public readonly colour: number[];
    public readonly pointSize: number;
    public offset: number = 0;

    constructor (buffer: WebGLBuffer, mode: GLenum, length: number, colour: WebGLColour, pointSize: number)
    {
        this.buffer = buffer;
        this.mode = mode;
        this.length = length;
        this.colour = typeof(colour) === 'string' ? hexToRgbaFloat(colour) : colour;
        this.pointSize = pointSize;
    }
}